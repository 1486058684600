import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { COLORS } from 'constants/colors';
import React from 'react';
import moment from 'moment';
import PrintHeader from 'views/components/PrintHeader';
import { useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';

const PrintBillingList = React.forwardRef((props, ref) => {
    const { user } = useSelector((state) => state?.user);
    var decoded = jwt_decode(user?.token);

    const { billingList, startDate, endDate, isPaid } = props;
    return (
        <div ref={ref} style={{ padding: 50 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <PrintHeader />
                    <Typography sx={{ fontSize: 40, fontWeight: 'bold', marginLeft: 3 }}>{decoded?.Name}</Typography>
                </Box>
                <Typography>Date: {moment(new Date()).format('MMM DD, YYYY - hh:mm A')}</Typography>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2, border: '2px solid black', my: 3 }}>
                <Typography>From: {moment(startDate).format('MMM DD, YYYY')}</Typography>
                <Typography>To: {moment(endDate).format('MMM DD, YYYY')}</Typography>
                <Typography>Status: {isPaid == 'all' ? 'All' : isPaid == true ? 'Paid' : 'Un-Paid'}</Typography>
            </Box>

            <TableContainer sx={{ maxHeight: 'auto' }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow
                            sx={{
                                '& th': {
                                    color: '#fff',
                                    backgroundColor: COLORS.secondory
                                }
                            }}
                        >
                            <TableCell align={'left'}>ID</TableCell>

                            <TableCell align={'left'}>Patient Name</TableCell>

                            <TableCell align={'left'}>Physician Name</TableCell>

                            <TableCell align={'center'}>Bill Date</TableCell>

                            <TableCell align={'right'}>Amount</TableCell>

                            <TableCell align={'center'}>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!!billingList && (
                            <>
                                {billingList?.items?.map((row) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.id}
                                            sx={{ '&:hover': { cursor: 'pointer' } }}
                                        >
                                            <TableCell align={'left'}>{row?.billId}</TableCell>

                                            <TableCell align={'left'} sx={{ textTransform: 'capitalize' }}>
                                                {row?.patientName?.toLowerCase()}
                                            </TableCell>

                                            <TableCell align={'left'} sx={{ textTransform: 'capitalize' }}>
                                                {row?.consultantName?.split('/')[0]?.toLowerCase()}
                                            </TableCell>

                                            <TableCell align={'center'}>
                                                {moment(row?.billDate).format('MMM DD, YYYY  -  hh:mm A')}
                                            </TableCell>

                                            <TableCell align={'right'}>{row?.netAmount?.toFixed(2)}</TableCell>

                                            <TableCell align={'center'}>{row?.status}</TableCell>
                                        </TableRow>
                                    );
                                })}
                                <TableRow sx={{ backgroundColor: '#e5e5e5' }}>
                                    <TableCell />
                                    <TableCell />
                                    <TableCell />
                                    <TableCell align={'right'} sx={{ fontWeight: 'bold' }}>
                                        Total
                                    </TableCell>
                                    <TableCell align={'right'} sx={{ fontWeight: 'bold' }}>
                                        {billingList?.items?.reduce((netAmount, bill) => netAmount + bill?.netAmount, 0)?.toFixed(2)}
                                    </TableCell>
                                    <TableCell />
                                </TableRow>
                            </>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
});

export default PrintBillingList;
