import { useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Button, CircularProgress, FormControl, FormHelperText, InputLabel, OutlinedInput } from '@mui/material';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project imports
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';

import { COLORS } from 'constants/colors';

import { getToken } from 'services/getToken';
import axios from 'axios';
import { BASE_URL } from 'constants/baseUrl';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router';

// ============================|| FIREBASE - LOGIN ||============================ //

const AuthAccount = ({ ...others }) => {
    const theme = useTheme();
    const scriptedRef = useScriptRef();

    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const handleAccount = async (email, domain) => {
        setLoading(true);
        try {
            console.log(email, domain);

            const resAccountForgot = await axios({
                method: 'post',
                url: `${BASE_URL}api/accounts/ForgetPassword`,
                data: {
                    Email: email,
                    Domain: domain
                }
            });

            if (resAccountForgot?.data) {
                navigate('reset-password', { state: { email, domain } });
            }
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.response?.data ?? error?.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Formik
                initialValues={{
                    email: '',
                    domain: '',
                    submit: null
                }}
                validationSchema={Yup.object().shape({
                    email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                    domain: Yup.string().max(255).required('Domain is required')
                })}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    try {
                        if (scriptedRef.current) {
                            setStatus({ success: true });
                            setSubmitting(false);

                            const { email, domain } = values;
                            handleAccount(email.trim().toLowerCase(), domain.trim().toLowerCase());
                        }
                    } catch (err) {
                        console.error(err);
                        if (scriptedRef.current) {
                            setStatus({ success: false });
                            setErrors({ submit: err.message });
                            setSubmitting(false);
                        }
                    }
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
                    <form noValidate onSubmit={handleSubmit} {...others}>
                        <FormControl fullWidth error={Boolean(touched.email && errors.email)} sx={{ ...theme.typography.customInput }}>
                            <InputLabel htmlFor="outlined-adornment-email-login">Email Address</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-email-login"
                                type="email"
                                value={values.email}
                                name="email"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                label="Email Address"
                                inputProps={{}}
                            />
                            {touched.email && errors.email && (
                                <FormHelperText error id="standard-weight-helper-text-email-login">
                                    {errors.email}
                                </FormHelperText>
                            )}
                        </FormControl>

                        <FormControl fullWidth error={Boolean(touched.domain && errors.domain)} sx={{ ...theme.typography.customInput }}>
                            <InputLabel htmlFor="outlined-adornment-domain-login">Domain</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-domain-login"
                                type="text"
                                value={values.domain}
                                name="domain"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                label="Domain"
                                inputProps={{}}
                            />
                            {touched.domain && errors.domain && (
                                <FormHelperText error id="standard-weight-helper-text-domain-login">
                                    {errors.domain}
                                </FormHelperText>
                            )}
                        </FormControl>
                        {errors.submit && (
                            <Box sx={{ mt: 3 }}>
                                <FormHelperText error>{errors.submit}</FormHelperText>
                            </Box>
                        )}

                        <Box sx={{ mt: 2 }}>
                            <AnimateButton>
                                <Button
                                    disableElevation
                                    disabled={loading}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    sx={{
                                        backgroundColor: COLORS.primary
                                    }}
                                >
                                    {loading ? <CircularProgress size={25} color="inherit" /> : 'Submit'}
                                </Button>
                            </AnimateButton>
                        </Box>
                    </form>
                )}
            </Formik>
        </>
    );
};

export default AuthAccount;
