import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { COLORS } from 'constants/colors';
import React from 'react';
import PrintHeader from 'views/components/PrintHeader';
import moment from 'moment';

const PrintPaymentList = React.forwardRef((props, ref) => {
    const { paymentVisits, amount } = props;
    return (
        <div ref={ref} style={{ padding: 50 }}>
            <PrintHeader />

            <Box sx={{ border: '2px solid black', p: 1, my: 2, display: 'flex', justifyContent: 'space-between' }}>
                <Typography>Total Amount: {amount?.total}</Typography>
                <Typography>Hospital Share: {amount?.total - amount?.physician}</Typography>
                <Typography>Physician Share: {amount?.physician}</Typography>
            </Box>

            <TableContainer sx={{ maxHeight: 'auto' }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow
                            sx={{
                                '& th': {
                                    color: '#fff',
                                    backgroundColor: COLORS.secondory
                                }
                            }}
                        >
                            <TableCell align={'left'}>ID</TableCell>

                            <TableCell align={'left'}>Patient Name</TableCell>

                            <TableCell align={'left'}>Physician Name</TableCell>

                            <TableCell align={'left'}>Meeting Type</TableCell>

                            <TableCell align={'left'}>Date Time</TableCell>

                            <TableCell align={'left'}>Summary Notes</TableCell>

                            <TableCell align={'left'}>Fee</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!!paymentVisits &&
                            paymentVisits?.visits?.map((row) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id} sx={{ '&:hover': { cursor: 'pointer' } }}>
                                        <TableCell align={'left'}>{row?.id}</TableCell>

                                        <TableCell align={'left'} sx={{ textTransform: 'capitalize' }}>
                                            {row?.patientName?.toLowerCase()}
                                        </TableCell>

                                        <TableCell align={'left'} sx={{ textTransform: 'capitalize' }}>
                                            {row?.consultantName?.toLowerCase()}
                                        </TableCell>

                                        <TableCell align={'left'}>{row?.meetingtype}</TableCell>

                                        <TableCell align={'left'}>
                                            {moment(row?.startDateTime).format('MMM DD, YYYY  -  hh:mm A')}
                                        </TableCell>

                                        <TableCell align={'left'}>{row?.title}</TableCell>

                                        <TableCell align={'left'}>{row?.amount}</TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
});

export default PrintPaymentList;
