import axios from 'axios';
import { BASE_URL } from 'constants/baseUrl';

export const uploadDiagnosis = async (selectedAllList, visit, user) => {
    try {
        let diagnosis = selectedAllList?.filter((i) => i?.table == 'diagnosis');

        if (diagnosis?.length > 0) {
            let refineDiagnosis = diagnosis?.map((i) => ({
                Consultant_NationalID: visit.consultant_NationalID,
                Patient_NationalID: visit.patient_NationalID,
                code: i?.code,
                VisitID: visit.id,
                createdBy: user.userId,
                createdOn: new Date()
            }));

            let medRes = null;

            for (let i = 0; i < refineDiagnosis.length; i++) {
                medRes = await axios.post(`${BASE_URL}api/patientdiagnostic`, refineDiagnosis[i], {
                    headers: {
                        Authorization: `Bearer ${user?.token}`,
                        'Content-Type': 'application/json'
                    }
                });
            }

            if (medRes.data) {
                return medRes?.data;
            }
        }
    } catch (error) {
        throw error;
    }
};

export const uploadPrescription = async (selectedAllList, visit, user) => {
    try {
        let medicines = selectedAllList?.filter((i) => i?.table == 'medicine');

        if (medicines?.length > 0) {
            let refinePrescription = medicines?.map((i) => ({
                VisitID: visit?.id,
                Consultant_NationalID: visit?.consultant_NationalID,
                Patient_NationalID: visit?.patient_NationalID,
                MedicineID: i?.medicineID,
                RefilDetails: null,
                dose: null,
                doseUnit: null,
                route: null,
                direction: null,
                duration: null,
                frequency: null,
                durationUnit: null,
                isDeleted: false,
                status: 'Continued',
                dosageInstruction: i?.instruction,
                createdOn: new Date().toLocaleString(),
                createdBy: user.userId
            }));

            let medRes = null;

            for (let i = 0; i < refinePrescription.length; i++) {
                medRes = await axios.post(`${BASE_URL}api/prescription`, refinePrescription[i], {
                    headers: {
                        Authorization: `Bearer ${user?.token}`,
                        'Content-Type': 'application/json'
                    }
                });
            }

            if (medRes.data) {
                return medRes?.data;
            }
        }
    } catch (error) {
        throw error;
    }
};

export const uploadLabTest = async (selectedAllList, visit, user) => {
    try {
        let labTest = selectedAllList?.filter((i) => i?.table == 'test');

        if (labTest?.length > 0) {
            let refineLabTest = labTest?.map((i) => ({
                Consultant_NationalID: visit?.consultant_NationalID,
                LabTestTypeID: i?.labTestTypeID,
                Patient_NationalID: visit?.patient_NationalID,
                VisitID: visit?.id,
                CreatedOn: new Date(),
                CreatedBy: user?.userId,
                notes: i?.instruction
            }));

            let medRes = null;

            for (let i = 0; i < refineLabTest.length; i++) {
                medRes = await axios.post(`${BASE_URL}api/VisitLabTest`, refineLabTest[i], {
                    headers: {
                        Authorization: `Bearer ${user?.token}`,
                        'Content-Type': 'application/json'
                    }
                });
            }

            if (medRes.data) {
                return medRes?.data;
            }
        }
    } catch (error) {
        throw error;
    }
};
