import { Link, useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Button, Grid, Stack, Typography, useMediaQuery } from '@mui/material';

// project imports
import logo from '../../../assets/images/cloud-clinic-logo.png';
import { COLORS } from 'constants/colors';
import AuthCardWrapper from '../AuthCardWrapper';
import CheckIcon from '@mui/icons-material/Check';

// ================================|| AUTH3 - LOGIN ||================================ //

const Success = () => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();

    return (
        <AuthCardWrapper>
            <Grid container spacing={2} alignItems="center" justifyContent="center">
                <Grid item sx={{ mb: 3 }}>
                    <Link to="#">
                        <img src={logo} alt="CloudClinicLogo" width="150" />
                    </Link>
                </Grid>
                <Grid item xs={12}>
                    <Grid container direction={matchDownSM ? 'column-reverse' : 'row'} alignItems="center" justifyContent="center">
                        <Grid item>
                            <Stack alignItems="center" justifyContent="center" spacing={1}>
                                <Box
                                    sx={{
                                        height: 70,
                                        width: 70,
                                        border: `5px solid ${COLORS.primary}`,
                                        borderRadius: 30,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        mt: -2,
                                        mb: 1
                                    }}
                                >
                                    <CheckIcon sx={{ fontSize: 40, color: COLORS.primary }} />
                                </Box>
                                <Typography color={COLORS.primary} gutterBottom variant={matchDownSM ? 'h3' : 'h2'}>
                                    Success!
                                </Typography>
                                <Typography
                                    variant="caption"
                                    fontSize="16px"
                                    sx={{ textAlign: 'center' }}
                                    textAlign={matchDownSM ? 'center' : 'inherit'}
                                >
                                    Account has been successfully created in Cloud Clinic. We have sended login credentials to your email
                                </Typography>

                                <Button onClick={() => navigate(-1)} variant="contained" size="large" sx={{ borderRadius: 50 }}>
                                    Login
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </AuthCardWrapper>
    );
};

export default Success;
