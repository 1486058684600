import React from 'react';

import { Box, Typography } from '@mui/material';
import moment from 'moment';
import { styles } from './styles';
import { LoadingButton } from '@mui/lab';

const DiagnosisCard = ({ diagnosis, showBtn, btnTitle, onClick, btnColor, loading }) => {
    const { code, name, physicianName, createdOn } = diagnosis;

    return (
        <Box sx={styles.prescriptionCard}>
            <Box sx={{ pr: 1 }}>
                <Typography sx={{ fontSize: 16, fontWeight: 600 }}>{`${code} : ${name}`}</Typography>
                <Box sx={{ display: 'flex', mt: 1 }}>
                    <Typography sx={{ fontWeight: '600', mr: 1, color: '#444444' }}>Diagnosed by: </Typography>
                    <Typography>{physicianName}</Typography>
                </Box>

                <Box sx={{ display: 'flex' }}>
                    <Typography sx={{ fontWeight: '600', mr: 1, color: '#444444' }}>Diagnosed on: </Typography>
                    <Typography>{moment(createdOn).format('MMM DD, YYYY')}</Typography>
                </Box>
            </Box>

            <Box>
                {showBtn && (
                    <LoadingButton
                        loading={loading}
                        onClick={onClick}
                        size="small"
                        variant="contained"
                        sx={{
                            float: 'right',
                            backgroundColor: btnColor,
                            '&:hover': {
                                backgroundColor: btnColor
                            }
                        }}
                    >
                        {btnTitle}
                    </LoadingButton>
                )}
            </Box>
        </Box>
    );
};

export default DiagnosisCard;
