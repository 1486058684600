// this function is used to separate all visits of each physician and that data
// will display on header of calender shown as buttons

export const getVisitPhysician = (data) => {
    const mergedData = data.reduce((acc, item) => {
        const existingItem = acc.find((obj) => obj.nationalID === item.consultant_NationalID);
        if (existingItem) {
            existingItem?.list?.push(item);
        } else {
            acc.push({
                name: item?.consultantName?.split('/')[0],
                nationalID: item?.consultant_NationalID,
                colorCode: item?.hexColor ?? 'gray'
            });
        }
        return acc;
    }, []);
    return mergedData;
};

// this function is used to create slot of excel data, in excel data we will provide start date and end date, to used those date
// it will create slots of any duration like 15, 30 etc

export const createSlots = (excelData, duration) => {
    const slotsArray = [];

    for (let row of excelData) {
        let current = new Date(row?.Start_time); // get start date from excel file
        while (current < new Date(row?.End_time)) {
            // it will loop until start date is less then end date
            let slotStart = new Date(current);
            current.setMinutes(current.getMinutes() + duration); // add 15 minutes in current date
            slotsArray.push({ startDateTime: slotStart, endDateTime: new Date(current) });
        }
    }

    return slotsArray;
};

// This function is used to transform visit json to our required type of JSON so
// we can show that it in calender and other components like print etc.

export const transformToCalendarJSON = async (data) => {
    try {
        let newArr = [];
        data?.map((item) => {
            let visitObj = {};
            visitObj.id = item.id;
            visitObj.start = new Date(item?.startDateTime);
            visitObj.end = new Date(item?.endDateTime);
            visitObj.title = item.title;
            visitObj.summaryNotes = item.summaryNotes;
            visitObj.amount = item.amount;
            visitObj.hexColor = item.colorCode;
            visitObj.consultant_NationalID = item.consultant_NationalID;
            visitObj.patient_NationalID = item?.patient_NationalID;
            visitObj.meetingtype = item?.meetingtype;
            visitObj.locationID = item?.locationID;
            visitObj.isCampOn = item?.isCampOn;
            visitObj.startDateTime = item?.startDateTime; // this and below other variables are only use for print RX form
            visitObj.patientFirstName = item?.patientFirstName;
            visitObj.patientLastName = item?.patientLastName;
            visitObj.patientGender = item?.patientGender;
            visitObj.patientAge = item?.patientAge;
            visitObj.consultantName = item?.consultantName;
            visitObj.doctorSpeciality = item?.doctorSpeciality;
            visitObj.consultantDegree = item?.consultantDegree;
            if (item.isDeleted === false) {
                newArr.push(visitObj);
            }
        });

        return newArr;
    } catch (error) {
        return [];
    }
};
