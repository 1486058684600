import { memo, useState } from 'react';
import { Box, Button, Card, Collapse, Fade, Typography } from '@mui/material';
import BiotechIcon from '@mui/icons-material/Biotech';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import IosShareIcon from '@mui/icons-material/IosShare';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import ScienceIcon from '@mui/icons-material/Science';
import moment from 'moment';
import CircleIcon from '@mui/icons-material/Circle';
import { COLORS } from 'constants/colors';
import { BASE_URL } from 'constants/baseUrl';

const PreviousVisitCard = ({
    physicianName,
    physicianImage,
    physicianSpeciality,
    physicianFee,
    visitId,
    visitTitle,
    visitDateTime,
    visitSummary,
    initialComplain,
    notes,
    referredTo,
    healthCareProvider,
    domain,
    isShared,
    showSharedBtn,
    onHistoryClick = () => {},
    onVitalClick = () => {},
    onLabReportClick = () => {},
    onPrescriptionClick = () => {},
    onDiagnosisClick = () => {},
    onLabTestClick = () => {},
    onShareClick = () => {}
}) => {
    const [isHovered, setIsHovered] = useState({ hovered: false, visitId: null });

    return (
        <Card
            sx={styles.mainContainer1}
            onMouseEnter={() => setIsHovered({ hovered: true, visitId: visitId })}
            onMouseLeave={() => setIsHovered({ hovered: false, visitId: null })}
        >
            <Box sx={[styles.mainContainer2, { position: 'relative' }]}>
                <Box sx={{ display: 'flex', width: 250, alignItems: 'center', gap: 1 }}>
                    <img
                        src={`${BASE_URL}media/${domain}/Profile/${physicianImage}`}
                        alt={'physician pic'}
                        style={{
                            width: 50,
                            height: 50,
                            borderRadius: 15,
                            backgroundColor: '#d5d5d5',
                            display: 'inline-block'
                        }}
                    />

                    <Box>
                        <Typography color={'#0098d9'} variant="h3">
                            {physicianName}
                        </Typography>
                        <Typography variant="subtitle2">{physicianSpeciality}</Typography>
                    </Box>
                </Box>

                {/* ------------------ Show When hover */}
                {isHovered?.hovered && isHovered?.visitId == visitId && (
                    <Fade in={isHovered?.hovered && isHovered?.visitId == visitId} timeout={500}>
                        <Typography sx={{ alignSelf: 'flex-end', p: 1, width: 90 }}>Fee: {physicianFee}</Typography>
                    </Fade>
                )}

                {/* ------------------ Hide When hover */}
                <Fade in={!(isHovered?.hovered && isHovered?.visitId == visitId)} timeout={500}>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <Box sx={[styles.colorBox1, { backgroundColor: '#dee8f4' }]}>
                            <TextWithTitle title={'Visit ID'} text={visitId} />
                            <TextWithTitle title={'Visit Date Time'} text={moment(visitDateTime).format('MMM DD, YYYY - hh:mm A')} />
                        </Box>

                        <Box sx={styles.colorBox1}>
                            <TextWithTitle title={'Visit Title'} text={visitTitle || 'N/A'} />
                        </Box>
                    </Box>
                </Fade>

                {healthCareProvider && <TextWithTitle title={'Healthcare Provider'} text={healthCareProvider || 'N/A'} />}

                {isShared && <CircleIcon titleAccess="Shared" sx={{ position: 'absolute', right: 0, color: 'green' }} />}
            </Box>

            {/* ----------------------- Show When Hover */}
            <Collapse in={isHovered?.hovered == true && isHovered?.visitId == visitId} timeout={500}>
                <Box>
                    <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
                        <Box sx={[styles.colorBox, { backgroundColor: '#dee8f4' }]}>
                            <Box sx={{ display: 'flex' }}>
                                <TextWithTitle title={'Visit ID'} text={visitId} sx={{ width: '30%' }} />
                                <TextWithTitle title={'Visit Date Time'} text={moment(visitDateTime).format('MMM DD, YYYY - hh:mm A')} />
                            </Box>

                            <TextWithTitle title={'Visit Title'} text={visitTitle || 'N/A'} />
                            <TextWithTitle title={'Visit Summary'} text={visitSummary || 'N/A'} />
                        </Box>

                        <Box sx={styles.colorBox}>
                            <TextWithTitle title={'Diagnosis and Plan'} text={initialComplain || 'N/A'} />
                            <TextWithTitle title={'Notes'} text={notes || 'N/A'} />
                            <TextWithTitle title={'Referred to'} text={referredTo || 'N/A'} />
                        </Box>
                    </Box>

                    <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
                        <Button onClick={onHistoryClick} size={'small'} variant="outlined" startIcon={<HistoryEduIcon />}>
                            History
                        </Button>

                        <Button onClick={onVitalClick} size={'small'} variant="outlined" startIcon={<MonitorHeartIcon />}>
                            Vitals
                        </Button>

                        <Button onClick={onLabReportClick} size={'small'} variant="outlined" startIcon={<ScienceIcon />}>
                            Lab Reports
                        </Button>

                        <Button onClick={onPrescriptionClick} size={'small'} variant="outlined" startIcon={<MedicalServicesIcon />}>
                            Prescription
                        </Button>

                        <Button onClick={onDiagnosisClick} size={'small'} variant="outlined" startIcon={<HealthAndSafetyIcon />}>
                            Diagnosis
                        </Button>

                        <Button onClick={onLabTestClick} size={'small'} variant="outlined" startIcon={<BiotechIcon />}>
                            Lab Test
                        </Button>

                        {showSharedBtn && (
                            <Button
                                onClick={onShareClick}
                                size={'small'}
                                sx={{ backgroundColor: COLORS.secondory, color: COLORS.white, '&:hover': { backgroundColor: '#0098d9' } }}
                                variant="filled"
                                startIcon={<IosShareIcon />}
                            >
                                {isShared ? 'Shared' : 'Share'}
                            </Button>
                        )}
                    </Box>
                </Box>
            </Collapse>
        </Card>
    );
};
const styles = {
    mainContainer1: {
        mb: 2,
        p: 1,
        gap: 2,
        '&:hover': {
            cursor: 'pointer'
        }
    },
    mainContainer2: {
        display: 'flex',
        gap: 2,
        alignItems: 'center'
    },
    colorBox: {
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        width: '50%',
        backgroundColor: '#95b7f9',
        borderRadius: 2,
        p: 1
    },
    colorBox1: {
        display: 'flex',
        alignItems: 'center',
        gap: 4,
        width: 260,
        backgroundColor: '#95b7f9',
        borderRadius: 2,
        p: 1
    }
};

//make this component available to the app
export default memo(PreviousVisitCard);

const TextWithTitle = ({ title, text, sx }) => {
    return (
        <Box sx={sx}>
            <Typography variant="h6" fontWeight={'bold'} color={'#686868'}>
                {title}
            </Typography>
            <Typography color={'#444'}>{text}</Typography>
        </Box>
    );
};
