import * as React from 'react';
import TablePagination from '@mui/material/TablePagination';
import { CircularProgress, Grid, IconButton, Typography } from '@mui/material';

import { COLORS } from 'constants/colors';
import PrintIcon from '@mui/icons-material/Print';
import { useState } from 'react';
import { BASE_URL } from 'constants/baseUrl';
import { useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ModalConfirmation from 'ui-component/modals/ModalConfirmation';
import SearchField from 'ui-component/FormUI/SearchField.js';

import IntakeHistoryComp from '../../components/IntakeHistoryComp';
import VitalSignComp from 'components/VitalSignComp';
import UploadReportComp from 'components/UploadReportComp';
import ViewReportsComp from 'components/ViewReportsComp';
import PtMeetingCard from './PtMeetingCard';
import { useNavigate } from 'react-router';
import TranslationContext from 'context/translation';

export default function AppointmentList() {
    const { user } = useSelector((state) => state?.user);
    const { translate } = React.useContext(TranslationContext);
    const navigate = useNavigate();

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [count, setCount] = useState(0);

    const handleChangePage = (event, newPage) => {
        getVisitList(newPage, rowsPerPage, searchQuery);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        getVisitList(page, event.target.value, searchQuery);
        setRowsPerPage(+event.target.value);
    };

    const [visitList, setVisitList] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [selectedVisit, setSelectedVisit] = useState(null);
    const [loadingReq, setLoadingReq] = useState(false);

    const [openHistoryModal, setOpenHistoryModal] = useState(false);
    const [openRescheduleModal, setOpenRescheduleModal] = useState(false);
    const [openCancelModal, setOpenCancelModal] = useState(false);
    const [openVitalSignModal, setOpenVitalSignModal] = useState(false);
    const [openUploadReportModal, setOpenUploadReportModal] = useState(false);
    const [openViewReportModal, setOpenViewReportModal] = useState(false);

    const getVisitList = async (pageNumber, pageSize, searchValue) => {
        try {
            setLoading(true);
            setError(null);
            setVisitList(null);

            const res = await axios({
                method: 'get',
                url: `${BASE_URL}api/patient/patientsUpcomingVisits/${user?.nationalID}?pageNumber=${
                    pageNumber + 1
                }&pageSize=${pageSize}&QuerySearch=${searchValue ?? ''}`,
                headers: {
                    Authorization: `Bearer ${user?.token}`
                }
            });

            setVisitList(res?.data);
            setCount(res?.data?.paginationMetadata?.totalCount);
        } catch (error) {
            console.log(error);
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getVisitList(page, rowsPerPage, searchQuery);
    }, []);

    // ----------------------  Reschedule Appointment  ---------------------------
    const handleRescheduleVisit = async () => {
        setLoadingReq(true);

        try {
            const resReschedule = await axios({
                method: 'put',
                url: `${BASE_URL}api/visit/sendRescheduleAppointmentEmail`,
                data: {
                    VisitId: selectedVisit?.id,
                    IsRescheduled: true,
                    RescheduleDatetime: new Date(),
                    UserId: user?.userId
                },
                headers: {
                    Authorization: `Bearer ${user?.token}`
                }
            });

            if (resReschedule?.data) {
                getVisitList(page, rowsPerPage, searchQuery);
                setSelectedVisit(null);
                setOpenRescheduleModal(false);
                return toast.success('Request sent for reschedule appointment');
            }
        } catch (error) {
            return toast.error(error?.response?.data?.message ?? error?.response?.data ?? error?.message);
        } finally {
            setLoadingReq(false);
        }
    };

    // ----------------------  Cancel Appointment  ---------------------------
    const handleCancelVisit = async () => {
        setLoadingReq(true);

        try {
            const resCancel = await axios({
                method: 'put',
                url: `${BASE_URL}api/visit/sendCancelAppointmentEmail`,
                data: {
                    VisitId: selectedVisit?.id,
                    UserId: user?.userId
                },
                headers: {
                    Authorization: `Bearer ${user?.token}`
                }
            });

            if (resCancel?.data) {
                getVisitList(page, rowsPerPage, searchQuery);
                setSelectedVisit(null);
                setOpenCancelModal(false);
                return toast.success('Request sent for cancel appointment');
            }
        } catch (error) {
            return toast.error(error?.response?.data?.message ?? error?.response?.data ?? error?.message);
        } finally {
            setLoadingReq(false);
        }
    };
    return (
        <div>
            {/* -----------------  Main Header  -------------------------- */}
            <Grid mb={2} container direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="h3" color={COLORS.color1} sx={{ py: 1 }}>
                    {translate('APPOINTMENT_LIST')}
                </Typography>
            </Grid>

            {/* --------------------------  Meeting Card  ------------------------- */}
            {!!visitList &&
                visitList?.items?.map((visit) => (
                    <PtMeetingCard
                        key={visit?.meetinglink}
                        visit={visit}
                        onClickStart={() => navigate(`/meeting/${visit?.id}`, { state: visit })}
                        onClickReschedule={() => {
                            setOpenRescheduleModal(true);
                            setSelectedVisit(visit);
                        }}
                        onClickCancel={() => {
                            setOpenCancelModal(true);
                            setSelectedVisit(visit);
                        }}
                        onClickVital={() => {
                            setOpenVitalSignModal(true);
                            setSelectedVisit(visit);
                        }}
                        onClickHistory={() => {
                            setOpenHistoryModal(true);
                            setSelectedVisit(visit);
                        }}
                        onClickViewReport={() => {
                            setOpenViewReportModal(true);
                            setSelectedVisit(visit);
                        }}
                        onClickUploadReport={() => {
                            setOpenUploadReportModal(true);
                            setSelectedVisit(visit);
                        }}
                        showButtons={true}
                    />
                ))}

            {loading && (
                <Grid sx={styles.loadingContainer}>
                    <CircularProgress size={35} color="inherit" />
                </Grid>
            )}
            {!!error && (
                <Grid sx={styles.loadingContainer}>
                    <Typography>{error?.response?.data?.message ?? error?.response?.data ?? error?.message}</Typography>
                </Grid>
            )}
            {searchQuery?.length > 0 && visitList?.length == 0 && (
                <Grid sx={styles.loadingContainer}>
                    <Typography sx={{ fontSize: 16, fontWeight: '600' }}>Does not match any results!</Typography>
                </Grid>
            )}

            {/* ---------------------------- Pagination ----------------------- */}
            {!!visitList && visitList?.items?.length > 0 && (
                <TablePagination
                    rowsPerPageOptions={[5, 10, 20]}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            )}

            {/* ---------------------------------  Reschedule Modal */}
            <ModalConfirmation
                open={openRescheduleModal}
                header={'Reschedule Confirmation'}
                description={'Are you sure, you want to reschedule this appointment?'}
                loading={loadingReq}
                onClickNo={() => {
                    setOpenRescheduleModal(false);
                    setSelectedVisit(null);
                }}
                onClickYes={() => handleRescheduleVisit()}
            />

            {/* ---------------------------------  Cancellation Modal */}
            <ModalConfirmation
                open={openCancelModal}
                header={'Cancel Confirmation'}
                description={'Are you sure, you want to cancel this appointment?'}
                loading={loadingReq}
                onClickNo={() => {
                    setOpenCancelModal(false);
                    setSelectedVisit(null);
                }}
                onClickYes={() => handleCancelVisit()}
            />

            {/* -----------------------  History Modal  -------------------------------------- */}
            {openHistoryModal && (
                <IntakeHistoryComp
                    open={openHistoryModal}
                    onClose={() => {
                        setOpenHistoryModal(false);
                    }}
                    isHistoryUpdated={(val, historyStatus, close) => {
                        // isHistoryUpdated will return us true from IntakeHistoryComp.js and if it is true we will change the historyStatus of selected visit to filled
                        if (val == true) {
                            const updatedVisitList = visitList?.items.map((obj) => {
                                if (obj.id === selectedVisit?.id) {
                                    obj.historystatus = historyStatus; // change historyStatus to filled
                                }
                                return obj;
                            });
                            let newVisitList = { items: updatedVisitList };
                            setVisitList(newVisitList);
                            close && setOpenHistoryModal(false);
                        }
                    }}
                    visit={selectedVisit}
                />
            )}

            {/* -----------------------  Vital Sign Modal  -------------------------------------- */}
            {openVitalSignModal && (
                <VitalSignComp
                    open={openVitalSignModal}
                    onClose={() => {
                        setOpenVitalSignModal(false);
                    }}
                    isVitalSignUpdated={(val) => {
                        // Same Working Done on Intake History
                        if (val == true) {
                            const updatedVisitList = visitList?.items.map((obj) => {
                                if (obj.id === selectedVisit?.id) {
                                    obj.vitalsignStatus = 'VitalSigns filled'; // change vitalSignStatus to filled
                                }
                                return obj;
                            });
                            let newVisitList = { items: updatedVisitList };
                            setVisitList(newVisitList);
                        }
                    }}
                    visit={selectedVisit}
                />
            )}

            {/* -----------------------  Upload Reports Modal  -------------------------------------- */}
            {openUploadReportModal && (
                <UploadReportComp
                    open={openUploadReportModal}
                    onClose={() => {
                        setOpenUploadReportModal(false);
                    }}
                    visit={selectedVisit}
                />
            )}

            {/* -----------------------  View Reports Modal  -------------------------------------- */}
            {openViewReportModal && (
                <ViewReportsComp
                    open={openViewReportModal}
                    onClose={() => {
                        setOpenViewReportModal(false);
                    }}
                    visit={selectedVisit}
                />
            )}
        </div>
    );
}

const styles = {
    loadingContainer: {
        height: 500,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    btnContainer: {
        display: 'flex',
        direction: 'row',
        gap: 1,
        justifyContent: 'flex-end'
    }
};
