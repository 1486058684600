import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles, useTheme } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import IconButton from '@mui/material/IconButton';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';
import MenuIcon from '@mui/icons-material/Menu';
import ContrastIcon from '@mui/icons-material/Contrast';
import SearchIcon from '@mui/icons-material/Search';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import StraightenIcon from '@mui/icons-material/Straighten';
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import './DwvComponent.css';
import { App, getDwvVersion, decoderScripts } from 'dwv';
import { useParams } from 'react-router';
import { BASE_URL } from 'constants/baseUrl';
import { CssBaseline } from '@mui/material';

decoderScripts.jpeg2000 = `../../../public/assets/decoders/pdfjs/decode-jpeg2000.js`;
decoderScripts['jpeg-lossless'] = `../../../public/assets/decoders/rii-mango/decode-jpegloss.js`;
decoderScripts['jpeg-baseline'] = `../../../public/assets/decoders/pdfjs/decode-jpegbaseline.js`;
decoderScripts.rle = `../../../public/assets/decoders/dwv/decode-rle.js`;

const styles = (theme) => ({
    appBar: {
        position: 'relative'
    },
    title: {
        flex: '0 0 auto'
    },
    iconSmall: {
        fontSize: 20
    }
});

const TransitionUp = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function DCMViewer({ classes }) {
    const { dcmId: imageURL, domain } = useParams();

    const [state, setState] = useState({
        versions: {
            dwv: getDwvVersion(),
            react: React.version
        },
        tools: {
            Scroll: {},
            ZoomAndPan: {},
            WindowLevel: {},
            Draw: {
                options: ['Ruler']
            }
        },
        selectedTool: 'Select Tool',
        loadProgress: 0,
        dataLoaded: false,
        dwvApp: null,
        metaData: {},
        orientation: undefined,
        showDicomTags: false,
        dropboxDivId: 'dropBox',
        dropboxClassName: 'dropBox',
        borderClassName: 'dropBoxBorder',
        hoverClassName: 'hover'
    });

    const { versions, tools, loadProgress, dataLoaded, metaData } = state;

    const handleToolChange = (event, newTool) => {
        if (newTool) {
            onChangeTool(newTool);
        }
    };

    const getToolIcon = (tool) => {
        let res;
        if (tool === 'Scroll') {
            res = <MenuIcon />;
        } else if (tool === 'ZoomAndPan') {
            res = <SearchIcon />;
        } else if (tool === 'WindowLevel') {
            res = <ContrastIcon />;
        } else if (tool === 'Draw') {
            res = <StraightenIcon />;
        }
        return res;
    };

    const canRunTool = (tool) => {
        if (tool === 'Scroll') {
            return state?.dwvApp?.canScroll();
        } else if (tool === 'WindowLevel') {
            return state?.dwvApp?.canWindowLevel();
        } else {
            return true;
        }
    };

    const toolsButtons = Object?.keys(tools)?.map((tool) => {
        return (
            <ToggleButton value={tool} key={tool} title={tool} disabled={!dataLoaded || !canRunTool(tool)}>
                {getToolIcon(tool)}
            </ToggleButton>
        );
    });

    const onChangeTool = (tool) => {
        if (state.dwvApp) {
            setState((pre) => ({ ...pre, selectedTool: tool }));
            state.dwvApp.setTool(tool);
            if (tool === 'Draw') {
                onChangeShape(state.tools.Draw.options[0]);
            }
        }
    };

    const toggleOrientation = () => {
        setState((prevState) => {
            const newOrientation =
                prevState.orientation === 'axial' ? 'coronal' : prevState.orientation === 'coronal' ? 'sagittal' : 'axial';

            const config = {
                '*': [
                    {
                        divId: 'layerGroup0',
                        orientation: newOrientation
                    }
                ]
            };
            prevState.dwvApp.setDataViewConfigs(config);
            const dataIds = prevState.dwvApp.getDataIds();
            for (const dataId of dataIds) {
                prevState.dwvApp.render(dataId);
            }

            return { ...prevState, orientation: newOrientation };
        });
    };

    const onChangeShape = (shape) => {
        if (state.dwvApp) {
            state.dwvApp.setToolFeatures({ shapeName: shape });
        }
    };

    const onReset = () => {
        if (state.dwvApp) {
            state.dwvApp.resetDisplay();
        }
    };

    const handleTagsDialogOpen = () => {
        setState((prevState) => ({ ...prevState, showDicomTags: true }));
    };

    const handleTagsDialogClose = () => {
        setState((prevState) => ({ ...prevState, showDicomTags: false }));
    };

    const onInputFile = (event) => {
        if (event.target && event.target.files) {
            state.dwvApp.loadFiles(event.target.files);
        }
    };

    useEffect(() => {
        const app = new App();
        app.init({
            dataViewConfigs: { '*': [{ divId: 'layerGroup0' }] },
            tools: state.tools
        });

        let nLoadItem = null;
        let nReceivedLoadError = null;
        let nReceivedLoadAbort = null;
        let isFirstRender = null;

        if (imageURL) {
            app.loadURLs([`${BASE_URL}media/${domain}/Documents/${imageURL}`]);
        } else {
            app.loadFromUri(window.location.href);
        }

        app.addEventListener('loadstart', () => {
            nLoadItem = 0;
            nReceivedLoadError = 0;
            nReceivedLoadAbort = 0;
            isFirstRender = true;
        });
        app.addEventListener('loadprogress', (event) => {
            setState((prevState) => ({ ...prevState, loadProgress: event.loaded }));
        });
        app.addEventListener('renderend', (event) => {
            console.log(event, 'event');
            if (isFirstRender) {
                isFirstRender = false;
                let selectedTool = 'ZoomAndPan';
                if (app.canScroll()) {
                    selectedTool = 'Scroll';
                }
                onChangeTool(selectedTool);
            }
        });
        app.addEventListener('load', (event) => {
            // console.log(event, 'event');
            setState((prevState) => ({
                ...prevState,
                metaData: app.getMetaData(event.dataid),
                dataLoaded: true
            }));
        });
        app.addEventListener('loadend', () => {
            if (nReceivedLoadError) {
                setState((prevState) => ({ ...prevState, loadProgress: 0 }));
                alert('Received errors during load. Check log for details.');
            }
            if (nReceivedLoadAbort) {
                setState((prevState) => ({ ...prevState, loadProgress: 0 }));
                alert('Load was aborted.');
            }
        });
        app.addEventListener('loaditem', () => {
            ++nLoadItem;
        });
        app.addEventListener('loaderror', (event) => {
            console.error('--> ', event.error);
            ++nReceivedLoadError;
        });
        app.addEventListener('loadabort', () => {
            ++nReceivedLoadAbort;
        });

        app.addEventListener('keydown', (event) => {
            app.defaultOnKeydown(event);
        });
        window.addEventListener('resize', app.onResize);

        setState((prevState) => ({ ...prevState, dwvApp: app }));
    }, [imageURL]);

    return (
        <div>
            <CssBaseline />
            <LinearProgress variant="determinate" value={loadProgress} />
            <Stack direction="row" spacing={1} padding={1} justifyContent="center" flexWrap="wrap">
                <ToggleButtonGroup size="small" color="primary" value={state.selectedTool} exclusive onChange={handleToolChange}>
                    {toolsButtons}
                </ToggleButtonGroup>

                <ToggleButton size="small" value="reset" title="Reset" disabled={!dataLoaded} onClick={onReset}>
                    <RefreshIcon />
                </ToggleButton>

                <ToggleButton
                    size="small"
                    value="toggleOrientation"
                    title="Toggle Orientation"
                    disabled={!dataLoaded}
                    onClick={toggleOrientation}
                >
                    <CameraswitchIcon />
                </ToggleButton>

                <ToggleButton size="small" value="tags" title="Tags" disabled={!dataLoaded} onClick={handleTagsDialogOpen}>
                    <LibraryBooksIcon />
                </ToggleButton>

                <Dialog open={state.showDicomTags} onClose={handleTagsDialogClose} TransitionComponent={TransitionUp}>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={handleTagsDialogClose} aria-label="Close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" color="inherit" className={classes.title}>
                                Tags
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    {/* <TagsTable metaData={metaData} /> */}
                </Dialog>
            </Stack>
            <div className="layerContainer">
                <div id="layerGroup0" className="layerGroup" style={{ width: 500, height: 500 }}>
                    <canvas className="imageLayer">Only for HTML5 compatible browsers...</canvas>
                    <div className="drawDiv"></div>
                </div>
            </div>
            <input type="file" id="inputfile" onChange={onInputFile} />
        </div>
    );
}

DCMViewer.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DCMViewer);
