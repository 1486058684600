import React from 'react';

import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DiagnosisCard from './DiagnosisCard';
import { styles } from './styles';
import axios from 'axios';
import { BASE_URL } from 'constants/baseUrl';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { COLORS } from 'constants/colors';

const ShowDiagnosis = ({ diagnosisList, setDiagnosisList, getPatientDiagnosis }) => {
    const { user } = useSelector((state) => state?.user);

    let currentDiag = diagnosisList?.filter((i) => i.isDeleted == false);
    let discountinuedDiag = diagnosisList?.filter((i) => i.isDeleted == true);

    const handleRemove = async (id) => {
        try {
            const updatedDiagList = diagnosisList.map((obj) => {
                if (obj.id === id) {
                    obj.isDeleted = true;
                }
                return obj;
            });
            setDiagnosisList(updatedDiagList);

            const resDiagDel = await axios({
                method: 'delete',
                url: `${BASE_URL}api/patientdiagnostic/${id}`,
                data: {
                    deleteOn: new Date(),
                    deleteBy: user?.userId
                },
                headers: {
                    Authorization: `Bearer ${user?.token}`
                }
            });

            if (resDiagDel?.data) {
                getPatientDiagnosis();
            }
        } catch (error) {
            toast?.error(error?.response?.data?.message ?? error?.response?.data ?? error?.message);
        }
    };

    const handleModify = async (id, visitID) => {
        try {
            const updatedDiagList = diagnosisList.map((obj) => {
                if (obj.id === id) {
                    obj.isDeleted = false;
                }
                return obj;
            });
            setDiagnosisList(updatedDiagList);

            const resDiagModify = await axios({
                method: 'put',
                url: `${BASE_URL}api/patientdiagnostic/${id}`,
                data: {
                    id: id,
                    visitID,
                    isDeleted: false,
                    lastModifiedBy: user?.userId,
                    lastModifiedOn: new Date()
                },
                headers: {
                    Authorization: `Bearer ${user?.token}`
                }
            });

            if (resDiagModify?.data) {
                getPatientDiagnosis();
            }
        } catch (error) {
            toast?.error(error?.response?.data?.message ?? error?.response?.data ?? error?.message);
        }
    };

    return (
        <>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography sx={styles?.accordianTitle}>Current Diagnosis - {currentDiag?.length}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {currentDiag?.map((item, index) => (
                        <DiagnosisCard
                            key={index}
                            diagnosis={item}
                            showBtn={true}
                            btnTitle={'Discontinue'}
                            btnColor={'red'}
                            onClick={() => handleRemove(item?.id)}
                        />
                    ))}
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1b-header">
                    <Typography sx={styles?.accordianTitle}>Discountinued Diagnosis - {discountinuedDiag?.length}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {discountinuedDiag?.map((item, index) => (
                        <DiagnosisCard
                            key={index}
                            diagnosis={item}
                            showBtn={true}
                            btnTitle={'Continue'}
                            btnColor={COLORS.secondory}
                            onClick={() => handleModify(item?.id, item?.visitID)}
                        />
                    ))}
                </AccordionDetails>
            </Accordion>
        </>
    );
};

export default ShowDiagnosis;
