import React, { useState, useEffect, useContext } from 'react';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    CircularProgress,
    Grid,
    IconButton,
    Typography,
    Button
} from '@mui/material';

import axios from 'axios';
import moment from 'moment/moment';
import { useDispatch, useSelector } from 'react-redux';

//--------------  Icons
import PrintIcon from '@mui/icons-material/Print';

//--------------  Constants
import { BASE_URL } from 'constants/baseUrl';
import { COLORS } from 'constants/colors';

//--------------  Custom Components
import SearchField from 'ui-component/FormUI/SearchField.js';
import TranslationContext from 'context/translation';
import CRUDApointmentModal from 'components/appointment/CRUDApointmentModal';
import { fetchLocationData } from 'store/slices/locationSlice';
import { fetchPatientList } from 'store/slices/patientListSlice';
import { fetchPhysicianList } from 'store/slices/physicianListSlice';

export default function Cancelled() {
    const { user } = useSelector((state) => state?.user);
    const { translate } = useContext(TranslationContext);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [count, setCount] = useState(0);

    const handleChangePage = (event, newPage) => {
        getCancelledVisitList(newPage, rowsPerPage, searchQuery);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        getCancelledVisitList(page, event.target.value, searchQuery);
        setRowsPerPage(+event.target.value);
    };

    // ------------------- Get list of Physician, Patient and Location for showing in AutoComplete Component
    const dispatch = useDispatch();
    const { data: locationList } = useSelector((state) => state.location);
    const { data: patientDataList } = useSelector((state) => state.patientList);
    const { data: physicianDataList } = useSelector((state) => state.physicianList);

    const [cancelledVisitList, setCancelledVisitList] = useState(null);
    const [searchQuery, setSearchQuery] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [modal, setModal] = useState({ open: false, type: 'add', data: null });

    const getCancelledVisitList = async (pageNumber, pageSize, searchValue) => {
        try {
            setLoading(true);
            setError(null);
            setCancelledVisitList(null);

            const res = await axios({
                method: 'get',
                url: `${BASE_URL}api/visit/cancelVisitsList/${user?.userId}?pageNumber=${pageNumber + 1}&pageSize=${pageSize}&QuerySearch=${
                    searchValue ?? ''
                }&isConfirm=false`,
                headers: {
                    Authorization: `Bearer ${user?.token}`
                }
            });

            setCancelledVisitList(res?.data);
            setCount(res?.data?.paginationMetadata?.totalCount);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        !locationList && dispatch(fetchLocationData());
        !patientDataList && dispatch(fetchPatientList());
        !physicianDataList && dispatch(fetchPhysicianList());

        getCancelledVisitList(page, rowsPerPage, searchQuery);
    }, []);

    return (
        <div>
            <Grid mb={2} container direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="h3" color={COLORS.color1} sx={{ py: 1 }}>
                    {translate('CANCELLED_APPOINTMENTS')}
                </Typography>
            </Grid>

            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <Grid container justifyContent="space-between" alignItems="center" p={2} rowGap={2}>
                    <Grid item lg={4} xs={12}>
                        <SearchField
                            label={translate('SEARCH')}
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            onClickSearch={() => {
                                setPage(0);
                                getCancelledVisitList(0, rowsPerPage, searchQuery);
                            }}
                            onClickClear={() => {
                                setPage(0);
                                setSearchQuery('');
                                getCancelledVisitList(0, rowsPerPage, '');
                            }}
                            titleSearchBtn={'Search Physician and Patients'}
                            titleClearBtn={'Clear search list'}
                        />
                    </Grid>

                    <Grid sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
                        <IconButton title="Print Cancelled Appointment List">
                            <PrintIcon />
                        </IconButton>
                    </Grid>
                </Grid>

                <TableContainer sx={{ maxHeight: 'auto' }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow
                                sx={{
                                    '& th': {
                                        color: '#fff',
                                        backgroundColor: COLORS.secondory
                                    }
                                }}
                            >
                                <TableCell align={'left'}>ID</TableCell>

                                <TableCell align={'left'}>{translate('PATIENT_NAME')}</TableCell>

                                <TableCell align={'left'}>{translate('PHYSICIAN_NAME')}</TableCell>

                                <TableCell align={'left'}>{translate('DATE_TIME')}</TableCell>

                                <TableCell align={'left'}>{translate('SUMMARY_NOTES')}</TableCell>

                                <TableCell align={'left'}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!!cancelledVisitList &&
                                cancelledVisitList?.items?.map((row) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.id}
                                            sx={{ '&:hover': { cursor: 'pointer' } }}
                                        >
                                            <TableCell align={'left'}>{row?.id}</TableCell>

                                            <TableCell align={'left'} sx={{ textTransform: 'capitalize' }}>
                                                {row?.patientName?.toLowerCase()}
                                            </TableCell>

                                            <TableCell align={'left'} sx={{ textTransform: 'capitalize' }}>
                                                {row?.consultantName?.toLowerCase()}
                                            </TableCell>

                                            <TableCell align={'left'}>
                                                {moment(row?.startDateTime).format('MMM DD, YYYY  -  hh:mm A')}
                                            </TableCell>

                                            <TableCell align={'left'}>{row?.title}</TableCell>

                                            <TableCell
                                                align={'center'}
                                                onClick={() =>
                                                    setModal({
                                                        open: true,
                                                        type: 'recreate',
                                                        data: { ...row, start: row?.startDateTime, end: row?.endDateTime },
                                                        show: true
                                                    })
                                                }
                                            >
                                                <Button>Edit</Button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}

                            {loading && (
                                <TableRow sx={{ height: 400 }}>
                                    <TableCell align="center" colSpan={6}>
                                        <CircularProgress size={35} color="inherit" />
                                    </TableCell>
                                </TableRow>
                            )}

                            {!!error && (
                                <TableRow sx={{ height: 400 }}>
                                    <TableCell align="center" colSpan={6}>
                                        <Typography>{error?.response?.data?.message ?? error?.response?.data ?? error?.message}</Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={[5, 10, 20]}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />

                <CRUDApointmentModal
                    modal={modal}
                    patientDataList={patientDataList}
                    physicianDataList={physicianDataList}
                    locationList={locationList}
                    onSuccess={async (type, visit) => {
                        if (type == 'add' || 'update') {
                            setModal({ open: false, type: null, data: null });
                        } else {
                            setModal({ open: false, type: null, data: null });
                        }
                    }}
                    onClose={() => {
                        setModal({ open: false, type: null, data: null });
                    }}
                />
            </Paper>
        </div>
    );
}
