import React, { useContext } from 'react';
import { Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';

import ModalCustom from 'ui-component/modals/ModalCustom';
import { MeetingContext } from 'views/meeting/MeetingContext';
import ShowDiagnosis from './ShowDiagnosis';
import AddDiagnosis from './AddDiagnosis';

export default function PatientDiagnosis({ visit, open, onClose }) {
    const { diagnosisList, setDiagnosisList, getDiagnosisByVisit } = useContext(MeetingContext);

    return (
        <ModalCustom open={open} title={'Diagnosis'}>
            <Box sx={{ width: '40vw' }}>
                <Box sx={{ position: 'absolute', top: 10, right: 10 }}>
                    <IconButton color="inherit" title="Refresh" onClick={() => getDiagnosisByVisit()}>
                        <RefreshIcon />
                    </IconButton>
                    <IconButton color="inherit" onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>

                <ShowDiagnosis
                    diagnosisList={diagnosisList}
                    getPatientDiagnosis={getDiagnosisByVisit}
                    setDiagnosisList={setDiagnosisList}
                />

                <AddDiagnosis visit={visit} getPatientDiagnosis={getDiagnosisByVisit} />
            </Box>
        </ModalCustom>
    );
}
