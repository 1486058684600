import React, { useContext } from 'react';
import { Button, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import TranslationContext from 'context/translation';

export default function SearchField({ onClickSearch, onChange, value, onClickClear, label, titleClearBtn, titleSearchBtn, size }) {
    const { translate } = useContext(TranslationContext);

    return (
        <FormControl size={size} onSubmit={() => {}} fullWidth variant="outlined">
            <InputLabel>{label}</InputLabel>
            <OutlinedInput
                value={value}
                onChange={onChange}
                onKeyPress={(event) => {
                    if (event.key == 'Enter') {
                        onClickSearch();
                    }
                }}
                endAdornment={
                    <InputAdornment position="end">
                        {value && (
                            <Button title={titleClearBtn} type="submit" variant="text" sx={{ color: 'gray' }} onClick={onClickClear}>
                                {translate('CLEAR')}
                            </Button>
                        )}

                        <IconButton type="submit" title={titleSearchBtn} onClick={onClickSearch} edge="end">
                            <SearchIcon />
                        </IconButton>
                    </InputAdornment>
                }
                label={label}
            />
        </FormControl>
    );
}
