import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    MenuItem,
    Select,
    TextField,
    Typography
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { COLORS } from 'constants/colors';
import { IconPlus } from '@tabler/icons';
import PrintIcon from '@mui/icons-material/Print';
import { useState } from 'react';
import { BASE_URL } from 'constants/baseUrl';
import { useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import ModalCustom from 'ui-component/modals/ModalCustom';
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ModalConfirmation from 'ui-component/modals/ModalConfirmation';
import SearchField from 'ui-component/FormUI/SearchField.js';
import useFetch from 'hooks/useFetch';
import moment from 'moment';
import { getData } from 'utils/indexedDB';
import AddNewPatient from 'views/staff/patients/AddNew';
import { useNavigate } from 'react-router';
import PrintBillingList from './PrintBillingList';
import { useReactToPrint } from 'react-to-print';
import PrintBillingDetail from './PrintBillingDetail';

export default function Prescription() {
    const { user } = useSelector((state) => state?.user);
    const navigate = useNavigate();

    const [billingDetailToPrint, setBillingDetailToPrint] = useState(null);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [count, setCount] = useState(0);

    const [billingList, setBillingList] = useState(null);
    const [searchQuery, setSearchQuery] = useState();
    const [isPaid, setIsPaid] = useState('all');

    const curDate = new Date();

    const [startDate, setStartDate] = useState(
        new Date(curDate.getFullYear(), curDate.getMonth(), curDate.getDate() - 14).toLocaleDateString('en-CA')
    );
    const [endDate, setEndDate] = useState(new Date().toLocaleDateString('en-CA'));
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const handleChangePage = (event, newPage) => {
        getBillingList(newPage, rowsPerPage, isPaid, startDate, endDate, searchQuery);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        getBillingList(page, event.target.value, isPaid, startDate, endDate, searchQuery);
        setRowsPerPage(+event.target.value);
    };

    const handleIsPaid = (e) => {
        getBillingList(0, rowsPerPage, e.target.value, startDate, endDate, searchQuery);
        setIsPaid(e.target.value);
        setPage(0);
    };

    const handleStartDate = (e) => {
        getBillingList(0, rowsPerPage, isPaid, e.target.value, endDate, searchQuery);
        setStartDate(e.target.value);
        setPage(0);
    };

    const handleEndDate = (e) => {
        getBillingList(0, rowsPerPage, isPaid, startDate, e.target.value, searchQuery);
        setEndDate(e.target.value);
        setPage(0);
    };

    const getBillingList = async (pageNumber, pageSize, paid, start, end, searchValue) => {
        try {
            setLoading(true);
            setError(null);
            setBillingList(null);

            const res = await axios({
                method: 'get',
                url: `${BASE_URL}api/bill/GetBillList?pageNumber=${pageNumber + 1}&pageSize=${pageSize}&QuerySearch=${
                    searchValue ?? ''
                }&StartDate=${start}&EndDate=${end}&isPaid=${paid == 'all' ? '' : paid}`,
                headers: {
                    Authorization: `Bearer ${user?.token}`
                }
            });

            setBillingList(res?.data);
            setCount(res?.data?.paginationMetadata?.totalCount);
        } catch (error) {
            console.log(error);
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getBillingList(page, rowsPerPage, isPaid, startDate, endDate, searchQuery);
        getData('medicine').then((res) => {
            setMedicine(res);
            return;
        });
    }, []);
    const [medicine, setMedicine] = useState(null);

    const [modal, setModal] = useState({ open: false, value: null });
    const [selectedRow, setSelectedRow] = useState(null);

    const [openModal, setOpenModal] = useState(false);
    const [loadingRequest, setLoadingRequest] = useState(false);
    const [prescriptionList, setPrescriptionList] = useState([]);

    const {
        data: patientDataList,
        loading: loadingpatientDataList,
        error: errorpatientDataList,
        refetch: refetchpatientDataList
    } = useFetch(`${BASE_URL}api/patient/patientSelectList?pageNumber=1&pageSize=100&QuerySearch=`);
    const [addNewPtModal, setAddNewPtModal] = useState(false);

    const {
        data: physicianDataList,
        loading: loadingphysicianDataList,
        error: errorphysicianDataList,
        refetch: refetchphysicianDataList
    } = useFetch(`${BASE_URL}api/physician/physicianSelectList?pageNumber=1&pageSize=100&QuerySearch=`);

    const {
        data: todaysVisitList,
        loading: loadingtodaysVisitList,
        error: errortodaysVisitList,
        refetch: refetchtodaysVisitList
    } = useFetch(`${BASE_URL}api/visit/TodayVisitsSelectList`);

    const [netAmount, setNetAmount] = useState(0);
    const [discount, setDiscount] = useState(0);

    const [selectedVisit, setSelectedVisit] = useState(null);
    const [selectedPatient, setSelectedPatient] = useState(null);
    const [selectedPhysician, setSelectedPhysician] = useState(null);
    let medRef = React.useRef('');
    const [isPaidBill, setIsPaidBill] = useState(true);
    const [rate, setRate] = useState(0);
    const [quantity, setQuantity] = useState(1);
    const [totalAmount, setTotalAmount] = useState(0);
    const [note, setNote] = useState('');

    const handleAdd = () => {
        if (medRef?.current?.value == '') {
            return toast.error('Please select medicine');
        }
        if (quantity <= 0 || quantity == null) {
            return toast.error('Quantity should be 1 or more');
        }
        if (rate < 0 || rate == null) {
            return toast.error('Please enter rate');
        }

        const id = prescriptionList?.length + 1;
        setPrescriptionList([
            ...prescriptionList,
            {
                id,
                name: medRef?.current?.value.split('-')[1].trim(),
                medicineID: medRef?.current?.value.split('-')[0].trim(),
                quantity,
                rate,
                amount: rate * quantity,
                createdBy: user?.userId,
                createdOn: new Date()
            }
        ]);

        setTotalAmount((prev) => prev + rate * quantity);
        medRef.current.value = '';
        setQuantity(1);
        setRate(0);
    };

    const handleMedDelete = (id) => {
        let updatePres = prescriptionList?.filter((i) => i?.id !== id);
        setTotalAmount(updatePres.reduce((amount, pres) => amount + pres?.amount, 0));
        setPrescriptionList(updatePres);
    };

    const handleSaveBill = async () => {
        if (selectedPatient == null) {
            return toast.error('Please select patient');
        }
        if (selectedPhysician == null) {
            return toast.error('Please select pysician');
        }
        if (prescriptionList?.length <= 0) {
            return toast.error('Please add atleast 1 medicine');
        }
        setLoadingRequest(true);
        try {
            const data = {
                VisitID: selectedVisit?.id,
                Patient_NationalID: selectedPatient?.nationalID,
                Consultant_NationalID: selectedPhysician,
                BillDate: new Date(),
                BillNo: `${user?.domain}-${Date.now()}`,
                Amount: totalAmount,
                Taxes: 0.0,
                Discount: discount,
                NetAmount: totalAmount - discount,
                PaidAmount: totalAmount,
                Bill_Name: `${selectedPatient?.name} ${selectedPatient?.lastName}`,
                Bill_Age: '',
                Bill_Address: '',
                Bill_Phone: selectedPatient?.phone,
                Note: note,
                Status: isPaidBill ? 'Paid' : 'Un-Paid',
                IsPaid: isPaidBill,
                createdBy: user?.userId,
                createdOn: new Date(),
                billItems: prescriptionList
            };

            const resp = await axios({
                method: 'post',
                url: `${BASE_URL}api/bill`,
                data: data,
                headers: {
                    Authorization: `Bearer ${user?.token}`
                }
            });

            if (resp?.data) {
                getBillingList(page, rowsPerPage, isPaid, startDate, endDate, searchQuery);
                setModal({ open: false, value: 'add' });
                setBillingDetailToPrint({
                    ...resp?.data,
                    consultantName: physicianDataList?.find((i) => i?.nationalID == selectedPhysician)?.name,
                    billItems: prescriptionList
                });
                handlePrintDetail();
                setPrescriptionList([]);
                setSelectedPatient(null);
                setSelectedPhysician(null);
                setNote('');
                return toast.success('Bill created successfully');
            }
        } catch (error) {
            return toast.error(error?.response?.data?.message || error?.response?.data || error?.message);
        } finally {
            setLoadingRequest(false);
        }
    };

    const handleDelete = async () => {
        setLoadingRequest(true);
        try {
            const res = await axios({
                method: 'delete',
                url: `${BASE_URL}api/bill/${selectedRow?.billId}`,
                headers: {
                    Authorization: `Bearer ${user?.token}`
                }
            });

            if (res?.data) {
                getBillingList(page, rowsPerPage, isPaid, startDate, endDate, searchQuery);
                setOpenModal(false);
                setSelectedRow(null);
                return toast.success('Bill deleted successfully');
            }
        } catch (error) {
            return toast.error(error?.response?.data?.message || error?.response?.data || error?.message);
        } finally {
            setLoadingRequest(false);
        }
    };

    const componentRef = React.useRef(null);
    const handlePrint = useReactToPrint({
        content: () => componentRef.current
    });

    const billingDetailRef = React.useRef(null);
    const handlePrintDetail = useReactToPrint({
        content: () => billingDetailRef.current
    });

    return (
        <div>
            <Grid mb={2} container direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="h3" color={COLORS.color1}>
                    Billing List
                </Typography>

                <Grid item sx={{ ml: 'auto' }}>
                    <Grid container spacing={2}>
                        <Grid item>
                            <Button
                                onClick={() => setModal({ open: true, value: 'add' })}
                                variant="contained"
                                startIcon={<IconPlus size={17} />}
                                sx={{ backgroundColor: COLORS.secondory, '&: hover': { background: COLORS.primary } }}
                            >
                                New Bill
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <Grid container alignItems="center" p={2} rowGap={2}>
                    <Grid item sx={{ display: 'flex', flex: 1 }}>
                        <Grid container gap={2}>
                            <Grid item lg={4} xs={12}>
                                <SearchField
                                    label="Search"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    onClickSearch={() => {
                                        setPage(0);
                                        getBillingList(0, rowsPerPage, Bill, startDate, endDate, searchQuery);
                                    }}
                                    onClickClear={() => {
                                        setPage(0);
                                        setSearchQuery('');
                                        getBillingList(0, rowsPerPage, isPaid, startDate, endDate, '');
                                    }}
                                    titleSearchBtn={'Search Physician and Patient'}
                                    titleClearBtn={'Clear search list'}
                                />
                            </Grid>

                            <Grid lg={2} xs={12}>
                                <TextField
                                    fullWidth
                                    type="date"
                                    variant="outlined"
                                    label="Start Date"
                                    InputLabelProps={{ shrink: true }}
                                    value={startDate}
                                    onChange={handleStartDate}
                                />
                            </Grid>

                            <Grid lg={2} xs={12}>
                                <TextField
                                    fullWidth
                                    type="date"
                                    variant="outlined"
                                    label="End Date"
                                    InputLabelProps={{ shrink: true }}
                                    value={endDate}
                                    onChange={handleEndDate}
                                />
                            </Grid>

                            <Grid lg={2} xs={12}>
                                <FormControl fullWidth>
                                    <Select value={isPaid} onChange={handleIsPaid}>
                                        <MenuItem value={'all'}>All</MenuItem>
                                        <MenuItem value={true}>Paid</MenuItem>
                                        <MenuItem value={false}>Un-Paid</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
                        <IconButton onClick={handlePrint} title="Print Bill List">
                            <PrintIcon />
                        </IconButton>
                    </Grid>
                </Grid>

                <TableContainer sx={{ maxHeight: 'auto' }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow
                                sx={{
                                    '& th': {
                                        color: '#fff',
                                        backgroundColor: COLORS.secondory
                                    }
                                }}
                            >
                                <TableCell align={'left'}>ID</TableCell>

                                <TableCell align={'left'}>Patient Name</TableCell>

                                <TableCell align={'left'}>Physician Name</TableCell>

                                <TableCell align={'center'}>Bill Date</TableCell>

                                <TableCell align={'right'}>Amount</TableCell>

                                <TableCell align={'center'}>Status</TableCell>

                                <TableCell sx={{ width: 100 }}></TableCell>

                                <TableCell sx={{ width: 80 }}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!!billingList && (
                                <>
                                    {billingList?.items?.map((row) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                <TableCell align={'left'}>{row?.billId}</TableCell>

                                                <TableCell align={'left'} sx={{ textTransform: 'capitalize' }}>
                                                    {row?.patientName?.toLowerCase()}
                                                </TableCell>

                                                <TableCell align={'left'} sx={{ textTransform: 'capitalize' }}>
                                                    {row?.consultantName?.split('/')[0]?.toLowerCase()}
                                                </TableCell>

                                                <TableCell align={'center'}>
                                                    {moment(row?.billDate).format('MMM DD, YYYY  -  hh:mm A')}
                                                </TableCell>

                                                <TableCell align={'right'}>{row?.netAmount?.toFixed(2)}</TableCell>

                                                <TableCell align={'center'}>{row?.status}</TableCell>

                                                <TableCell align={'center'}>
                                                    <IconButton
                                                        sx={{ m: -1 }}
                                                        title="View Item"
                                                        onClick={() => navigate('detail', { state: row })}
                                                    >
                                                        <VisibilityIcon style={{ color: COLORS.secondory }} />
                                                    </IconButton>
                                                </TableCell>

                                                <TableCell>
                                                    <IconButton
                                                        sx={{ m: -1 }}
                                                        title="Delete Item"
                                                        onClick={() => {
                                                            setOpenModal(true);
                                                            setSelectedRow(row);
                                                        }}
                                                    >
                                                        <DeleteIcon style={{ color: 'red' }} />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    <TableRow sx={{ backgroundColor: '#e5e5e5' }}>
                                        <TableCell />
                                        <TableCell />
                                        <TableCell />
                                        <TableCell align={'right'}>Total</TableCell>
                                        <TableCell align={'right'}>
                                            {billingList?.items?.reduce((netAmount, bill) => netAmount + bill?.netAmount, 0)?.toFixed(2)}
                                        </TableCell>
                                        <TableCell />
                                        <TableCell />
                                        <TableCell />
                                    </TableRow>
                                </>
                            )}

                            {loading && (
                                <TableRow sx={{ height: 400 }}>
                                    <TableCell align="center" colSpan={16}>
                                        <CircularProgress size={35} color="inherit" />
                                    </TableCell>
                                </TableRow>
                            )}

                            {!!error && (
                                <TableRow sx={{ height: 400 }}>
                                    <TableCell align="center" colSpan={16}>
                                        <Typography>{error?.response?.data?.message || error?.response?.data || error?.message}</Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={[5, 10, 20]}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>

            <ModalConfirmation
                open={openModal}
                header={'Delete Confirmation'}
                description={'Are you sure, you want to delete this bill?'}
                loading={loadingRequest}
                onClickNo={() => setOpenModal(false)}
                onClickYes={() => handleDelete()}
            />

            <ModalCustom open={modal.open} title={'Add Bill'}>
                <Formik onSubmit={(values, { resetForm }) => handleRequest(values, resetForm)}>
                    <Form>
                        <Grid container direction="row" spacing={2}>
                            {(loadingpatientDataList || loadingphysicianDataList || loadingtodaysVisitList) && (
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 2 }}>
                                    <CircularProgress size={25} color="inherit" />
                                </Box>
                            )}

                            {patientDataList && physicianDataList && (
                                <>
                                    <Grid item lg={2} md={4} sm={6} xs={12}>
                                        <Autocomplete
                                            options={todaysVisitList ?? []}
                                            size="small"
                                            getOptionLabel={(visit) => `${visit?.id}`}
                                            value={selectedVisit && todaysVisitList?.find((i) => i?.id == selectedVisit?.id)}
                                            onChange={(event, selected) => {
                                                setSelectedVisit(selected || null);
                                                setSelectedPatient(
                                                    patientDataList?.find((i) => i?.nationalID == selected?.patient_NationalID)
                                                );
                                                setSelectedPhysician(selected?.consultant_NationalID);
                                                setPrescriptionList([
                                                    {
                                                        id: 1,
                                                        name: `Physician Fee`,
                                                        medicineID: 15066,
                                                        quantity: 1,
                                                        rate: selected?.amount,
                                                        amount: selected?.amount,
                                                        createdBy: user?.userId,
                                                        createdOn: new Date()
                                                    }
                                                ]);
                                                setTotalAmount(selected?.amount);
                                            }}
                                            renderInput={(params) => <TextField {...params} label="Visit" variant="outlined" />}
                                        />
                                    </Grid>

                                    <Grid item lg={4} md={5} sm={5} xs={12}>
                                        <Autocomplete
                                            disabled={!!selectedVisit}
                                            options={patientDataList ?? []}
                                            size="small"
                                            getOptionLabel={(patient) => `${patient?.name}  ${patient?.lastName} - ${patient?.phone}`}
                                            value={
                                                selectedPatient &&
                                                patientDataList?.find((i) => i?.nationalID == selectedPatient?.nationalID)
                                            }
                                            onChange={(event, selected) => {
                                                setSelectedPatient(selected || null);
                                            }}
                                            renderInput={(params) => <TextField {...params} label="Patient" variant="outlined" />}
                                        />
                                    </Grid>

                                    <Grid item lg={2} md={2} sm={2} xs={6}>
                                        <Button
                                            size="small"
                                            onClick={() => {
                                                setAddNewPtModal(true);
                                            }}
                                        >
                                            Add Patient
                                        </Button>
                                    </Grid>

                                    <Grid item lg={4} md={5} sm={5} xs={8}>
                                        <Autocomplete
                                            disabled={!!selectedVisit}
                                            options={physicianDataList ?? []}
                                            size="small"
                                            getOptionLabel={(physician) =>
                                                `${physician?.name}  ${physician?.phone} - ${physician?.speciality}`
                                            }
                                            value={selectedPhysician && physicianDataList?.find((i) => i?.nationalID == selectedPhysician)}
                                            onChange={(event, selected) => {
                                                setSelectedPhysician(selected?.nationalID || null);
                                            }}
                                            renderInput={(params) => <TextField {...params} label="Physician" variant="outlined" />}
                                        />
                                    </Grid>

                                    <Grid item lg={10} md={3} sm={3} xs={12}>
                                        <TextField
                                            value={note}
                                            onChange={(e) => setNote(e.target.value)}
                                            fullWidth
                                            size="small"
                                            label="Note"
                                        />
                                    </Grid>

                                    <Grid item lg={2} md={2} sm={2} xs={4}>
                                        <FormControlLabel
                                            control={<Checkbox checked={isPaidBill} onChange={(e) => setIsPaidBill(e.target.checked)} />}
                                            label={isPaidBill ? 'Paid' : 'Un-Paid'}
                                        />
                                    </Grid>

                                    <Grid item lg={6} md={7} sm={7} xs={12}>
                                        <AutoCompleteCom
                                            listName={'medicine'}
                                            data={medicine}
                                            inputref={medRef}
                                            placeholder={'Select Medicine'}
                                            showKey={['medicineID', 'name', 'genericName']}
                                            onClick={(selectedMed) => setRate(selectedMed?.rate || 0)}
                                        />
                                    </Grid>

                                    <Grid item lg={2} md={3} sm={3} xs={12}>
                                        <TextField
                                            value={quantity}
                                            onChange={(e) => setQuantity(e.target.value)}
                                            fullWidth
                                            type="number"
                                            size="small"
                                            label="Quantity"
                                            inputProps={{
                                                min: 1, // Minimum value
                                                max: 100 // Maximum value
                                            }}
                                        />
                                    </Grid>

                                    <Grid item lg={2} md={3} sm={3} xs={12}>
                                        <TextField
                                            value={rate}
                                            onChange={(e) => setRate(e.target.value)}
                                            fullWidth
                                            type="number"
                                            size="small"
                                            label="Rate"
                                            inputProps={{
                                                min: 0 // Minimum value
                                            }}
                                        />
                                    </Grid>

                                    <Grid item lg={2} md={2} sm={2} xs={12}>
                                        <Button onClick={handleAdd} fullWidth variant="contained">
                                            Add
                                        </Button>
                                    </Grid>

                                    {/* {selectedVisit && (
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    backgroundColor: '#e5e5e5',
                                                    my: 1,
                                                    borderRadius: 1,
                                                    p: 2
                                                }}
                                            >
                                                <Typography sx={{ fontWeight: 'bold' }}>Physician Fee</Typography>
                                                <Typography>{selectedVisit?.amount}</Typography>
                                            </Box>
                                        </Grid>
                                    )} */}

                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <TableContainer>
                                            {prescriptionList?.length > 0 && (
                                                <Table stickyHeader aria-label="sticky table">
                                                    <TableHead>
                                                        <TableRow
                                                            sx={{
                                                                '& th': {
                                                                    color: '#fff',
                                                                    backgroundColor: COLORS.secondory
                                                                }
                                                            }}
                                                        >
                                                            <TableCell sx={{ width: 40 }}>Id</TableCell>
                                                            <TableCell align={'left'}>Medicine</TableCell>
                                                            <TableCell align={'right'}>Qty</TableCell>
                                                            <TableCell align={'right'}>Rate</TableCell>
                                                            <TableCell align={'right'}>Amount</TableCell>
                                                            <TableCell sx={{ width: 80 }}></TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {prescriptionList?.map((item) => (
                                                            <TableRow key={item?.id}>
                                                                <TableCell>{item?.id}</TableCell>
                                                                <TableCell>{item?.name}</TableCell>
                                                                <TableCell align={'right'}>{item?.quantity}</TableCell>
                                                                <TableCell align={'right'}>{item?.rate}</TableCell>
                                                                <TableCell align={'right'}>{item?.amount}</TableCell>
                                                                <TableCell>
                                                                    <IconButton
                                                                        sx={{ m: -1 }}
                                                                        title="Delete Medicine"
                                                                        onClick={() => handleMedDelete(item?.id)}
                                                                    >
                                                                        <DeleteIcon style={{ color: 'red' }} />
                                                                    </IconButton>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                        <TableRow sx={{ backgroundColor: '#e5e5e5' }}>
                                                            <TableCell></TableCell>
                                                            <TableCell>
                                                                <TextField
                                                                    size="small"
                                                                    value={discount}
                                                                    onChange={(e) => setDiscount(e.target.value)}
                                                                    label="Discount"
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <TextField
                                                                    size="small"
                                                                    value={(totalAmount - discount).toFixed(2)}
                                                                    disabled
                                                                    label="Net Amount"
                                                                />
                                                            </TableCell>
                                                            <TableCell align={'right'}>Total</TableCell>
                                                            <TableCell align={'right'}>{totalAmount?.toFixed(2)}</TableCell>
                                                            <TableCell />
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            )}
                                        </TableContainer>
                                    </Grid>
                                </>
                            )}

                            <Grid item lg={12} md={12} sm={12} xs={12} mt={1}>
                                <Box sx={styles.btnContainer}>
                                    {loadingRequest ? (
                                        <CircularProgress size={25} color="inherit" />
                                    ) : (
                                        <>
                                            <Button
                                                onClick={() => {
                                                    setModal({ open: false, value: null });
                                                    setPrescriptionList([]);
                                                    setSelectedPatient(null);
                                                    setSelectedPhysician(null);
                                                    setSelectedVisit(null);
                                                    setTotalAmount(0);
                                                    setRate(0);
                                                    setQuantity(1);
                                                }}
                                                variant="text"
                                                sx={{ color: 'red' }}
                                            >
                                                Cancel
                                            </Button>
                                            <Button onClick={() => setPrescriptionList([])} variant="text" sx={{ color: 'red' }}>
                                                Clear All
                                            </Button>
                                            <Button onClick={handleSaveBill} variant="text" sx={{ color: COLORS.secondory }}>
                                                {modal.value == 'add' ? 'Save' : 'Update'}
                                            </Button>
                                        </>
                                    )}
                                </Box>
                            </Grid>
                        </Grid>
                    </Form>
                </Formik>
            </ModalCustom>

            <ModalCustom open={addNewPtModal}>
                <Box sx={{ width: '70vw' }}>
                    <IconButton
                        color="inherit"
                        onClick={() => {
                            setAddNewPtModal(false);
                        }}
                        sx={{ position: 'absolute', top: 10, right: 10 }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <AddNewPatient
                        isPatientAdd={async (val, newPt) => {
                            if (val == true) {
                                refetchpatientDataList();
                                setAddNewPtModal(false);
                                setSelectedPatient(newPt);
                            }
                        }}
                    />
                </Box>
            </ModalCustom>

            <div style={{ display: 'none' }}>
                <PrintBillingList ref={componentRef} billingList={billingList} startDate={startDate} endDate={endDate} isPaid={isPaid} />
            </div>

            <div style={{ display: 'none' }}>
                <PrintBillingDetail
                    ref={billingDetailRef}
                    billingDetail={{
                        ...billingDetailToPrint,
                        totalAmount: billingDetailToPrint?.amount,
                        physician: physicianDataList?.find((i) => i?.nationalID == selectedPhysician),
                        physicianFee: selectedVisit?.amount
                    }}
                />
            </div>
        </div>
    );
}

const styles = {
    btnContainer: {
        display: 'flex',
        direction: 'row',
        gap: 1,
        justifyContent: 'flex-end'
    },
    inputField: {
        padding: 10,
        borderRadius: 10,
        border: '1px solid #bfc0c2',
        fontSize: 14,
        width: '100%',
        outline: 'none',
        backgroundColor: '#f8fafc'
    }
};

// -----------------------  Custom component for AutoComplete Search Box
const AutoCompleteCom = React.memo(
    ({ data, inputref, placeholder, showKey, listName, onClick }) => {
        const handleOptionClick = (event) => {
            const selectedValue = event.target.value;
            const selectedObject = data.find(
                (item) =>
                    `${item[showKey[0]]} ${listName === 'medicine' ? `- ${item[showKey[1]]} - ${item[showKey[2]]}` : ''}` === selectedValue
            );
            if (selectedObject) {
                onClick(selectedObject);
            }
        };

        return (
            <div style={{ width: '100%' }}>
                <input
                    list={listName}
                    placeholder={placeholder}
                    style={styles.inputField}
                    type="text"
                    ref={inputref}
                    onFocus={(e) => {
                        e.target.style.border = '2px solid #2196f3'; // change border color on focus
                    }}
                    onBlur={(e) => {
                        e.target.style.border = '1px solid #bfc0c2'; // revert border color on blur
                    }}
                    onChange={handleOptionClick}
                />
                <datalist id={listName}>
                    {data &&
                        data.map((item, index) => (
                            <option
                                key={index}
                                value={`${item[showKey[0]]} ${listName == 'medicine' ? `- ${item[showKey[1]]} - ${item[showKey[2]]}` : ''}`}
                            />
                        ))}
                </datalist>
            </div>
        );
    },
    (prevProps, nextProps) => {
        // Compare relevant props, excluding data
        return prevProps.listName === nextProps.listName;
    }
);
