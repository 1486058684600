import axios from 'axios';
import { BASE_URL } from 'constants/baseUrl';

export const getAllDomains = async () => {
    const response = await axios({
        method: 'get',
        url: `${BASE_URL}api/tenant`
    });
    return response;
};
