import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Box, CircularProgress, Grid, IconButton, Tab, Tabs, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import LabTest from 'views/components/labtestPrevious';
import Diagnosis from 'views/components/diagnosisPrevious';
import Prescription from 'views/components/prescriptionPrevious';
import VitalSign from 'views/components/vitalSignPrevious';
import { BASE_URL } from 'constants/baseUrl';
import ModalCustom from 'ui-component/modals/ModalCustom';
import PrevIntakeHistoryComp from 'components/PrevIntakeHistoryComp';
import PreviousVisitCard from 'components/PreviousVisitCard';
import ViewReportsComp from 'components/ViewReportsComp';
import 'react-toastify/dist/ReactToastify.css';
// ==============================|| PreviousVisits ||============================== //

const PreviousVisitsComp = ({ patient_NationalID }) => {
    const { user } = useSelector((state) => state?.user);

    const [apiEndPoint, setApiEndPoint] = useState('patientsPreviousVisits');
    const [selectedTabValue, setSelectedTabValue] = useState(0);

    const [previousVisitsList, setPreviousVisitsList] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [open, setOpen] = useState({ title: null, visit: null });

    const getPreviousVisitsList = async () => {
        try {
            setLoading(true);
            setError(null);
            setPreviousVisitsList(null);

            const res = await axios({
                method: 'get',
                url: `${BASE_URL}api/patient/${apiEndPoint}/${patient_NationalID}?pageNumber=1&pageSize=10&QuerySearch=`,
                headers: {
                    Authorization: `Bearer ${user?.token}`
                }
            });

            setPreviousVisitsList(res?.data);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getPreviousVisitsList();

        return () => {
            setLoading(true);
            setError(null);
            setPreviousVisitsList(null);
        };
    }, [selectedTabValue]);

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`
        };
    }

    const handleChange = (event, newValue) => {
        setApiEndPoint(newValue == 0 ? 'patientsPreviousVisits' : 'patientsPreviousVisitsShared');
        setSelectedTabValue(newValue);
        console.log('val  -- ', newValue);
    };

    return (
        <div style={{ width: '70vw' }}>
            {/* -----------------------  History Modal  -------------------------------------- */}
            {open.title == 'History' && (
                <PrevIntakeHistoryComp
                    open={open.title == 'History'}
                    onClose={() => {
                        setOpen({ title: null, visit: null });
                    }}
                    visit={open?.visit}
                    domain={open?.visit?.domain || ''}
                />
            )}
            {/* -----------------------  Vital Modal  -------------------------------------- */}
            <ModalCustom open={open.title == 'Vital'} title={'Vital Signs'}>
                <Box sx={{ width: '40vw' }}>
                    <IconButton
                        color="inherit"
                        onClick={() => setOpen({ title: null, visit: null })}
                        sx={{ position: 'absolute', top: 10, right: 10 }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <VitalSign visitID={open?.visit?.id} domain={open?.visit?.domain || ''} />
                </Box>
            </ModalCustom>

            {/* -----------------------  Lab Report Modal  -------------------------------------- */}
            {open.title == 'LabReport' && (
                <ViewReportsComp
                    open={open.title == 'LabReport'}
                    onClose={() => {
                        setOpen({ title: null, visit: null });
                    }}
                    visit={open?.visit}
                    domain={open?.visit?.domain || ''}
                />
            )}

            {/* -----------------------  Prescription Modal  -------------------------------------- */}
            <ModalCustom open={open?.title == 'Prescription'} title={'Prescritption'}>
                <Box sx={{ width: '40vw' }}>
                    <IconButton
                        color="inherit"
                        onClick={() => setOpen({ title: null, visit: null })}
                        sx={{ position: 'absolute', top: 10, right: 10 }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Prescription visitID={open?.visit?.id} domain={open?.visit?.domain || ''} />
                </Box>
            </ModalCustom>

            {/* -----------------------  Diagnosis Modal  -------------------------------------- */}
            <ModalCustom open={open?.title == 'Diagnosis'} title={'Diagnosis'}>
                <Box sx={{ width: '40vw' }}>
                    <IconButton
                        color="inherit"
                        onClick={() => setOpen({ title: null, visit: null })}
                        sx={{ position: 'absolute', top: 10, right: 10 }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Diagnosis visitID={open?.visit?.id} domain={open?.visit?.domain || ''} />
                </Box>
            </ModalCustom>

            {/* -----------------------  Lab Test Modal  -------------------------------------- */}
            <ModalCustom open={open?.title == 'LabTest'} title={'Lab Test'}>
                <Box sx={{ width: '40vw' }}>
                    <IconButton
                        color="inherit"
                        onClick={() => setOpen({ title: null, visit: null })}
                        sx={{ position: 'absolute', top: 10, right: 10 }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <LabTest visitID={open?.visit?.id} domain={open?.visit?.domain || ''} />
                </Box>
            </ModalCustom>

            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={selectedTabValue} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Current" {...a11yProps(0)} />
                    <Tab label="Shared" {...a11yProps(1)} />
                </Tabs>
            </Box>

            <Box sx={{ gap: 10, mt: 2 }}>
                {!!previousVisitsList &&
                    previousVisitsList.items.map((row) => {
                        return (
                            <PreviousVisitCard
                                key={row?.id}
                                physicianName={row?.doctorName}
                                physicianImage={row?.physicianProfileImage}
                                physicianSpeciality={row?.doctorSpeciality}
                                physicianFee={row?.amount}
                                visitId={row?.id}
                                visitDateTime={row?.startDateTime}
                                visitTitle={row?.title}
                                visitSummary={row?.summaryNotes}
                                initialComplain={row?.initialComplain}
                                notes={row?.notes}
                                referredTo={row?.referredTo}
                                healthCareProvider={selectedTabValue == 0 ? false : row?.providerName}
                                domain={row?.domain || user?.domain}
                                onHistoryClick={() => setOpen({ title: 'History', visit: row })}
                                onVitalClick={() => setOpen({ title: 'Vital', visit: row })}
                                onLabReportClick={() => setOpen({ title: 'LabReport', visit: row })}
                                onPrescriptionClick={() => setOpen({ title: 'Prescription', visit: row })}
                                onDiagnosisClick={() => setOpen({ title: 'Diagnosis', visit: row })}
                                onLabTestClick={() => setOpen({ title: 'LabTest', visit: row })}
                            />
                        );
                    })}

                {loading && (
                    <Grid sx={styles.loadingContainer}>
                        <CircularProgress size={35} color="inherit" />
                    </Grid>
                )}

                {!!error && (
                    <Grid sx={styles.loadingContainer}>
                        <Typography>{error?.response?.data?.message ?? error?.response?.data ?? error?.message}</Typography>
                    </Grid>
                )}
            </Box>
        </div>
    );
};

export default PreviousVisitsComp;

const styles = {
    loadingContainer: {
        height: 500,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    btnContainer: {
        display: 'flex',
        direction: 'row',
        gap: 1,
        justifyContent: 'flex-end',
        mt: 2
    },
    loaderBoxContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
};
