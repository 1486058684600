import React, { useState, useEffect, useRef } from 'react';

import { Accordion, AccordionDetails, AccordionSummary, Box, CircularProgress, IconButton, Typography } from '@mui/material';

import { BASE_URL } from 'constants/baseUrl';
import { useSelector } from 'react-redux';

import ModalCustom from 'ui-component/modals/ModalCustom';
import CloseIcon from '@mui/icons-material/Close';
import { getReportByVisit } from 'services/labReports';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';

export default function ViewReportsComp({ visit, open, onClose, domain = '' }) {
    const { user } = useSelector((state) => state?.user);

    const [reports, setReports] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedImage, setSelectedImage] = useState({ open: false, image: null });

    const getReports = async () => {
        try {
            const res = await getReportByVisit(visit?.id, user?.token, domain);
            setReports(res);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getReports();

        return () => {
            setLoading(true);
            setError(null);
            setReports(null);
        };
    }, [open]);

    const isImage = (ext) => ['jpg', 'jpeg', 'png', 'gif'].includes(ext);
    const isVideo = (ext) => ['mp4', 'webm'].includes(ext);
    const isAudio = (ext) => ['mp3', 'wav'].includes(ext);
    const getFileExtension = (url) => {
        return url.split('.').pop().toLowerCase();
    };

    return (
        <>
            <ModalCustom open={open} title={'Lab Reports'}>
                <Box sx={{ width: '40vw' }}>
                    {loading ? (
                        <Box sx={styles.loadingContainer}>
                            <CircularProgress size={35} color="inherit" />
                        </Box>
                    ) : (
                        <>
                            <IconButton color="inherit" onClick={onClose} sx={{ position: 'absolute', top: 10, right: 10 }}>
                                <CloseIcon />
                            </IconButton>

                            {reports?.map((item) => (
                                <Accordion key={item?.type}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography sx={styles.accordianTitle}>{item?.type}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                                            {item?.list.map((report) => (
                                                <Box
                                                    key={report?.imagePath}
                                                    onClick={() => {
                                                        if (isImage(getFileExtension(report?.imagePath))) {
                                                            setSelectedImage({
                                                                open: true,
                                                                image: report?.imagePath,
                                                                domain: report?.domain
                                                            });
                                                        } else {
                                                            window.open(
                                                                `${BASE_URL}media/${report?.domain}/Documents/${report?.imagePath}`,
                                                                '_blank'
                                                            );
                                                        }
                                                    }}
                                                    sx={styles.imageBox}
                                                >
                                                    {isImage(getFileExtension(report?.imagePath)) ? (
                                                        <img
                                                            src={`${BASE_URL}media/${report?.domain}/Documents/${report?.imagePath}`}
                                                            alt={'lab report'}
                                                            style={{
                                                                width: 80,
                                                                height: 60,
                                                                backgroundColor: '#d5d5d5',
                                                                display: 'inline-block',
                                                                objectFit: 'cover'
                                                            }}
                                                        />
                                                    ) : (
                                                        <div
                                                            style={{
                                                                width: 80,
                                                                height: 60,
                                                                backgroundColor: '#d5d5d5',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center'
                                                            }}
                                                        >
                                                            <h5>{getFileExtension(report?.imagePath)?.toUpperCase()}</h5>
                                                        </div>
                                                    )}

                                                    <Typography variant="h6">{report?.domain?.toUpperCase()}</Typography>
                                                    <Typography variant="h6">Visit ID: {report?.visitId}</Typography>
                                                    <Typography variant="h6">{moment(report?.createdOn).format('MMM DD, YYYY')}</Typography>

                                                    {user?.userId == report?.createdBy &&
                                                        moment().diff(moment(report?.createdOn), 'hours') < 1 && ( // if image created time is in 1 hour then user can delete the image who have uploaded it
                                                            <IconButton
                                                                size="small"
                                                                sx={{ bgcolor: 'red', position: 'absolute', top: 5, right: 5 }}
                                                                onClick={(e) => {
                                                                    e?.stopPropagation();
                                                                    setLabReportId(report?.labTestID);
                                                                    setConfirmationModal(true);
                                                                }}
                                                            >
                                                                <DeleteIcon sx={{ fontSize: 20, color: '#fff' }} />
                                                            </IconButton>
                                                        )}
                                                </Box>
                                            ))}
                                        </Box>
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </>
                    )}
                    {!!error && (
                        <Typography sx={{ ...styles.loadingContainer, py: 1 }}>
                            {error?.response?.data?.message || error?.response?.data || error?.message}
                        </Typography>
                    )}
                </Box>
            </ModalCustom>

            <ModalCustom open={selectedImage?.open}>
                <IconButton
                    color="inherit"
                    onClick={() => setSelectedImage({ open: false, image: null })}
                    sx={{ position: 'absolute', top: 8, right: 5, backgroundColor: '#fff' }}
                >
                    <CloseIcon />
                </IconButton>
                <Box sx={{ ml: -2.1, mr: -2.7, mb: -1.7, mt: -3.2 }}>
                    <img
                        src={`${BASE_URL}media/${domain || user?.domain}/Documents/${selectedImage?.image}`}
                        alt={'select report'}
                        width={'100%'}
                    />
                </Box>
            </ModalCustom>
        </>
    );
}

const styles = {
    loadingContainer: {
        height: 'auto',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    accordianTitle: { fontSize: 16, fontWeight: 600 },
    imageBox: {
        border: '1px solid #d5d5d5',
        borderRadius: 0.7,
        overFlow: 'hidden',
        padding: 0.5,
        width: 90,
        position: 'relative',
        cursor: 'pointer'
    }
};
