import { combineReducers, configureStore } from '@reduxjs/toolkit';
import customizationReducer from './customizationReducer';
import userReducer from './slices/userSlice';
import configReducer from './slices/configSlice';
import profileReducer from './slices/profileSlice';
import campVisitReducer from './slices/campVisitSlice';
import transcriptionReducer from './slices/transcriptionSlice';
import thunk from 'redux-thunk';
import locationReducer from './slices/locationSlice';
import patientListReducer from './slices/patientListSlice';
import physicianListReducer from './slices/physicianListSlice';

// ==============================|| REDUX - MAIN STORE ||============================== //

let rootReducer = combineReducers({
    customization: customizationReducer,
    user: userReducer,
    config: configReducer,
    profiles: profileReducer,
    campVisit: campVisitReducer,
    transcription: transcriptionReducer,
    location: locationReducer,
    patientList: patientListReducer,
    physicianList: physicianListReducer
});

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk)
});
