import axios from 'axios';
import { BASE_URL } from 'constants/baseUrl';
const user = JSON.parse(localStorage.getItem('user'));

// -------------------------  Get Reports using visit Id

export const getReportByVisit = async (visitId, token, domain = '') => {
    const response = await axios({
        method: 'get',
        url: `${BASE_URL}api/LabTest/visitLabtest/${visitId}/${domain}`,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    });

    if (response?.data) {
        const mergedData = response?.data.reduce((acc, item) => {
            const existingItem = acc.find((obj) => obj.type === item.type);

            if (existingItem) {
                existingItem.list.push(item);
            } else {
                acc.push({
                    type: item.type,
                    list: [item]
                });
            }

            return acc;
        }, []);

        return mergedData;
    }

    return response;
};

// ------------------------ Get Reports of Single Patient from all Domains

export const getReportByPatient = async (patientNationalId, token) => {
    const response = await axios({
        method: 'get',
        url: `${BASE_URL}api/LabTest/patientLabtests/${patientNationalId}`,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    });

    if (response?.data) {
        const mergedData = response?.data.reduce((acc, item) => {
            const existingItem = acc.find((obj) => obj.type === item.type);

            if (existingItem) {
                existingItem.list.push(item);
            } else {
                acc.push({
                    type: item.type,
                    list: [item]
                });
            }

            return acc;
        }, []);

        return mergedData;
    }

    return response;
};

export const postReportByVisit = async (visit) => {
    const response = await axios({
        method: 'post',
        url: `${BASE_URL}api/${role}/${nationalId}`,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user?.token}`
        }
    });

    return response;
};
