import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import { Autocomplete, Button, CircularProgress, FormControlLabel, Grid, Switch, TextField, Typography } from '@mui/material';

import * as Yup from 'yup';
import { Formik, Form } from 'formik';

import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// ------- Importing Components
import Textfield from 'ui-component/FormUI/Textfield';
import SelectCustom from 'ui-component/FormUI/Select';
import MuiPhoneNumber from 'material-ui-phone-number';

// ------- Importing Constants
import { BASE_URL } from 'constants/baseUrl';
import { COLORS } from 'constants/colors';

// ------- Importing Redux Store
import { useDispatch, useSelector } from 'react-redux';
import useFetch from 'hooks/useFetch';
import TranslationContext from 'context/translation';
import { fetchPatientList } from 'store/slices/patientListSlice';

export default function AddNewPatient({ isPatientAdd }) {
    const { translate } = React.useContext(TranslationContext);
    const { user } = useSelector((state) => state.user);
    const dispatch = useDispatch();

    const [dateOfBirth, setDateOfBirth] = useState(null);
    const [age, setAge] = useState(null);
    const [guardianPhone, setGuardianPhone] = useState('');
    const [inHospital, setInHospital] = useState(false);
    const [selectedWardId, setSelectedWardId] = useState(null);
    const [mobileNo, setMobileNo] = useState('');
    const [loading, setLoading] = useState(false);

    const { data: wardList, loading: loadingWard, error: errorWard, refetch: refetchWard } = useFetch(`${BASE_URL}api/ward`);

    let { config } = useSelector((state) => state?.config);
    const checkMrNumber = config?.find((mr) => mr?.title == 'MrAutoEnable');

    const INITIAL_FORM_STATE = {
        title: '',
        mrNumber: '',
        firstName: '',
        lastName: '',
        emailId: '',
        identificationNo: '',
        gender: '',
        address: '',
        guardianName: ''
    };

    const FORM_VALIDATION = Yup.object().shape({
        ...(checkMrNumber?.value == 'true' ||
            (checkMrNumber?.value == true
                ? {}
                : {
                      mrNumber: Yup.number().typeError('Medical Record No. must be a number').required('Required')
                  })),
        title: Yup.string().required('Required'),
        firstName: Yup.string().trim().required('Required'),
        lastName: Yup.string().required('Required'),
        emailId: Yup.string().email('Invalid email'),
        identificationNo: Yup.number().typeError('National ID must be a number').required('Required'),
        // .test('length', 'Input must be exactly 13 characters', (value) => value && value.toString().length === 13),
        gender: Yup.string().required('Required'),
        address: Yup.string().required('Required')
    });

    const handleSubmit = async (values, resetForm) => {
        setLoading(true);
        try {
            if (!!mobileNo?.trim().replace(/\D/g, '') == false) {
                return toast.error('Please enter mobile number');
            }
            if (inHospital && selectedWardId == null) {
                return toast.error('Please select ward');
            }
            if (dateOfBirth == null) {
                return toast.error('Please enter date of birth');
            }

            const { title, mrNumber, firstName, lastName, emailId, identificationNo, gender, address, guardianName } = values;
            let nationalID = uuidv4();

            const responseCreateAccount = await axios({
                method: 'post',
                url: `${BASE_URL}api/accounts/create`,
                data: {
                    email: emailId ? emailId?.trim() : `${user?.domain}-${firstName.trim().replace(' ', '-')}-${nationalID}@dummy.com`,
                    userName: emailId ? emailId?.trim() : `${user?.domain}-${firstName.trim().replace(' ', '-')}-${nationalID}@dummy.com`,
                    firstName: firstName?.trim(),
                    lastName: lastName?.trim(),
                    roleName: 'Patient',
                    phoneNumber: mobileNo?.trim().replace(/\D/g, ''),
                    address: address?.trim(),
                    titles: title?.trim(),
                    employeeCode: mrNumber?.trim(),
                    identificationNo: identificationNo?.trim(),
                    createdBy: user?.userId,
                    createdOn: new Date(),
                    profileImage: 'cloudclinic_dummy.jpg'
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user?.token}`
                }
            });

            if (responseCreateAccount?.data) {
                const { id } = responseCreateAccount?.data;

                const data = {
                    nationalID: nationalID,
                    identificationNo: identificationNo?.trim(),
                    name: firstName?.trim(),
                    dob: dateOfBirth,
                    address: address?.trim(),
                    phone: mobileNo?.trim().replace(/\D/g, ''),
                    gender: gender,
                    guardianName: guardianName?.trim(),
                    guardianPhone: guardianPhone?.trim().replace(/\D/g, ''),
                    email: emailId ? emailId?.trim() : `${user?.domain}-${firstName.trim().replace(' ', '-')}-${nationalID}@dummy.com`,
                    userID: id,
                    titles: title?.trim(),
                    lastName: lastName?.trim(),
                    inHospital: inHospital,
                    mrNumber: mrNumber?.trim(),
                    role: 'Patient',
                    relation: 'self',
                    createdBy: user?.userId,
                    createdOn: new Date(),
                    isActive: true,
                    profileImage: 'cloudclinic_dummy.jpg',
                    wardID: inHospital ? selectedWardId : null
                };

                const responseCreatePatient = await axios({
                    method: 'post',
                    url: `${BASE_URL}api/patient`,
                    data,
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${user?.token}`
                    }
                });

                if (responseCreatePatient?.data) {
                    resetForm();
                    setMobileNo('+92');
                    setGuardianPhone('+92');
                    setDateOfBirth(null);
                    setInHospital(false);
                    setSelectedWardId(null);
                    isPatientAdd(true, responseCreatePatient?.data, age);
                    setAge(null);
                    dispatch(fetchPatientList());
                    return toast.success('Patient created successfully');
                }
            }
        } catch (error) {
            console.log(error?.response?.status);
            if (error?.response?.status == 403) {
                return toast.error('Employee Id already exit');
            }
            return toast.error(error?.response?.data?.modelState[''][0] ?? error?.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <Grid mb={3} container direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="h3" color={COLORS.color1}>
                    {translate('ADD_NEW_PATIENT')}
                </Typography>
            </Grid>

            <Formik
                initialValues={{
                    ...INITIAL_FORM_STATE
                }}
                validationSchema={FORM_VALIDATION}
                onSubmit={(values, { resetForm }) => {
                    setLoading(true);
                    handleSubmit(values, resetForm);
                }}
            >
                <Form>
                    <Typography variant="h4" mb={1} sx={{ fontWeight: 500 }} color={COLORS.color1}>
                        {translate('PERSONAL_INFORMATION')}
                    </Typography>

                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <Grid container direction="row" p={2} spacing={2}>
                            <Grid item lg={1} md={1.5} sm={2.5} xs={12}>
                                <SelectCustom defaultValue="" name="title" label={`${translate('TITLE')}*`} options={['Mr', 'Ms']} />
                            </Grid>

                            <Grid item lg={2} md={2.5} sm={3.5} xs={12}>
                                <Textfield
                                    name="mrNumber"
                                    label={`${translate('MEDICAL_RECORD_NUMBER')}*`}
                                    disabled={checkMrNumber?.value == 'true'}
                                />
                            </Grid>

                            <Grid item lg={3} md={4} sm={6} xs={12}>
                                <Textfield name="firstName" label={`${translate('FIRST_NAME')}*`} />
                            </Grid>

                            <Grid item lg={3} md={4} sm={6} xs={12}>
                                <Textfield name="lastName" label={`${translate('LAST_NAME')}*`} />
                            </Grid>

                            <Grid item lg={3} md={4} sm={6} xs={12}>
                                <Textfield name="emailId" label={`${translate('EMAIL')}`} />
                            </Grid>

                            <Grid item lg={3} md={4} sm={6} xs={12}>
                                <Textfield name="identificationNo" label={`${translate('NATIONAL_ID')}*`} />
                            </Grid>

                            <Grid item lg={3} md={4} sm={6} xs={12}>
                                <SelectCustom
                                    defaultValue=""
                                    name="gender"
                                    label={`${translate('GENDER')}*`}
                                    options={['Male', 'Female', 'Other']}
                                />
                            </Grid>

                            <Grid item lg={2} md={4} sm={6} xs={12}>
                                <TextField
                                    type="date"
                                    variant="standard"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    label={`${translate('DATE_OF_BIRTH')}*`}
                                    value={dateOfBirth}
                                    InputProps={{
                                        inputProps: {
                                            max: new Date().toLocaleDateString('en-CA')
                                        }
                                    }}
                                    onChange={(e) => {
                                        let x = e.target.value;
                                        let y = x.split('-')[0];
                                        let a = new Date().getFullYear();

                                        let b = a - y;
                                        setDateOfBirth(x);
                                        setAge(b);
                                    }}
                                />
                            </Grid>

                            <Grid item lg={1} md={1} sm={2} xs={4}>
                                <TextField
                                    label={`${translate('AGE')}*`}
                                    variant="standard"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    value={age}
                                    onChange={(event) => {
                                        const age = event.target.value;
                                        setAge(age);

                                        // Calculate DOB and set it
                                        if (age) {
                                            const today = dateOfBirth ? new Date(dateOfBirth) : new Date();
                                            const birthYear = new Date().getFullYear() - parseInt(age, 10);
                                            const dob = new Date(birthYear, today.getMonth(), today.getDate());
                                            setDateOfBirth(new Date(dob).toLocaleDateString('en-CA'));
                                        } else {
                                            // If age is cleared, clear the DOB as well
                                            setDateOfBirth(null);
                                        }
                                    }}
                                />
                            </Grid>

                            <Grid item lg={3} md={4} sm={6} xs={12}>
                                <MuiPhoneNumber
                                    fullWidth
                                    // onlyCountries={['us', 'pk']}
                                    defaultCountry={'pk'}
                                    autoFormat={true}
                                    disableAreaCodes={true}
                                    label={`${translate('MOBLIE_NUMBER')}*`}
                                    value={mobileNo}
                                    onChange={(value) => setMobileNo(value)}
                                />
                            </Grid>

                            <Grid item lg={3} md={4} sm={6} xs={12}>
                                <Textfield name="guardianName" label={`${translate('EMERGENCY_CONTACT_NAME')}`} />
                            </Grid>

                            <Grid item lg={3} md={4} sm={6} xs={12}>
                                <MuiPhoneNumber
                                    fullWidth
                                    // onlyCountries={['us', 'pk']}
                                    defaultCountry={'pk'}
                                    autoFormat={true}
                                    disableAreaCodes={true}
                                    label={`${translate('EMERGENCY_CONTACT_NUMBER')}`}
                                    value={guardianPhone}
                                    onChange={(value) => setGuardianPhone(value)}
                                />
                            </Grid>

                            <Grid item lg={6} md={4} sm={12} xs={12}>
                                <Textfield name="address" label={`${translate('ADDRESS')}*`} />
                            </Grid>

                            <Grid item lg={3} md={4} sm={6} xs={12}>
                                <FormControlLabel
                                    control={<Switch checked={inHospital} onChange={(e) => setInHospital(e.target.checked)} />}
                                    label={`${translate('IN_HOSPITAL')}`}
                                />
                            </Grid>

                            {inHospital && (
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <Autocomplete
                                        options={wardList ?? []}
                                        getOptionLabel={(ward) => `${ward?.name}`}
                                        value={wardList?.find((i) => i?.wardID == selectedWardId)}
                                        onChange={(event, selected) => {
                                            setSelectedWardId(selected?.wardID || null);
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} label={`${translate('WARD')}`} variant="standard" />
                                        )}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </Paper>

                    <div style={{ marginTop: 16, display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            disabled={loading}
                            type="submit"
                            variant="contained"
                            sx={{ width: 200, backgroundColor: COLORS.secondory, '&: hover': { background: COLORS.primary } }}
                        >
                            {loading ? <CircularProgress size={25} color="inherit" /> : translate('SAVE')}
                        </Button>
                    </div>
                </Form>
            </Formik>
        </div>
    );
}

AddNewPatient.defaultProps = {
    isPatientAdd: () => {}
};
