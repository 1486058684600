import React from 'react';

import './print.css';
import { BASE_URL } from 'constants/baseUrl';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Typography } from '@mui/material';
import jwt_decode from 'jwt-decode';

const PrintToken = React.forwardRef((props, ref) => {
    const { user } = useSelector((state) => state.user);
    const { visit } = props;

    var decoded = jwt_decode(user?.token);
    return (
        <div ref={ref} style={styles.container}>
            {[1, 2]?.map((i) => (
                <div key={i}>
                    <div style={{ display: 'flex' }} key={i}>
                        <img src={`${BASE_URL}images/${user?.domainLogo}`} alt="logo" style={styles.logo} />

                        <div style={{ marginLeft: 20 }}>
                            <Typography sx={{ fontSize: 35, fontWeight: 'bold', color: '#444' }}>{decoded?.Name}</Typography>
                            <Typography>
                                {visit?.location?.name}, {visit?.location?.address}
                            </Typography>
                        </div>
                    </div>

                    <div style={styles?.line} />

                    <div style={{ display: 'flex' }}>
                        <div style={{ width: 200 }}>
                            <Typography sx={styles.headerToken}>Token No.</Typography>
                        </div>
                        <Typography sx={styles.headerToken}>{visit?.tokenNumber}</Typography>
                    </div>

                    <HorizontalText left={'Name'} right={`${visit?.patientFirstName} ${visit?.patientLastName}`} />
                    <HorizontalText left={'Age / Gender'} right={`${visit?.patientAge} / ${visit?.patientGender}`} />
                    <HorizontalText left={'Physician'} right={visit?.consultantName?.split('/')[0]} />
                    <HorizontalText left={'Speciality'} right={visit?.doctorSpeciality} />
                    <HorizontalText left={'Order Time'} right={moment(visit?.startDateTime).format('MMM DD, YYYY - hh:mm A')} />

                    <div style={{ border: '1px dotted #444', padding: 10, marginTop: 10 }}>
                        <div style={{ marginTop: -10 }}>
                            <HorizontalText left={'Profile / Tests:'} right={`Consultancy`} />
                        </div>

                        <div style={{ display: 'flex' }}>
                            <div style={{ width: '50%' }}>
                                <HorizontalText left={'Amount'} right={`Rs. ${visit?.amount?.toFixed(2)}`} />
                            </div>
                            <HorizontalText left={'Net Amount'} right={`Rs. ${visit?.amount?.toFixed(2)}`} />
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
});

const HorizontalText = ({ left, right }) => {
    return (
        <div style={{ display: 'flex', marginTop: 10 }}>
            <Typography sx={{ width: 200, fontSize: 15, fontWeight: 'bold' }}>{left}</Typography>
            <Typography sx={{ fontSize: 15 }}>{right}</Typography>
        </div>
    );
};

export default PrintToken;

const styles = {
    container: {
        margin: 50,
        display: 'flex',
        flexDirection: 'column',
        gap: 100
    },
    logo: {
        height: 50
    },
    line: { height: 3, backgroundColor: '#444', marginTop: 20, marginBottom: 20 },
    headerToken: {
        py: 1,
        textAlign: 'center',
        width: 130,
        borderRadius: 100,
        border: '1px solid #000',
        fontSize: 16,
        fontWeight: 'bold'
    }
};
