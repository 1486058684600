import * as React from 'react';
import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    CircularProgress,
    Typography,
    Button
} from '@mui/material';

import { COLORS } from 'constants/colors';
import { useState } from 'react';
import { BASE_URL } from 'constants/baseUrl';
import { useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SearchField from 'ui-component/FormUI/SearchField.js';
import moment from 'moment';
import TranslationContext from 'context/translation';
import { useNavigate } from 'react-router';

export default function CampVisits({ visit, setVisit, updateVisitData, updatePatientTags, onClose }) {
    const navigate = useNavigate();
    const { user } = useSelector((state) => state?.user);
    const { translate } = React.useContext(TranslationContext);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [count, setCount] = useState(0);

    const handleChangePage = (event, newPage) => {
        getCampVisitList(newPage, rowsPerPage, searchQuery);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        getCampVisitList(page, event.target.value, searchQuery);
        setRowsPerPage(+event.target.value);
    };

    const [campVisitList, setCampVisitList] = useState(null);
    const [searchQuery, setSearchQuery] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [selectedRow, setSelectedRow] = useState(null);

    const [openModal, setOpenModal] = useState(false);
    const [loadingRequest, setLoadingRequest] = useState(false);

    const getCampVisitList = async (pageNumber, pageSize, searchValue) => {
        try {
            setLoading(true);
            setError(null);
            setCampVisitList(null);

            const res = await axios({
                method: 'get',
                url: `${BASE_URL}api/visit/primaryCareVisitsList/${user?.userId}?pageNumber=${
                    pageNumber + 1
                }&pageSize=${pageSize}&QuerySearch=${searchValue ?? ''}&isConfirm=&isCampOn=true`,
                headers: {
                    Authorization: `Bearer ${user?.token}`
                }
            });

            if (res?.data?.length == 0) {
                throw Error('Record not found');
            } else {
                setCampVisitList(res?.data);
                setCount(res?.data?.paginationMetadata?.totalCount);
            }
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    const updateMeeting = async () => {
        setLoadingRequest(true);
        try {
            const data = {
                title: selectedRow?.title,
                summaryNotes: selectedRow?.summaryNotes,
                StartDateTime: moment(selectedRow?.startDateTime).format(),
                EndDateTime: moment(selectedRow?.endDateTime).format(),
                patient_NationalID: selectedRow?.patient_NationalID,
                consultant_NationalID: visit?.consultant_NationalID,
                meetinglink: visit?.meetinglink,
                status: 1,
                lastModifiedBy: user?.userId,
                lastModifiedOn: new Date()
            };

            console.log('visit?.consultant_NationalID ', visit?.consultant_NationalID);
            console.log('selectedRow?.consultant_NationalID ', selectedRow?.consultant_NationalID);

            const responseUpdateApt = await axios({
                method: 'put',
                url: `${BASE_URL}api/visit/updateEvent/${selectedRow.id}`,
                data,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user?.token}`
                }
            });

            if (responseUpdateApt.status == 200) {
                toast.success(translate('APPOINTMENT_UPDATED_SUCCESSFULLY'));
                handleEndMeeting();
                handleSendNotification();
                setVisit({ ...selectedRow, ...data });
                navigate(`/meeting/${selectedRow?.id}`, { state: { ...selectedRow, ...data } });
                onClose();
                return;
            }
        } catch (error) {
            return toast.error(error?.response?.data?.message ?? error?.response?.data ?? error?.message);
        } finally {
            setLoadingRequest(false);
        }
    };

    const handleEndMeeting = async () => {
        try {
            const resUpdateVisit = await axios({
                method: 'put',
                url: `${BASE_URL}api/visit/updateVisit/${visit.id}`,
                data: updateVisitData,
                headers: { Authorization: `Bearer ${user?.token}` }
            });

            const resUpdatePtTags = await axios({
                method: 'put',
                url: `${BASE_URL}api/visit/updatePatientTags/${visit.patient_NationalID}`,
                data: updatePatientTags,
                headers: { Authorization: `Bearer ${user?.token}` }
            });

            if (resUpdateVisit.data && resUpdatePtTags.data) {
                toast.success('Next Meeting Started');
            }
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.response?.data ?? error?.message);
        }
    };

    const handleSendNotification = async () => {
        try {
            const resNotif = await axios({
                method: 'put',
                url: `${BASE_URL}api/visit/SendNextAppointmentNotification/${selectedRow.id}?userid=${user.userId}`,
                headers: { Authorization: `Bearer ${user?.token}` }
            });

            if (resNotif.data) {
                console.log('notification sended');
            }
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.response?.data ?? error?.message);
        }
    };

    const startMeeting = async () => {
        navigate(`/meeting/${selectedRow?.id}`, { state: selectedRow });
        setVisit(selectedRow);
        handleEndMeeting();
        handleSendNotification();
        onClose();
    };

    useEffect(() => {
        getCampVisitList(page, rowsPerPage, searchQuery);
    }, []);

    return (
        <div>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <Box sx={{ mb: 1, mt: 1 }}>
                    <SearchField
                        size="small"
                        label="Search"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        onClickSearch={() => {
                            setPage(0);
                            getCampVisitList(0, rowsPerPage, searchQuery);
                        }}
                        onClickClear={() => {
                            setPage(0);
                            setSearchQuery('');
                            getCampVisitList(0, rowsPerPage, '');
                        }}
                        titleSearchBtn={'Search Patient'}
                        titleClearBtn={'Clear search list'}
                    />
                </Box>

                <TableContainer sx={{ maxHeight: 'auto' }}>
                    <Table size="small" stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow
                                sx={{
                                    '& th': {
                                        color: '#fff',
                                        backgroundColor: COLORS.secondory
                                    }
                                }}
                            >
                                <TableCell align={'left'}>Visit Id</TableCell>
                                <TableCell align={'left'}>Patient</TableCell>
                                <TableCell align={'left'}>Physician</TableCell>
                                <TableCell align={'left'}>Start Time</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!!campVisitList &&
                                campVisitList?.items?.map((row) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.medicineID}
                                            sx={{
                                                backgroundColor: selectedRow?.id == row?.id ? '#d8f1ff' : '#fff',
                                                '&:hover': { cursor: 'pointer' }
                                            }}
                                            onClick={() => setSelectedRow(row)}
                                        >
                                            <TableCell align={'left'}>{row?.id}</TableCell>

                                            <TableCell align={'left'} sx={{ textTransform: 'capitalize' }}>
                                                {row?.patientFirstName?.toLowerCase()} {row?.patientLastName?.toLowerCase()}
                                            </TableCell>

                                            <TableCell align={'left'} sx={{ textTransform: 'capitalize' }}>
                                                {row?.consultantName?.split('/')[0]?.toLowerCase()}
                                            </TableCell>

                                            <TableCell align={'left'}>{moment(row?.startDateTime).format('MMM DD - hh:mm A')}</TableCell>
                                        </TableRow>
                                    );
                                })}

                            {loading && (
                                <TableRow sx={{ height: 400 }}>
                                    <TableCell align="center" colSpan={10}>
                                        <CircularProgress size={35} color="inherit" />
                                    </TableCell>
                                </TableRow>
                            )}

                            {!!error && (
                                <TableRow sx={{ height: 400 }}>
                                    <TableCell align="center" colSpan={10}>
                                        <Typography>{error?.response?.data?.message ?? error?.message}</Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

                {console.log('SC ', selectedRow?.consultant_NationalID, ' - VC ', visit?.consultant_NationalID)}

                {selectedRow && (
                    <Box sx={styles.btnContainer}>
                        {loadingRequest ? (
                            <CircularProgress size={25} color="inherit" />
                        ) : (
                            <>
                                {selectedRow?.consultant_NationalID == visit?.consultant_NationalID ? (
                                    <Button onClick={startMeeting} variant="text" sx={{ color: COLORS.secondory }}>
                                        Start
                                    </Button>
                                ) : (
                                    <Button onClick={updateMeeting} variant="text" sx={{ color: COLORS.secondory }}>
                                        Update and Start
                                    </Button>
                                )}
                            </>
                        )}
                    </Box>
                )}

                <TablePagination
                    rowsPerPageOptions={[5, 10, 20]}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
}

const styles = {
    btnContainer: {
        display: 'flex',
        direction: 'row',
        gap: 1,
        justifyContent: 'flex-end',
        mt: 1,
        mb: -1
    }
};
