export const french = {
    ABNORMAL_LAB_TEST: 'Test de laboratoire anormal',
    ABORTION_MISCARRIAGE: 'Avortement/fausse couche',
    ACCIDENT: 'Accident',
    ACTIVE: 'Actif',
    ADD: 'Ajouter',
    ADD_A_NEW_RECEPTIONIST: 'Ajouter une nouvelle réceptioniste',
    ADD_APPOINTMENT: 'Ajouter un rendez-vous',
    ADD_APPOINTMENT_SLOTS: 'Ajouter des rendez-vous possibles',
    ADD_DOCUMENTS: 'Ajouter un document',
    ADD_LOCATION: 'Ajouter un emplacement',
    ADD_MULTIPLE_NEW_PATIENT: 'Ajouter plusieurs nouveaux patients',
    ADD_MULTIPLE_NEW_PHYSICIAN: 'Ajouter plusieurs nouveaux médecins',
    ADD_NEW_LOCATION: 'Ajouter un nouvel emplacement',
    ADD_NEW_MEDICINE: 'Ajouter un nouveau médicament',
    ADD_NEW_NURSE: 'Ajouter un nouveau Médecin/Infirmière',
    ADD_NEW_PACKAGE: 'Ajouter un nouveau forfait',
    ADD_NEW_PATIENT: 'Ajouter un nouveau patient',
    ADD_NEW_PHYSICIAN: 'Ajouter un nouveau médecin',
    ADD_NEW_RECEPTIONIST: 'Ajouter un nouveau Réceptionniste',
    ADD_NEW_WARD: 'Ajouter un nouveau pavillon',
    ADD_NOTES: 'Ajouter des remarques',
    ADD_NURSE: 'Ajouter une infirmière',
    ADD_PACKAGE: 'Ajouter un forfait',
    ADD_PATIENT: 'Ajouter un patient',
    ADD_PHYSICIAN: 'Ajouter un médecin',
    ADD_RECEPTIONIST: 'Ajouter un réceptionniste',
    ADD_WARD: 'Ajouter un quartier',
    ADD_INTAKE_HISTORY: "Ajouter l'historique",
    ADD_VITAL_SIGNS: 'Ajouter des signes vitaux',
    ADD_EDIT_APPOINTMENT: 'Ajouter / Modifier un rendez-vous',
    ADD_EDIT: 'Ajouter / Modifier',
    ADD_EDIT_SLOTS: 'Ajouter / Modifier Emplacements',
    ADD_SLOTS: 'Ajouter Emplacements',
    ADDRESS: 'Adresse',
    AGE: 'Âge',
    AGE_AT_MENSTURATION: 'Âge à la menstruation',
    AGE_AT_MENOPAUSE: 'Âge à la ménopause',
    ALLERGIES: 'Allergies',
    ALL: 'Toute',
    ALL_APPOINTMENTS: 'Tous les rendez-vous',
    AMOUNT: 'Montante',
    ANEMIA: 'Anémie',
    ANY_OTHER_COMPLAINT: 'Toute autre plainte',
    APPOINTMENTS: 'Rendez-vous',
    APPOINTMENT_LIST: 'Liste de rendez-vous',
    APPOINTMENTS_SLOT: 'Plage de rendez-vous',
    APPOINTMENTS_TO_BE_ADDED: 'Rendez-vous à ajouter',
    APPOINTMENTS_TO_BE_RESCHEDULDED: 'Rendez-vous à reporter',
    ARRIVE_BY: 'Arrive avant',
    ASSESSMENTS: 'Évaluations',
    ASSESSMENTS_PLAN: 'Évaluation et planification',
    AT_HOME: 'À la maison',

    BACK: 'Retour',
    BACKACHE: 'Mal au dos',
    BACK_TO_NURSE_LIST: 'Retour à la liste des infirmières',
    BACK_TO_PATIENT_LIST: 'Retour à la liste des patients',
    BACK_TO_PHYSICIANS_LIST: 'Retour à la liste des médecins',
    BACK_TO_RECEPTIONIST_LIST: 'Retour à la liste des réceptionnistes',
    BLEEDING_PROBLEM: 'Problème de saignement',
    BLOOD_DISEASE: 'Maladie du sang',
    BLOOD_IN_STOOL: 'Sang dans les selles',
    BLOOD_IN_URINE: 'Sang dans les urines',
    BLOOD_TRANSFUSION: 'Transfusion sanguine',
    BONE_DISEASE: 'Maladie des os',
    BREAST_FEEDING: 'Allaitement maternel',
    BREAST_LUMP: 'Masse mammaire',
    BURNING_URINE: 'Urine brûlante',

    CALENDER_COLOR: 'Couleur du calendrier',
    CANCER: 'Cancer',
    CANCEL_APPOINTMENT: 'Annuler rendez-vous',
    CANCEL_MEETING: 'annuler la réunion',
    CANCELLED_APPOINTMENTS: 'Rendez-vous annulés',
    CHANGE_PASSWORD: 'Changer le mot de passe',
    CHIEF_COMPALINTS: 'Principales plaintes',
    CHESTPAIN: 'Douleur de poitrine',
    CLOSE: 'Fermer',
    CANCEL: 'Annuler',
    CONTINUE: 'Continuer',
    COLLECTIONS: 'Collections',
    COMING_SOON: 'Disponible bientôt ...',
    CONFIRMED: 'confirmé',
    CONFIRM: 'Confirmer',
    CONFIRM_PASSWORD: 'Confirmez le mot de passe',
    CONSULTATION_NEEDED: 'Consultation nécessaire',
    CONSULTATION: 'Consultation',
    CONTINUE_PRESCRIPTION: "Continuer l'ordonnance",
    CONTINUE_DIAGNOSTIC: 'Continuer le diagnostic',
    COUGH: 'La toux',
    CREATE_APPOINTMENT: 'Créer un rendez-vous',
    CREATE_SLOTS: 'Créer des emplacements',
    CURRENT_APPOINTMENT_IF_ANY: "Rendez-vous actuel (s'il existe)",
    CURRENT_DIAGNOSIS: 'Diagnostic actuel',
    CURRENT_MEDICATION: 'Prise de médicaments actuelle',
    CURRENT_MEDICINE: 'Médicaments actuels',

    DASHBOARD: 'Tableau de bord',
    DATE_TIME: 'Date/Heure',
    DATE_OF_BIRTH: 'Date de naissance',
    DATE_OF_VISIT: 'Date de visite',
    DAY: 'Jour',
    DEGREE: 'Diplôme',
    DELETE: 'Supprimer',
    DEMENTIA: 'Démence',
    DENTAL_PROBLEM: 'Problème dentaire',
    DESCRIPTION: 'Description',
    DIARRHEA: 'La diarrhée',
    DIABETES: 'Diabète',
    DIAGNOSIS: 'Diagnostic',
    DIAG_BY: 'Diagnostiqué par',
    DIAG_PLAN: 'Diagnostic et planification',
    DIAG_UPDATED: 'Diagnostic mis à jour',
    DISEASES: 'Maladies (Cochez tous vos problèmes actifs)',
    DISCOUNT: 'Rabais',
    DISCOUNT_PERC: 'Rabais %',
    DISCOUNTINUED_MEDICATION: 'Médicaments à prix réduit',
    DOCTOR: 'Médecin',
    DOMAIN: 'domaine',
    DOSAGE: 'Dosage',
    DOSAGE_FORM: 'Type de dosage',
    DOSAGE_INSTRUCTIONS: 'Instructions posologiques',
    DOWNLOAD_SAMPLE: "Télécharger l'échantillon",
    DRINKING: 'Boire',
    DRINKING_FREQUENCY: 'Fréquence de consommation',
    DRUGS: 'Médicaments',
    DRUGS_FREQUENCY: 'Fréquence des drogues',

    EAR_PROBLEM: "Problème d'oreille",
    EDIT_APPOINTMENT: 'Modifier le rendez-vous',
    EDIT_PATIENT: 'Modifier le patient',
    EDIT_MEDICINE: 'Modifier médicament',
    EDIT_NURSE: 'Modifier Médecin/Infirmière',
    EDIT_PHYSICIAN: 'Modifier le médecin',
    EDIT_RECEPTIONIST: 'Modifier la réceptionniste',
    EDIT_INTAKE_HISTORY: "Modifier l'historique",
    EDIT_VITAL_SIGNS: 'Modifier les signes vitaux',
    EMAIL: 'E-mail',
    EMAIL_ID: 'Identifiant de messagerie',
    EMPLOYEE_ID: 'ID employé',
    EMPLOYMENT_ID: "ID d'emploi",
    END: 'Finir',
    END_DATE: 'Date de Fin',
    END_TIME: 'Heure de fin',
    ENTER_TEXT_HERE: 'Entrez le texte ici',
    EYE_PROBLEM: 'Problème oculaire',

    FACIAL_PUFFINESS: 'Poches du visage',
    FAMILY_HISTORY: 'Histoire de famille',
    FAIMLY_MEMBER_DIED_FOR_SPECIFC_ILLNESS: "Membre de  famille décédé d'une maladie spécifique",
    FAIMLY_MEMBER_HAVING_NOTEABLE_ILLNESS: 'Membre de famille ayant une maladie notoire',
    FAIMLY_MEMBER_HAVING_SAME_MEDICAL_PROBLEMS: 'Membre de famille ayant les mêmes problèmes médicaux',
    FAMILY: 'Famille',
    FEE: 'Frais',
    FEE_SHARE: 'Partage des frais',
    FEMALE: 'Femme',
    FEMALE_SECTION_ONLY: 'Section féminine uniquement',
    FIRST_NAME: 'Prénom',
    FEVER: 'Fièvre',
    FOLLOWUP_DAY: 'Suivre le jour',
    FOLLOWUP_NEEDED: 'Suivi nécessaire',
    FOLLOWUP: 'Suivi nécessaire',
    FOR_HOW_LONG: 'Pour combien de temps',
    FORM: 'Forme',
    FREQUENCY: 'Fréquence',
    FROM: 'À partir de',

    GENDER: 'Genre',
    GENRIC_NAME: 'Nom générique',
    GUARDIAN_MOBILE: 'Mobile du tuteur',
    GUARDIAN_NAME: 'Nom du tuteur',

    HEADACHE: 'Mal de tête',
    HEALTHCARE_LICENSE_CODE: 'Le code de licence de soins de santé',
    HEALTHCARE_LICENSE_VALIDITY_DATE: 'Date de validité de la licence de soins de santé',
    HEART_BURN: "Brûlures d'estomac",
    HEART_PROBLEM: 'Problème cardiaque',
    HEART_RACING: 'Coeur battant',
    HEIGHT: 'Hauteur',
    HIGH_BLOOD_PRESSURE: 'Hypertension artérielle',
    HEPATITIS: 'Hépatite',
    HISTORY: 'Histoire',
    HOLD_TO_SPEAK: 'Tenir pour parler',
    HOT_FLASHES: 'Hot Flashes',

    ID: 'identifiant',
    IMAGE: 'Image',
    IMAGE_LAB_REPORT: "rapport d'Imagerie/laboratoire",
    IMAGE_LAB_REPORTS: "rapports d'Imagerie/laboratoire",
    IMMUNIZATIONS: 'Immunisations',
    IN_HOSPITAL: "À l'hôpital",
    IN_PERSON: 'En personne',
    IN_PERSON_MEETING: 'Réunion en personne',
    INITIAL_COMPLAIN: 'plainte initiale',
    INITIAL_DIAGNOSIS_PLAN: 'Plan de diagnostic initial',
    INTAKE_HISTORY: 'Historique de santé',
    INVOICE_DATE: 'Date de facturation',
    INVOICE_NO: 'Facture n°',
    INVOICE_STATUS: 'Statut de la facture',
    INVOICE_LIST: 'Liste des factures',
    IRREGULAR_ECG_RHYTHM: 'Rythme ECG irrégulier',

    JAUNDICE: 'Jaunisse',
    JOINT_SWELLING_PAIN: 'Gonflement/douleur articulaire',

    KIDNEY_DISEASE: 'Maladie rénale',
    KIDNEY_STONES: 'Calculs rénaux',

    LAB_RESULTs: 'Résultats de laboratoire',
    LAST_NAME: 'Nom de famille',
    LAST_MENSTRUAL_PERIOD: 'Last Menstrual Period',
    LAST_PAP_SMEAR: 'Last Pap Smear',
    LAST_MAMOGRAM: 'Last Mamogram',
    LIVER_DISEASE: 'Maladie du foie',
    LOCATION: 'Emplacement',
    LOCATIONS: 'Emplacements',
    LOCATION_LIST: 'Liste des emplacements',
    LOGIN: 'Connexion',
    LOGOUT: 'Se déconnecter',
    LOSS_OF_CONSCIOUSNESS: 'Perte de conscience',
    LUNG_DISEASE: 'Les maladies pulmonaires',

    MAKE_AN_APPOINTMENT: 'Prendre rendez-vous',
    MALE: 'Homme',
    MEDICAL_RECORD_NUMBER: 'Numéro de dossier médical',
    MEDICINE: 'Médicament',
    MEDICINE_ID: 'ID de médicament',
    MEDICINES: 'Médicaments',
    MOBILE: 'Mobile',
    MEDICINE_NAME: 'Nom du médicament',
    MOBLIE_NUMBER: 'Numéro mobile',
    MODIFY: 'Modifier',
    MONTH: 'Mois',
    MOUTH_PROBLEM: 'Problème de bouche',
    MUSCLE_WEAKNESS: 'Faiblesse musculaire',
    MY_APPOINTMENTS: 'mes rendez-vous',
    MY_FUTURE_APPOINTMENTS: 'Mes futurs rendez-vous',
    MY_PATIENTS: 'Mes patients',
    MEDICAL_OFFICER_NURSES: 'Médecin / Infirmières',

    NAME: 'Nom',
    NATIONAL_ID: "Carte d'identité nationale",
    NATIONAL_IDENTIFICATION_CODE: "Code national d'identification",
    NET_AMOUNT: 'Montant net',
    NEXT: 'Suivant',
    NEW_PASSWORD: 'nouveau mot de passe',
    NEW_APT_REQUEST: 'Nouvelle demande de rendez-vous',
    NEW_APPOINTMENT_REQUEST: 'Nouvelle demande de rendez-vous',
    NOSE_PROBLEM: 'Problème de nez',
    NO_CURRENT_DIAGNOSIS: 'Aucun diagnostic actuel',
    NO_CURRENT_MEDICATIONS: 'Aucun médicament actuel',
    NO_DISCONTINUED_PRESCRIPTION: 'Aucune ordonnance abandonnée',
    NO_IMAGING_LAB_REPORTS_AVAILABLE: "Aucun rapport d'imagerie / de laboratoire disponible",
    NO_PACKAGE_AVAILABLE: 'Aucun forfait disponible',
    NO_PRESCRIPTION_FOUND: 'Aucune ordonnance trouvée',
    NO_PREVIOUS_DIAGNOSIS: 'Aucun diagnostic antérieur',
    NOTES: 'Remarques',
    NUMBER_OF_PREGNANCIES: 'Nombre de grossesses',
    NURSE: 'Infirmière',
    NURSE_APPOINTMENTS: 'Rendez-vous infirmiers',
    NURSE_LIST: 'Liste des infirmières',
    NURSES: 'Infirmières',

    OLD_PASSWORD: 'ancien mot de passe',
    OTHER_COMPALINTS: 'Autres plaintes',
    OTHER_DETAILS: 'Autres détails',

    PACKAGE: 'Package',
    PASSWORD: 'le mot de passe',
    PATIENT: 'Patient',
    PATIENT_ID: 'ID du patient',
    PATIENT_LIST: 'Liste des patients',
    PATIENT_NAME: 'Nom du Patient',
    PATIENT_MOBILE: 'Mobile pour les patients',
    PATIENT_NAME_ID: 'Nom/ID du patient',
    PATIENTS: 'Patients',
    PATIENT_VISITS: 'Visites de patients',
    PATIENT_VITAL_SIGNS: 'Signes vitaux du patient',
    PERSONAL: 'Personnel',
    PHONE_NO: 'Téléphone',
    PHONE_NUMBER: 'Numéro de téléphone',
    PHYSICIAN: 'Médecin',
    PHYSICIAN_NAME: 'Nom du Médecin',
    PHYSICIAN_APPOINTMENTS: 'Rendez-vous avec le médecin',
    PHYSICIAN_PACKAGE_LIST: 'Liste des prix des actes',
    PHYSICIANS: 'Médecins',
    PHYSICIANS_LIST: 'Liste des médecins',
    PREVIOUS_HISTORY: 'Histoire précédente',
    PREGNANT: 'Enceinte',
    PRESCRIPTION: 'Ordonnance',
    PREVIOUS: 'précédent',
    PREVIOUS_ASSESSMENTS: 'Évaluations précédentes',
    PREVIOUS_VISITS: 'Visites précédentes',
    PROFESSION: 'Métier',
    PROFESSION_JOB: 'Métier / Emploi',
    PROFILE: 'Profil',
    PRESCRIBED: 'Prescrite',
    PRESCRIBED_BY: 'Prescrit par',
    PSYCHOLOGICAL_PROBLEM: 'Problème psychologique',
    PURPOSE: 'Raison',

    QUANTITY: 'Quantité',

    READY_FOR_SUBMIT: 'Prêt à envoyer',
    RECEPTIONIST: 'Réceptionniste',
    RECEPTIONISTS: 'Réceptionnistes',
    REFRERRED_TO: 'Référé à',
    REFILL_DETAILS: 'Détails de la recharge',
    REGULAR_ECG_RHYTHM: 'Rythme ECG régulier',
    RESCHEDULE: 'Reprogrammer',
    RESCHEDULE_MEETING: 'Reprogrammer la réunion',
    RESCHEDULE_APPOINTMENT: 'Reprogrammer la rendez-vous',
    RESET_PASSWORD: 'réinitialiser le mot de passe',
    REMARKS: 'Remarques',
    REMOVE: 'Retirer',
    ROLE: 'Rôle',
    ROW_PER_PAGE: 'Ligne par page',

    SAVE: 'Sauvegarder',
    SAVE_CHANGES: 'Sauvegarder les modifications',
    SEARCH: 'Chercher',
    SEARCH_PATIENTS: 'Rechercher un patient',
    SEARCH_PHYSICIAN: 'Rechercher un médecin',
    SHORTNESS_OF_BREATH: 'Essoufflement',
    SELECT_DIAGNOSIS: 'Sélectionnez Diagnostic',
    SELECT_MEDICINE: 'Sélectionnez un médicament',
    SELECT_PACKAGE: 'Sélectionnez un forfait',
    SEIZURE: 'Saisie',
    SKIN_DISEASE: 'Maladie de peau',
    SKIN_RASH: 'Démangeaison de la peau',
    SLEEP_DISTURBANCE: 'Trouble du sommeil',
    SMOKING: 'Fumeur',
    SMOKING_REQUENCY: 'Fréquence du tabagisme',
    SOCIAL_HISTORY: 'Histoire sociale',
    SPECIALITY: 'Spécialité',
    START: 'début',
    START_DATE: 'Date de Début',
    START_TIME: 'Heure de début',
    START_MEETING: 'Démarrer la réunion',
    STARTS_AT: 'commence à',
    STATUS: 'Statut',
    STOMACHPAIN: "Douleur d'estomac",
    STROKE: 'Accident vasculaire cérébral',
    SUBMIT: 'Mettre à jour',
    SUMMARY_NOTES: 'Notes de synthèse',
    SUMMARY: 'résumé',
    SURGERIES_OPERATIONS: 'Chirurgies/opérations',
    SWALLOWING_PROBLEM: 'Problème de déglutition',
    SWOLLEN_FEET: 'Pieds enflés',
    SYMPTOMS: 'Symptômes (Cochez tous vos problèmes actifs)',

    THROAT_PROBLEM: 'Problème de gorge',
    THYROID_PROBLEM: 'Problème de la thyroïde',
    TITLE: 'Titre',
    TODAY: "Aujourd'hui",
    TODAY_VISIT: "Les visites d'aujourd'hui",
    TODAY_APPOINTMENTS: 'Les rendez-vous du jour',
    TODAY_VIRTUAL_APPOINTMENTS: "Les rendez-vous virtuels d'aujourd'hui",
    TODAY_INPERSON_APPOINTMENTS: "Les rendez-vous en personne d'aujourd'hui",
    TOTAL_APPOINTMENTS: 'Nombre total de rendez-vous',
    TO_BE_ADDED: 'Être ajouté',
    TRANSCRIPT: 'transcription',
    TUMOR: 'Tumeur',
    TYPE: 'Taper',

    UNCONFIRMED: 'non confirmé',
    UPCOMING_VISITS: 'Visites à venir',
    UPDATE: 'Mettre à jour',
    UPDATE_DIAGNOSTIC: 'Diagnostic de mise à jour',
    UPDATE_PACKAGE: 'Paquet de mise à jour',
    UPDATE_PRESCRIPTION: "Mettre à jour l'ordonnance",
    UPLOAD: 'Télécharger',
    UPLOAD_DOCUMENTS: 'Télécharger des documents',
    UPLOAD_PHYSICIANS: 'Télécharger des médecins',
    UPLOAD_IMAGE_LAB_REPORTS: "Télécharger les rapports d'imagerie/laboratoire",
    UPLOAD_LAB_REPORTS: 'Télécharger des rapports de laboratoire',
    UPLOAD_REPORTS: 'Télécharger des rapports',
    USER_LIST: "liste d'utilisateur",
    USER_NAME: "Nom d'utilisateur",
    USERNAME: "nom d'utilisateur",
    USERS: 'Utilisateurs',
    USER_PROFILE: "Profil de l'utilisateur",
    UTERUS_BLEEDING: "Saignement de l'utérus",

    VACCINES: 'Vaccins',
    VOMITING: 'Vomiting',
    VIEW_LAB_REPORTS: 'Afficher les rapports de laboratoire',
    VIEW_REPORTS: 'Afficher les rapports',
    VIEW_LOCATIONS: 'Afficher les emplacements',
    VIEW_MEDICINES: 'Voir les médicaments',
    VIEW_PROFILE: 'Voir le profil',
    VIEW_WARDS: 'Voir les quartiers',
    VIRTUAL: 'Virtuel',
    VIRTUAL_MEETING: 'Réunion virtuelle',
    VIRTUAL_CLINIC: 'CLINIQUE VIRTUELLE',
    VISIT_DATE_TIME: 'Date/Heure de la visite',
    VISIT_DESCRIPTION: 'Description de la visite',
    VISIT_ID: 'ID de visite',
    VISIT: 'Visite',
    VISIT_REASON: 'Raison de la visite',
    VITAL_SIGNS: 'Signes vitaux',

    WARD: 'Pavillon',
    WARD_LIST: 'Liste des pavillon',
    WARDS: 'Pavillon',
    WARD_SUCCESS_MESSAGE: 'Service mis à jour avec succès',
    WEAKNESS: 'La faiblesse',
    WEIGHT: 'Poids',
    WEIGHTLOSS: 'Perte de poids',
    WEEK: 'Semaine',

    PLEASE_ENTER_A_VALID_EMAIL_ID: 'Veuillez saisir un identifiant de messagerie valide',
    PLEASE_ENTER_USER_NAME: "Veuillez entrer le nom d'utilisateur",
    PLEASE_ENTER_PASSWORD: 'Veuillez entrer le mot de passe',
    INCORRECT_EMAIL_PASSWORD: 'E-mail ou mot de passe incorrect',
    PRESCRIPTION_UPDATED: 'Ordonnance mise à jour',
    PRESCRIPTION_DETAILS: "Détails de l'ordonnance",
    PLEASE_COMPLETE_THE_FORM: 'Veuillez remplir le formulaire',
    SORRY_NO_RECORD_FOUND: 'Désolé aucun enregistrement trouvé',
    START_DATETIME_PAST_DATETIME: "La date et l'heure de début ne doivent pas être des dates et heures passées",
    END_DATETIME_AFTER_DATETIME: "La date et l'heure de fin doivent être postérieures à la date et à l'heure de début",

    ULTRASOUND: 'Ultrason',
    OTHER: 'Autre',
    LAB_TEST: 'Test de laboratoire',
    MRI: 'IRM',
    CT_SCAN: 'tomodensitométrie',
    X_RAY: 'Radiographie',

    FOLLOW_UP_DATE_TIME: 'Date de suivi Heure',
    ACTION: 'Action',
    SCHEDULE_VISIT: 'Planifier la visite',
    TO_BE_CANCELLED: 'Être annulé',
    AVAILABLE_SLOTS: 'Emplacements disponibles',
    COMPLETED: 'Complété',
    CANCELLED: 'Annulé',
    PAYMENT: 'Paiement',
    DEVICE: 'Appareil',
    UPDATE_APPOINTMENT: 'Mettre à jour le rendez-vous',
    START_DATE_TIME: 'Date de début Heure',
    END_DATE_TIME: 'Date de fin Heure',
    PRINT: 'Imprimer',
    PRINT_TOKEN: 'Imprimer le jeton',
    APPOINTMENT_ADDED_SUCCESSFULLY: 'Rendez-vous ajouté avec succès',
    APPOINTMENT_UPDATED_SUCCESSFULLY: 'Rendez-vous mis à jour avec succès',
    APPOINTMENT_DELETED_SUCCESSFULLY: 'Rendez-vous supprimé avec succès',
    COMPLETED_APPOINTMENTS: 'Rendez-vous terminés',
    PAID: 'Payé',
    UN_PAID: 'Non payé',
    PRINT_SUMMARY: 'Imprimer le résumé',
    PRINT_PRESCRIPTION_BY: "Imprimer l'ordonnance par",
    OVERALL_PRESCRIPTION: 'Prescription globale',
    LOADING_PRESCRIPTION: 'Chargement des données de prescription',
    SEND_SMS: 'Envoyer un SMS',
    DURATION: 'Durée',
    CHOOSE_FILE: 'Choisir le fichier',
    APPOINTMENT_RESCHEDULE_DATE_TIME: 'Rendez-vous reporté Date Heure',
    CANCEL_CONFIRMATION: 'Annuler la Confirmation',
    CANCEL_CONFIRMATION_TEXT: 'Êtes-vous sûr de vouloir annuler ce rendez-vous ?',
    YES: 'Oui',
    NO: 'Non',
    CANCEL_REQUEST_SENT: 'Annuler la demande envoyée',
    NEW_PHYSICIAN: 'Nouveaux Médecin',
    MULTIPLE_NEW_PHYSICIAN: 'Plusieurs Nouveaux Médecins',
    BATCH_VISIT: 'Visite par lots',
    CLEAR_SEARCH_LIST: 'Effacer la liste de recherche',
    CLEAR: 'Claire',
    PROFILE_PICTURE: 'Image de profil',

    PERSONAL_INFORMATION: 'Informations Personnelles',
    EDUCATION_AND_SPECIALITY: 'Éducation et Spécialité',
    HEALTHCARE_LICENSE_INFORMATION: 'Informations sur la Licence de soins de Santé',
    NEW_PATIENT: 'Nouveaux Patients',
    MULTIPLE_NEW_PATIENT: 'Plusieurs Nouveaux Patients',
    ALL_PATIENTS: 'Tous les Patients',
    OUTSIDE_HOSPITAL: `À l'extérieur de l'hôpital`,
    EMERGENCY_CONTACT_NAME: `Nom à contacter en cas d'urgence`,
    EMERGENCY_CONTACT_NUMBER: `Numéro de contact en cas d'urgence`,
    NEW_RECEPTIONIST: 'Nouvelle Réceptioniste'
};
