import React, { useState } from 'react';

import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { COLORS } from 'constants/colors';
import PrescriptionCard from './PrescriptionCard';
import { styles } from './styles';
import axios from 'axios';
import { BASE_URL } from 'constants/baseUrl';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ShowPrescription = ({ prescription, getPatientPrescription, setPrescriptionList }) => {
    const { user } = useSelector((state) => state?.user);

    let currentMed = prescription?.filter((i) => i.isDeleted == false);
    let discountinuedMed = prescription?.filter((i) => i.isDeleted == true);

    const [loading, setLoading] = useState(false);

    const handleRemove = async (prescriptionId, domain) => {
        try {
            const updatedPrescriptionList = prescription.map((obj) => {
                if (obj.prescriptionId === prescriptionId && obj.domain == domain) {
                    obj.isDeleted = true;
                }
                return obj;
            });
            setPrescriptionList(updatedPrescriptionList);

            const resPrescDel = await axios({
                method: 'delete',
                url: `${BASE_URL}api/prescription/delete-prescription/${prescriptionId}/${domain}`,
                data: {
                    isDeleted: true,
                    deletedBy: user?.userId,
                    deletedOn: new Date()
                },
                headers: {
                    Authorization: `Bearer ${user?.token}`
                }
            });

            if (resPrescDel?.data) {
                getPatientPrescription();
            }
        } catch (error) {
            toast?.error(error?.response?.data?.message ?? error?.response?.data ?? error?.message);
        }
    };

    const handleModify = async (prescriptionId, domain) => {
        try {
            const updatedPrescriptionList = prescription.map((obj) => {
                if (obj.prescriptionId === prescriptionId && obj.domain == domain) {
                    obj.isDeleted = false;
                }
                return obj;
            });
            setPrescriptionList(updatedPrescriptionList);

            const resPrescModify = await axios({
                method: 'put',
                url: `${BASE_URL}api/prescription/update-prescription/${prescriptionId}/${domain}`,
                data: {
                    isDeleted: false,
                    lastModifiedBy: user?.userId,
                    lastModifiedOn: new Date()
                },
                headers: {
                    Authorization: `Bearer ${user?.token}`
                }
            });

            if (resPrescModify?.data) {
                getPatientPrescription();
            }
        } catch (error) {
            toast?.error(error?.response?.data?.message ?? error?.response?.data ?? error?.message);
        }
    };

    return (
        <>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography sx={styles?.accordianTitle}>Current Medication - {currentMed?.length}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {currentMed?.map((item, index) => (
                        <PrescriptionCard
                            key={index}
                            prescription={item}
                            showBtn={true}
                            btnTitle={'Discontinue'}
                            btnColor={'red'}
                            onClick={() => handleRemove(item?.prescriptionId, item?.domain)}
                        />
                    ))}
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography sx={styles?.accordianTitle}>Discountinued Medication - {discountinuedMed?.length}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {discountinuedMed?.map((item, index) => (
                        <PrescriptionCard
                            key={index}
                            prescription={item}
                            showBtn={true}
                            btnTitle={'Continue'}
                            btnColor={COLORS?.secondory}
                            onClick={() => handleModify(item?.prescriptionId, item?.domain)}
                        />
                    ))}
                </AccordionDetails>
            </Accordion>
        </>
    );
};

export default ShowPrescription;
