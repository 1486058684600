import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    Radio,
    RadioGroup,
    TextField
} from '@mui/material';
import { COLORS } from 'constants/colors';
import TranslationContext from 'context/translation';
import { Form, Formik } from 'formik';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import DateTimePicker from 'ui-component/FormUI/DateTimePicker';
import { useSelector } from 'react-redux';
import Textfield from 'ui-component/FormUI/Textfield';
import ModalCustom from 'ui-component/modals/ModalCustom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup';
import AddNewPatient from 'views/staff/patients/AddNew';
import { fetchPatientList } from 'store/slices/patientListSlice';
import CloseIcon from '@mui/icons-material/Close';
import { BASE_URL } from 'constants/baseUrl';
import axios from 'axios';

function CRUDApointmentModal({
    modal,
    patientDataList,
    physicianDataList,
    locationList,
    onSuccess,
    onClose,
    handlePrintRXForm,
    handlePrintToken,
    showPrintRXFormButton = false,
    showPrintTokenButton = false
}) {
    console.log(modal, 'modal');

    const { user } = useSelector((state) => state?.user);
    let { config } = useSelector((state) => state?.config);
    const { translate } = useContext(TranslationContext);

    let isShowBillItem = config?.find((i) => i.title == 'ShowBillList') || null;
    let isDefaultMeetingType = config?.find((i) => i.title == 'DefaultMeetingType')?.value || null;
    let isHideVisitSummary = config?.find((i) => i.title == 'HideVisitSummary')?.value || null;
    let isGenerateTokenNo = config?.find((i) => i.title == 'GenerateTokenNo')?.value || null;

    // destructureing modal data
    const {
        id = '',
        start = '',
        end = '',
        title = '',
        summaryNotes = '',
        amount = 0,
        consultant_NationalID,
        patient_NationalID,
        meetingtype = 'Virtual',
        locationID = '',
        isCampOn: isCamp = false,
        patientAge: ptAge = ''
    } = modal?.data || {};

    // states
    const [selectedPatient, setSelectedPatient] = useState(null);
    const [selectedPhysician, setSelectedPhysician] = useState(null);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [meetingType, setMeetingType] = useState(isDefaultMeetingType == 'true' ? 'Inperson' : 'Virtual');
    const [isCampOn, setIsCampOn] = useState(false);
    const [patientAge, setPatientAge] = useState(0);

    const [addNewPtModal, setAddNewPtModal] = useState(false);
    const [loadingRequest, setLoadingRequest] = useState(false);

    useEffect(() => {
        setSelectedPatient(patient_NationalID);
        setSelectedPhysician(consultant_NationalID);
        setMeetingType(meetingtype);
        setIsCampOn(isCamp);
        setPatientAge(ptAge);

        if (locationList) {
            if (user?.roles == 'Receptionist') {
                setSelectedLocation(user?.locationID);
            } else {
                setSelectedLocation(locationID || (locationList && locationList[0]?.locationID));
            }
        }
    }, [patient_NationalID, consultant_NationalID, locationID, meetingtype, isCamp, ptAge, locationList]);

    //------------------------------  Initial Values for our appointment form
    const INITIAL_VALUES =
        modal?.type == 'add'
            ? {
                  title: '',
                  summaryNotes: '',
                  startDate: !!modal?.data ? moment(start).format('YYYY-MM-DDTHH:mm') : new Date().toISOString().slice(0, 16),
                  endDate: !!modal?.data ? moment(end).format('YYYY-MM-DDTHH:mm') : new Date().toISOString().slice(0, 16),
                  amount: 0
              }
            : {
                  title: title,
                  summaryNotes: summaryNotes,
                  startDate: !!modal?.data ? moment(start).format('YYYY-MM-DDTHH:mm') : new Date().toISOString().slice(0, 16),
                  endDate: !!modal?.data ? moment(end).format('YYYY-MM-DDTHH:mm') : new Date().toISOString().slice(0, 16),
                  amount: amount || physicianDataList?.find((phy) => phy?.nationalID == consultant_NationalID)?.fee
              };

    //------------------------------  Validation Schema for our appointment form
    const FORM_VALIDATION = Yup.object().shape({
        title: Yup.string(),
        summaryNotes: Yup.string(),
        startDate: Yup.date()
            .min(Yup.ref('endDate'), 'Start date time must be less than end date time')
            .min(new Date(), 'Start date time must be after current date time')
            .required('Start date is required'),
        endDate: Yup.date()
            .min(Yup.ref('startDate'), 'End date time must be greater than start date time')
            .required('End date is required'),
        amount: Yup.number().typeError('Amount must be a number').required('Required')
    });

    //------------------------------- Create, Update or Delete Visit
    const handleRequest = async (values, resetForm) => {
        if (selectedLocation == null || selectedLocation == '' || selectedLocation == undefined) {
            return toast.error('Please select location');
        }
        if (selectedPatient == null) {
            return toast.error('Please select patient');
        }
        if (selectedPhysician == null) {
            return toast.error('Please select physician');
        }
        setLoadingRequest(true);

        let physicianName = physicianDataList?.find((i) => i?.nationalID == selectedPhysician)?.name?.split(' ')[1];
        let campMeetingLink = `camp-${physicianName}-${moment(new Date()).format('DDMMYYYY')}`;

        try {
            const data = {
                ...values,
                title: values?.title == '' ? 'Undefined Complaint' : values?.title,
                summaryNotes: values?.summaryNotes == '' ? 'Undefined Complaint' : values?.summaryNotes,
                Patient_NationalID: selectedPatient,
                Consultant_NationalID: selectedPhysician,
                StartDateTime: moment(values.startDate).format(),
                EndDateTime: moment(values.endDate).format(),
                meetingType,
                meetinglink: isCampOn ? campMeetingLink : new Date().getTime(),
                VitalSignID: null,
                HistoryID: null,
                patientAge: patientDataList?.find((i) => i?.nationalID == selectedPatient)?.age,
                status: 1,
                isConfirm: false,
                IsPaid: true,
                PaymentDate: new Date(),
                DiscountPerc: '0',
                NetAmount: values?.amount,
                locationID: selectedLocation,
                lastModifiedBy: modal.type == 'add' ? null : user?.userId,
                lastModifiedOn: modal.type == 'add' ? null : new Date(),
                isCampOn,
                ...(modal.type == 'add' && { createdOn: new Date(), createdBy: user?.userId })
            };
            let method = modal.type == 'add' || modal?.type == 'recreate' ? 'post' : 'put';
            let url =
                modal.type == 'add' || modal?.type == 'recreate'
                    ? `${BASE_URL}api/visit`
                    : `${BASE_URL}api/visit/updateEvent/${modal?.data?.id}`;
            const responseCreateAppointment = await axios({
                method,
                url,
                data,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user?.token}`
                }
            });

            if (responseCreateAppointment.status == 200) {
                onSuccess(modal.type, responseCreateAppointment?.data);
                setSelectedPatient(null);
                setSelectedPhysician(null);
                setPatientAge(0);
                toast.success(
                    `${modal.type == 'add' ? translate('APPOINTMENT_ADDED_SUCCESSFULLY') : translate('APPOINTMENT_UPDATED_SUCCESSFULLY')}`
                );
            }
        } catch (error) {
            return toast.error(error?.response?.data?.message ?? error?.response?.data ?? error?.message);
        } finally {
            setLoadingRequest(false);
        }
    };

    const handleDeleteVisit = async () => {
        setLoadingRequest(true);
        try {
            const resDeleteVisit = await axios({
                method: 'delete',
                url: `${BASE_URL}api/visit/${modal?.data?.id}`,
                data: { deletedOn: new Date(), deletedBy: user?.userId },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user?.token}`
                }
            });
            if (resDeleteVisit.status == 200);
            {
                onSuccess('delete');
                toast.success(translate('APPOINTMENT_DELETED_SUCCESSFULLY'));
            }
        } catch (error) {
            return toast.error(error?.response?.data?.message ?? error?.response?.data ?? error?.message);
        } finally {
            setLoadingRequest(false);
        }
    };

    return (
        <div>
            <ModalCustom open={modal.open} title={modal.type == 'add' ? translate('ADD_APPOINTMENT') : translate('UPDATE_APPOINTMENT')}>
                <Formik
                    initialValues={INITIAL_VALUES}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, { resetForm }) => handleRequest(values, resetForm)}
                >
                    {({ values, setFieldValue }) => (
                        <Form>
                            <Box sx={{ maxWidth: 400 }}>
                                <Grid container direction="row" spacing={2}>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Textfield name="title" label={translate('TITLE')} variant="standard" size="small" />
                                    </Grid>

                                    {isHideVisitSummary !== 'true' && (
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Textfield name="summaryNotes" label={translate('SUMMARY')} variant="standard" size="small" />
                                        </Grid>
                                    )}

                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Box sx={{ display: 'flex' }}>
                                            <Autocomplete
                                                fullWidth
                                                options={patientDataList ?? []}
                                                size="small"
                                                getOptionLabel={(patient) => `${patient?.name} ${patient?.lastName} - ${patient?.phone}`}
                                                value={selectedPatient && patientDataList?.find((i) => i?.nationalID == selectedPatient)}
                                                onChange={(event, selected) => {
                                                    setSelectedPatient(selected?.nationalID || null);
                                                    setPatientAge(selected?.age);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField {...params} label={translate('PATIENT')} variant="standard" />
                                                )}
                                            />

                                            <TextField
                                                disabled
                                                InputLabelProps={{ shrink: true }}
                                                value={patientAge}
                                                label={translate('AGE')}
                                                variant="standard"
                                                size="small"
                                                type="number"
                                                sx={{ width: 90, marginLeft: 2 }}
                                            />

                                            <Button size="small" onClick={() => setAddNewPtModal(true)}>
                                                {translate('ADD')}
                                            </Button>
                                        </Box>
                                    </Grid>

                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Autocomplete
                                            size="small"
                                            options={physicianDataList ?? []}
                                            getOptionLabel={(physician) =>
                                                `${physician?.name} ${physician?.phone} - ${physician?.speciality}`
                                            }
                                            value={physicianDataList?.find((i) => i?.nationalID == selectedPhysician)}
                                            onChange={(event, selected) => {
                                                setSelectedPhysician(selected?.nationalID || null);
                                                setFieldValue('amount', selected?.fee);
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} label={translate('PHYSICIAN')} variant="standard" />
                                            )}
                                        />
                                    </Grid>

                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <DateTimePicker
                                            size="small"
                                            name="startDate"
                                            label={translate('START_DATE_TIME')}
                                            type="datetime-local"
                                            variant="standard"
                                        />
                                    </Grid>

                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <DateTimePicker
                                            size="small"
                                            name="endDate"
                                            label={translate('END_DATE_TIME')}
                                            type="datetime-local"
                                            variant="standard"
                                        />
                                    </Grid>

                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Textfield
                                            name="amount"
                                            label={translate('AMOUNT')}
                                            variant="standard"
                                            size="small"
                                            type="number"
                                        />
                                    </Grid>

                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Autocomplete
                                            disabled={locationList?.length == 1}
                                            size="small"
                                            options={locationList ?? []}
                                            getOptionLabel={(location) => `${location?.name} - ${location?.address}`}
                                            value={selectedLocation && locationList?.find((i) => i?.locationID == selectedLocation)}
                                            onChange={(event, selected) => {
                                                setSelectedLocation(selected?.locationID || null);
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} label={translate('LOCATION')} variant="standard" />
                                            )}
                                        />
                                    </Grid>

                                    <Grid item lg={8} md={8} sm={8} xs={12}>
                                        <FormControl>
                                            <RadioGroup row value={meetingType} onChange={(e) => setMeetingType(e.target.value)}>
                                                <FormControlLabel value="Inperson" control={<Radio />} label={translate('IN_PERSON')} />
                                                <FormControlLabel value="Virtual" control={<Radio />} label={translate('VIRTUAL')} />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>

                                    <Grid item lg={4} md={4} sm={4} xs={12}>
                                        <FormControlLabel
                                            control={<Checkbox checked={isCampOn} onChange={(e) => setIsCampOn(e.target.checked)} />}
                                            label={translate('BATCH_VISIT')}
                                        />
                                    </Grid>

                                    <Grid item lg={12} md={12} sm={12} xs={12} mt={1}>
                                        <Box sx={styles.btnContainer}>
                                            {loadingRequest ? (
                                                <CircularProgress size={25} color="inherit" />
                                            ) : (
                                                <>
                                                    <Button
                                                        onClick={() => {
                                                            onClose();
                                                            setPatientAge(null);
                                                        }}
                                                        variant="text"
                                                        sx={{ color: 'red' }}
                                                    >
                                                        {modal?.show ? translate('CANCEL') : translate('CLOSE')}
                                                    </Button>
                                                    {isShowBillItem?.value == 'true' && modal.type == 'update' && showPrintRXFormButton && (
                                                        <Button onClick={handlePrintRXForm} variant="text" sx={{ color: COLORS.secondory }}>
                                                            {translate('PRINT')}
                                                        </Button>
                                                    )}
                                                    {isGenerateTokenNo == 'true' && modal.type == 'update' && showPrintTokenButton && (
                                                        <Button
                                                            onClick={() => handlePrintToken(modal?.data)}
                                                            variant="text"
                                                            sx={{ color: COLORS.secondory }}
                                                        >
                                                            {translate('PRINT_TOKEN')}
                                                        </Button>
                                                    )}
                                                    {modal?.show == true && (
                                                        <>
                                                            {modal.type == 'add' || modal?.type == 'recreate' ? (
                                                                <></>
                                                            ) : (
                                                                <Button
                                                                    type="submit"
                                                                    variant="text"
                                                                    sx={{ color: 'red' }}
                                                                    onClick={handleDeleteVisit}
                                                                >
                                                                    {translate('DELETE')}
                                                                </Button>
                                                            )}
                                                            <Button type="submit" variant="text" sx={{ color: COLORS.secondory }}>
                                                                {modal.type == 'add' || modal?.type == 'recreate'
                                                                    ? translate('SAVE')
                                                                    : translate('UPDATE')}
                                                            </Button>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </ModalCustom>

            <ModalCustom open={addNewPtModal}>
                <Box sx={{ width: '70vw' }}>
                    <IconButton color="inherit" onClick={() => setAddNewPtModal(false)} sx={{ position: 'absolute', top: 10, right: 10 }}>
                        <CloseIcon />
                    </IconButton>
                    <AddNewPatient
                        isPatientAdd={(val, newPt, age) => {
                            if (val == true) {
                                dispatch(fetchPatientList());
                                setSelectedPatient(newPt?.nationalID);
                                setAddNewPtModal(false);
                                setPatientAge(age);
                            }
                        }}
                    />
                </Box>
            </ModalCustom>
        </div>
    );
}

export default CRUDApointmentModal;

const styles = {
    loadingContainer: {
        height: '70vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    btnContainer: {
        display: 'flex',
        direction: 'row',
        gap: 1,
        justifyContent: 'flex-end'
    }
};
