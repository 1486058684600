import React from 'react';
import MicIcon from '@mui/icons-material/Mic';
import './styles.css';

export default function AnimatedMic({ listening, onClick, style }) {
    return (
        <button onClick={onClick} style={{ border: '0', backgroundColor: 'transparent', ...style }}>
            <div className="mic-container">
                <MicIcon sx={{ position: 'absolute', color: '#fff' }} />
                {listening ? <div className="mic-circle"></div> : <div />}
            </div>
        </button>
    );
}
