import { Box, ClickAwayListener, IconButton, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import ModalCustom from 'ui-component/modals/ModalCustom';
import NLP from '.';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { MeetingContext } from 'views/meeting/MeetingContext';

export default function ModalNLP({ open, onClose, visit }) {
    const { getDiagnosisByVisit, getLabTestByVisit, getPrescriptionByVisit } = useContext(MeetingContext);
    const [openPopup, setOpenPopup] = React.useState(false);

    const handleInfoPopup = () => {
        setOpenPopup((prev) => !prev);
    };

    const handleClickAway = () => {
        setOpenPopup(false);
    };

    const styles = {
        position: 'absolute',
        top: 33,
        right: 10,
        width: 300,
        zIndex: 1,
        border: '1px solid',
        p: 1,
        borderRadius: 1,
        bgcolor: 'background.paper'
    };

    /// ========================= Speak Comp
    const [currentUtterance, setCurrentUtterance] = useState(null);
    const [isSpeaking, setIsSpeaking] = useState(false);

    useEffect(() => {
        const speakInstructions = () => {
            const instructions = [
                'Cloud Clinic mein aap ko khush aamdeed.',
                "Dawaiyon ki fehrist dekhne ke liye, 'medicine' kahen, phir dawai ka naam kahen. Misal ke taur par, 'medicine Panadol'.",
                "Dawai ko chunne ke liye, 'select number' kahen, phir fehrist se number kahen. Jaise ke, 'select number 3'.",
                "Dosage shamil karne ke liye, 'dosage' kahen, phir dosage ke tafseelaat kahen, jaise ke 'Take two time daily for two weeks'.",
                "Diagnose ke liye, 'diagnosis' kahen, phir fehrist ke mutabiq number chunen, aur lab tests ke liye, 'test' kahen, phir test ka naam aur number chunen.",
                'Aapki asaani, hamari tarjeeh.'
            ];

            speakSequentially(instructions, 0);
        };

        const speakSequentially = async (instructions, index) => {
            if (index < instructions.length) {
                const message = new SpeechSynthesisUtterance();
                message.text = instructions[index];

                // Wait for voices to be loaded
                await new Promise((resolve) => {
                    const voicesInterval = setInterval(() => {
                        const availableVoices = window.speechSynthesis.getVoices();
                        if (availableVoices.length > 0) {
                            clearInterval(voicesInterval);
                            resolve();
                        }
                    }, 100);
                });

                const voiceIndex = 10; // Adjust this index based on the available voices
                message.voice = window.speechSynthesis.getVoices()[voiceIndex];

                // Set the current utterance
                setCurrentUtterance(message);
                window.speechSynthesis.speak(message);
                setIsSpeaking(true);

                // Wait for the current message to finish before speaking the next one
                message.onend = () => speakSequentially(instructions, index + 1);
            } else {
                // All instructions have been spoken
                setCurrentUtterance(null);
                setIsSpeaking(false);
            }
        };

        speakInstructions();

        // Clean up event listener
        return () => {
            window.speechSynthesis.cancel();
            setCurrentUtterance(null);
            setIsSpeaking(false);
        };
    }, []);

    const pauseSpeaking = () => {
        if (currentUtterance) {
            setIsSpeaking(false);
            window.speechSynthesis.pause();
        }
    };

    const startSpeaking = () => {
        if (currentUtterance) {
            setIsSpeaking(true);
            window.speechSynthesis.resume();
        }
    };

    return (
        <ModalCustom open={open} title={'NLP Version'}>
            <Box sx={{ minWidth: '70vw' }}>
                <Box sx={{ position: 'absolute', top: 10, right: 10, display: 'flex' }}>
                    <IconButton onClick={() => (isSpeaking ? pauseSpeaking() : startSpeaking())} color="inherit">
                        {isSpeaking ? <PauseIcon /> : <PlayArrowIcon />}
                    </IconButton>

                    <ClickAwayListener onClickAway={handleClickAway}>
                        <Box sx={{ position: 'relative' }}>
                            <IconButton color="inherit" onClick={handleInfoPopup}>
                                <InfoIcon />
                            </IconButton>
                            {openPopup ? (
                                <Box sx={styles}>
                                    <Typography variant="body1">
                                        Welcome to our health care portal.
                                        <ul style={{ padding: 20 }}>
                                            <li>
                                                To view a list of medicines, say 'medicine' followed by the medicine name. For example,
                                                'medicine Panadol'.
                                            </li>
                                            <li>
                                                To select a medicine, say 'select number' followed by the number from the list. For
                                                instance, 'select number 3'
                                            </li>
                                            <li>
                                                To add dosage instructions, say 'dosage' followed by the dosage details, like 'dosage 2
                                                times a day for 2 weeks
                                            </li>

                                            <li>
                                                For diagnosis and treatment plans, say 'diagnosis' followed by the condition, and select a
                                                number as before.
                                            </li>

                                            <li>For lab tests, say 'test' followed by the test name and select a number.</li>
                                        </ul>
                                        Your health, your way.
                                    </Typography>
                                </Box>
                            ) : null}
                        </Box>
                    </ClickAwayListener>

                    <IconButton color="inherit" onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>

                <NLP
                    visit={visit}
                    onClose={() => {
                        getDiagnosisByVisit();
                        getLabTestByVisit();
                        getPrescriptionByVisit();
                        onClose();
                    }}
                />
            </Box>
        </ModalCustom>
    );
}
