import React, { useContext } from 'react';

import { Box, Button, IconButton } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';

import ModalCustom from 'ui-component/modals/ModalCustom';
import { MeetingContext } from 'views/meeting/MeetingContext';
import ShowPrescription from './ShowPrescription';
import AddPrescription from './AddPrescription';
import { useState } from 'react';
import AddNewMedicine from 'components/AddNewMedicine';
import { addData } from 'utils/indexedDB';

export default function PatientPrescription({ visit, open, onClose }) {
    const { prescriptionList, getPrescriptionByVisit, setPrescriptionList } = useContext(MeetingContext);

    const [showNewMed, setShowNewMed] = useState(false);
    const [key, setKey] = useState(0);

    const whenMedAdded = (medicine) => {
        setShowNewMed(false);
        addData('medicine', [medicine]);
        setKey((pre) => pre + 1);
    };

    return (
        <ModalCustom open={open} title={'Prescription'}>
            <Box sx={{ width: '40vw' }}>
                <Box sx={{ position: 'absolute', top: 10, right: 10 }}>
                    <Button onClick={() => setShowNewMed(true)}>Add New Medicine</Button>

                    <IconButton color="inherit" title="Refresh" onClick={() => getPrescriptionByVisit()}>
                        <RefreshIcon />
                    </IconButton>
                    <IconButton color="inherit" onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>

                <ShowPrescription
                    prescription={prescriptionList}
                    getPatientPrescription={getPrescriptionByVisit}
                    setPrescriptionList={setPrescriptionList}
                />

                {/* use key to force fully render the component when new med added */}
                <AddPrescription key={key} visit={visit} getPatientPrescription={getPrescriptionByVisit} />

                <ModalCustom open={showNewMed} title={'Add New Medicine'}>
                    <Box sx={{ position: 'absolute', top: 10, right: 10 }}>
                        <IconButton color="inherit" onClick={() => setShowNewMed(false)}>
                            <CloseIcon />
                        </IconButton>
                    </Box>

                    <AddNewMedicine onAdded={whenMedAdded} />
                </ModalCustom>
            </Box>
        </ModalCustom>
    );
}
