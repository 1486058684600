import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { convertStringToNumber } from 'utils/compareArrayOfObjects';
import { getData } from 'utils/indexedDB';
import MicIcon from '@mui/icons-material/Mic';
import SelectedList from './SelectedList';
import FilteredList from './FilteredList';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';
import { uploadDiagnosis, uploadPrescription, uploadLabTest } from './uploadData';
import AnimatedMic from 'ui-component/FormUI/Button/AnimatedMic';
import ModalConfirmation from 'ui-component/modals/ModalConfirmation';

export default function NLP({ visit, onClose }) {
    const { user } = useSelector((state) => state?.user);

    const [labTestData, setLabTestData] = useState(null);
    const [diagnosisData, setDiagnosisData] = useState(null);
    const [medicineData, setMedicineData] = useState(null);

    const [errorData, setErrorData] = useState(null);
    const [isLoadingData, setIsDataLoading] = useState(true);

    const [message, setMessage] = useState({ command: '', name: '' });
    const [filteredTests, setFilteredTests] = useState([]);
    const [selectedTest, setSelectedTest] = useState({});

    const [selectedAllList, setSelectedAllList] = useState([]);
    const [openConfirmation, setOpenConfirmation] = useState(false);

    useEffect(() => {
        const getAllData = async () => {
            try {
                const [labData, diagData, medData] = await Promise.all([getData('labTestType'), getData('diagnosis'), getData('medicine')]);

                setLabTestData(labData);
                setDiagnosisData(diagData);
                setMedicineData(medData);
                SpeechRecognition.startListening({ continuous: true, language: 'en-US' });
            } catch (error) {
                setErrorData(error);
            } finally {
                setIsDataLoading(false);
            }
        };

        // Call the function to fetch data from all APIs
        getAllData();

        return () => {
            SpeechRecognition.stopListening();
        };
    }, []);

    const commands = [
        {
            command: [`test`],
            callback: (text) => {
                console.log('=', text);
                setMessage({ command: 'test', name: text });
                // filterLabTest(text);
            },
            matchInterim: true
        },
        {
            command: [`diagnosis`],
            callback: (text) => {
                console.log('=', text);
                setMessage({ command: 'diagnosis', name: text });
                // filterDiagnosis(text);
            },
            matchInterim: true
        },
        {
            command: [`medicine`],
            callback: (text) => {
                console.log('=', text);
                setMessage({ command: 'medicine', name: text });
                // filterMedicine(text);
            },
            matchInterim: true
        },
        {
            command: [`take *`],
            callback: (ins) => {
                if (message?.command == 'medicine' || message?.command == 'test') {
                    if (selectedAllList.length > 0) {
                        selectedAllList[selectedAllList.length - 1].instruction = ins;
                    }
                }
            },
            matchInterim: true
        },
        {
            command: [`delete number *`, `delete #*`, `delete *`],
            callback: (initialText) => {
                let text = initialText.replaceAll(/[.#]/g, '').trim();
                let num = convertStringToNumber(text);
                if (num && num > 0 && num <= selectedAllList?.at(-1)?._id) {
                    // setMessage({ command: 'delete', name: num });
                    setSelectedAllList(selectedAllList?.filter((i) => i?._id !== num));
                }
            },
            matchInterim: true
        },
        {
            command: 'save it',
            callback: () => {
                if (selectedAllList?.length > 0) {
                    setOpenConfirmation(true);
                }
            },
            matchInterim: true
        },
        {
            command: 'no',
            callback: () => {
                if (openConfirmation == true) {
                    setOpenConfirmation(false);
                }
            },
            matchInterim: true
        },
        {
            command: 'yes',
            callback: () => {
                if (openConfirmation == true) {
                    uploadData();
                    setOpenConfirmation(false);
                }
            },
            matchInterim: true
        },
        {
            command: [`select number *`, `select #*`, `select *`],
            callback: (initialText) => {
                let text = initialText.replaceAll(/[.#]/g, '').trim();
                let num = convertStringToNumber(text);
                if (num && num > 0 && num <= filteredTests?.length) {
                    setSelectedTest(filteredTests[Number(num) - 1]);
                    if (message?.command) {
                        setSelectedAllList([
                            ...selectedAllList,
                            { ...filteredTests[Number(num) - 1], table: message?.command, _id: (selectedAllList?.at(-1)?._id || 0) + 1 }
                        ]);
                    }
                }
            }
        },
        {
            command: '*',
            callback: (text) => {
                if (
                    text &&
                    !text.includes('select number') &&
                    !text.includes('delete number') &&
                    !text.includes('save it') &&
                    !text.includes('take')
                ) {
                    let correctText = text.replaceAll(/[.#]/g, '').trim();
                    if (message?.command == 'medicine') {
                        correctText?.split(' ')[0] == 'medicine' ? filterMedicine(correctText?.split(' ')[1]) : filterMedicine(correctText);
                    }
                    if (message?.command == 'diagnosis') {
                        console.log(correctText);
                        correctText?.split(' ')[0] == 'diagnosis'
                            ? filterDiagnosis(correctText?.split(' ')[1])
                            : filterDiagnosis(correctText);
                    }
                    if (message?.command == 'test') {
                        correctText?.split(' ')[0] == 'test' ? filterLabTest(correctText?.split(' ')[1]) : filterLabTest(correctText);
                    }
                }
            }
        },
        {
            command: 'clear',
            callback: ({ resetTranscript }) => resetTranscript()
        }
    ];

    const filterLabTest = (test) => {
        const res = labTestData?.filter((i) => i?.test?.toLowerCase()?.includes(test?.toLowerCase()));
        res?.length > 0 && setFilteredTests(res);
        resetTranscript();
    };

    const filterDiagnosis = (test) => {
        const res = diagnosisData?.filter((i) => {
            let cmdDiag = i?.code + ' ' + i?.name;
            return cmdDiag?.toLowerCase()?.includes(test?.toLowerCase());
        });
        res?.length > 0 && setFilteredTests(res);
        resetTranscript();
    };

    const filterMedicine = (test) => {
        const res = medicineData?.filter((i) => {
            let cmdMed = i?.name + ' ' + i?.genericName;
            return cmdMed?.toLowerCase()?.includes(test?.toLowerCase());
        });
        res?.length > 0 && setFilteredTests(res);
        resetTranscript();
    };

    const { transcript, resetTranscript, listening } = useSpeechRecognition({ commands });

    const toggleListening = () => {
        listening ? SpeechRecognition.stopListening() : SpeechRecognition.startListening({ continuous: true, language: 'en-US' });
    };

    if (isLoadingData) {
        return (
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: 200 }}>
                <CircularProgress size={35} color="inherit" />
            </Box>
        );
    }

    if (!!errorData) {
        return <Typography>{error?.response?.data?.message || error?.response?.data || error?.message}</Typography>;
    }

    const uploadData = async () => {
        setIsDataLoading(true);
        try {
            if (selectedAllList?.length > 0) {
                const [res1, res2, res3] = await Promise.all([
                    uploadDiagnosis(selectedAllList, visit, user),
                    uploadPrescription(selectedAllList, visit, user),
                    uploadLabTest(selectedAllList, visit, user)
                ]);

                if (res1 || res2 || res3) {
                    toast.success('Prescription Uploaded');
                    onClose();
                }
            }
        } catch (error) {
            toast.error(error?.response?.data?.message || error?.response?.data || error?.message);
        } finally {
            setIsDataLoading(false);
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid item lg={3} md={3} sm={12} xs={12}>
                <Box position={'relative'}>
                    <Typography variant="subtitle1">Transcription</Typography>
                    <Typography>{transcript}</Typography>

                    <AnimatedMic onClick={toggleListening} listening={listening} style={{ position: 'absolute', top: -10, right: 0 }} />
                </Box>
            </Grid>

            <Grid item lg={4.5} md={4.5} sm={12} xs={12}>
                <FilteredList message={message} filteredTests={filteredTests} selectedTest={selectedTest} />
            </Grid>

            <Grid item lg={4.5} md={4.5} sm={12} xs={12}>
                <Grid container spacing={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <SelectedList
                            selectedDiagList={selectedAllList?.filter((i) => i?.table == 'diagnosis')}
                            selectedMedList={selectedAllList?.filter((i) => i?.table == 'medicine')}
                            selectedTestList={selectedAllList?.filter((i) => i?.table == 'test')}
                            setSelectedAllList={setSelectedAllList}
                        />
                    </Grid>

                    <Grid item lg={2} md={2} sm={2} xs={2} />
                    <Grid item lg={5} md={5} sm={5} xs={5}>
                        <Button onClick={() => resetTranscript()}>Reset Transcription</Button>
                    </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={4}>
                        <Button onClick={() => uploadData()}>Upload</Button>
                    </Grid>
                </Grid>
            </Grid>

            <ModalConfirmation
                open={openConfirmation}
                header={'Save Precription'}
                description={'Are you sure, you want to save prescription?'}
                onClickNo={() => {
                    setOpenConfirmation(false);
                }}
                onClickYes={() => {
                    uploadData();
                    setOpenConfirmation(false);
                }}
            />
        </Grid>
    );
}
