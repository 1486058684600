import { Box, Button, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import axios from 'axios';
import { BASE_URL } from 'constants/baseUrl';
import React from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import ReactToPrint from 'react-to-print';
import { MeetingContext } from 'views/meeting/MeetingContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TranslationContext from 'context/translation';

export const AlertPrintPopUp = ({ selectedPrint, onSelectedPrint, content }) => {
    const { user } = useSelector((state) => state?.user);
    const { translate } = useContext(TranslationContext);

    const {
        getPrescriptionByVisit,
        getVisit,
        singleVisit,
        getLabTestByVisit,
        getDiagnosisByVisit,
        getVitalsByVisit,
        loadingVisit,
        loadingPres,
        loadingLabTest,
        loadingDiag,
        loadingVital
    } = useContext(MeetingContext);

    useEffect(() => {
        getVisit();
        getPrescriptionByVisit();
        getLabTestByVisit();
        getDiagnosisByVisit();
        getVitalsByVisit();
    }, []);

    const sendSMS = async () => {
        try {
            const res = await axios({
                method: 'post',
                url: `${BASE_URL}api/visit/sendSMSToPatient`,
                data: {
                    visitid: singleVisit?.id,
                    tag: 'Prescription'
                },
                headers: { Authorization: `Bearer ${user?.token}` }
            });
        } catch (error) {
            toast?.error(error?.response?.data?.message ?? error?.response?.data ?? error?.message);
        }
    };

    return (
        <>
            {loadingDiag || loadingPres || loadingLabTest || loadingVital || loadingVisit ? (
                <Typography>{translate('LOADING_PRESCRIPTION')}...</Typography>
            ) : (
                <>
                    <Typography sx={{ marginBottom: 1 }}>{translate('PRINT_PRESCRIPTION_BY')}</Typography>

                    <Box sx={{ mb: 1 }}>
                        <FormControl>
                            <RadioGroup row value={selectedPrint} onChange={onSelectedPrint}>
                                <FormControlLabel value={true} control={<Radio />} label={translate('VISIT')} />
                                <FormControlLabel value={false} control={<Radio />} label={translate('OVERALL_PRESCRIPTION')} />
                            </RadioGroup>
                        </FormControl>
                    </Box>
                    <ReactToPrint trigger={() => <Button>{translate('PRINT')}</Button>} content={content} />

                    <Button onClick={sendSMS}>{translate('SEND_SMS')}</Button>
                </>
            )}
        </>
    );
};
