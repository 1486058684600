// material-ui
import {
    Autocomplete,
    Button,
    CircularProgress,
    FormControlLabel,
    FormGroup,
    Grid,
    Paper,
    Switch,
    TextField,
    Typography
} from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import { BASE_URL } from 'constants/baseUrl';
import { COLORS } from 'constants/colors';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLocationData } from 'store/slices/locationSlice';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LoadingButton } from '@mui/lab';
import { saveConfig } from 'store/slices/configSlice';

// ==============================|| SAMPLE PAGE ||============================== //

const Configuration = () => {
    const { user } = useSelector((state) => state.user);
    let { config } = useSelector((state) => state?.config);
    const { data: locations, isLoading: isLoadingLocations, error: errorLocations } = useSelector((state) => state.location);
    const dispatch = useDispatch();

    let prescriptionPrint = config?.find((i) => i.title == 'PrescriptionPrint') || null;
    const [printConfig, setPrintConfig] = useState(
        prescriptionPrint?.value
            ? JSON.parse(prescriptionPrint?.value)
            : {
                  reverseHeader: false,
                  showHospitalName: false,
                  showClinicName: false,
                  showClinicNameTo: null,
                  showVitalSign: true,
                  showPrescription: true,
                  showDiagnosis: true,
                  showLabTest: true,
                  showNotes: true,
                  showInitialComplain: true
              }
    );
    const [selectedLocations, setSelectedLocations] = useState(printConfig?.showClinicNameTo?.split(', '));
    const [isLoading, setIsLoading] = useState({ type: '', val: false });

    useEffect(() => {
        if (!locations) {
            dispatch(fetchLocationData());
        }
    }, []);

    const handleChange = (key, val) => {
        let checked = val?.target.checked;
        setPrintConfig((pre) => ({ ...pre, [key]: checked }));
    };

    const handlePresConfig = async () => {
        setIsLoading({ type: 'Pres', val: true });
        try {
            let url = prescriptionPrint ? `${BASE_URL}api/SetupItem/${prescriptionPrint?.id}` : `${BASE_URL}api/SetupItem`;
            let method = prescriptionPrint ? 'put' : 'post';
            const res = await axios({
                method,
                url,
                data: {
                    title: 'PrescriptionPrint',
                    value: JSON.stringify({
                        ...printConfig,
                        showClinicNameTo: printConfig?.showClinicName ? selectedLocations?.join(', ') : null
                    }),
                    value2: '',
                    type: 'Config',
                    isActive: true,
                    createdOn: new Date(),
                    createdBy: user?.userId,
                    ...(prescriptionPrint && {
                        id: prescriptionPrint?.id,
                        lastModifiedBy: user?.userId,
                        lastModifiedOn: new Date()
                    })
                },
                headers: { Authorization: `Bearer ${user?.token}` }
            });

            if (res?.data) {
                let resConfig = await axios.get(`${BASE_URL}api/SetupItem/GetByType/Config`, {
                    headers: { Authorization: `Bearer ${user?.token}` }
                });

                if (resConfig.data) {
                    dispatch(saveConfig(resConfig.data));
                    return toast.success('Configuration Updated!');
                }
            }
        } catch (error) {
            return toast.error(error?.response?.data?.message ?? error?.response?.data ?? error?.message);
        } finally {
            setIsLoading({ type: 'Pres', val: false });
        }
    };

    return (
        <div>
            <Grid mb={2} container direction="row" justifyContent="space-between" alignItems="center" sx={{ py: 1 }}>
                <Typography variant="h3" color={COLORS.color1}>
                    Configuration
                </Typography>
            </Grid>

            <Paper sx={{ width: '100%', overflow: 'hidden', p: 2, position: 'relative' }}>
                <FormGroup>
                    <Typography sx={{ fontSize: 18, fontWeight: 'bold', mb: 1 }}>Prescription Layout</Typography>
                    <Grid container>
                        <Grid item>
                            <Box>
                                <FormControlLabel
                                    sx={{ width: 200 }}
                                    control={
                                        <Switch checked={printConfig?.reverseHeader} onChange={(e) => handleChange('reverseHeader', e)} />
                                    }
                                    label="Reverse Header"
                                />
                                <FormControlLabel
                                    sx={{ width: 200 }}
                                    control={
                                        <Switch
                                            checked={printConfig?.showHospitalName}
                                            onChange={(e) => handleChange('showHospitalName', e)}
                                        />
                                    }
                                    label="Show Hospital Name"
                                />
                                <FormControlLabel
                                    sx={{ width: 200 }}
                                    control={
                                        <Switch checked={printConfig?.showVitalSign} onChange={(e) => handleChange('showVitalSign', e)} />
                                    }
                                    label="Show Vital Sign"
                                />
                                <FormControlLabel
                                    sx={{ width: 200 }}
                                    control={
                                        <Switch
                                            checked={printConfig?.showPrescription}
                                            onChange={(e) => handleChange('showPrescription', e)}
                                        />
                                    }
                                    label="Show Prescription"
                                />
                                <FormControlLabel
                                    sx={{ width: 200 }}
                                    control={
                                        <Switch checked={printConfig?.showDiagnosis} onChange={(e) => handleChange('showDiagnosis', e)} />
                                    }
                                    label="Show Diagnosis"
                                />
                                <FormControlLabel
                                    sx={{ width: 200 }}
                                    control={<Switch checked={printConfig?.showLabTest} onChange={(e) => handleChange('showLabTest', e)} />}
                                    label="Show Lab Test"
                                />
                                <FormControlLabel
                                    sx={{ width: 200 }}
                                    control={
                                        <Switch
                                            checked={printConfig?.showInitialComplain}
                                            onChange={(e) => handleChange('showInitialComplain', e)}
                                        />
                                    }
                                    label="Show Initial Complain"
                                />
                                <FormControlLabel
                                    sx={{ width: 200 }}
                                    control={<Switch checked={printConfig?.showNotes} onChange={(e) => handleChange('showNotes', e)} />}
                                    label="Show Notes"
                                />
                                <FormControlLabel
                                    sx={{ width: 200 }}
                                    control={
                                        <Switch checked={printConfig?.showClinicName} onChange={(e) => handleChange('showClinicName', e)} />
                                    }
                                    label="Show Clinic Name"
                                />

                                {printConfig?.showClinicName && (
                                    <>
                                        {isLoadingLocations ? (
                                            <CircularProgress size={20} />
                                        ) : (
                                            <Autocomplete
                                                multiple
                                                sx={{ mt: 1 }}
                                                size="small"
                                                options={locations?.map((i) => i.name) ?? []}
                                                defaultValue={[]}
                                                getOptionLabel={(loc) => `${loc}`}
                                                value={selectedLocations || []}
                                                onChange={(event, selected) => {
                                                    setSelectedLocations(selected);
                                                }}
                                                renderInput={(params) => <TextField {...params} label="Locations" variant="outlined" />}
                                            />
                                        )}
                                    </>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </FormGroup>
                <LoadingButton
                    loading={isLoading?.type == 'Pres' && isLoading?.val == true}
                    onClick={handlePresConfig}
                    variant="contained"
                    sx={{ textTransform: 'none', position: 'absolute', top: 10, right: 10 }}
                >
                    Save
                </LoadingButton>
            </Paper>
        </div>
    );
};

export default Configuration;
