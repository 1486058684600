import { Box, Button, CircularProgress } from '@mui/material';
import axios from 'axios';
import { BASE_URL } from 'constants/baseUrl';
import { COLORS } from 'constants/colors';
import { Form, Formik } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Textfield from 'ui-component/FormUI/Textfield';
import AnimateButton from 'ui-component/extended/AnimateButton';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState } from 'react';
import { saveUserData } from 'store/slices/userSlice';
import { encrypt } from 'utils/ecryptDecrypt';

export const ChangePassword = () => {
    const { user } = useSelector((state) => state?.user);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    //------------------------------  Initial Values
    const INITIAL_VALUES = {
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
    };

    //------------------------------  Validation Schema
    const FORM_VALIDATION = Yup.object().shape({
        oldPassword: Yup.string().required('Required'),
        newPassword: Yup.string().min(8, 'Password must be at least 8 characters long').required('Required'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
            .required('Required')
    });

    const handleRequest = async (values, resetForm) => {
        setLoading(true);
        try {
            const response = await axios({
                method: 'post',
                url: `${BASE_URL}api/accounts/ChangePassword`,
                data: { ...values, userId: user?.userId },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user?.token}`
                }
            });

            if (response?.data) {
                let newPass = { ...user, key: encrypt(values?.newPassword) };
                localStorage.setItem('user', JSON.stringify(newPass));
                dispatch(saveUserData(newPass));
                return toast.success('Your account password changed successfully');
            }
        } catch (error) {
            if (error?.response?.data?.modelState[''][0]) {
                return toast.error(error?.response?.data?.modelState[''][0]);
            }
            return toast.error(error?.response?.data?.message ?? error?.response?.data ?? error?.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box sx={{ maxWidth: 400 }}>
            <Formik
                initialValues={INITIAL_VALUES}
                validationSchema={FORM_VALIDATION}
                onSubmit={(values, { resetForm }) => handleRequest(values, resetForm)}
            >
                <Form>
                    <Textfield sx={{ mt: 2 }} label="Old Password" name="oldPassword" variant="outlined" />
                    <Textfield sx={{ mt: 2 }} label="New Password" name="newPassword" variant="outlined" />
                    <Textfield sx={{ mt: 2 }} label="Confirm Password" name="confirmPassword" variant="outlined" />

                    <Box sx={{ mt: 2 }}>
                        <AnimateButton>
                            <Button
                                disableElevation
                                disabled={loading}
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                color="primary"
                                sx={{
                                    backgroundColor: COLORS.primary
                                }}
                            >
                                {loading ? <CircularProgress size={25} color="inherit" /> : 'Submit'}
                            </Button>
                        </AnimateButton>
                    </Box>
                </Form>
            </Formik>
        </Box>
    );
};
