import axios from 'axios';
import { BASE_URL } from 'constants/baseUrl';
import React, { createContext, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { getVisitDetail } from 'services/visit';
import { syncVisit } from 'store/slices/campVisitSlice';

const MeetingContext = createContext();

const MeetingContextProvider = ({ children, visit, setCampVisit }) => {
    const [singleVisit, setVisit] = useState(null);
    const [prescriptionList, setPrescriptionList] = useState([]);
    const [diagnosisList, setDiagnosisList] = useState([]);
    const [labTestList, setLabTestList] = useState([]);
    const [vitalSigns, setVitalSigns] = useState(null);

    const [loadingVisit, setLoadingVisit] = useState(true);
    const [loadingPres, setLoadingPres] = useState(true);
    const [loadingDiag, setLoadingDiag] = useState(true);
    const [loadingLabTest, setLoadingLabTest] = useState(true);
    const [loadingVital, setLoadingVital] = useState(true);

    const { user } = useSelector((state) => state?.user);
    const { campVisit } = useSelector((state) => state?.campVisit);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const getVisit = async () => {
        try {
            setLoadingVisit(true);
            const response = await getVisitDetail(visit?.id);
            setVisit(response?.data[0]);
        } catch (error) {
            console.log('Error in Visit ', error);
        } finally {
            setLoadingVisit(false);
        }
    };

    const getPrescriptionByVisit = async () => {
        try {
            setLoadingPres(true);
            const response = await axios.get(`${BASE_URL}api/prescription/PrescriptionByPatient/${visit?.patient_NationalID}`, {
                headers: {
                    Authorization: `Bearer ${user?.token}`
                }
            });
            setPrescriptionList(response?.data);
        } catch (error) {
            console.log('Error in Prescription ', error);
        } finally {
            setLoadingPres(false);
        }
    };

    const getDiagnosisByVisit = async () => {
        try {
            setLoadingDiag(true);
            const response = await axios.get(`${BASE_URL}api/patientdiagnostic/diagnosticsByPatient/${visit?.patient_NationalID}`, {
                headers: {
                    Authorization: `Bearer ${user?.token}`
                }
            });
            setDiagnosisList(response?.data);
        } catch (error) {
            console.log('Error in Diagnosis ', error);
        } finally {
            setLoadingDiag(false);
        }
    };

    const getLabTestByVisit = async () => {
        try {
            setLoadingLabTest(true);
            const response = await axios.get(`${BASE_URL}api/VisitLabTest/GetByVisit/${visit?.id}`, {
                headers: {
                    Authorization: `Bearer ${user?.token}`
                }
            });
            setLabTestList(response?.data);
        } catch (error) {
            console.log('Error in Lab Test ', error);
        } finally {
            setLoadingLabTest(false);
        }
    };

    const getVitalsByVisit = async () => {
        try {
            setLoadingVital(true);
            const response = await axios.get(`${BASE_URL}api/vitalsign/getPatientVitalSignbyVisit/${visit?.id}`, {
                headers: {
                    Authorization: `Bearer ${user?.token}`
                }
            });
            setVitalSigns(response?.data);
        } catch (error) {
            console.log('Error in Vitals ', error);
        } finally {
            setLoadingVital(false);
        }
    };

    useEffect(() => {
        getVisit();
        getPrescriptionByVisit();
        getLabTestByVisit();
        getDiagnosisByVisit();
        getVitalsByVisit();

        return () => {
            setVisit(null);
            setPrescriptionList([]);
            setLabTestList([]);
            setDiagnosisList([]);
            setVitalSigns(null);
        };
    }, [visit?.id]);

    useEffect(() => {
        const getVisit = async () => {
            try {
                const response = await getVisitDetail(campVisit);
                setCampVisit(response?.data[0]);
                navigate(`/meeting/${campVisit}`, { state: response?.data[0] });
            } catch (error) {
                console.log('Error in Visit ', error);
            }
        };

        if (!!campVisit && visit?.id != campVisit) {
            getVisit();
        }

        return () => {
            dispatch(syncVisit(null));
        };
    }, [campVisit]);

    return (
        <MeetingContext.Provider
            value={{
                singleVisit,
                setVisit,
                getVisit,
                loadingVisit,
                loadingPres,
                prescriptionList,
                setPrescriptionList,
                getPrescriptionByVisit,
                loadingLabTest,
                labTestList,
                setLabTestList,
                getLabTestByVisit,
                loadingDiag,
                diagnosisList,
                setDiagnosisList,
                getDiagnosisByVisit,
                loadingVital,
                vitalSigns,
                setVitalSigns,
                getVitalsByVisit
            }}
        >
            {children}
        </MeetingContext.Provider>
    );
};

export { MeetingContext, MeetingContextProvider };
