import React, { useState, useEffect, useRef, memo } from 'react';

import { Box, Button, CircularProgress, TextField, Typography } from '@mui/material';

import { BASE_URL } from 'constants/baseUrl';
import { useSelector } from 'react-redux';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { getData } from 'utils/indexedDB';
import { LoadingButton } from '@mui/lab';
import { styles } from './styles';
import axios from 'axios';

const AddDiagnosis = ({ visit, getPatientDiagnosis, showDiagAndPlan = true }) => {
    const { user } = useSelector((state) => state?.user);

    const [diagnosisList, setDiagnosisList] = useState([]);

    const [diagnosis, setDiagnosis] = useState(null);
    const [loading, setLoading] = useState(true);

    const [defaultInitialComp, setDefaultInitialComp] = useState(visit?.initialComplain);

    let diagnosisPlanRef = useRef('');
    const [loadSubmit, setLoadSubmit] = useState(false);

    // ---------------------------- Getting All Diagnosis selectable list from indexed DB
    useEffect(() => {
        getData('diagnosis').then((res) => {
            setDiagnosis(res);
            setLoading(false);
        });
    }, []);

    let diagRef = useRef(null);

    //-----------------------------  Function Called when Add Button Clicked
    const handleAdd = async () => {
        if (diagRef?.current?.value == '') {
            return toast.error('Please select diagnosis');
        }

        const diag = {
            Consultant_NationalID: visit.consultant_NationalID,
            Patient_NationalID: visit.patient_NationalID,
            code: diagRef?.current?.value?.split(':')[0].trim(),
            VisitID: visit.id,
            medicineName: diagRef?.current?.value?.split(':')[1].trim(),
            createdBy: user.userId,
            createdOn: new Date(),
            name: diagRef?.current?.value?.split(':')[1].trim()
        };

        setDiagnosisList([...diagnosisList, diag]);

        diagRef.current.value = '';
    };

    const handleDelete = (diagnosis) => {
        const newList = diagnosisList?.filter((item) => item?.name !== diagnosis?.name);
        setDiagnosisList(newList);
    };

    const handleSubmitAll = async () => {
        try {
            setLoadSubmit(true);

            let medRes = null;

            for (let i = 0; i < diagnosisList.length; i++) {
                medRes = await axios.post(`${BASE_URL}api/patientdiagnostic`, diagnosisList[i], {
                    headers: {
                        Authorization: `Bearer ${user?.token}`,
                        'Content-Type': 'application/json'
                    }
                });
            }

            if (medRes.data) {
                getPatientDiagnosis();
                toast.success('Diagnosis added successfully');
                setDiagnosisList([]);
            }
        } catch (error) {
            return toast.error(error?.response?.data?.message ?? error?.response?.data ?? error?.message);
        } finally {
            setLoadSubmit(false);
        }
    };

    const submitDiagAndPlan = async () => {
        try {
            const resUpdateVisit = await axios({
                method: 'put',
                url: `${BASE_URL}api/visit/updateVisit/${visit.id}`,
                data: {
                    initialComplain: diagnosisPlanRef.current.value
                },
                headers: { Authorization: `Bearer ${user?.token}` }
            });

            if (resUpdateVisit?.data) {
                setDefaultInitialComp(resUpdateVisit?.data?.initialComplain);
                return toast.success('Diagnosis and Plan updated');
            }
        } catch (error) {
            return toast.error(error?.response?.data?.message ?? error?.response?.data ?? error?.message);
        }
    };

    return (
        <>
            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress size={35} />
                </Box>
            ) : (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {showDiagAndPlan && (
                        <TextField
                            id="outlined-multiline-static"
                            fullWidth
                            label="Diagnosis and Plan"
                            inputRef={diagnosisPlanRef}
                            defaultValue={defaultInitialComp}
                            multiline
                            rows={2}
                            InputProps={{ endAdornment: <Button onClick={submitDiagAndPlan}>Save</Button> }}
                        />
                    )}

                    <Box sx={{ display: 'flex', flex: 1, gap: 1 }}>
                        <AutoCompleteCom
                            listName={'diagnosis'}
                            data={diagnosis}
                            inputref={diagRef}
                            placeholder={'Select Diagnosis'}
                            showKey={['code', 'name', 'diseaseDiscription']}
                        />

                        {/* -------------------------------  Add Button */}
                        <Button variant="outlined" onClick={handleAdd}>
                            Add
                        </Button>
                    </Box>
                </Box>
            )}

            {diagnosisList?.map((item, index) => (
                <Box key={index} sx={styles.prescriptionCard}>
                    <Box>
                        <Typography sx={{ fontSize: 16, fontWeight: 600 }}>{`${item?.code} : ${item?.name}`}</Typography>
                    </Box>

                    <Box>
                        <Button onClick={() => handleDelete(item)} size="small" variant="contained" sx={{ float: 'right' }}>
                            Delete
                        </Button>
                    </Box>
                </Box>
            ))}

            {diagnosisList?.length > 0 && (
                <LoadingButton loading={loadSubmit} onClick={() => handleSubmitAll()} variant="contained" sx={{ float: 'right', mt: 1 }}>
                    Submit All
                </LoadingButton>
            )}
        </>
    );
};

const AutoCompleteCom = memo(({ data, inputref, placeholder, showKey, listName }) => {
    return (
        <div style={{ width: '100%' }}>
            <input
                list={listName}
                placeholder={placeholder}
                style={styles.inputField}
                type="text"
                ref={inputref}
                onFocus={(e) => {
                    e.target.style.border = '2px solid #2196f3'; // change border color on focus
                }}
                onBlur={(e) => {
                    e.target.style.border = '1px solid #bfc0c2'; // revert border color on blur
                }}
            />
            <datalist id={listName}>
                {data &&
                    data.map((item, index) => (
                        <option key={index} value={`${item[showKey[0]]} : ${item[showKey[1]]} ( ${item[showKey[2]]} )`} />
                    ))}
            </datalist>
        </div>
    );
});

export default AddDiagnosis;
