import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    transcriptionStarted: false,
    transcription: null
};

export const transcriptionSlice = createSlice({
    name: 'transcription',
    initialState,
    reducers: {
        startedTranscription: (state = initialState, action) => {
            state.transcriptionStarted = action.payload;
        },
        loadTranscription: (state = initialState, action) => {
            state.transcription = action.payload;
        }
    }
});

export const { startedTranscription, loadTranscription } = transcriptionSlice.actions;

export default transcriptionSlice.reducer;
