import { Box, Button, Card, CircularProgress, IconButton, Paper, Typography } from '@mui/material';
import { BASE_URL } from 'constants/baseUrl';
import useFetch from 'hooks/useFetch';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { profileImage } from 'utils/fetchImage';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ModalConfirmation from 'ui-component/modals/ModalConfirmation';
import axios from 'axios';
import { getProfiles } from 'services/loginUser';
import { saveProfileData } from 'store/slices/profileSlice';
import jwt_decode from 'jwt-decode';
import { SignalCellularNullRounded } from '@mui/icons-material';

export default function AddProfiles() {
    const { user } = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const tokenDecode = jwt_decode(user?.token);

    const [selectedProfile, setSelectedProfile] = useState(null);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    const [loadingAddProfile, setLoadingAddProfile] = useState(false);

    // ------------------- Get Profiles List from other domains
    const {
        data: profileList,
        loading: loadingProfileList,
        error: errorProfileList,
        refetch: refetchProfileList
    } = useFetch(`${BASE_URL}api/patient/patientProfiles/${user?.userId}`);

    const handleAdd = (profile) => {
        setSelectedProfile(profile);
        setShowConfirmationModal(true);
    };

    const handleCreateProfile = async () => {
        try {
            setLoadingAddProfile(true);
            const responseCreatePatient = await axios({
                method: 'post',
                url: `${BASE_URL}api/accounts/CreatePatientProfile`,
                data: {
                    ...selectedProfile,
                    role: 'Patient',
                    createdBy: user?.userId,
                    createdOn: new Date()
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user?.token}`
                }
            });

            if (responseCreatePatient?.data) {
                const profiles = await getProfiles(user?.token, user?.userId);
                dispatch(saveProfileData(profiles?.data));
                refetchProfileList();
                setSelectedProfile(null);
                setShowConfirmationModal(false);
            }
        } catch (error) {
            return toast.error(error?.response?.data?.message ?? error?.response?.data ?? error?.message);
        } finally {
            setLoadingAddProfile(false);
        }
    };

    return (
        <>
            {profileList?.length > 0 && (
                <Paper sx={{ p: 2, m: 2 }}>
                    <Typography variant="h4" sx={{ marginBottom: 2 }}>
                        Profiles in other healthcare providers
                    </Typography>
                    <Box style={{ display: 'flex', gap: 20, justifyContent: 'center', alignItems: 'center' }}>
                        {profileList?.map((profile) => (
                            <Card onClick={() => {}} variant="outlined" sx={styles.boxStyle}>
                                <img
                                    alt={'Profile'}
                                    src={`${BASE_URL}media/${profile?.domain}/Profile/${profile?.profileImage}`}
                                    style={styles.cardProfilePicture}
                                />
                                <Typography variant="h5" sx={{ mt: 0.5, mb: 0.5, textAlign: 'center' }}>
                                    {profile?.name}
                                </Typography>

                                <Button size="small" variant="outlined" onClick={() => handleAdd(profile)}>
                                    Add
                                </Button>
                            </Card>
                        ))}
                    </Box>

                    <ModalConfirmation
                        open={showConfirmationModal}
                        header={'Add Profile'}
                        description={`Are you sure, you want to add this profile in ${tokenDecode?.Name}?`}
                        loading={loadingAddProfile}
                        onClickNo={() => {
                            setSelectedProfile(null);
                            setShowConfirmationModal(false);
                        }}
                        onClickYes={() => handleCreateProfile()}
                    />
                </Paper>
            )}
            {loadingProfileList && (
                <Paper sx={{ p: 2, m: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress size={35} color="inherit" />
                </Paper>
            )}
        </>
    );
}

const styles = {
    boxStyle: {
        padding: 1,
        width: 120,
        backgroundColor: '#f5f5f5',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    cardProfilePicture: {
        width: 50,
        height: 50,
        borderRadius: 80 / 2,
        marginBottom: 5,
        objectFit: 'cover'
    }
};
