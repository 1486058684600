import { Box, Divider, Typography } from '@mui/material';
import React from 'react';
import PrintHeader from 'views/components/PrintHeader';
import moment from 'moment';

const PrintAptList = React.forwardRef((props, ref) => {
    const { visitList } = props;

    return (
        <div ref={ref} style={{ margin: 40 }}>
            <PrintHeader />

            {visitList &&
                visitList?.items?.map((visit) => (
                    <Box key={visit?.id}>
                        <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Typography>
                                Visit ID: {visit?.id} {`(${visit?.isConfirm ? 'Confirmed' : 'Unconfirmed'})`}
                            </Typography>
                            <Typography>Date: {moment(visit?.startDateTime).format('MMM DD, YYYY  -  hh:mm A')}</Typography>
                        </Box>
                        <Typography>Title: {visit?.title}</Typography>
                        <Typography>Meeting Type: {visit?.meetingtype}</Typography>
                        <Typography>Patient: {`${visit?.patientTitle} ${visit?.patientFirstName} ${visit?.patientLastName}`}</Typography>
                        <Typography>
                            Physician:{' '}
                            {`${visit?.consultantTitle ? `${visit?.consultantTitle}` : `${visit?.doctorTitle}`}  ${
                                visit?.consultantFirstName ? `${visit?.consultantFirstName}` : `${visit?.doctorFirstName}`
                            } ${visit?.consultantLastName ? `${visit?.consultantLastName}` : `${visit?.doctorLastName}`} (${
                                visit?.doctorSpeciality
                            })`}
                        </Typography>
                        <Divider sx={{ my: 1 }} />
                    </Box>
                ))}
        </div>
    );
});

export default PrintAptList;
