import React, { useState, useEffect } from 'react';

import {
    TableRow,
    TablePagination,
    TableHead,
    TableContainer,
    TableBody,
    TableCell,
    Table,
    Paper,
    CircularProgress,
    Grid,
    IconButton,
    Typography,
    TextField,
    Autocomplete,
    Box
} from '@mui/material';

// -----------------  Importing Icons
import PrintIcon from '@mui/icons-material/Print';

// -----------------  Importing Constants
import { COLORS } from 'constants/colors';
import { BASE_URL } from 'constants/baseUrl';

// -----------------  Utilis

import { useSelector } from 'react-redux';
import axios from 'axios';
import SearchField from 'ui-component/FormUI/SearchField.js';
import useFetch from 'hooks/useFetch';
import moment from 'moment';
import PrintPaymentList from './print';
import { useReactToPrint } from 'react-to-print';

export default function Payment() {
    const { user } = useSelector((state) => state?.user);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [count, setCount] = useState(0);

    const [completedVisitList, setCompletedVisitList] = useState(null);
    const [searchQuery, setSearchQuery] = useState();
    const [selectedPhysician, setSelectedPhysician] = useState('');
    const [amount, setAmount] = useState({ total: 0, physician: 0 });

    const curDate = new Date();

    const [startDate, setStartDate] = useState(
        new Date(curDate.getFullYear(), curDate.getMonth(), curDate.getDate() - 14).toLocaleDateString('en-CA')
    );
    const [endDate, setEndDate] = useState(new Date().toLocaleDateString('en-CA'));
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const handleChangePage = (event, newPage) => {
        getCompletedVisitList(newPage, rowsPerPage, selectedPhysician, startDate, endDate, searchQuery);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        getCompletedVisitList(page, event.target.value, selectedPhysician, startDate, endDate, searchQuery);
        setRowsPerPage(+event.target.value);
    };

    const handleSelectedPhysician = (e, selected) => {
        getCompletedVisitList(0, rowsPerPage, selected?.nationalID, startDate, endDate, searchQuery);
        setSelectedPhysician(selected?.nationalID || null);
        setPage(0);
    };

    const handleStartDate = (e) => {
        getCompletedVisitList(0, rowsPerPage, selectedPhysician, e.target.value, endDate, searchQuery);
        setStartDate(e.target.value);
        setPage(0);
    };

    const handleEndDate = (e) => {
        getCompletedVisitList(0, rowsPerPage, selectedPhysician, startDate, e.target.value, searchQuery);
        setEndDate(e.target.value);
        setPage(0);
    };

    const { data: physicianDataList } = useFetch(`${BASE_URL}api/physician/physicianSelectList?pageNumber=1&pageSize=100&QuerySearch=`);

    const getCompletedVisitList = async (pageNumber, pageSize, physician, start, end, searchValue) => {
        try {
            setLoading(true);
            setError(null);
            setCompletedVisitList(null);

            const res = await axios({
                method: 'get',
                url: `${BASE_URL}api/payment/GetPhysicianPayments?pageNumber=${pageNumber + 1}&pageSize=${pageSize}&QuerySearch=${
                    searchValue ?? ''
                }&StartDate=${start}&EndDate=${end}&physicianid=${physician}`,
                headers: {
                    Authorization: `Bearer ${user?.token}`
                }
            });

            setCompletedVisitList(res?.data);
            setCount(res?.data?.paginationMetadata?.totalCount);
            const totalAmount = res?.data?.visits?.reduce((accumulator, currentValue) => {
                return accumulator + currentValue.amount;
            }, 0);

            const physicianShare = res?.data?.visits?.reduce((accumulator, currentValue) => {
                return accumulator + currentValue.physicianShare;
            }, 0);

            setAmount({ total: totalAmount, physician: physicianShare });
        } catch (error) {
            console.log(error);
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getCompletedVisitList(page, rowsPerPage, selectedPhysician, startDate, endDate, searchQuery);
    }, []);

    const componentRef = React.useRef(null);
    const handlePrint = useReactToPrint({
        content: () => componentRef.current
    });

    return (
        <div>
            {/* --------------------------  Header with title and two button for Add User and Multiple User */}

            <Grid mb={2} container direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="h3" color={COLORS.color1} sx={{ py: 1 }}>
                    Payment List
                </Typography>

                <Grid item>
                    <Box sx={{ display: 'flex', gap: 4 }}>
                        <Typography>Total Amount: {amount?.total}</Typography>
                        <Typography>Hospital Share: {amount?.total - amount?.physician}</Typography>
                        <Typography>Physician Share: {amount?.physician}</Typography>
                    </Box>
                </Grid>
            </Grid>

            {/* -------------------------------  Search Layout button */}

            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <Grid container alignItems="center" p={2} rowGap={2}>
                    <Grid item sx={{ display: 'flex', flex: 1 }}>
                        <Grid container gap={2}>
                            <Grid item lg={3} xs={12}>
                                <SearchField
                                    label="Search"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    onClickSearch={() => {
                                        setPage(0);
                                        getCompletedVisitList(0, rowsPerPage, selectedPhysician, startDate, endDate, searchQuery);
                                    }}
                                    onClickClear={() => {
                                        setPage(0);
                                        setSearchQuery('');
                                        getCompletedVisitList(0, rowsPerPage, selectedPhysician, startDate, endDate, '');
                                    }}
                                    titleSearchBtn={'Search Physician and Patient'}
                                    titleClearBtn={'Clear search list'}
                                />
                            </Grid>

                            <Grid lg={2} xs={12}>
                                <TextField
                                    fullWidth
                                    type="date"
                                    variant="outlined"
                                    label="Start Date"
                                    InputLabelProps={{ shrink: true }}
                                    value={startDate}
                                    onChange={handleStartDate}
                                />
                            </Grid>

                            <Grid lg={2} xs={12}>
                                <TextField
                                    fullWidth
                                    type="date"
                                    variant="outlined"
                                    label="End Date"
                                    InputLabelProps={{ shrink: true }}
                                    value={endDate}
                                    onChange={handleEndDate}
                                />
                            </Grid>

                            <Grid lg={3} xs={12}>
                                <Autocomplete
                                    options={physicianDataList ?? []}
                                    getOptionLabel={(physician) => `${physician?.name}  ${physician?.phone} - ${physician?.speciality}`}
                                    value={physicianDataList?.find((i) => i?.nationalID == selectedPhysician)}
                                    onChange={(event, selected) => handleSelectedPhysician(event, selected)}
                                    renderInput={(params) => <TextField {...params} label="Physician" />}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
                        <IconButton title="Print Completed Visit List" onClick={handlePrint}>
                            <PrintIcon />
                        </IconButton>
                    </Grid>
                </Grid>

                <TableContainer sx={{ maxHeight: 'auto' }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow
                                sx={{
                                    '& th': {
                                        color: '#fff',
                                        backgroundColor: COLORS.secondory
                                    }
                                }}
                            >
                                <TableCell align={'left'}>ID</TableCell>

                                <TableCell align={'left'}>Patient Name</TableCell>

                                <TableCell align={'left'}>Physician Name</TableCell>

                                <TableCell align={'left'}>Meeting Type</TableCell>

                                <TableCell align={'left'}>Date Time</TableCell>

                                <TableCell align={'left'}>Summary Notes</TableCell>

                                <TableCell align={'left'}>Fee</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!!completedVisitList &&
                                completedVisitList?.visits?.map((row) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.id}
                                            sx={{ '&:hover': { cursor: 'pointer' } }}
                                        >
                                            <TableCell align={'left'}>{row?.id}</TableCell>

                                            <TableCell align={'left'} sx={{ textTransform: 'capitalize' }}>
                                                {row?.patientName?.toLowerCase()}
                                            </TableCell>

                                            <TableCell align={'left'} sx={{ textTransform: 'capitalize' }}>
                                                {row?.consultantName?.toLowerCase()}
                                            </TableCell>

                                            <TableCell align={'left'}>{row?.meetingtype}</TableCell>

                                            <TableCell align={'left'}>
                                                {moment(row?.startDateTime).format('MMM DD, YYYY  -  hh:mm A')}
                                            </TableCell>

                                            <TableCell align={'left'}>{row?.title}</TableCell>

                                            <TableCell align={'left'}>{row?.amount}</TableCell>
                                        </TableRow>
                                    );
                                })}

                            {loading && (
                                <TableRow sx={{ height: 400 }}>
                                    <TableCell align="center" colSpan={10}>
                                        <CircularProgress size={35} color="inherit" />
                                    </TableCell>
                                </TableRow>
                            )}

                            {!!error && (
                                <TableRow sx={{ height: 400 }}>
                                    <TableCell align="center" colSpan={10}>
                                        <Typography>{error?.response?.data?.message || error?.response?.data || error?.message}</Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={[5, 10, 20]}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>

            <div style={{ display: 'none' }}>
                <PrintPaymentList ref={componentRef} paymentVisits={completedVisitList} amount={amount} />
            </div>
        </div>
    );
}

const styles = {
    loadingContainer: {
        height: 400,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
};
