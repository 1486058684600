import { createRoot } from 'react-dom/client';
// third party
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

// project imports
import * as serviceWorker from 'serviceWorker';
import App from 'App';
import { store } from 'store';
import ReactDOM from 'react-dom';
// style + assets
import 'assets/scss/style.scss';
import config from './config';

// ==============================|| REACT DOM RENDER  ||============================== //
import TranslationContext from './context/translation';
import { useTranslation } from './hooks/useTranslation';
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
const Index = () => {
    const translation = useTranslation();

    return (
        <Provider store={store}>
            <BrowserRouter basename={config.basename}>
                <TranslationContext.Provider value={translation}>
                    <App />
                </TranslationContext.Provider>
            </BrowserRouter>
        </Provider>
    );
};
ReactDOM.render(<Index />, document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
