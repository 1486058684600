import { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    ButtonBase,
    ClickAwayListener,
    Grid,
    Paper,
    Popper,
    Stack,
    Typography,
    useMediaQuery,
    CircularProgress
} from '@mui/material';

import { BASE_URL } from 'constants/baseUrl';
// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import NotificationList from './NotificationList';
import axios from 'axios';

// assets
import { IconBell } from '@tabler/icons';
import { COLORS } from 'constants/colors';

// ==============================|| NOTIFICATION ||============================== //

const NotificationSection = () => {
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
    const { user } = useSelector((state) => state.user);
    const [open, setOpen] = useState(false);
    const [notifications, setNotifications] = useState();
    const [loading, setLoading] = useState(true);
    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const prevOpen = useRef(open);
    const getNotifications = async () => {
        try {
            let url = `${BASE_URL}api/notification/notificationList/${user?.userId}/Web/ALL/${user?.email}/${user?.roles}/?pageNumber=1&pageSize=20&QuerySearch=`;

            const res = await axios({
                method: 'get',
                url,
                headers: {
                    Authorization: `Bearer ${user?.token}`
                }
            });
            setNotifications(res?.data?.items);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        getNotifications();
        prevOpen.current = open;
    }, [open]);

    const handleReadAll = async () => {
        const res = await axios({
            method: 'put',
            url: `${BASE_URL}api/notification/readAllNotification/${user?.userId}`,
            headers: {
                Authorization: `Bearer ${user?.token}`
            }
        });
        getNotifications();
    };
    return (
        <>
            <Box
                sx={{
                    ml: 2,
                    mr: 3,
                    [theme.breakpoints.down('md')]: {
                        mr: 2
                    }
                }}
            >
                <ButtonBase sx={{ borderRadius: '12px' }}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: COLORS.secondory,
                            color: COLORS.white,
                            '&[aria-controls="menu-list-grow"],&:hover': {
                                background: COLORS.primary,
                                color: COLORS.white
                            }
                        }}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}
                        color="inherit"
                    >
                        <IconBell stroke={1.5} size="1.3rem" />
                    </Avatar>
                </ButtonBase>
            </Box>
            <Popper
                placement={matchesXs ? 'bottom' : 'bottom-end'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [matchesXs ? 5 : 0, 20]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions position={matchesXs ? 'top' : 'top-right'} in={open} {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item xs={12}>
                                            <Grid container alignItems="center" justifyContent="space-between" sx={{ pt: 2, px: 2 }}>
                                                <Grid item>
                                                    <Stack direction="row" spacing={2}>
                                                        <Typography variant="subtitle1">All Notification</Typography>
                                                        {/* <Chip
                                                            size="small"
                                                            label="01"
                                                            sx={{
                                                                color: theme.palette.background.default,
                                                                bgcolor: '#1565c0'
                                                            }}
                                                        /> */}
                                                    </Stack>
                                                </Grid>
                                                <Grid item sx={{ px: 2 }}>
                                                    <Typography
                                                        onClick={handleReadAll}
                                                        component={Link}
                                                        to="#"
                                                        variant="subtitle2"
                                                        color="primary"
                                                    >
                                                        Mark as all read
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <PerfectScrollbar
                                                style={{ height: '100%', maxHeight: 'calc(100vh - 205px)', overflowX: 'hidden' }}
                                            >
                                                {!loading && (
                                                    <NotificationList notifications={notifications} getNotifications={getNotifications} />
                                                )}
                                                {loading && <CircularProgress size={10} color="inherit" />}
                                            </PerfectScrollbar>
                                        </Grid>
                                    </Grid>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </>
    );
};

export default NotificationSection;
