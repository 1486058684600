export function removeDuplicates(arr1, arr2, compare) {
    // Use filter to remove elements present in arr2
    const filteredArr = arr1.filter((item1) => !arr2.some((item2) => areObjectsEqual(item1, item2, compare)));
    return filteredArr;
}

export function areObjectsEqual(obj1, obj2, compare) {
    // Implement your own logic to compare objects
    // For simplicity, this example assumes objects are equal if their 'id' properties match
    return obj1[compare] === obj2[compare];
}

export function convertStringToNumber(input) {
    const numberWords = [
        'one',
        'two',
        'three',
        'four',
        'five',
        'six',
        'seven',
        'eight',
        'nine',
        'ten',
        'eleven',
        'twelve',
        'thirteen',
        'fourteen',
        'fifteen'
    ];

    if (typeof input === 'number' || (typeof input === 'string' && !isNaN(input))) {
        // If the input is already a number or a numeric string, convert it to a number
        return Number(input);
    } else if (typeof input === 'string') {
        // If the input is a string, check if it's a valid number word
        const index = numberWords.indexOf(input.toLowerCase());
        if (index !== -1 && index + 1 >= 1 && index + 1 <= 15) {
            // If valid, return the corresponding number
            return index + 1;
        }
    }

    // If the input is not a valid number or number word, return null or handle accordingly
    return null;
}
