import React, { useState, useEffect, useRef } from 'react';

import { Box, Button, CircularProgress, Typography } from '@mui/material';

import { BASE_URL } from 'constants/baseUrl';
import { useSelector } from 'react-redux';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';

import { getData } from 'utils/indexedDB';
import { LoadingButton } from '@mui/lab';
import { styles } from './styles';
import axios from 'axios';

const AddPrescription = ({ visit, getPatientPrescription }) => {
    const { user } = useSelector((state) => state?.user);
    let { config } = useSelector((state) => state?.config);
    let isRequiredPrescDetail = config?.find((i) => i.title == 'isRequiredPrescDetail')?.value || null;

    const [prescriptionList, setPrescriptionList] = useState([]);

    const [medicine, setMedicine] = useState(null);
    const [loading, setLoading] = useState(true);

    const [loadSubmit, setLoadSubmit] = useState(false);

    //----------------------------  Getting List of Medicine, Route, Direction etc from LocalStorage and IndexedDB
    const route = JSON.parse(localStorage.getItem('route'));
    const frequency = JSON.parse(localStorage.getItem('frequency'));
    const duration = JSON.parse(localStorage.getItem('duration'));
    const direction = JSON.parse(localStorage.getItem('direction'));
    const doseUnit = JSON.parse(localStorage.getItem('doseUnit'));

    useEffect(() => {
        getData('medicine').then((res) => {
            setMedicine(res);
            setLoading(false);
            return;
        });

        return () => {
            setMedicine(null);
        };
    }, []);

    //-----------------------------  Setting Ref to handle all selected Values from list
    let medRef = useRef('');
    let freqRef = useRef('');
    let routeRef = useRef('');
    let directionRef = useRef('');
    let durationRef = useRef('');
    let doseUnitRef = useRef('');
    let doseRef = useRef(1);
    let instructionRef = useRef('');

    //-----------------------------  Function Called when Add Button Clicked
    const handleAdd = async () => {
        if (medRef?.current?.value == '') {
            return toast.error('Please select medicine');
        }

        if (
            freqRef?.current?.value == '' ||
            routeRef?.current?.value == '' ||
            directionRef?.current?.value == '' ||
            durationRef?.current?.value == '' ||
            doseRef?.current?.value == '' ||
            doseUnitRef?.current?.value == ''
        ) {
            return toast.error('Please fill medicine detail');
        }

        const prescription = {
            VisitID: visit?.id,
            Consultant_NationalID: visit?.consultant_NationalID,
            Patient_NationalID: visit?.patient_NationalID,
            MedicineID: medRef?.current?.value.split('-')[0].trim(),
            RefilDetails: null,
            dose: doseRef.current.value,
            doseUnit: doseUnitRef.current.value,
            route: routeRef?.current?.value,
            direction: directionRef.current.value,
            duration: durationRef.current.value,
            frequency: freqRef.current.value,
            durationUnit: doseUnitRef.current.value,
            isDeleted: false,
            status: 'Continued',
            dosageInstruction: instructionRef.current.value,
            createdOn: new Date().toLocaleString(),
            createdBy: user.userId,
            //------------------below values are just to handle frontend prescription values
            medicineName: medRef?.current?.value.split('-')[1].trim(),
            medicineGenericName: medRef?.current?.value.split('-')[2].trim(),
            prescriptionId: medRef?.current?.value.split('-')[0].trim()
        };

        //-----------------------  Appending New Prescription in Prescription List
        setPrescriptionList([prescription, ...prescriptionList]);

        //-----------------------  Reseting all Values
        medRef.current.value = '';
        routeRef.current.value = '';
        durationRef.current.value = '';
        directionRef.current.value = '';
        doseUnitRef.current.value = '';
        doseRef.current.value = 1;
        instructionRef.current.value = '';
        freqRef.current.value = '';
    };

    const handleDelete = (prescription) => {
        const afterDelPre = prescriptionList.filter((item) => item?.prescriptionId !== prescription?.prescriptionId);

        setPrescriptionList(afterDelPre);
    };

    const handleSubmitAll = async () => {
        try {
            setLoadSubmit(true);

            let medRes = null;

            for (let i = 0; i < prescriptionList.length; i++) {
                medRes = await axios.post(`${BASE_URL}api/prescription`, prescriptionList[i], {
                    headers: {
                        Authorization: `Bearer ${user?.token}`,
                        'Content-Type': 'application/json'
                    }
                });
            }

            if (medRes.data) {
                getPatientPrescription();
                toast.success('Prescription added successfully');
                setPrescriptionList([]);
            }
        } catch (error) {
            return toast.error(error?.response?.data?.message ?? error?.response?.data ?? error?.message);
        } finally {
            setLoadSubmit(false);
        }
    };

    return (
        <>
            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress size={35} />
                </Box>
            ) : (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    <Box>
                        <AutoCompleteCom
                            listName={'medicine'}
                            data={medicine}
                            inputref={medRef}
                            placeholder={'Select Medicine'}
                            showKey={['medicineID', 'name', 'genericName']}
                        />
                    </Box>

                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <Box sx={{ display: 'flex', flex: 1 }}>
                            <AutoCompleteCom
                                listName={'route'}
                                data={route}
                                inputref={routeRef}
                                placeholder={'Route'}
                                showKey={['title']}
                            />
                        </Box>

                        <Box sx={{ display: 'flex', flex: 1 }}>
                            <AutoCompleteCom
                                listName={'frequency'}
                                data={frequency}
                                inputref={freqRef}
                                placeholder={'Frequency'}
                                showKey={['title']}
                            />
                        </Box>

                        <Box sx={{ display: 'flex', flex: 1 }}>
                            <AutoCompleteCom
                                listName={'direction'}
                                data={direction}
                                inputref={directionRef}
                                placeholder={'Direction'}
                                showKey={['title']}
                            />
                        </Box>
                    </Box>

                    <Box sx={{ display: 'flex', flex: 1, gap: 1 }}>
                        <Box sx={{ display: 'flex', flex: 1 }}>
                            <AutoCompleteCom
                                listName={'duration'}
                                data={duration}
                                inputref={durationRef}
                                placeholder={'Duration'}
                                showKey={['title']}
                            />
                        </Box>

                        <Box sx={{ display: 'flex', flex: 1, gap: 1 }}>
                            <input
                                type="number"
                                placeholder={'Quantity'}
                                ref={doseRef}
                                defaultValue={1}
                                style={styles.inputField}
                                onFocus={(e) => {
                                    e.target.style.border = '2px solid #2196f3'; // change border color on focus
                                }}
                                onBlur={(e) => {
                                    e.target.style.border = '1px solid #bfc0c2'; // revert border color on blur
                                }}
                            />
                            <AutoCompleteCom
                                listName={'doseUnit'}
                                data={doseUnit}
                                inputref={doseUnitRef}
                                placeholder={'Dose Unit'}
                                showKey={['title']}
                            />
                        </Box>
                    </Box>

                    <Box sx={{ display: 'flex', flex: 1, gap: 1 }}>
                        <input
                            type="text"
                            placeholder={'Instruction'}
                            ref={instructionRef}
                            style={styles.inputField}
                            onFocus={(e) => {
                                e.target.style.border = '2px solid #2196f3'; // change border color on focus
                            }}
                            onBlur={(e) => {
                                e.target.style.border = '1px solid #bfc0c2'; // revert border color on blur
                            }}
                        />

                        {/* -------------------------------  Add Button */}
                        <Button variant="outlined" onClick={handleAdd}>
                            Add
                        </Button>
                    </Box>
                </Box>
            )}

            {prescriptionList?.map((item, index) => (
                <Box key={index} sx={styles.prescriptionCard}>
                    <Box>
                        <Typography sx={{ fontSize: 16, fontWeight: 600 }}>
                            {`${item?.medicineName} ${
                                item?.medicineGenericName?.toLowerCase() !== 'null' ? `(${item?.medicineGenericName})` : ''
                            }`}
                        </Typography>
                        <Typography>{`( ${item?.frequency} ) ${item?.dose} ${item?.doseUnit}${item?.doseUnit ? ', ' : ''} ${
                            item?.route
                        } - ${item?.direction} ${item?.duration}`}</Typography>
                        {item?.dosageInstruction && <Typography>{item?.dosageInstruction}</Typography>}
                    </Box>

                    <Box>
                        <Button onClick={() => handleDelete(item)} size="small" variant="contained" sx={{ float: 'right' }}>
                            Delete
                        </Button>
                    </Box>
                </Box>
            ))}

            {prescriptionList?.length > 0 && (
                <LoadingButton loading={loadSubmit} onClick={() => handleSubmitAll()} variant="contained" sx={{ float: 'right', mt: 1 }}>
                    Submit All
                </LoadingButton>
            )}
        </>
    );
};

const AutoCompleteCom = React.memo(
    ({ data, inputref, placeholder, showKey, listName }) => {
        return (
            <div style={{ width: '100%' }}>
                <input
                    list={listName}
                    placeholder={placeholder}
                    style={styles.inputField}
                    type="text"
                    ref={inputref}
                    onFocus={(e) => {
                        e.target.style.border = '2px solid #2196f3'; // change border color on focus
                    }}
                    onBlur={(e) => {
                        e.target.style.border = '1px solid #bfc0c2'; // revert border color on blur
                    }}
                />
                <datalist id={listName}>
                    {data &&
                        data.map((item, index) => (
                            <option
                                key={index}
                                value={`${item[showKey[0]]} ${listName == 'medicine' ? `- ${item[showKey[1]]} - ${item[showKey[2]]}` : ''}`}
                            />
                        ))}
                </datalist>
            </div>
        );
    },
    (prevProps, nextProps) => {
        // Compare relevant props, excluding data
        return prevProps.listName === nextProps.listName;
    }
);

export default AddPrescription;
