import { Box, Typography } from '@mui/material';
import React, { memo } from 'react';
const FilteredList = ({ filteredTests, selectedTest }) => {
    return (
        <Box sx={{ backgroundColor: '#f5f5f5', height: 465, borderRadius: 2, p: 1.5, overflow: 'auto' }}>
            <Typography variant="subtitle1">Filtered List</Typography>

            {filteredTests?.slice(0, 15)?.map?.((i, index) => (
                <Typography
                    key={i?.name}
                    sx={{
                        backgroundColor: i?.code == selectedTest?.code ? '#d5d5d5' : '#e5e5e5',
                        padding: 1,
                        borderRadius: 1,
                        my: 0.8
                    }}
                >
                    {index + 1}: {i?.code} - {i?.test || i?.name}
                </Typography>
            ))}
        </Box>
    );
};

export default memo(FilteredList);
