import React from 'react';

import { Button, CircularProgress, Grid, TextField } from '@mui/material';

import * as Yup from 'yup';
import { Formik, Form } from 'formik';

// ------- Importing Components
import Textfield from 'ui-component/FormUI/Textfield';
import SelectCustom from 'ui-component/FormUI/Select';
import MuiPhoneNumber from 'material-ui-phone-number';

// ------- Importing Constants
import { COLORS } from 'constants/colors';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL } from 'constants/baseUrl';
import { getProfiles } from 'services/loginUser';
import { saveProfileData } from 'store/slices/profileSlice';

export default function CreateProfile({ closeModal }) {
    const { user } = useSelector((state) => state.user);
    const dispatch = useDispatch();

    const [loadingRequest, setLoadingRequest] = useState(false);
    const [dateOfBirth, setDateOfBirth] = useState(null);

    const INITIAL_FORM_STATE = {
        firstName: '',
        lastName: '',
        identificationNo: '',
        gender: '',
        relation: ''
    };

    const FORM_VALIDATION = Yup.object().shape({
        firstName: Yup.string().trim().required('Required'),
        lastName: Yup.string().required('Required'),
        identificationNo: Yup.number()
            .typeError('National ID must be a number')
            .required('Required')
            .test('length', 'Input must be exactly 13 characters', (value) => value && value.toString().length === 13),
        gender: Yup.string().required('Required'),
        relation: Yup.string().required('Required')
    });

    const createProfile = async (val, resetForm) => {
        setLoadingRequest(true);
        try {
            const data = {
                identificationNo: val?.identificationNo?.trim(),
                name: val?.firstName?.trim(),
                dob: dateOfBirth,
                address: user?.address,
                phone: user?.phoneNumber,
                gender: val?.gender,
                relation: val?.relation?.toLowerCase(),
                guardianName: user?.fullName,
                guardianPhone: user?.phoneNumber,
                email: user?.email,
                userID: user?.userId,
                titles: val?.gender == 'Male' ? 'Mr' : 'Ms',
                lastName: val?.lastName?.trim(),
                inHospital: false,
                mrNumber: null,
                role: 'Patient',
                createdBy: user?.userId,
                createdOn: new Date(),
                isActive: true,
                profileImage: 'cloudclinic_dummy.jpg',
                wardID: null
            };

            const responseCreatePatient = await axios({
                method: 'post',
                url: `${BASE_URL}api/accounts/CreatePatientProfile`,
                data,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user?.token}`
                }
            });

            if (responseCreatePatient?.data) {
                const profiles = await getProfiles(user?.token, user?.userId);
                dispatch(saveProfileData(profiles?.data));
                resetForm();
                closeModal();
            }
        } catch (error) {
            return toast.error(error?.response?.data?.message ?? error?.response?.data ?? error?.message);
        } finally {
            setLoadingRequest(false);
        }
    };

    return (
        <Formik
            initialValues={{
                ...INITIAL_FORM_STATE
            }}
            validationSchema={FORM_VALIDATION}
            onSubmit={(values, { resetForm }) => createProfile(values, resetForm)}
        >
            <Form>
                <Grid container spacing={2}>
                    <Grid item lg={4}>
                        <Textfield name="firstName" label="First Name" />
                    </Grid>
                    <Grid item lg={4}>
                        <Textfield name="lastName" label="Last Name" />
                    </Grid>
                    <Grid item lg={4}>
                        <Textfield name="identificationNo" label="National ID" />
                    </Grid>
                    <Grid item lg={4}>
                        <SelectCustom defaultValue="" name="gender" label="Gender" options={['Male', 'Female', 'Other']} />
                    </Grid>
                    <Grid item lg={4}>
                        <SelectCustom defaultValue="" name="relation" label="Relation" options={['Family', 'Friend', 'Other']} />
                    </Grid>
                    <Grid item lg={4}>
                        <TextField
                            type="date"
                            variant="standard"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            label="Date of Birth"
                            value={dateOfBirth}
                            InputProps={{
                                inputProps: {
                                    max: new Date().toLocaleDateString('en-CA')
                                }
                            }}
                            onChange={(e) => {
                                let x = e.target.value;
                                let y = x.split('-')[0];
                                let a = new Date().getFullYear();

                                let b = a - y;
                                setDateOfBirth(x);
                                setAge(b);
                            }}
                        />
                    </Grid>

                    <div style={{ marginTop: 16, display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
                        <Button
                            disabled={loadingRequest}
                            type="submit"
                            variant="contained"
                            sx={{ width: 200, backgroundColor: COLORS.secondory, '&: hover': { background: COLORS.primary } }}
                        >
                            {loadingRequest ? <CircularProgress size={25} color="inherit" /> : 'Save'}
                        </Button>
                    </div>
                </Grid>
            </Form>
        </Formik>
    );
}
