import { Box, Button, CircularProgress, IconButton, Typography } from '@mui/material';
import axios from 'axios';
import { BASE_URL } from 'constants/baseUrl';
import { COLORS } from 'constants/colors';
import { Form, Formik, useField, useFormikContext } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Textfield from 'ui-component/FormUI/Textfield';
import AnimateButton from 'ui-component/extended/AnimateButton';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState } from 'react';
import SelectCustom from 'ui-component/FormUI/Select';
import DateTimePicker from 'ui-component/FormUI/DateTimePicker';
import MuiPhoneNumber from 'material-ui-phone-number';
import { useEffect } from 'react';
import { saveUserData } from 'store/slices/userSlice';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { getProfiles } from 'services/loginUser';
import { saveProfileData } from 'store/slices/profileSlice';

export const EditProfile = ({ onClose }) => {
    const { user } = useSelector((state) => state?.user);
    const [loading, setLoading] = useState(false);
    const [phone, setPhone] = useState(user?.phoneNumber);

    const [userData, setUserData] = useState(null);
    const [loadingData, setLoadingData] = useState(false);
    const [error, setError] = useState(null);

    const [profileImage, setProfileImage] = useState(null);
    const [imageLoading, setImageLoading] = useState(false);

    const dispatch = useDispatch();

    const getUserData = async (isLoad) => {
        setLoadingData(isLoad);
        try {
            let endpoint =
                user?.roles == 'Physician' || user?.roles == 'Nurse'
                    ? 'api/physician/getPhysician/'
                    : user?.roles == 'Patient'
                    ? 'api/patient/getPatient/'
                    : 'api/accounts/user/';

            const res = await axios({
                method: 'get',
                url: `${BASE_URL}${endpoint}${user?.userId}`,
                headers: {
                    Authorization: `Bearer ${user?.token}`
                }
            });

            let data = user?.roles == 'Physician' || user?.roles == 'Patient' || user?.roles == 'Nurse' ? res?.data[0] : res?.data;
            setUserData(data);
        } catch (error) {
            setError(error);
        } finally {
            setLoadingData(false);
        }
    };

    useEffect(() => {
        getUserData(true);
    }, []);

    //------------------------------  Initial Values
    const INITIAL_VALUES = {
        name: user?.firstName,
        dob: new Date(user?.roles == 'Patient' ? user?.dob : userData?.dob).toLocaleDateString('en-CA'),
        address: user?.address,
        titles: user?.titles,
        lastName: user?.lastName
    };

    //------------------------------  Validation Schema
    const FORM_VALIDATION = Yup.object().shape({
        name: Yup.string().required('Required'),
        lastName: Yup.string().required('Required'),
        titles: Yup.string().required('Required'),
        address: Yup.string().required('Required'),
        ...(user.roles !== 'Receptionist' && { dob: Yup.date().required('Required') })
    });

    const handleRequest = async (values, resetForm) => {
        setLoading(true);
        try {
            if (phone?.trim().replace(/\D/g, '').length == 0) {
                return toast.error('Please enter phone number');
            }
            if (phone?.trim().replace(/\D/g, '').length > 0 && phone?.trim().replace(/\D/g, '').length < 12) {
                return toast.error('Please enter valid phone number');
            }

            let body =
                user?.roles == 'Receptionist'
                    ? {
                          FirstName: values?.name?.trim(),
                          LastName: values?.lastName?.trim(),
                          PhoneNumber: phone?.trim().replace(/\D/g, ''),
                          Titles: values?.titles?.trim(),
                          Email: user?.email,
                          UserName: userData?.userName,
                          EmployeeCode: user?.employeeCode,
                          address: values?.address?.trim(),
                          LocationID: user?.locationID,
                          lastModifiedOn: new Date(),
                          lastModifiedBy: user?.userId
                      }
                    : {
                          name: values?.name?.trim(),
                          dob: values?.dob,
                          address: values?.address?.trim(),
                          phone: phone?.trim().replace(/\D/g, ''),
                          titles: values?.titles?.trim(),
                          lastName: values?.lastName.trim(),
                          lastModifiedOn: new Date(),
                          lastModifiedBy: user?.userId
                      };

            let endpoint =
                user?.roles == 'Physician' || user?.roles == 'Nurse'
                    ? `api/physician/UpdatePhysicianProfile/${userData?.nationalID}`
                    : user?.roles == 'Patient'
                    ? `api/patient/UpdatePatientProfile/${user?.nationalID}`
                    : `api/accounts/user/${user?.userId}`;

            const resUpdate = await axios({
                method: 'put',
                url: `${BASE_URL}${endpoint}`,
                data: body,
                headers: {
                    Authorization: `Bearer ${user?.token}`
                }
            });

            if (resUpdate?.data) {
                const userLocal = JSON.parse(localStorage.getItem('user'));

                const user = {
                    ...userLocal,
                    firstName: values?.name?.trim(),
                    lastName: values?.lastName?.trim(),
                    address: values?.address?.trim(),
                    phoneNumber: phone?.trim().replace(/\D/g, ''),
                    fullName: `${values?.name?.trim()} ${values?.lastName?.trim()}`
                };

                // --- if any patient will update his/her profile, the profiles list will also fetch and update
                if (user?.roles == 'Patient') {
                    const resProfile = await getProfiles(user?.token, user?.userId);
                    dispatch(saveProfileData(resProfile?.data));
                }
                localStorage.setItem('user', JSON.stringify(user));
                dispatch(saveUserData(user));
                onClose();
                return toast.success('Your profile updated successfully');
            }
        } catch (error) {
            return toast.error(error?.response?.data?.message ?? error?.response?.data ?? error?.message);
        } finally {
            setLoading(false);
        }
    };

    let options = user?.roles == 'Physician' ? ['Dr', 'Mr', 'Ms'] : ['Mr', 'Ms'];

    const handleImageUpload = async (e) => {
        try {
            const file = e.target.files[0];
            if (file) {
                let imageName = `${user?.userId}_` + Date.now() + `_${user?.domain}.` + file?.name?.split('.').pop();

                let image = {
                    name: imageName,
                    image: file,
                    url: URL.createObjectURL(file)
                };
                setProfileImage(image);
                setImageLoading(true);

                let endpoint =
                    user?.roles == 'Physician' || user?.roles == 'Nurse'
                        ? `api/physician/profileImage/${userData?.nationalID}`
                        : user?.roles == 'Patient'
                        ? `api/patient/profileImage/${user?.nationalID}`
                        : `api/accounts/profileImage/${user?.userId}`;

                let formdata = new FormData();
                formdata.append('body', file, imageName);

                const res = await axios({
                    method: 'put',
                    url: `${BASE_URL}${endpoint}`,
                    data: formdata,
                    headers: {
                        Authorization: `Bearer ${user?.token}`,
                        'Content-Type': 'multipart/form-data;',
                        Accept: 'application/json'
                    }
                });

                if (res?.data) {
                    const updatedUser = {
                        ...user,
                        profileImage: imageName
                    };

                    // --- if any patient will update his/her profile, the profiles list will also fetch and update
                    if (user?.roles == 'Patient') {
                        const resProfile = await getProfiles(user?.token, user?.userId);
                        dispatch(saveProfileData(resProfile?.data));
                    }
                    localStorage.setItem('user', JSON.stringify(updatedUser));
                    dispatch(saveUserData(updatedUser));

                    return toast.success('Profile Picture Updated');
                }
            }
        } catch (error) {
            console.error('Error uploading image:', error);
        } finally {
            setImageLoading(false);
        }
    };

    return (
        <Box sx={{ maxWidth: 400 }}>
            {loadingData && (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress size={35} />
                </Box>
            )}

            {error && (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography>{error?.response?.data?.message ?? error?.response?.data ?? error?.message}</Typography>
                </Box>
            )}

            {userData && (
                <Formik
                    initialValues={INITIAL_VALUES}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, { resetForm }) => handleRequest(values, resetForm)}
                >
                    <Form>
                        <div style={styles.imgCont}>
                            <img
                                alt={'profile pic'}
                                src={profileImage?.url || `${BASE_URL}media/${user.domain}/Profile/${user?.profileImage}`}
                                style={styles.profileImage}
                            />

                            {imageLoading ? (
                                <Box sx={{ ...styles.btnWrapper, ...styles.iconButton }}>
                                    <CircularProgress size={20} sx={{ color: '#fff' }} />
                                </Box>
                            ) : (
                                <>
                                    <input
                                        accept="image/*"
                                        style={{ display: 'none' }}
                                        id="image-upload"
                                        type="file"
                                        onChange={handleImageUpload}
                                    />
                                    <label htmlFor="image-upload" style={styles.btnWrapper}>
                                        <Box title="Upload Profile Image" sx={styles.iconButton}>
                                            <CameraAltIcon style={{ color: '#fff', fontSize: 17 }} />
                                        </Box>
                                    </label>
                                </>
                            )}
                        </div>

                        <SelectCustom defaultValue="" name="titles" label="Title" options={options} variant={'outlined'} sx={{ mt: 2 }} />
                        <Textfield name="name" label="First Name" variant={'outlined'} sx={{ mt: 2 }} />
                        <Textfield name="lastName" label="Last Name" variant={'outlined'} sx={{ mt: 2 }} />

                        {(user?.roles == 'Physician' || user?.roles == 'Patient' || user?.roles == 'Nurse') && (
                            <DateTimePicker
                                variant={'outlined'}
                                sx={{ mt: 2 }}
                                name="dob"
                                label="Date of Birth"
                                InputProps={{
                                    inputProps: {
                                        max: new Date().toLocaleDateString('en-CA')
                                    }
                                }}
                            />
                        )}

                        <MuiPhoneNumber
                            fullWidth
                            onlyCountries={['us', 'pk']}
                            defaultCountry={'pk'}
                            autoFormat={true}
                            disableAreaCodes={true}
                            label="Phone Number"
                            variant={'outlined'}
                            sx={{ mt: 2 }}
                            onChange={(val) => setPhone(val)}
                            value={phone}
                        />

                        <Textfield name="address" label="Address" variant={'outlined'} sx={{ mt: 2 }} />

                        {user?.roles !== 'Admin' && (
                            <Box sx={{ mt: 2 }}>
                                <AnimateButton>
                                    <Button
                                        disableElevation
                                        disabled={loading}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        sx={{
                                            backgroundColor: COLORS.primary
                                        }}
                                    >
                                        {loading ? <CircularProgress size={25} color="inherit" /> : 'Update'}
                                    </Button>
                                </AnimateButton>
                            </Box>
                        )}
                    </Form>
                </Formik>
            )}
        </Box>
    );
};

const styles = {
    imgCont: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    profileImage: {
        backgroundColor: '#d5d5d5',
        borderRadius: 100 / 2,
        width: 100,
        height: 100,
        marginLeft: 20,
        objectFit: 'cover'
    },
    btnWrapper: {
        position: 'relative',
        left: -30,
        bottom: -30
    },
    iconButton: {
        height: 30,
        width: 30,
        borderRadius: 30 / 2,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: COLORS.primary,
        '&:hover': {
            cursor: 'pointer'
        }
    }
};
