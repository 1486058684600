import React from 'react';
import { useContext } from 'react';
import { MeetingContext } from './MeetingContext';

import './printSummary.css';
import { BASE_URL } from 'constants/baseUrl';
import { useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';
import moment from 'moment';
import { Typography } from '@mui/material';

const PrintSummary = React.forwardRef((props, ref) => {
    const { user } = useSelector((state) => state.user);
    let { config } = useSelector((state) => state?.config);
    let prescriptionPrint = config?.find((i) => i.title == 'PrescriptionPrint')?.value || null;
    const { visit, printByVisit } = props;
    var decoded = jwt_decode(user?.token);

    const { prescriptionList: prescList, labTestList, diagnosisList: diagList, vitalSigns, singleVisit } = useContext(MeetingContext);

    const diagnosisList = diagList?.filter((i) => i.visitID == visit?.id);
    const prescriptionList = printByVisit == 'true' ? prescList?.filter((i) => i.visitID == visit?.id) : prescList;

    let docDeg = singleVisit?.consultantDegree ?? visit?.doctorDegree;

    let printConfig = null;

    if (prescriptionPrint == null) {
        printConfig = {
            reverseHeader: false,
            showHospitalName: false,
            showClinicName: false,
            showClinicNameTo: null,
            showVitalSign: true,
            showPrescription: true,
            showDiagnosis: true,
            showLabTest: true,
            showNotes: true,
            showInitialComplain: true
        };
    } else {
        printConfig = JSON.parse(prescriptionPrint);
    }

    return (
        <div ref={ref} style={styles.container}>
            {printConfig?.showClinicName &&
                printConfig?.showClinicNameTo?.toLowerCase()?.includes(singleVisit?.location?.toLowerCase()?.split(',')[0]) && (
                    <Typography
                        sx={{
                            fontSize: 25,
                            fontWeight: '600',
                            fontFamily: 'Cambria',
                            marginBottom: 2,
                            textAlign: 'center',
                            textTransform: 'uppercase'
                        }}
                    >
                        {singleVisit?.location?.split(',')[0]}
                    </Typography>
                )}

            <div style={{ ...styles.header, flexDirection: printConfig?.reverseHeader ? 'row-reverse' : 'row' }}>
                {/* -------------------------------  Main Header  ------------------------------- */}
                <img src={`${BASE_URL}images/${user?.domainLogo}`} alt="car" style={styles.logo} />

                {printConfig?.showHospitalName && (
                    <Typography sx={{ fontSize: 25, fontWeight: '600', fontFamily: 'Cambria' }}>{decoded?.Name}</Typography>
                )}

                <div style={styles.docInfo}>
                    <p style={styles.docName}>
                        {visit?.consultantName?.split('/')[0] ?? visit?.physicianName?.split('/')[0] ?? visit?.doctorName?.split('/')[0]}
                    </p>
                    {docDeg?.split(',')?.map((item, index) => (
                        <p key={`${item}-${index}`} style={styles.docEdu}>
                            {item}
                        </p>
                    ))}
                    {singleVisit?.healthCareLicenseID && <p style={styles.docEdu}>{singleVisit?.healthCareLicenseID}</p>}
                    <p style={{ ...styles.docEdu, textTransform: 'capitalize', fontWeight: 'bold' }}>
                        {singleVisit?.doctorSpeciality?.toLowerCase()}
                    </p>
                </div>
            </div>

            {/* ------------------------------------  RX  ------------------------------------ */}
            <div style={{ marginTop: 15 }}>
                <div style={styles.line}></div>
                <p style={styles.rx}>Rx</p>
            </div>

            {/* ------------------------------------  Patient Info  ----------------------------- */}
            <div style={styles.patientInfoCont}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <NameComp title={'Visit ID:'} sub={visit?.id} />
                    <NameComp title={'Visit Date Time:'} sub={moment(visit?.startDateTime).format('MMM DD, YYYY - hh:mm A')} />
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: 13
                    }}
                >
                    <NameComp title={'P. Name:'} sub={`${singleVisit?.patientFirstName} ${singleVisit?.patientLastName}`} />
                    <NameComp title={'Age:'} sub={singleVisit?.patientAge} />
                    <NameComp title={'Gender:'} sub={singleVisit?.patientGender} />
                </div>
            </div>

            {/* ----------------------------------  Main Body  ---------------------------------- */}
            <div style={styles.body}>
                <div style={styles.bodyLeft}>
                    {/* ----------------------------  Follow-up Date */}
                    {(visit?.followupdatetime || singleVisit?.followupdatetime) && (
                        <div>
                            <p style={styles.heading}>Follow Up</p>
                            <p style={{ ...styles.sub, marginBottom: 20, lineHeight: 1.2 }}>
                                {moment(visit?.followupdatetime || singleVisit?.followupdatetime)?.format('MMM DD, YYYY')}
                            </p>
                        </div>
                    )}

                    {/* ----------------------------  Vital Signs */}
                    {printConfig?.showVitalSign && (
                        <div>
                            <p style={styles.heading}>Patient Vitals</p>
                            <VitalSignTextComp title={'Weight'} sub={`${vitalSigns?.weight || ''} kg`} />
                            <VitalSignTextComp title={'Height'} sub={vitalSigns?.height || ''} />
                            <VitalSignTextComp title={'Temp'} sub={`${vitalSigns?.temp || ''} °F`} />
                            <VitalSignTextComp title={'SPO2'} sub={`${vitalSigns?.spO2 || ''} %`} />
                            <VitalSignTextComp title={'Systolic'} sub={vitalSigns?.sys || ''} />
                            <VitalSignTextComp title={'Diastolic '} sub={vitalSigns?.dia || ''} />
                            <VitalSignTextComp title={'Perfusion Index '} sub={vitalSigns?.pi || ''} />
                            <VitalSignTextComp title={'Pulse Rate'} sub={`${vitalSigns?.pr || ''} /min`} />
                            <VitalSignTextComp title={'BSR'} sub={`${vitalSigns?.bsr || ''}`} />
                        </div>
                    )}

                    {/* ----------------------------  Diagnosis */}
                    {printConfig?.showDiagnosis && (
                        <>
                            {diagnosisList && diagnosisList?.length > 0 && (
                                <div style={{ marginTop: 30, paddingRight: 10 }}>
                                    <p style={styles.heading}>Diagnosis</p>
                                    {diagnosisList?.map((item, index) => {
                                        if (item?.isDeleted == false) {
                                            return (
                                                <p key={index} style={{ ...styles.sub, marginBottom: 7, lineHeight: 1.2 }}>
                                                    • {item?.code}: {item?.name}
                                                </p>
                                            );
                                        }
                                    })}
                                </div>
                            )}
                        </>
                    )}

                    {/* ----------------------------  Lab Test */}
                    {printConfig?.showLabTest && (
                        <>
                            {labTestList && labTestList?.length > 0 && (
                                <div style={{ marginTop: 30, paddingRight: 10 }}>
                                    <p style={styles.heading}>Lab Test</p>

                                    {labTestList?.map((item, index) => (
                                        <div key={index} style={{ marginBottom: 7 }}>
                                            <p style={{ ...styles.sub, marginBottom: 8, lineHeight: 1.2 }}>
                                                • {item?.code}: {item?.test}
                                            </p>
                                            {item?.notes && <InsComp title={'Instruction: '} sub={item?.notes} />}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </>
                    )}
                </div>

                <div style={styles.bodyRight}>
                    <div style={{ paddingLeft: 20 }}>
                        {/* ---------------------------- Prescription */}
                        <p style={styles.heading}>Prescription</p>

                        {printConfig?.showPrescription && (
                            <>
                                {prescriptionList &&
                                    prescriptionList?.map((item, index) => {
                                        if (item?.isDeleted == false) {
                                            return (
                                                <div key={index} style={{ marginBottom: 7 }}>
                                                    <p style={{ ...styles.sub, marginBottom: 8, lineHeight: 1.2 }}>{`• ${
                                                        item?.medicineName
                                                    } ${item?.frequency ? `(${item?.frequency})` : ' '}  ${
                                                        item?.route ? `${item?.route} - ` : ' '
                                                    } ${item?.direction ? `${item?.direction} - ` : ' '}  ${
                                                        item?.duration ? item?.duration : ''
                                                    }`}</p>

                                                    {item?.dosageInstruction && (
                                                        <InsComp title={'Instruction: '} sub={item?.dosageInstruction} />
                                                    )}
                                                </div>
                                            );
                                        }
                                    })}
                            </>
                        )}

                        {printConfig?.showInitialComplain && (
                            <>
                                {singleVisit?.initialComplain && (
                                    <>
                                        <p style={{ ...styles.heading, marginTop: 20 }}>Diagnosis and Plan</p>
                                        <p style={styles?.sub}>{singleVisit?.initialComplain}</p>
                                    </>
                                )}
                            </>
                        )}

                        {printConfig?.showNotes && (
                            <>
                                {singleVisit?.notes && (
                                    <>
                                        <p style={{ ...styles.heading, marginTop: 20 }}>Notes</p>
                                        <p style={styles?.sub}>{singleVisit?.notes}</p>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
            <p style={{ ...styles.sub, marginTop: 10 }}>
                Address: {decoded?.Name}, {singleVisit?.location}
            </p>
        </div>
    );
});

export default PrintSummary;

const NameComp = ({ title, sub }) => {
    return (
        <div style={{ display: 'flex' }}>
            <p style={styles.titleHead}>{title}</p>
            <p style={styles.sub}>{sub}</p>
        </div>
    );
};

const InsComp = ({ title, sub }) => {
    return (
        <div style={{ display: 'flex' }}>
            <p style={styles.titleHead}>{title}</p>
            <p style={{ ...styles.sub, marginLeft: 5 }}>{sub}</p>
        </div>
    );
};

const VitalSignTextComp = ({ title, sub }) => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                paddingRight: 15,
                marginTop: 7
            }}
        >
            <p style={styles.sub}>{title}</p>
            <p style={styles.sub}>{sub}</p>
        </div>
    );
};

const styles = {
    container: {
        margin: 50,
        display: 'flex',
        flexDirection: 'column',
        height: 1030
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 7
    },
    logo: {
        height: 70,
        objectFit: 'contain'
    },
    docInfo: {
        width: 250,
        marginBottom: -15
    },
    docName: {
        fontFamily: 'Cambria',
        fontWeight: '600',
        fontSize: 23,
        marginBottom: 7,
        color: '#1d97d4'
    },
    docEdu: {
        fontFamily: 'Cambria',
        fontSize: 15,
        color: '#1f1f1f'
    },
    line: {
        height: 8,
        backgroundColor: '#1d97d4',
        width: '100%',
        position: 'relative',
        bottom: -20
    },
    rx: {
        fontSize: 32,
        color: '#000',
        fontFamily: 'Cambria',
        fontWeight: 600,
        backgroundColor: '#fff',
        width: 70,
        height: 20,
        textAlign: 'center',
        marginLeft: 15,
        position: 'relative'
    },
    heading: {
        fontFamily: 'Cambria',
        marginBottom: 15,
        fontSize: 18,
        fontWeight: '600',
        color: '#000'
    },
    titleHead: {
        fontFamily: 'Cambria',
        fontSize: 15,
        fontWeight: 600,
        marginRight: 10,
        // width: 120,
        color: '#1f1f1f'
    },
    patientInfoCont: {
        border: '3px solid #000',
        padding: 10,
        marginTop: 15
    },
    sub: {
        fontFamily: 'Cambria',
        fontSize: 15,
        color: '#1f1f1f'
    },
    body: {
        display: 'flex',
        flex: 1,
        marginTop: 15
    },
    bodyLeft: {
        borderRight: '2px solid #000',
        // height: "95vh",
        width: '30%'
    },
    bodyRight: { width: '70%' },
    prescriptionCardCont: { display: 'flex', flexWrap: 'wrap', marginBottom: 20 }
};
