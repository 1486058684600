import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    Grid,
    IconButton,
    Tab,
    TablePagination,
    Tabs,
    Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import LabTest from 'views/components/labtestPrevious';
import Diagnosis from 'views/components/diagnosisPrevious';
import Prescription from 'views/components/prescriptionPrevious';
import VitalSign from 'views/components/vitalSignPrevious';
import { BASE_URL } from 'constants/baseUrl';
import ModalCustom from 'ui-component/modals/ModalCustom';
import PrevIntakeHistoryComp from 'components/PrevIntakeHistoryComp';
import PreviousVisitCard from 'components/PreviousVisitCard';
import SearchField from 'ui-component/FormUI/SearchField.js';
import { COLORS } from 'constants/colors';
import ViewReportsComp from 'components/ViewReportsComp';
import useFetch from 'hooks/useFetch';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// ==============================|| PreviousVisits ||============================== //

const PreviousVisits = (props) => {
    const { user } = useSelector((state) => state?.user);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [count, setCount] = useState(0);

    const [apiEndPoint, setApiEndPoint] = useState('patientsPreviousVisits');
    const [selectedTabValue, setSelectedTabValue] = useState(0);

    const [previousVisitsList, setPreviousVisitsList] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');

    const handleChangePage = (event, newPage) => {
        getPreviousVisitsList(newPage, rowsPerPage, searchQuery);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        getPreviousVisitsList(page, event.target.value, searchQuery);
        setRowsPerPage(+event.target.value);
    };

    const [open, setOpen] = useState({ title: null, visit: null });

    const getPreviousVisitsList = async (pageNumber, pageSize, searchValue) => {
        try {
            setLoading(true);
            setError(null);
            setPreviousVisitsList(null);

            const res = await axios({
                method: 'get',
                url: `${BASE_URL}api/patient/${apiEndPoint}/${user?.nationalID}?pageNumber=${
                    pageNumber + 1
                }&pageSize=${pageSize}&QuerySearch=${searchValue ?? ''}`,
                headers: {
                    Authorization: `Bearer ${user?.token}`
                }
            });

            setPreviousVisitsList(res?.data);
            setCount(res?.data?.paginationMetadata?.totalCount);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getPreviousVisitsList(page, rowsPerPage, searchQuery);

        return () => {
            setLoading(true);
            setError(null);
            setPreviousVisitsList(null);
            setSearchQuery('');
        };
    }, [selectedTabValue]);

    const {
        data: domainsList,
        loading: loadingDomainsList,
        error: errorDomainsList,
        refetch: refetchDomainsList
    } = useFetch(`${BASE_URL}api/tenant/UserTenants?id=${user?.userId}&email=${user?.email}`);

    const [loadingShared, setLoadingShared] = useState(false);

    // State to keep track of selected values
    const [selectedValues, setSelectedValues] = useState([]);

    // Function to handle checkbox change
    const handleCheckboxChange = (domain) => {
        // Check if the domain is already selected, then remove it; otherwise, add it
        const updatedValues = selectedValues.includes(domain)
            ? selectedValues.filter((item) => item !== domain)
            : [...selectedValues, domain];

        // Update the state
        setSelectedValues(updatedValues);
    };

    const handleSave = async (unshare) => {
        try {
            setLoadingShared(true);
            if (selectedValues?.length == 0) {
                return toast.error('Please select any healthcare provider');
            }

            const res = await axios({
                url: `${BASE_URL}api/visit/updateVisitMeetingStatus/${open?.visit?.id}`,
                method: 'put',
                data: {
                    isShared: unshare ? false : true,
                    sharedWith: unshare ? '' : selectedValues.join(', ')
                },
                headers: {
                    Authorization: `Bearer ${user?.token}`
                }
            });

            if (res?.data) {
                getPreviousVisitsList(page, rowsPerPage, searchQuery);
                setOpen({ title: null, visit: null });
                setSelectedValues([]);
                return toast.success(`Visit Shared Successfully`);
            }
        } catch (error) {
            return toast.error(error?.response?.data?.message ?? error?.response?.data ?? error?.message);
        } finally {
            setLoadingShared(false);
        }
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`
        };
    }

    const handleChange = (event, newValue) => {
        setApiEndPoint(newValue == 0 ? 'patientsPreviousVisits' : 'patientsPreviousVisitsShared');
        setSelectedTabValue(newValue);
        console.log('val  -- ', newValue);
    };

    return (
        <>
            {/* -----------------------  History Modal  -------------------------------------- */}
            {open.title == 'History' && (
                <PrevIntakeHistoryComp
                    open={open.title == 'History'}
                    onClose={() => {
                        setOpen({ title: null, visit: null });
                    }}
                    visit={open?.visit}
                    domain={open?.visit?.domain || ''}
                />
            )}
            {/* -----------------------  Vital Modal  -------------------------------------- */}
            <ModalCustom open={open.title == 'Vital'} title={'Vital Signs'}>
                <Box sx={{ width: '40vw' }}>
                    <IconButton
                        color="inherit"
                        onClick={() => setOpen({ title: null, visit: null })}
                        sx={{ position: 'absolute', top: 10, right: 10 }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <VitalSign visitID={open?.visit?.id} domain={open?.visit?.domain || ''} />
                </Box>
            </ModalCustom>

            {/* -----------------------  Lab Report Modal  -------------------------------------- */}
            {open.title == 'LabReport' && (
                <ViewReportsComp
                    open={open.title == 'LabReport'}
                    onClose={() => {
                        setOpen({ title: null, visit: null });
                    }}
                    visit={open?.visit}
                    domain={open?.visit?.domain || ''}
                />
            )}

            {/* -----------------------  Prescription Modal  -------------------------------------- */}
            <ModalCustom open={open?.title == 'Prescription'} title={'Prescritption'}>
                <Box sx={{ width: '40vw' }}>
                    <IconButton
                        color="inherit"
                        onClick={() => setOpen({ title: null, visit: null })}
                        sx={{ position: 'absolute', top: 10, right: 10 }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Prescription visitID={open?.visit?.id} domain={open?.visit?.domain || ''} />
                </Box>
            </ModalCustom>

            {/* -----------------------  Diagnosis Modal  -------------------------------------- */}
            <ModalCustom open={open?.title == 'Diagnosis'} title={'Diagnosis'}>
                <Box sx={{ width: '40vw' }}>
                    <IconButton
                        color="inherit"
                        onClick={() => setOpen({ title: null, visit: null })}
                        sx={{ position: 'absolute', top: 10, right: 10 }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Diagnosis visitID={open?.visit?.id} domain={open?.visit?.domain || ''} />
                </Box>
            </ModalCustom>

            {/* -----------------------  Lab Test Modal  -------------------------------------- */}
            <ModalCustom open={open?.title == 'LabTest'} title={'Lab Test'}>
                <Box sx={{ width: '40vw' }}>
                    <IconButton
                        color="inherit"
                        onClick={() => setOpen({ title: null, visit: null })}
                        sx={{ position: 'absolute', top: 10, right: 10 }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <LabTest visitID={open?.visit?.id} domain={open?.visit?.domain || ''} />
                </Box>
            </ModalCustom>

            {/* -----------------------  Previous Visits  -------------------------------------- */}

            <Box mb={2} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="h3" color={COLORS.color1} sx={{ py: 1 }}>
                    Previous Visits
                </Typography>

                <Box sx={{ width: '30%' }}>
                    <SearchField
                        label="Search"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        onClickSearch={() => {
                            setPage(0);
                            getPreviousVisitsList(searchQuery);
                        }}
                        onClickClear={() => {
                            setPage(0);
                            setSearchQuery('');
                            getPreviousVisitsList('');
                        }}
                        titleSearchBtn={'Search Physician'}
                        titleClearBtn={'Clear search list'}
                        size={'small'}
                    />
                </Box>
            </Box>

            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={selectedTabValue} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Current" {...a11yProps(0)} />
                    <Tab label="Shared" {...a11yProps(1)} />
                </Tabs>
            </Box>

            <Box sx={{ gap: 10, mt: 2 }}>
                {!!previousVisitsList &&
                    previousVisitsList.items.map((row) => {
                        return (
                            <PreviousVisitCard
                                key={row?.id}
                                physicianName={row?.doctorName}
                                physicianImage={row?.physicianProfileImage}
                                physicianSpeciality={row?.doctorSpeciality}
                                physicianFee={row?.amount}
                                visitId={row?.id}
                                visitDateTime={row?.startDateTime}
                                visitTitle={row?.title}
                                visitSummary={row?.summaryNotes}
                                initialComplain={row?.initialComplain}
                                notes={row?.notes}
                                referredTo={row?.referredTo}
                                showSharedBtn={selectedTabValue == 0 ? true : false}
                                healthCareProvider={selectedTabValue == 0 ? false : row?.providerName}
                                domain={'clinic'}
                                isShared={selectedTabValue == 0 ? row?.isShared : false}
                                onHistoryClick={() => setOpen({ title: 'History', visit: row })}
                                onVitalClick={() => setOpen({ title: 'Vital', visit: row })}
                                onLabReportClick={() => setOpen({ title: 'LabReport', visit: row })}
                                onPrescriptionClick={() => setOpen({ title: 'Prescription', visit: row })}
                                onDiagnosisClick={() => setOpen({ title: 'Diagnosis', visit: row })}
                                onLabTestClick={() => setOpen({ title: 'LabTest', visit: row })}
                                onShareClick={() => {
                                    setSelectedValues(row?.sharedWith ? row?.sharedWith?.toLowerCase()?.split(', ') : []);
                                    setOpen({ title: 'Shared', visit: row });
                                }}
                            />
                        );
                    })}

                {loading && (
                    <Grid sx={styles.loadingContainer}>
                        <CircularProgress size={35} color="inherit" />
                    </Grid>
                )}

                {!!error && (
                    <Grid sx={styles.loadingContainer}>
                        <Typography>{error?.response?.data?.message ?? error?.response?.data ?? error?.message}</Typography>
                    </Grid>
                )}

                {!!error == false && (
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 20]}
                        component="div"
                        count={count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                )}

                <ModalCustom open={open?.title == 'Shared'} title={'Share visit data to'}>
                    {loadingDomainsList ? (
                        <CircularProgress size={35} color="inherit" />
                    ) : (
                        <>
                            {domainsList?.length == 1 ? (
                                <Typography>Please join any healthcare provider to share visit</Typography>
                            ) : (
                                <>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={(e) => {
                                                    e.target.checked
                                                        ? setSelectedValues(domainsList?.map((item) => item.domain?.toLowerCase()))
                                                        : setSelectedValues([]);
                                                }}
                                            />
                                        }
                                        label={'Select All'}
                                    />

                                    {domainsList &&
                                        domainsList.map(({ domain, title }) => {
                                            if (user?.domain !== domain) {
                                                // ------------ Not Show Domain in which user is logged In
                                                return (
                                                    <FormControlLabel
                                                        key={domain}
                                                        control={
                                                            <Checkbox
                                                                checked={selectedValues?.includes(domain?.toLowerCase())}
                                                                onChange={() => handleCheckboxChange(domain)}
                                                            />
                                                        }
                                                        label={title}
                                                    />
                                                );
                                            }
                                        })}

                                    <Box sx={styles.btnContainer}>
                                        {loadingShared ? (
                                            <Box sx={styles.loaderBoxContainer}>
                                                <CircularProgress size={35} color="inherit" />
                                            </Box>
                                        ) : (
                                            <>
                                                <Button
                                                    onClick={() => {
                                                        setOpen({ title: null, visit: null });
                                                        setSelectedValues([]);
                                                    }}
                                                    variant="text"
                                                    sx={{ color: COLORS.secondory }}
                                                >
                                                    Cancel
                                                </Button>
                                                <Button onClick={() => handleSave(true)} variant="text" sx={{ color: COLORS.secondory }}>
                                                    UnShare
                                                </Button>
                                                <Button onClick={() => handleSave(false)} variant="text" sx={{ color: COLORS.secondory }}>
                                                    Save
                                                </Button>
                                            </>
                                        )}
                                    </Box>
                                </>
                            )}
                        </>
                    )}
                </ModalCustom>
            </Box>
        </>
    );
};

export default PreviousVisits;

const styles = {
    loadingContainer: {
        height: 500,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    btnContainer: {
        display: 'flex',
        direction: 'row',
        gap: 1,
        justifyContent: 'flex-end',
        mt: 2
    },
    loaderBoxContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
};
