import { Box, Grid, IconButton, Typography } from '@mui/material';
import React, { memo } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';

const SelectedList = ({ selectedMedList, selectedDiagList, selectedTestList, setSelectedAllList }) => {
    return (
        <Box sx={{ backgroundColor: '#f5f5f5', height: 400, borderRadius: 2, p: 1.5, overflow: 'auto' }}>
            {selectedMedList?.length > 0 && (
                <>
                    <Typography variant="subtitle1">Prescription</Typography>
                    {selectedMedList?.map?.((i, index) => (
                        <Box key={i?.name} sx={styles.boxStyle}>
                            <Typography sx={{ width: '100%', padding: 1 }}>
                                {i?._id}: {i?.name}
                                {i?.instruction && <Typography variant="subtitle2">Instructions: {i?.instruction}</Typography>}
                            </Typography>
                            <IconButton
                                onClick={() => setSelectedAllList((pre) => pre?.filter((p) => p?._id !== i?._id))}
                                size="small"
                                color="inherit"
                                style={{ paddingVertical: -10 }}
                            >
                                <DeleteIcon style={{ color: 'red', fontSize: 20 }} />
                            </IconButton>
                        </Box>
                    ))}
                </>
            )}

            {selectedDiagList?.length > 0 && (
                <>
                    <Typography variant="subtitle1">Diagnosis & Plan</Typography>
                    {selectedDiagList?.map?.((i, index) => (
                        <Box sx={styles.boxStyle}>
                            <Typography sx={{ width: '100%', padding: 1 }}>
                                {i?._id}: {i?.code} - {i?.name}
                            </Typography>
                            <IconButton
                                onClick={() => setSelectedAllList((pre) => pre?.filter((p) => p?._id !== i?._id))}
                                size="small"
                                color="inherit"
                                style={{ paddingVertical: -10 }}
                            >
                                <DeleteIcon style={{ color: 'red', fontSize: 20 }} />
                            </IconButton>
                        </Box>
                    ))}
                </>
            )}

            {selectedTestList?.length > 0 && (
                <>
                    <Typography variant="subtitle1">Lab Test</Typography>
                    {selectedTestList?.map?.((i, index) => (
                        <Box onClick={() => setSelectedAllList((pre) => pre?.filter((p) => p?._id !== i?._id))} sx={styles.boxStyle}>
                            <Typography sx={{ width: '100%', padding: 1 }}>
                                {i?._id}: {i?.code} - {i?.test}
                            </Typography>
                            <IconButton size="small" color="inherit" style={{ paddingVertical: -10 }}>
                                <DeleteIcon style={{ color: 'red', fontSize: 20 }} />
                            </IconButton>
                        </Box>
                    ))}
                </>
            )}
        </Box>
    );
};

export default memo(SelectedList);

const styles = {
    boxStyle: {
        backgroundColor: '#e5e5e5',
        display: 'flex',
        borderRadius: 1,
        alignItems: 'center',
        my: 0.8
    }
};
