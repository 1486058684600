import React, { useState, useEffect, useRef } from 'react';

import { Box, CircularProgress, IconButton } from '@mui/material';

import { BASE_URL } from 'constants/baseUrl';
import { useSelector } from 'react-redux';

import ModalCustom from 'ui-component/modals/ModalCustom';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import PrevHistoryForm from 'ui-component/PrevHistoryForm';

export default function PrevIntakeHistoryComp({ visit, open, onClose, domain = '' }) {
    const { user } = useSelector((state) => state?.user);

    const [loading, setLoading] = useState(true);
    const patientHistory = useRef({});

    // ------------------------  Get Patient Last History  ---------------------------
    const getPatientHistory = async () => {
        try {
            const res = await axios({
                method: 'get',
                url: `${BASE_URL}api/History/getPatientHistorybyVisit/${visit?.id}/${domain}`,
                headers: {
                    Authorization: `Bearer ${user?.token}`
                }
            });

            patientHistory.current = res?.data;
        } catch (error) {
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getPatientHistory();

        return () => {
            setLoading(true);
        };
    }, [open]);

    return (
        <ModalCustom open={open} title={'Intake History'}>
            <Box sx={{ width: '70vw' }}>
                <IconButton color="inherit" onClick={onClose} sx={{ position: 'absolute', top: 10, right: 10 }}>
                    <CloseIcon />
                </IconButton>
                {loading ? (
                    <Box sx={{ ...styles.loadingContainer }}>
                        <CircularProgress size={35} color="inherit" />
                    </Box>
                ) : (
                    <PrevHistoryForm
                        data={patientHistory?.current || {}} //----------- sending last history of patient to initialize value
                        visit={visit} //-------------- send visit detail for checking gender and visit id
                    />
                )}
            </Box>
        </ModalCustom>
    );
}

const styles = {
    loadingContainer: {
        height: 400,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    btnContainer: {
        display: 'flex',
        direction: 'row',
        gap: 1,
        justifyContent: 'flex-end'
    }
};
