import React, { useContext } from 'react';

import { Box, IconButton } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';

import ModalCustom from 'ui-component/modals/ModalCustom';
import { MeetingContext } from 'views/meeting/MeetingContext';
import LabTestComp from 'components/LabTestComp';

export default function PatientLabTest({ visit, open, onClose }) {
    const { getLabTestByVisit } = useContext(MeetingContext);

    return (
        <ModalCustom open={open} title={'Lab Test'}>
            <Box sx={{ width: '40vw' }}>
                <Box sx={{ position: 'absolute', top: 10, right: 10 }}>
                    <IconButton color="inherit" title="Refresh" onClick={() => getLabTestByVisit()}>
                        <RefreshIcon />
                    </IconButton>
                    <IconButton color="inherit" onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>

                <LabTestComp visit={visit} />
            </Box>
        </ModalCustom>
    );
}
