import React, { useState, useRef } from 'react';

import {
    Autocomplete,
    Box,
    Button,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    Radio,
    RadioGroup,
    TextField,
    Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { JitsiMeeting } from '@jitsi/react-sdk';
import ReactToPrint from 'react-to-print';

import { BASE_URL } from 'constants/baseUrl';
import { COLORS } from 'constants/colors';

import useFetch from 'hooks/useFetch';
import axios from 'axios';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { profileImage } from 'utils/fetchImage';

import VitalSign from 'views/components/vitalSign';
import MedicineComp from 'components/MedicineComp';
import DiagnosisComp from 'components/DiagnosisComp';
import LabTestComp from 'components/LabTestComp';
import IntakeHistoryComp from 'components/IntakeHistoryComp';
import ViewReportsByPatient from 'components/ViewReportsByPatient';
import ModalCustom from 'ui-component/modals/ModalCustom';

import { MeetingContextProvider } from './MeetingContext';
import PrintSummary from './PrintSummary';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { memo } from 'react';
import { useNavigate } from 'react-router';
import ModalConfirmation from 'ui-component/modals/ModalConfirmation';
import UploadReportComp from 'components/UploadReportComp';
import PreviousVisitsComp from 'views/components/PreviousVisitsComp';

const MeetingRoom2 = ({ state }) => {
    const { user } = useSelector((state) => state?.user);
    const navigate = useNavigate();

    const [visit, setVisit] = useState(state);
    const notesRef = useRef('');
    const diagPlanRef = useRef('');
    const componentRef = useRef();

    const [openHistoryModal, setOpenHistoryModal] = useState(false);
    const [openViewReportModal, setOpenViewReportModal] = useState(false);
    const [openUploadReportModal, setOpenUploadReportModal] = useState(false);
    const [openPreviousVisits, setOpenPreviousVisits] = useState(false);
    const [openFollowUp, setOpenFollowUp] = useState(false);
    const [openPrintModal, setOpenPrintModal] = useState(false);
    const [openMeetingEndModal, setOpenMeetingEndModal] = useState(false);
    const [loadingEndMeeting, setLoadingEndMeeting] = useState(false);

    const [specialityList, setSpecialityList] = useState([]);
    const [openConsultationNeeded, setOpenConsultationNeeded] = useState(false);
    const [followUpDateTime, setFollowUpDateTime] = useState(null);
    const [selectedSpeciality, setSelectedSpeciality] = useState(null);

    const [printByVisit, setPrintByVisit] = useState(false);

    const [updateVisitData, setUpdateVisitData] = useState({
        notes: '',
        initialComplain: '',
        isConsultantRequired: false,
        referredTo: null,
        followupdatetime: null,
        isfollowup: false,
        isActive: false
    });

    const [updatePatientTags, setUpdatePatientTags] = useState({
        followupdatetime: null,
        isfollowup: false,
        IsAppointmentSchedule: false,
        isConsultantappointmentSchedule: false
    });

    const {
        data: specialityDate,
        loading: loadingspecialityDate,
        error: errorspecialityDate,
        refetch: refetchspecialityDate
    } = useFetch(`${BASE_URL}api/colorcode`);

    const handleFollowUpDateTime = () => {
        if (followUpDateTime == null) {
            return toast.error('Please enter follow up date time');
        }
        setUpdateVisitData((pre) => ({ ...pre, isfollowup: true, followupdatetime: followUpDateTime }));
        setUpdatePatientTags((pre) => ({ ...pre, isfollowup: true, followupdatetime: followUpDateTime }));
        setOpenFollowUp(false);
    };

    const handleConsultationNeeded = () => {
        if (selectedSpeciality == null) {
            return toast.error('Please select any speciality');
        }
        setUpdateVisitData((pre) => ({ ...pre, isConsultantRequired: true, referredTo: selectedSpeciality?.trim() }));
        setOpenConsultationNeeded(false);
    };

    const handleEndMeeting = async () => {
        setLoadingEndMeeting(true);
        try {
            console.log('updateVisitData ', {
                ...updateVisitData,
                notes: notesRef?.current?.value,
                initialComplain: diagPlanRef?.current?.value
            });

            const resUpdateVisit = await axios({
                method: 'put',
                url: `${BASE_URL}api/visit/updateVisit/${visit.id}`,
                data: {
                    ...updateVisitData,
                    notes: notesRef?.current?.value,
                    initialComplain: diagPlanRef?.current?.value
                },
                headers: { Authorization: `Bearer ${user?.token}` }
            });

            const resUpdatePtTags = await axios({
                method: 'put',
                url: `${BASE_URL}api/visit/updatePatientTags/${visit.patient_NationalID}`,
                data: updatePatientTags,
                headers: { Authorization: `Bearer ${user?.token}` }
            });

            if (resUpdateVisit.data && resUpdatePtTags.data) {
                toast.success('Meeting Ended Successfully');
                navigate(-1);
            }
        } catch (error) {
            toast.success(error?.response?.data?.message ?? error?.response?.data ?? error?.message);
        } finally {
            setLoadingEndMeeting(false);
        }
    };

    return (
        <MeetingContextProvider visit={state}>
            <Grid container columnSpacing={1} sx={{ width: '100%', height: '100%', ml: -2, mt: -1 }}>
                {/* -----------------------------  Button Grid */}

                <Grid item lg={8} md={12} sm={12}>
                    <Grid container spacing={1}>
                        <CustomButton title="Follow Up" onClick={() => setOpenFollowUp(true)} />

                        <CustomButton
                            title="Consultation"
                            onClick={() => {
                                setOpenConsultationNeeded(true);
                                setSpecialityList([...new Set(specialityDate?.map((item) => item.speciality))]);
                            }}
                        />

                        <CustomButton title="Previous Visit" onClick={() => setOpenPreviousVisits(true)} />

                        <CustomButton
                            title="Intake History"
                            onClick={() => {
                                setOpenHistoryModal(true);
                            }}
                        />

                        <CustomButton
                            title="Upload Report"
                            onClick={() => {
                                setOpenUploadReportModal(true);
                            }}
                        />

                        <CustomButton
                            title="View Report"
                            onClick={() => {
                                setOpenViewReportModal(true);
                            }}
                        />

                        <Grid item lg={12} md={12} sm={12}>
                            <Grid container spacing={1}>
                                <Grid item lg={4} md={4} sm={4} sx={{ display: 'flex' }}>
                                    <Box
                                        sx={{ width: 55, height: 55, borderRadius: 55 / 2, overflow: 'hidden', backgroundColor: '#adadad' }}
                                    >
                                        <img
                                            alt={'Profile'}
                                            src={profileImage(visit?.patientProfileImage)}
                                            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                        />
                                    </Box>

                                    <Box sx={{ ml: 2 }}>
                                        <Typography sx={{ fontSize: 16, fontWeight: '600' }}>
                                            {visit?.patientFirstName} {visit?.patientLastName}
                                        </Typography>
                                        <Typography sx={{ fontSize: 12 }}>Gender: {visit?.patientGender}</Typography>
                                        <Typography sx={{ fontSize: 12 }}>Age: {visit?.patientAge}</Typography>
                                    </Box>
                                </Grid>

                                <Grid item lg={5} md={8} sm={8}>
                                    <Typography sx={{ fontSize: 16 }}>{visit?.title}</Typography>
                                    <Typography sx={{ fontSize: 13 }}>{visit?.summaryNotes}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item lg={12} md={12} sm={12}>
                            <Grid container spacing={1}>
                                <Grid item lg={6} md={12} sm={12}>
                                    <Grid container spacing={1} sx={{ maxHeight: '70vh' }}>
                                        <Grid item lg={12} md={6} sm={6}>
                                            {/* ----------------------------------------------  Lab Test Comp  ------------------------------------*/}
                                            <LabTestComp visit={state} />
                                        </Grid>

                                        <Grid item lg={12} md={6} sm={6}>
                                            {/* ----------------------------------------------  Diagnosis Comp  ------------------------------------*/}
                                            <DiagnosisComp visit={state} diagPlanRef={diagPlanRef} />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item lg={6} md={12} sm={12} sx={{ maxHeight: '65vh' }}>
                                    {/* ----------------------------------------------  Medicine Comp  ------------------------------------*/}
                                    <MedicineComp visit={state} />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item lg={12} sx={{ mb: -1 }}>
                            <Box sx={{ display: 'flex', gap: 2 }}>
                                <TextField inputRef={notesRef} type="text" fullWidth size="small" placeholder="Add Notes here" />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item lg={4}>
                    <Grid container spacing={1} sx={{ mr: -2 }}>
                        <Grid item lg={12} sx={{ height: 250 }}>
                            <Box sx={{ backgroundColor: '#adadad', width: '105%', height: '100%', borderRadius: 3, overflow: 'hidden' }}>
                                <JitsiMeeting
                                    domain={'meet.cloudclinic.ai'}
                                    roomName={visit?.meetinglink}
                                    configOverwrite={{
                                        startWithAudioMuted: false,
                                        disableModeratorIndicator: true,
                                        enableEmailInStats: false,
                                        disableSimulcast: false
                                    }}
                                    interfaceConfigOverwrite={{
                                        DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
                                        filmStripOnly: false,
                                        SHOW_JITSI_WATERMARK: false,
                                        SHOW_WATERMARK_FOR_GUEST: false
                                    }}
                                    userInfo={{
                                        displayName: user?.firstName
                                    }}
                                    getIFrameRef={(iframeRef) => {
                                        iframeRef.style.height = '100%';
                                    }}
                                />
                            </Box>
                        </Grid>

                        <Grid item lg={12} sx={{ height: '46.5vh' }}>
                            <Box sx={{ width: '105%', height: '100%', backgroundColor: '#adadad', borderRadius: 3 }}>
                                <VitalSign visitID={visit?.id} show={false} />
                            </Box>
                        </Grid>

                        <Grid item lg={12}>
                            <Grid container spacing={1} sx={{ mb: -1 }}>
                                <CustomButton onClick={() => setOpenMeetingEndModal(true)} title="End Meeting" />

                                <CustomButton title="Print Summary" onClick={() => setOpenPrintModal(true)} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                {/* -----------------------  History Modal  -------------------------------------- */}
                {openHistoryModal && (
                    <IntakeHistoryComp
                        open={openHistoryModal}
                        onClose={() => {
                            setOpenHistoryModal(false);
                        }}
                        isHistoryUpdated={(val, historyStatus, close) => {
                            if (val == true) {
                                setVisit({ ...visit, historystatus: historyStatus });
                                close && setOpenHistoryModal(false);
                            }
                        }}
                        visit={visit}
                    />
                )}

                {/* -----------------------  View Reports Modal  -------------------------------------- */}
                {openViewReportModal && (
                    <ViewReportsByPatient
                        open={openViewReportModal}
                        onClose={() => {
                            setOpenViewReportModal(false);
                        }}
                        visit={visit}
                    />
                )}

                {/* -----------------------  Upload Reports Modal  -------------------------------------- */}
                {openUploadReportModal && (
                    <UploadReportComp
                        open={openUploadReportModal}
                        onClose={() => {
                            setOpenUploadReportModal(false);
                        }}
                        visit={visit}
                    />
                )}

                {/* -----------------------  Previous Visit Modal  -------------------------------------- */}

                <ModalCustom open={openPreviousVisits} title={'Previous Visits'}>
                    <Box>
                        <IconButton
                            color="inherit"
                            onClick={() => setOpenPreviousVisits(false)}
                            sx={{ position: 'absolute', top: 10, right: 10 }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <PreviousVisitsComp />
                    </Box>
                </ModalCustom>

                {/* ------------------------  Modal Follow Up  -------------------------- */}
                <ModalCustom open={openFollowUp} title={'Follow Up'}>
                    <Box sx={{ width: '20vw' }}>
                        <TextField
                            fullWidth
                            type="datetime-local"
                            variant="outlined"
                            label="Follow Up Date Time"
                            InputLabelProps={{ shrink: true }}
                            sx={{ my: 2 }}
                            InputProps={{
                                inputProps: {
                                    min: moment(new Date()).format('YYYY-MM-DDTHH:mm')
                                }
                            }}
                            value={followUpDateTime}
                            onChange={(e) => setFollowUpDateTime(e?.target?.value)}
                        />

                        <Box sx={styles.btnContainer}>
                            <Button onClick={() => setOpenFollowUp(false)} variant="text" sx={{ color: 'red' }}>
                                Cancel
                            </Button>

                            <Button onClick={handleFollowUpDateTime} variant="text" sx={{ color: COLORS.secondory }}>
                                Submit
                            </Button>
                        </Box>
                    </Box>
                </ModalCustom>

                {/* ------------------------  Modal Consultation Needed  -------------------------- */}
                <ModalCustom open={openConsultationNeeded} title={'Consultation'}>
                    <Box sx={{ width: '20vw' }}>
                        <Autocomplete
                            sx={{ my: 2 }}
                            options={specialityList}
                            getOptionLabel={(item) => `${item}`}
                            value={specialityList?.find((i) => i == selectedSpeciality)}
                            onChange={(event, selected) => {
                                setSelectedSpeciality(selected || null);
                            }}
                            renderInput={(params) => <TextField {...params} label="Consult with" variant="outlined" />}
                        />

                        <Box sx={styles.btnContainer}>
                            <Button onClick={() => setOpenConsultationNeeded(false)} variant="text" sx={{ color: 'red' }}>
                                Cancel
                            </Button>

                            <Button onClick={handleConsultationNeeded} variant="text" sx={{ color: COLORS.secondory }}>
                                Submit
                            </Button>
                        </Box>
                    </Box>
                </ModalCustom>

                {/* ----------------------------  Print Summary Comp  ------------------------------ */}
                <div style={{ height: '0px', width: '0px', overflow: 'hidden' }}>
                    <PrintSummary ref={componentRef} visit={visit} printByVisit={printByVisit} />
                </div>

                <ModalCustom open={openPrintModal} title={'Print Summary'}>
                    <IconButton color="inherit" onClick={() => setOpenPrintModal(false)} sx={{ position: 'absolute', top: 10, right: 10 }}>
                        <CloseIcon />
                    </IconButton>

                    <Typography sx={{ marginBottom: 1 }}>Print Prescription by</Typography>

                    <Box>
                        <FormControl>
                            <RadioGroup row value={printByVisit} onChange={(e) => setPrintByVisit(e.target.value)}>
                                <FormControlLabel value={true} control={<Radio />} label="Visit" />
                                <FormControlLabel value={false} control={<Radio />} label="Overall Prescription" />
                            </RadioGroup>
                        </FormControl>
                    </Box>
                    <ReactToPrint trigger={() => <Button>Print</Button>} content={() => componentRef.current} />
                </ModalCustom>

                {/* --------------------------  Confirmation for End Meeting  --------------------------- */}
                <ModalConfirmation
                    open={openMeetingEndModal}
                    header={'End Meeting'}
                    description={'Are you sure, you want to end this meeting?'}
                    loading={loadingEndMeeting}
                    onClickNo={() => setOpenMeetingEndModal(false)}
                    onClickYes={() => handleEndMeeting()}
                />
            </Grid>
        </MeetingContextProvider>
    );
};

export default MeetingRoom2;

const CustomButton = memo(({ title, onClick, sx }) => {
    return (
        <Grid item>
            <Button onClick={onClick} fullWidth variant="contained" sx={{ backgroundColor: COLORS.secondory, ...sx }}>
                {title}
            </Button>
        </Grid>
    );
});

const styles = {
    btnContainer: {
        display: 'flex',
        direction: 'row',
        gap: 1,
        justifyContent: 'flex-end'
    }
};
