import { Box, Button, CircularProgress, Grid } from '@mui/material';
import axios from 'axios';
import { BASE_URL } from 'constants/baseUrl';
import { COLORS } from 'constants/colors';
import { Form, Formik } from 'formik';
import React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import Textfield from 'ui-component/FormUI/Textfield';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function AddNewMedicine({ onAdded }) {
    const { user } = useSelector((state) => state?.user);
    const [loadingRequest, setLoadingRequest] = useState(false);

    const FORM_VALIDATION = Yup.object().shape({
        name: Yup.string().trim().required('Required'),
        dosageForm: Yup.string().trim().required('Required')
    });

    const handleRequest = async (values, resetForm) => {
        setLoadingRequest(true);
        try {
            let data = { ...values, createdBy: user?.userId, createdOn: new Date(), isDeleted: false };

            const res = await axios({
                method: 'post',
                data,
                url: `${BASE_URL}api/medicine`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user?.token}`
                }
            });

            if (res?.data) {
                resetForm();
                let med = {
                    medicineID: res?.data?.medicineID,
                    name: res?.data?.name,
                    genericName: res?.data?.genericName,
                    purpose: res?.data?.purpose,
                    dosageForm: res?.data?.dosageForm,
                    isDeleted: res?.data?.isDeleted,
                    rate: res?.data?.rate
                };
                onAdded(med);
                return toast.success(`Medicine Added Successfully`);
            }
        } catch (error) {
            return toast.error(error?.response?.data?.message ?? error?.response?.data ?? error?.message);
        } finally {
            setLoadingRequest(false);
        }
    };

    return (
        <Formik
            initialValues={{
                name: '',
                genericName: '',
                dosageForm: '',
                rate: '',
                purpose: ''
            }}
            validationSchema={FORM_VALIDATION}
            onSubmit={(values, { resetForm }) => handleRequest(values, resetForm)}
        >
            <Form>
                <Grid container direction="row" spacing={2}>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Textfield name="name" label="Name" />
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Textfield name="genericName" label="Generic Name" />
                    </Grid>

                    <Grid item lg={3} md={6} sm={6} xs={12}>
                        <Textfield name="dosageForm" label="Dosage Form" />
                    </Grid>

                    <Grid item lg={3} md={6} sm={6} xs={12}>
                        <Textfield name="rate" label="Rate" />
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Textfield name="purpose" label="Purpose" />
                    </Grid>

                    <Grid item lg={12} md={12} sm={12} xs={12} mt={1}>
                        <Box sx={styles.btnContainer}>
                            {loadingRequest ? (
                                <CircularProgress size={25} color="inherit" />
                            ) : (
                                <Button type="submit" variant="text" sx={{ color: COLORS.secondory }}>
                                    Save
                                </Button>
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </Form>
        </Formik>
    );
}

const styles = {
    btnContainer: {
        display: 'flex',
        direction: 'row',
        gap: 1,
        justifyContent: 'flex-end'
    }
};
