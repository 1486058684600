import { useContext, useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Grid, IconButton, Typography } from '@mui/material';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import MoodIcon from '@mui/icons-material/Mood';
import MoodBadIcon from '@mui/icons-material/MoodBad';
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';
// ==============================|| VitalSign ||============================== //
import ModalCustom from 'ui-component/modals/ModalCustom';
import BloodPressureGraph from 'views/graph/BloodPressureGraph';
import SPO2Graph from 'views/graph/SPO2Graph';
import HRGraph from 'views/graph/HRGraph';
import MapGraph from 'views/graph/MeanArterialPressureGraph';
import PRGraph from 'views/graph/PRGraph';
import PIGraph from 'views/graph/PIGraph';
import QTCGraph from 'views/graph/QTCGraph';
import QRSGraph from 'views/graph/QTCGraph';
import QTGraph from 'views/graph/QTGraph';
import TempGraph from 'views/graph/TempGraph';
import Canvas from 'views/graph/Canvas';
import { MeetingContext } from 'views/meeting/MeetingContext';
import { BASE_URL } from 'constants/baseUrl';
import axios from 'axios';
import { useSelector } from 'react-redux';

const VitalSign = (props) => {
    const { user } = useSelector((state) => state?.user);

    const [vitalSignData, setVitalSignData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [refresh, setRefresh] = useState(false);

    const getVitalsByVisit = async () => {
        try {
            const response = await axios.get(`${BASE_URL}api/vitalsign/getPatientVitalSignbyVisit/${props?.visitID}`, {
                headers: {
                    Authorization: `Bearer ${user?.token}`
                }
            });
            setVitalSignData(response?.data);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getVitalsByVisit();

        return () => {
            setVitalSignData(null);
            setLoading(true);
            setError(null);
        };
    }, [props?.refetchVital, refresh, props?.visitID]);

    const [modalIsOpenBPModal, setIsOpenBPModal] = useState(false);
    const [modalIsOpenMAPModal, setIsOpenMAPModal] = useState(false);
    const [modalIsOpenPRModal, setIsOpenPRModal] = useState(false);
    const [modalIsOpenQTModal, setIsOpenQTModal] = useState(false);
    const [modalIsOpenQRSModal, setIsOpenQRSModal] = useState(false);
    const [modalIsOpenQTCModal, setIsOpenQTCModal] = useState(false);
    const [modalIsOpenHRModal, setIsOpenHRModal] = useState(false);
    const [modalIsOpenPIModal, setIsOpenPIModal] = useState(false);
    const [modalIsOpenSPO2Modal, setIsOpenSPO2Modal] = useState(false);
    const [modalIsOpenTempModal, setIsOpenTempModal] = useState(false);
    const [modalIsOpenEcgGraph, setIsOpenEcgGraph] = useState(false);

    return (
        <div style={{ height: '100%' }}>
            {!!vitalSignData && (
                <>
                    <ModalCustom open={modalIsOpenBPModal} title={'Blood Pressure Trend over Previous Visits'}>
                        <Box sx={{ width: '50vw' }}>
                            <IconButton
                                color="inherit"
                                onClick={() => setIsOpenBPModal(false)}
                                sx={{ position: 'absolute', top: 10, right: 10 }}
                            >
                                <CloseIcon />
                            </IconButton>
                            <BloodPressureGraph patient_id={vitalSignData.patient_NationalID} dateTime={vitalSignData.datetimeECG} />
                        </Box>
                    </ModalCustom>
                    <ModalCustom open={modalIsOpenMAPModal} title={'Mean Arteiral Pressure Trend over Previous Visits'}>
                        <Box sx={{ width: '50vw' }}>
                            <IconButton
                                color="inherit"
                                onClick={() => setIsOpenMAPModal(false)}
                                sx={{ position: 'absolute', top: 10, right: 10 }}
                            >
                                <CloseIcon />
                            </IconButton>
                            <MapGraph patient_id={vitalSignData.patient_NationalID} dateTime={vitalSignData.datetimeECG} />
                        </Box>
                    </ModalCustom>
                    <ModalCustom open={modalIsOpenPRModal} title={'Pulse Rate Trend over Previous Visits'}>
                        <Box sx={{ width: '50vw' }}>
                            <IconButton
                                color="inherit"
                                onClick={() => setIsOpenPRModal(false)}
                                sx={{ position: 'absolute', top: 10, right: 10 }}
                            >
                                <CloseIcon />
                            </IconButton>
                            <PRGraph patient_id={vitalSignData.patient_NationalID} dateTime={vitalSignData.datetimeECG} />
                        </Box>
                    </ModalCustom>
                    <ModalCustom open={modalIsOpenPIModal} title={'Perfusion Index Trend over Previous Visits'}>
                        <Box sx={{ width: '50vw' }}>
                            <IconButton
                                color="inherit"
                                onClick={() => setIsOpenPIModal(false)}
                                sx={{ position: 'absolute', top: 10, right: 10 }}
                            >
                                <CloseIcon />
                            </IconButton>
                            <PIGraph patient_id={vitalSignData.patient_NationalID} dateTime={vitalSignData.datetimeECG} />
                        </Box>
                    </ModalCustom>
                    <ModalCustom open={modalIsOpenQTModal} title={'QT Trend over Previous Visits'}>
                        <Box sx={{ width: '50vw' }}>
                            <IconButton
                                color="inherit"
                                onClick={() => setIsOpenQTModal(false)}
                                sx={{ position: 'absolute', top: 10, right: 10 }}
                            >
                                <CloseIcon />
                            </IconButton>
                            <QTGraph patient_id={vitalSignData.patient_NationalID} dateTime={vitalSignData.datetimeECG} />
                        </Box>
                    </ModalCustom>
                    <ModalCustom open={modalIsOpenQTCModal} title={'QTc Trend over Previous Visits'}>
                        <Box sx={{ width: '50vw' }}>
                            <IconButton
                                color="inherit"
                                onClick={() => setIsOpenQTCModal(false)}
                                sx={{ position: 'absolute', top: 10, right: 10 }}
                            >
                                <CloseIcon />
                            </IconButton>
                            <QTCGraph patient_id={vitalSignData.patient_NationalID} dateTime={vitalSignData.datetimeECG} />
                        </Box>
                    </ModalCustom>
                    <ModalCustom open={modalIsOpenQRSModal} title={'QRS Trend over Previous Visits'}>
                        <Box sx={{ width: '50vw' }}>
                            <IconButton
                                color="inherit"
                                onClick={() => setIsOpenQRSModal(false)}
                                sx={{ position: 'absolute', top: 10, right: 10 }}
                            >
                                <CloseIcon />
                            </IconButton>
                            <QRSGraph patient_id={vitalSignData.patient_NationalID} dateTime={vitalSignData.datetimeECG} />
                        </Box>
                    </ModalCustom>
                    <ModalCustom open={modalIsOpenHRModal} title={'Heart Rate Trend over Previous Visits'}>
                        <Box sx={{ width: '50vw' }}>
                            <IconButton
                                color="inherit"
                                onClick={() => setIsOpenHRModal(false)}
                                sx={{ position: 'absolute', top: 10, right: 10 }}
                            >
                                <CloseIcon />
                            </IconButton>
                            <HRGraph patient_id={vitalSignData.patient_NationalID} dateTime={vitalSignData.datetimeECG} />
                        </Box>
                    </ModalCustom>
                    <ModalCustom open={modalIsOpenSPO2Modal} title={'SPO2 Trend over Previous Visits'}>
                        <Box sx={{ width: '50vw' }}>
                            <IconButton
                                color="inherit"
                                onClick={() => setIsOpenSPO2Modal(false)}
                                sx={{ position: 'absolute', top: 10, right: 10 }}
                            >
                                <CloseIcon />
                            </IconButton>
                            <SPO2Graph patient_id={vitalSignData.patient_NationalID} dateTime={vitalSignData.datetimeECG} />
                        </Box>
                    </ModalCustom>
                    <ModalCustom open={modalIsOpenTempModal} title={'Temperature Trend over Previous Visits'}>
                        <Box sx={{ width: '50vw' }}>
                            <IconButton
                                color="inherit"
                                onClick={() => setIsOpenTempModal(false)}
                                sx={{ position: 'absolute', top: 10, right: 10 }}
                            >
                                <CloseIcon />
                            </IconButton>
                            <TempGraph patient_id={vitalSignData.patient_NationalID} dateTime={vitalSignData.datetimeECG} />
                        </Box>
                    </ModalCustom>
                    <ModalCustom open={modalIsOpenEcgGraph} title={'ECG Trend over Previous Visits'}>
                        <Box>
                            <IconButton
                                color="inherit"
                                onClick={() => setIsOpenEcgGraph(false)}
                                sx={{ position: 'absolute', top: 10, right: 10 }}
                            >
                                <CloseIcon />
                            </IconButton>
                            <Canvas modal={setIsOpenEcgGraph} cw={'50vw'} ch={'50vh'} visitID={props?.visitID} />
                        </Box>
                    </ModalCustom>
                </>
            )}
            <Grid
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                    height: '100%',
                    justifyContent: 'space-between',
                    backgroundColor: '#636f83',
                    border: 5,
                    borderColor: '#DDE6ED',
                    borderRadius: 3,
                    p: 1
                }}
            >
                {!!vitalSignData && (
                    <>
                        <Grid container sx={{ backgroundColor: '#636f83', borderRadius: 1 }}>
                            {props.show && (
                                <>
                                    <Grid item lg={6} sx={{ borderBottom: 2, borderColor: '#424949' }}>
                                        {' '}
                                        <Typography
                                            variant="h6"
                                            component="h2"
                                            sx={{ color: 'white', p: 1, '&: hover': { backgroundColor: '#465469' } }}
                                        >
                                            Gender: <span style={{ color: '#E7FFC2', p: 1 }}>{vitalSignData?.gender}</span>
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={4} sx={{ borderBottom: 2, borderColor: '#424949' }}>
                                        {' '}
                                        <Typography
                                            variant="h6"
                                            component="h2"
                                            sx={{ color: 'white', p: 1, '&: hover': { backgroundColor: '#465469' } }}
                                        >
                                            Age: <span style={{ color: '#E7FFC2', p: 1 }}>{vitalSignData?.age}</span>
                                            <small> (years)</small>
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={2} sx={{ borderBottom: 2, borderColor: '#424949' }}>
                                        <IconButton color="inherit" onClick={() => setRefresh(!refresh)}>
                                            <RefreshIcon sx={{ color: '#fff' }} />
                                        </IconButton>
                                    </Grid>
                                </>
                            )}

                            <Grid item lg={6}>
                                {' '}
                                <Typography
                                    variant="h6"
                                    component="h2"
                                    sx={{ color: 'white', p: 1, '&: hover': { backgroundColor: '#465469' } }}
                                >
                                    Weight: <span style={{ color: '#E7FFC2', p: 1 }}>{vitalSignData.weight} </span>
                                    <small>(kg)</small>
                                </Typography>
                            </Grid>
                            <Grid item lg={6}>
                                <Typography
                                    variant="h6"
                                    component="h2"
                                    sx={{ color: 'white', p: 1, '&: hover': { backgroundColor: '#465469' } }}
                                >
                                    Height: <span style={{ color: '#E7FFC2', p: 1 }}>{vitalSignData.height} </span>
                                    <small>(standard)</small>
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container sx={{ backgroundColor: '#424949', borderRadius: 1 }}>
                            <Grid item lg={12} sx={{ borderBottom: 2, borderColor: '#636f83' }}>
                                {' '}
                                <Typography
                                    variant="h4"
                                    component="h2"
                                    sx={{ color: 'white', p: 1, '&: hover': { backgroundColor: '#465469' } }}
                                >
                                    NIBP <small>Oscillometric mmHg</small>
                                </Typography>
                            </Grid>
                            <Grid item lg={3}>
                                {' '}
                                <Button
                                    variant="h6"
                                    component="h2"
                                    sx={{ color: 'white', p: 1, '&: hover': { backgroundColor: '#465469' } }}
                                    onClick={() => setIsOpenBPModal((modalIsOpenBPModal) => !modalIsOpenBPModal)}
                                >
                                    SYS:&nbsp; <span style={{ color: '#AFE6FF', p: 1 }}>{vitalSignData.sys} </span>
                                </Button>
                            </Grid>
                            <Grid item lg={3}>
                                {' '}
                                <Button
                                    variant="h6"
                                    component="h2"
                                    sx={{ color: 'white', p: 1, '&: hover': { backgroundColor: '#465469' } }}
                                    onClick={() => setIsOpenBPModal((modalIsOpenBPModal) => !modalIsOpenBPModal)}
                                >
                                    DIA:&nbsp; <span style={{ color: '#AFE6FF', p: 1 }}>{vitalSignData.dia} </span>
                                </Button>
                            </Grid>
                            <Grid item lg={3}>
                                <Button
                                    variant="h6"
                                    component="h2"
                                    sx={{ color: 'white', p: 1, ml: -1 }}
                                    onClick={() => setIsOpenMAPModal((modalIsOpenMAPModal) => !modalIsOpenMAPModal)}
                                >
                                    MAP: &nbsp;<span style={{ color: '#AFE6FF', p: 1 }}>{parseFloat(vitalSignData.map).toFixed(2)} </span>
                                </Button>
                            </Grid>
                            <Grid item lg={3}>
                                <Button
                                    variant="h6"
                                    component="h2"
                                    sx={{ color: 'white', p: 1, '&: hover': { backgroundColor: '#465469' } }}
                                    onClick={() => setIsOpenPRModal((modalIsOpenPRModal) => !modalIsOpenPRModal)}
                                >
                                    PR: &nbsp;<span style={{ color: '#AFE6FF', p: 1 }}>{vitalSignData.pr} </span>
                                    &nbsp;<small> /min</small>
                                </Button>
                            </Grid>
                        </Grid>

                        <Grid container sx={{ backgroundColor: '#424949', mt: 1, borderRadius: 1 }}>
                            <Grid item lg={6} sx={{ borderBottom: 2, borderColor: '#636f83' }}>
                                {' '}
                                <Button
                                    variant="h5"
                                    component="h2"
                                    sx={{ color: 'white', p: 1, '&: hover': { backgroundColor: '#465469' } }}
                                    onClick={() => setIsOpenTempModal((modalIsOpenTempModal) => !modalIsOpenTempModal)}
                                >
                                    TEMP: &nbsp;<span style={{ color: '#AFE6FF', p: 1 }}>{vitalSignData.temp} </span>&nbsp;
                                    <small>°F</small>
                                </Button>
                            </Grid>
                            <Grid item lg={6} sx={{ borderBottom: 2, borderColor: '#636f83' }}>
                                <Button
                                    variant="h5"
                                    component="h2"
                                    sx={{ color: 'white', ml: -1, p: 1 }}
                                    // onClick={() => setIsOpenTempModal((modalIsOpenTempModal) => !modalIsOpenTempModal)}
                                >
                                    BSR: &nbsp;<span style={{ color: '#AFE6FF', p: 1 }}>{vitalSignData.bsr} </span>&nbsp;
                                </Button>
                            </Grid>
                            <Grid item lg={4}>
                                {' '}
                                <Button
                                    variant="h5"
                                    component="h2"
                                    sx={{ color: 'white', p: 1, '&: hover': { backgroundColor: '#465469' } }}
                                    onClick={() => setIsOpenSPO2Modal((modalIsOpenSPO2Modal) => !modalIsOpenSPO2Modal)}
                                >
                                    SPO2:&nbsp; <span style={{ color: '#AFE6FF', p: 1 }}> {vitalSignData.spO2} </span> &nbsp;
                                    <small>%</small>
                                </Button>
                            </Grid>
                            <Grid item lg={4}>
                                {' '}
                                <Button
                                    variant="h6"
                                    component="h2"
                                    sx={{ color: 'white', p: 1, '&: hover': { backgroundColor: '#465469' } }}
                                    onClick={() => setIsOpenPIModal((modalIsOpenPIModal) => !modalIsOpenPIModal)}
                                >
                                    PI: &nbsp;<span style={{ color: '#AFE6FF', p: 1 }}>{vitalSignData.pi} </span>
                                </Button>
                            </Grid>
                            <Grid item lg={4}>
                                <Button
                                    variant="h6"
                                    component="h2"
                                    sx={{ color: 'white', p: 1, '&: hover': { backgroundColor: '#465469' } }}
                                    onClick={() => setIsOpenPRModal((modalIsOpenPRModal) => !modalIsOpenPRModal)}
                                >
                                    PR: &nbsp;<span style={{ color: '#AFE6FF', p: 1 }}>{vitalSignData.pr} </span>&nbsp;
                                    <small> /min</small>
                                </Button>
                            </Grid>
                        </Grid>

                        <Grid container sx={{ backgroundColor: '#636f83', borderRadius: 1 }}>
                            <Grid item lg={10} sx={{ borderBottom: 2, borderColor: '#424949' }}>
                                {' '}
                                <Typography variant="h4" component="h2" sx={{ color: '#E7FFC2', p: 1 }}>
                                    ECG{' '}
                                    <span style={{ color: 'white', fontSize: '12px' }}>
                                        {' '}
                                        {vitalSignData.ma == 0 ? '( Regular ECG Rhythm )' : 'Irreglar ECG Rhythm'}{' '}
                                    </span>
                                </Typography>
                            </Grid>
                            <Grid item lg={1} sx={{ borderBottom: 2, borderColor: '#424949' }}>
                                <Typography variant="h6" component="h2" sx={{ color: '#E7FFC2', p: 1 }}>
                                    {' '}
                                    {vitalSignData.ma == 0 ? <MoodIcon sx={{ fontSize: 20 }} /> : <MoodBadIcon sx={{ fontSize: 20 }} />}
                                </Typography>
                            </Grid>
                            <Grid item lg={1} sx={{ borderBottom: 2, borderColor: '#424949' }}>
                                {' '}
                                <Typography
                                    variant="h6"
                                    component="h2"
                                    sx={{ color: '#E7FFC2', p: 1, '&:hover': { cursor: 'pointer' } }}
                                    onClick={() => setIsOpenEcgGraph((modalIsOpenEcgGraph) => !modalIsOpenEcgGraph)}
                                >
                                    <PlayCircleOutlineIcon sx={{ fontSize: 20 }} />
                                </Typography>
                            </Grid>
                            <Grid item lg={3}>
                                {' '}
                                <Button
                                    variant="h6"
                                    component="h2"
                                    sx={{ color: 'white', p: 1, '&: hover': { backgroundColor: '#465469' } }}
                                    onClick={() => setIsOpenQTModal((modalIsOpenQTModal) => !modalIsOpenQTModal)}
                                >
                                    QT:&nbsp; <span style={{ color: '#E7FFC2', p: 1 }}>{vitalSignData.qt} </span>
                                </Button>
                            </Grid>
                            <Grid item lg={3}>
                                {' '}
                                <Button
                                    variant="h6"
                                    component="h2"
                                    sx={{ color: 'white', p: 1, '&: hover': { backgroundColor: '#465469' } }}
                                    onClick={() => setIsOpenQRSModal((modalIsOpenQRSModal) => !modalIsOpenQRSModal)}
                                >
                                    QRs: &nbsp;<span style={{ color: '#E7FFC2', p: 1 }}>{vitalSignData.qrs} </span>
                                </Button>
                            </Grid>
                            <Grid item lg={3}>
                                <Button
                                    variant="h6"
                                    component="h2"
                                    sx={{ color: 'white', p: 1, '&: hover': { backgroundColor: '#465469' } }}
                                    onClick={() => setIsOpenQTCModal((modalIsOpenQTCModal) => !modalIsOpenQTCModal)}
                                >
                                    Qtc: &nbsp;<span style={{ color: '#E7FFC2', p: 1 }}>{vitalSignData.qtc} </span>
                                </Button>
                            </Grid>
                            <Grid item lg={3}>
                                <Button
                                    variant="h6"
                                    component="h2"
                                    sx={{ color: 'white', p: 1, '&: hover': { backgroundColor: '#465469' } }}
                                    onClick={() => setIsOpenHRModal((modalIsOpenHRModal) => !modalIsOpenHRModal)}
                                >
                                    HR:&nbsp; <span style={{ color: '#E7FFC2', p: 1 }}>{vitalSignData.hr} </span>
                                    &nbsp;<small>/Min</small>
                                </Button>
                            </Grid>
                        </Grid>
                    </>
                )}

                {loading && (
                    <Box sx={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                        <CircularProgress size={35} color="inherit" />
                    </Box>
                )}

                {error && (
                    <Box sx={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                        {error?.response?.status == '404' ? (
                            <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Typography sx={{ color: '#fff' }}>Patient vitals are not uploaded</Typography>
                                <Button onClick={() => setRefresh(!refresh)} sx={{ mt: 1 }} variant="contained" startIcon={<RefreshIcon />}>
                                    Refresh
                                </Button>
                            </Box>
                        ) : (
                            <Typography sx={{ color: '#fff' }}>
                                {error?.response?.data?.message ?? error?.response?.data ?? error?.message}
                            </Typography>
                        )}
                    </Box>
                )}
            </Grid>
        </div>
    );
};

export default VitalSign;
