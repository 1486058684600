export const styles = {
    loadingContainer: {
        height: 155,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    btnContainer: {
        display: 'flex',
        direction: 'row',
        gap: 1,
        mt: 2,
        justifyContent: 'flex-end'
    },
    inputField: {
        padding: 10,
        borderRadius: 10,
        border: '1px solid #bfc0c2',
        fontSize: 14,
        width: '100%',
        outline: 'none',
        backgroundColor: '#f8fafc'
    },
    loadingBox: {
        display: 'flex',
        flex: 1,
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    addBtn: {
        backgroundColor: '#fff',
        '&:hover': {
            backgroundColor: '#d1e7ff'
        }
    },
    accordianTitle: { fontSize: 16, fontWeight: 600 },
    prescriptionCard: {
        display: 'flex',
        flexWrap: 'warp',
        justifyContent: 'space-between',
        backgroundColor: '#eef2f6',
        borderRadius: 2,
        p: 1,
        my: 1
    }
};
