import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    config: []
};

export const configSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {
        saveConfig: (state = initialState, action) => {
            state.config = action.payload;
        },
        removeConfig: (state = initialState) => {
            state.config = [];
        }
    }
});

export const { saveConfig, removeConfig } = configSlice.actions;

export default configSlice.reducer;
