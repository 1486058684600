import { Button, CircularProgress, IconButton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useContext, useEffect, useState } from 'react';
import ModalCustom from 'ui-component/modals/ModalCustom';
import CloseIcon from '@mui/icons-material/Close';
import { getData } from 'utils/indexedDB';
import { COLORS } from 'constants/colors';
import FilterPrescription from './FilterPrescription';
import FilterDiagnosis from './FilterDiagnosis';
import FilterLabTest from './FilterLabTest';
import TranslationContext from 'context/translation';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { BASE_URL } from 'constants/baseUrl';
import { useSelector } from 'react-redux';

function OrganizeTranscriptionData({ visit, open, onClose }) {
    const { translate } = useContext(TranslationContext);
    const { user } = useSelector((state) => state?.user);

    const [labTestData, setLabTestData] = useState(null);
    const [diagnosisData, setDiagnosisData] = useState(null);
    const [medicineData, setMedicineData] = useState(null);

    const [errorData, setErrorData] = useState(null);
    const [isLoadingData, setIsDataLoading] = useState(true);

    const [selectedTab, setSelectedTab] = useState('Prescription');

    const [filteredPrescription, setFilteredPrescription] = useState([]);
    const [filteredDiagnosis, setFilteredDiagnosis] = useState([]);
    const [filteredLabTest, setFilteredLabTest] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const getAllData = async () => {
            try {
                const [labData, diagData, medData] = await Promise.all([getData('labTestType'), getData('diagnosis'), getData('medicine')]);
                setLabTestData(labData);
                setDiagnosisData(diagData);
                setMedicineData(medData);
            } catch (error) {
                setErrorData(error);
            } finally {
                setIsDataLoading(false);
            }
        };

        getAllData();

        return () => {};
    }, []);

    if (isLoadingData) {
        return (
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: 200 }}>
                <CircularProgress size={35} color="inherit" />
            </Box>
        );
    }

    if (!!errorData) {
        return <Typography>{error?.response?.data?.message || error?.response?.data || error?.message}</Typography>;
    }

    let jsonData = JSON.parse(visit?.transcriptionData);
    // let jsonData = {
    //     json_response: {
    //         diagnosis: [
    //             {
    //                 name: 'Fever and severe coughing'
    //             }
    //         ],
    //         diagnosisAndPlan:
    //             'The patient has a fever and cough for the past three days. Advised medication, a Blood CP Test and Chest X-Ray.',
    //         labTest: [
    //             {
    //                 instruction: 'Not specified',
    //                 name: 'Blood CP Test'
    //             },
    //             {
    //                 instruction: 'Not specified',
    //                 name: 'Chest X-ray'
    //             }
    //         ],
    //         notes: 'The results of the Blood CP Test and Chest X-ray should be shared with the doctor.',
    //         prescription: [
    //             {
    //                 direction: 'Not specified',
    //                 dose: 'Not specified',
    //                 duration: 'Not specified',
    //                 frequency: 'Once every day',
    //                 instruction: 'Do not stop taking this medicine pre-maturely.',
    //                 name: 'Ezumax Capsule 500mg'
    //             },
    //             {
    //                 direction: 'Not specified',
    //                 dose: 'Not specified',
    //                 duration: 'Not specified',
    //                 frequency: 'Morning, Afternoon, Evening',
    //                 instruction: 'Not specified',
    //                 name: 'Fexet 60mg'
    //             },
    //             {
    //                 direction: 'Not specified',
    //                 dose: 'Not specified',
    //                 duration: 'For three days',
    //                 frequency: '2 in the morning, 2 in the afternoon, 2 in the evening',
    //                 instruction: 'Not specified',
    //                 name: 'Panadol'
    //             }
    //         ]
    //     },
    //     plain_text_response:
    //         'Plain Text:\n\nDiagnosis:\nThe patient is suffering from fever and severe coughing for the past three days.\n\nPrescription:\n1. Medicine Name: Izumax Capsule 500mg\n   - Frequency: Once every day\n   - Duration: Not specified\n   - Direction: Not specified\n   - Dose: Not specified\n   - Instruction: Do not stop taking this medicine prematurely.\n   \n2. Medicine Name: Fexet 60mg \n   - Frequency: Morning, Afternoon, Evening\n   - Duration: Not specified\n   - Direction: Not specified\n   - Dose: Not specified\n   - Instruction: Not specified\n\n3. Medicine Name: Panadol\n   - Frequency: 2 in the morning, 2 in the afternoon, 2 in the evening\n   - Duration: For three days\n   - Direction: Not specified\n   - Dose: Not specified \n   - Instruction: Not specified\n\n\nLab Tests:\n1. Test Name: Blood CP Test\n   - Instruction: Not specified\n\n2. Test Name: Chest X-ray\n   - Instruction: Not specified\n\n\nDiagnosis And Plan:\nThe patient has a fever and cough for the past three days. The doctor advises medication and also suggests to carry out a Blood CP Test and Chest X-Ray. \n\nNotes: \nThe results of the Blood CP Test and Chest X-ray should be shared with the doctor.\n\nJSON:'
    // };

    const handleSave = async () => {
        setIsLoading(true);
        try {
            const headers = {
                Authorization: `Bearer ${user?.token}`,
                'Content-Type': 'application/json'
            };

            const prescriptionPromises = filteredPrescription.map((prescription) =>
                axios.post(`${BASE_URL}api/prescription`, prescription, { headers })
            );

            const diagnosisPromises = filteredDiagnosis.map((diagnosis) =>
                axios.post(`${BASE_URL}api/patientdiagnostic`, diagnosis, { headers })
            );

            const labTestPromises = filteredLabTest.map((labTest) => axios.post(`${BASE_URL}api/visitLabTest`, labTest, { headers }));

            // Execute all POST requests concurrently
            const [medRes, diagRes, labRes] = await Promise.all([
                Promise.all(prescriptionPromises),
                Promise.all(diagnosisPromises),
                Promise.all(labTestPromises)
            ]);

            // Check if all requests are successful
            if (medRes.length && diagRes.length && labRes.length) {
                onClose();
                return toast.success('Prescription added successfully!');
            }
        } catch (error) {
            return toast.error(error?.response?.data?.message ?? error?.response?.data ?? error?.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <ModalCustom open={open} title={'Refine Data'}>
            <Box sx={{ minWidth: '50vw', minHeight: '60vh' }}>
                <Box sx={{ position: 'absolute', top: 10, right: 10 }}>
                    <IconButton color="inherit" onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>

                <Box display={'flex'} bgcolor={'#f5f5f5'} borderRadius={0.8}>
                    <TabButton label={'Prescription'} selected={selectedTab} setSelectedTab={setSelectedTab} />
                    <TabButton label={'Diagnosis'} selected={selectedTab} setSelectedTab={setSelectedTab} />
                    <TabButton label={'Lab Test'} selected={selectedTab} setSelectedTab={setSelectedTab} />
                </Box>

                {selectedTab === 'Prescription' ? (
                    <FilterPrescription
                        visit={visit}
                        medicineData={medicineData}
                        gptPrescription={jsonData?.json_response?.prescription}
                        filtered={filteredPrescription}
                        setFiltered={setFilteredPrescription}
                    />
                ) : selectedTab === 'Diagnosis' ? (
                    <FilterDiagnosis
                        visit={visit}
                        diagnosisData={diagnosisData}
                        gptDiagnosis={jsonData?.json_response?.diagnosis}
                        filtered={filteredDiagnosis}
                        setFiltered={setFilteredDiagnosis}
                    />
                ) : (
                    <FilterLabTest
                        visit={visit}
                        labTestData={labTestData}
                        gptLabTest={jsonData?.json_response?.labTest}
                        filtered={filteredLabTest}
                        setFiltered={setFilteredLabTest}
                    />
                )}
            </Box>
            <Box sx={styles.btnContainer}>
                {isLoading ? (
                    <CircularProgress size={25} color="inherit" />
                ) : (
                    <>
                        <Button onClick={onClose} variant="text" sx={{ color: 'red' }}>
                            Cancel
                        </Button>

                        <Button onClick={() => handleSave()} variant="text" sx={{ color: COLORS.secondory }}>
                            Save
                        </Button>
                    </>
                )}
            </Box>
        </ModalCustom>
    );
}

export default OrganizeTranscriptionData;

const TabButton = ({ label, selected, setSelectedTab }) => {
    return (
        <Button
            fullWidth
            onClick={() => setSelectedTab(label)}
            sx={{
                bgcolor: selected == label ? COLORS.secondory : 'none',
                color: selected == label ? '#fff' : 'none',
                '&:hover': {
                    bgcolor: '#3a97e8',
                    color: '#fff'
                }
            }}
        >
            {label}
        </Button>
    );
};

const styles = {
    loadingContainer: {
        height: 400,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    btnContainer: {
        display: 'flex',
        direction: 'row',
        gap: 1,
        justifyContent: 'flex-end'
    }
};
