// project imports
import React, { useRef, useState, useEffect, useContext } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    Typography
} from '@mui/material';
import { BASE_URL } from 'constants/baseUrl';
import { getData } from 'utils/indexedDB';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MeetingContext } from 'views/meeting/MeetingContext';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { COLORS } from 'constants/colors';

const MedicineComp = ({ visit }) => {
    const { user } = useSelector((state) => state?.user);

    //--------------------------- Getting Patient Prescrition from Context
    const { prescriptionList, setPrescriptionList, getPrescriptionByVisit } = useContext(MeetingContext);

    const [medicine, setMedicine] = useState(null);
    const [loading, setLoading] = useState(true);

    //----------------------------  Getting List of Medicine, Route, Direction etc from LocalStorage and IndexedDB
    const route = JSON.parse(localStorage.getItem('route'));
    const duration = JSON.parse(localStorage.getItem('duration'));
    const direction = JSON.parse(localStorage.getItem('direction'));
    const doseUnit = JSON.parse(localStorage.getItem('doseUnit'));

    useEffect(() => {
        getData('medicine').then((res) => {
            setMedicine(res);
            setLoading(false);
            return;
        });

        return () => {
            setMedicine(null);
        };
    }, []);

    //-----------------------------  Setting Ref to handle all selected Values from list
    let medRef = useRef('');
    let routeRef = useRef('');
    let directionRef = useRef('');
    let durationRef = useRef('');
    let doseUnitRef = useRef('');
    let doseRef = useRef(0);
    let instructionRef = useRef('');

    const [selectedFrequency, setSelectedFrequency] = useState({ morning: true, evening: true, night: true });

    //-----------------------------  Function Called when Add Button Clicked
    const handleAdd = async () => {
        if (medRef?.current?.value == '') {
            return toast.error('Please select medicine');
        }

        const prescription = {
            VisitID: visit?.id,
            Consultant_NationalID: visit?.consultant_NationalID,
            Patient_NationalID: visit?.patient_NationalID,
            MedicineID: medRef?.current?.value.split('-')[0].trim(),
            RefilDetails: null,
            dose: doseRef.current.value,
            doseUnit: doseUnitRef.current.value,
            route: routeRef?.current?.value,
            direction: directionRef.current.value,
            duration: durationRef.current.value,
            frequency: `${selectedFrequency?.morning == true ? '1' : '0'}+${selectedFrequency?.evening == true ? '1' : '0'}+${
                selectedFrequency?.night == true ? '1' : '0'
            }`,
            durationUnit: doseUnitRef.current.value,
            isDeleted: false,
            status: 'Continued',
            dosageInstruction: instructionRef.current.value,
            createdOn: new Date(),
            createdBy: user.userId,
            //------------------below values are just to handle frontend prescription values
            medicineName: medRef?.current?.value.split('-')[1].trim(),
            medicineGenericName: medRef?.current?.value.split('-')[2].trim(),
            prescriptionId: medRef?.current?.value.split('-')[0].trim()
        };

        //-----------------------  Appending New Prescription in Prescription List
        setPrescriptionList([prescription, ...prescriptionList]);

        //-----------------------  Reseting all Values
        medRef.current.value = '';
        durationRef.current.value = '';
        directionRef.current.value = '';
        doseUnitRef.current.value = '';
        doseRef.current.value = 0;
        instructionRef.current.value = '';
        setSelectedFrequency({ morning: true, evening: true, night: true });

        //-----------------------  Posting New Precription
        const resPresc = await axios({
            method: 'post',
            url: `${BASE_URL}api/prescription`,
            data: prescription,
            headers: {
                Authorization: `Bearer ${user?.token}`
            }
        });

        //-----------------------  If Response is successfull then call get Prescription List Api
        if (resPresc?.data) {
            getPrescriptionByVisit();
        }
    };

    const handleDelete = async (prescriptionId) => {
        //----------------- find the prescription from list and set is Deleted to true
        const updatedPrescriptionList = prescriptionList.map((obj) => {
            if (obj.prescriptionId === prescriptionId) {
                obj.isDeleted = true;
            }
            return obj;
        });
        setPrescriptionList(updatedPrescriptionList);

        const resPrescDel = await axios({
            method: 'delete',
            url: `${BASE_URL}api/prescription/${prescriptionId}`,
            data: {},
            headers: {
                Authorization: `Bearer ${user?.token}`
            }
        });

        if (resPrescDel?.data) {
            getPrescriptionByVisit();
        }
    };

    const handleModify = async (prescriptionId) => {
        //----------------- find the prescription from list and set is Deleted to false
        const updatedPrescriptionList = prescriptionList.map((obj) => {
            if (obj.prescriptionId === prescriptionId) {
                obj.isDeleted = false;
            }
            return obj;
        });
        setPrescriptionList(updatedPrescriptionList);

        const resPrescModify = await axios({
            method: 'put',
            url: `${BASE_URL}api/prescription/updatePrescription/${prescriptionId}`,
            data: {
                isDeleted: false,
                lastModifiedBy: user?.userId,
                lastModifiedOn: new Date()
            },
            headers: {
                Authorization: `Bearer ${user?.token}`
            }
        });

        if (resPrescModify?.data) {
            getPrescriptionByVisit();
        }
    };

    return (
        <Box sx={{ backgroundColor: '#fff', p: 0.5, borderRadius: 2, height: '100%' }}>
            {medicine && (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                    <Box>
                        <AutoCompleteCom
                            listName={'medicine'}
                            data={medicine}
                            inputref={medRef}
                            placeholder={'Select Medicine'}
                            showKey={['medicineID', 'name', 'genericName']}
                        />
                    </Box>

                    <Box sx={{ display: 'flex', flex: 1, gap: 0.5 }}>
                        <Box sx={{ display: 'flex', flex: 1, justifyContent: 'center', mb: -0.5 }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={selectedFrequency?.morning}
                                        onChange={(e) => setSelectedFrequency({ ...selectedFrequency, morning: e.target.checked })}
                                    />
                                }
                                label={selectedFrequency?.morning ? '1' : '0'}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={selectedFrequency?.evening}
                                        onChange={(e) => setSelectedFrequency({ ...selectedFrequency, evening: e.target.checked })}
                                    />
                                }
                                label={selectedFrequency?.evening ? '1' : '0'}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={selectedFrequency?.night}
                                        onChange={(e) => setSelectedFrequency({ ...selectedFrequency, night: e.target.checked })}
                                    />
                                }
                                label={selectedFrequency?.night ? '1' : '0'}
                            />
                        </Box>
                        {/* <Box sx={{ display: 'flex', flex: 1 }}>
                            <AutoCompleteCom
                                listName={'route'}
                                data={route}
                                inputref={routeRef}
                                placeholder={'Route'}
                                showKey={['title']}
                            />
                        </Box> */}
                        <Box sx={{ display: 'flex', flex: 1 }}>
                            <AutoCompleteCom
                                listName={'direction'}
                                data={direction}
                                inputref={directionRef}
                                placeholder={'Direction'}
                                showKey={['title']}
                            />
                        </Box>
                    </Box>

                    <Box sx={{ display: 'flex', flex: 1, gap: 0.5 }}>
                        <Box sx={{ display: 'flex', flex: 1 }}>
                            <AutoCompleteCom
                                listName={'duration'}
                                data={duration}
                                inputref={durationRef}
                                placeholder={'Duration'}
                                showKey={['title']}
                            />
                        </Box>

                        <Box sx={{ display: 'flex', flex: 1, gap: 0.5 }}>
                            <input
                                type="number"
                                placeholder={'Quantity'}
                                ref={doseRef}
                                style={styles.inputField}
                                onFocus={(e) => {
                                    e.target.style.border = '2px solid #2196f3'; // change border color on focus
                                }}
                                onBlur={(e) => {
                                    e.target.style.border = '1px solid #bfc0c2'; // revert border color on blur
                                }}
                            />
                            <AutoCompleteCom
                                listName={'doseUnit'}
                                data={doseUnit}
                                inputref={doseUnitRef}
                                placeholder={'Dose Unit'}
                                showKey={['title']}
                            />
                        </Box>
                    </Box>

                    <Box sx={{ display: 'flex', flex: 1, gap: 0.5 }}>
                        <input
                            type="text"
                            placeholder={'Instruction'}
                            ref={instructionRef}
                            style={styles.inputField}
                            onFocus={(e) => {
                                e.target.style.border = '2px solid #2196f3'; // change border color on focus
                            }}
                            onBlur={(e) => {
                                e.target.style.border = '1px solid #bfc0c2'; // revert border color on blur
                            }}
                        />

                        {/* -------------------------------  Add Button */}
                        <Button variant="outlined" onClick={handleAdd}>
                            Add
                        </Button>
                    </Box>
                </Box>
            )}

            {prescriptionList?.length > 0 && (
                <Box sx={[styles.scrollBarStyle, { maxHeight: '41vh' }]}>
                    {/* -----------------------------  Current Medication Prescription */}
                    <Accordion defaultExpanded={true}>
                        <AccordionSummary
                            sx={{ px: 1 }}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography sx={styles?.accordianTitle}>
                                Current Medication - {prescriptionList?.filter((i) => i.isDeleted == false).length}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ p: 0, mt: -2 }}>
                            {prescriptionList?.map((item, index) => (
                                <div key={index}>
                                    {item?.isDeleted == false && (
                                        <PrecriptionCard
                                            prescription={item}
                                            btnTitle={'Remove'}
                                            onClick={() => handleDelete(item?.prescriptionId)}
                                        />
                                    )}
                                </div>
                            ))}
                        </AccordionDetails>
                    </Accordion>

                    {/* -----------------------------  Discountinued Prescription */}
                    <Accordion>
                        <AccordionSummary
                            sx={{ px: 1 }}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography sx={styles?.accordianTitle}>
                                Discountinued Medication - {prescriptionList?.filter((i) => i.isDeleted == true).length}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ p: 0, mt: -2 }}>
                            {prescriptionList?.map((item, index) => (
                                <div key={index}>
                                    {item?.isDeleted == true && (
                                        <PrecriptionCard
                                            prescription={item}
                                            btnTitle={'Modify'}
                                            onClick={() => handleModify(item?.prescriptionId)}
                                        />
                                    )}
                                </div>
                            ))}
                        </AccordionDetails>
                    </Accordion>
                </Box>
            )}

            {loading && (
                <Box sx={styles.loadingBox}>
                    <CircularProgress size={35} color="inherit" />
                </Box>
            )}
        </Box>
    );
};

export default React.memo(MedicineComp);

// -----------------------  Custom component for AutoComplete Search Box
const AutoCompleteCom = React.memo(
    ({ data, inputref, placeholder, showKey, listName }) => {
        return (
            <div style={{ width: '100%' }}>
                <input
                    list={listName}
                    placeholder={placeholder}
                    style={styles.inputField}
                    type="text"
                    ref={inputref}
                    onFocus={(e) => {
                        e.target.style.border = '2px solid #2196f3'; // change border color on focus
                    }}
                    onBlur={(e) => {
                        e.target.style.border = '1px solid #bfc0c2'; // revert border color on blur
                    }}
                />
                <datalist id={listName}>
                    {data &&
                        data.map((item, index) => (
                            <option
                                key={index}
                                value={`${item[showKey[0]]} ${listName == 'medicine' ? `- ${item[showKey[1]]} - ${item[showKey[2]]}` : ''}`}
                            />
                        ))}
                </datalist>
            </div>
        );
    },
    (prevProps, nextProps) => {
        // Compare relevant props, excluding data
        return prevProps.listName === nextProps.listName;
    }
);

// -----------------------  Prescription Card
const PrecriptionCard = React.memo(({ prescription, btnTitle, onClick }) => {
    const { prescriptionId, medicineName, medicineGenericName, frequency, dose, doseUnit, direction, duration, dosageInstruction } =
        prescription;

    return (
        <Box sx={styles.prescriptionCardContainer}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography sx={{ fontWeight: '600' }}>
                    {prescriptionId}: {medicineName} - {medicineGenericName}
                </Typography>

                {/* ------------------------- Button */}
                <Button
                    size="small"
                    sx={{ p: 0, color: btnTitle == 'Modify' ? COLORS.secondory : 'red' }}
                    variant="standard"
                    onClick={onClick}
                >
                    {btnTitle}
                </Button>
            </Box>

            <Typography>
                {frequency} | {dose} {doseUnit} {direction} {duration}
            </Typography>

            {dosageInstruction && <Typography>{dosageInstruction}</Typography>}
        </Box>
    );
});

// -----------------------  Styling Sheet
const styles = {
    inputField: {
        padding: 10,
        borderRadius: 10,
        border: '1px solid #bfc0c2',
        fontSize: 14,
        width: '100%',
        outline: 'none',
        backgroundColor: '#f8fafc'
    },
    loadingBox: {
        display: 'flex',
        flex: 1,
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    addBtn: {
        backgroundColor: '#fff',
        '&:hover': {
            backgroundColor: '#d1e7ff'
        }
    },
    prescriptionCardContainer: {
        p: 0.5,
        m: 0.5,
        borderRadius: 2,
        backgroundColor: '#eef2f6'
    },
    accordianTitle: { fontSize: 16, fontWeight: 600 },
    scrollBarStyle: {
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: '0.2em'
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            borderRadius: 10
        }
    }
};
