import { IconButton, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import { useSelector } from 'react-redux';

function FilterPrescription({ medicineData, gptPrescription: pres, visit, filtered, setFiltered }) {
    const { user } = useSelector((state) => state?.user);
    const [gptPrescription, setGPTPrescription] = useState(pres);
    const [selected, setSelected] = useState({});

    const [selectEdit, setSelectedEdit] = useState(null);
    const [editValue, setEditValue] = useState(null);

    const savePres = () => {
        let arr = gptPrescription.find((i) => i?.name == selectEdit);
        arr.name = editValue;
        setGPTPrescription(gptPrescription);
    };

    const addPrescription = (item) => {
        const signlePres = {
            visitID: visit?.id,
            consultant_NationalID: visit?.consultant_NationalID,
            patient_NationalID: visit?.patient_NationalID,
            medicineID: item?.medicineID,
            refilDetails: null,
            dose: selected?.dose == 'Not specified' ? null : selected?.dose || null,
            doseUnit: selected?.doseUnit == 'Not specified' ? null : selected?.doseUnit || null,
            route: selected?.route == 'Not specified' ? null : selected?.route || null,
            direction: selected?.direction == 'Not specified' ? null : selected?.direction || null,
            duration: selected?.duration == 'Not specified' ? null : selected?.duration || null,
            frequency: selected?.frequency == 'Not specified' ? null : selected?.frequency || null,
            durationUnit: selected?.doseUnit == 'Not specified' ? null : selected?.doseUnit || null,
            isDeleted: false,
            status: 'Continued',
            dosageInstruction: selected?.instruction == 'Not specified' ? null : selected?.instruction || null,
            createdOn: new Date().toLocaleString(),
            createdBy: user.userId,
            medicineName: item?.name,
            medicineGenericName: item?.genericName,
            ename: selected?.name
        };

        setFiltered((pre) => [...pre, signlePres]);
        setSelected({});
    };

    const deletePrescription = (item) => {
        setFiltered((pre) => pre?.filter((i) => i?.medicineID !== item?.medicineID));
        setSelected({});
    };

    return (
        <div style={{ display: 'flex', flex: 1, backgroundColor: '#f5f5f5', marginTop: 15, borderRadius: 10, padding: 10, gap: 10 }}>
            <Box width={'50%'}>
                {gptPrescription
                    ?.filter((item1) => !filtered.some((item2) => item1.name?.includes(item2.ename)))
                    ?.map((item) => (
                        <Box display={'flex'} alignItems={'center'}>
                            {selectEdit === item?.name ? (
                                <TextField
                                    value={editValue}
                                    onChange={(e) => setEditValue(e?.target?.value)}
                                    fullWidth
                                    size="small"
                                    inputProps={{ style: { padding: 8 } }}
                                />
                            ) : (
                                <Box
                                    sx={{
                                        p: 1,
                                        cursor: 'pointer',
                                        bgcolor: item?.name == selected?.name ? '#d5d5d5' : 'none',
                                        borderRadius: 1,
                                        flex: 1
                                    }}
                                    onClick={() => {
                                        setSelected(item);
                                        setSelectedEdit(null);
                                    }}
                                >
                                    <Typography>{item?.name}</Typography>
                                </Box>
                            )}

                            {item?.name === selected?.name && (
                                <IconButton
                                    onClick={() => {
                                        if (selectEdit) {
                                            setSelectedEdit(null);
                                            savePres();
                                        } else {
                                            setSelectedEdit(item?.name);
                                            setEditValue(item?.name);
                                        }
                                    }}
                                    size="small"
                                >
                                    {selectEdit ? <CheckIcon fontSize="10" /> : <EditIcon fontSize="10" />}
                                </IconButton>
                            )}
                        </Box>
                    ))}
            </Box>
            <Box width={'50%'}>
                {medicineData?.map((item) => {
                    if (item?.name?.toLowerCase()?.includes(selected?.name?.toLowerCase()?.split(' ')[0]))
                        return (
                            <Box p={0.5} display={'flex'} alignItems={'center'}>
                                <Typography flex={1}>{item?.name}</Typography>
                                <IconButton onClick={() => addPrescription(item)} size="small">
                                    <AddIcon fontSize="10" />
                                </IconButton>
                            </Box>
                        );
                })}
            </Box>
            <Box width={'50%'}>
                {filtered?.map((item) => (
                    <Box p={0.5} my={0.3} bgcolor={'#e5e5e5'} borderRadius={1}>
                        <Box display={'flex'} alignItems={'center'}>
                            <Typography flex={1}>{item?.medicineName}</Typography>
                            <IconButton onClick={() => deletePrescription(item)} size="small">
                                <DeleteIcon sx={{ color: 'red' }} fontSize="10" />
                            </IconButton>
                        </Box>

                        {item?.duration && (
                            <Typography fontSize={12}>
                                <b>Duration:</b> {item?.duration}
                            </Typography>
                        )}
                        {item?.dose && (
                            <Typography fontSize={12}>
                                <b>Dose:</b> {item?.dose} {item?.doseUnit}
                            </Typography>
                        )}
                        {item?.frequency && (
                            <Typography fontSize={12}>
                                <b>Frequency:</b> {item?.frequency}
                            </Typography>
                        )}
                        {item?.direction && (
                            <Typography fontSize={12}>
                                <b>Direction:</b> {item?.direction}
                            </Typography>
                        )}
                        {item?.route && (
                            <Typography fontSize={12}>
                                <b>Route:</b> {item?.route}
                            </Typography>
                        )}
                        {item?.dosageInstruction && (
                            <Typography fontSize={12}>
                                <b>Instruction:</b> {item?.dosageInstruction}
                            </Typography>
                        )}
                    </Box>
                ))}
            </Box>
        </div>
    );
}

export default FilterPrescription;
