import axios from 'axios';
import { BASE_URL } from 'constants/baseUrl';

const instance = axios.create({
    baseURL: `${BASE_URL}`,
    headers: {
        'Content-Type': 'application/json'
    }
});

// Add an interceptor to include the authorization token in the headers
instance.interceptors.request.use(
    async (config) => {
        // Retrieve the token from AsyncStorage
        const userData = JSON.parse(localStorage.getItem('user'));
        config.headers.Authorization = `Bearer ${userData?.token}`;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Custom function to wrap around Axios methods
const request = (method, url, data) => {
    return instance[method](url, data);
};

// Export the wrapped function
export const apiRequest = request;
