import React, { useState, useEffect, useContext } from 'react';

import { Form, Formik } from 'formik';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
// -----------------  Importing Icons

// -----------------  Importing Constants
import { COLORS } from 'constants/colors';
import { BASE_URL } from 'constants/baseUrl';

// -----------------  Utilis
import * as XLSX from 'xlsx';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import axios, { all } from 'axios';
import useFetch from 'hooks/useFetch';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import ModalCustom from 'ui-component/modals/ModalCustom';
import { toast } from 'react-toastify';
import CloseIcon from '@mui/icons-material/Close';
import 'react-toastify/dist/ReactToastify.css';
import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    Grid,
    IconButton,
    TextField,
    Typography,
    Paper,
    InputLabel,
    Select,
    MenuItem,
    Checkbox
} from '@mui/material';
import physicianSchedule from 'assets/sampleFiles/PhysicianSchedule.xlsx';

import SquareIcon from '@mui/icons-material/Square';
const localizer = momentLocalizer(moment);

import { createSlots, getVisitPhysician, transformToCalendarJSON } from 'utils/visits';
import TranslationContext from 'context/translation';
import { fetchLocationData } from 'store/slices/locationSlice';
import { fetchPatientList } from 'store/slices/patientListSlice';
import { fetchPhysicianList } from 'store/slices/physicianListSlice';
import CRUDApointmentModal from 'components/appointment/CRUDApointmentModal';
import { apiRequest } from 'utils/api';

var allVisit = [];

export default function Scheduler() {
    const { user } = useSelector((state) => state?.user);
    let { config } = useSelector((state) => state?.config);
    const { translate } = useContext(TranslationContext);

    const dispatch = useDispatch();
    const { data: locationList } = useSelector((state) => state.location);
    const { data: patientDataList } = useSelector((state) => state.patientList);
    const { data: physicianDataList } = useSelector((state) => state.physicianList);

    const [visitList, setVisitList] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [loadVisit, setLoadVisit] = useState(false);

    const [visitPhysician, setVisitPhysician] = useState([]);
    const [selectedVisitPhysician, setSelectedVisitPhysician] = useState(null);

    const [selectedPhysician, setSelectedPhysician] = useState(null);

    const [excelData, setExcelData] = useState([]);
    const [modal, setModal] = useState({ open: false, type: 'add', data: null });
    const [openAddSlots, setOpenAddSlots] = useState(false);
    const [duration, setDuration] = useState(15);

    const getVisitList = async () => {
        setError(null);
        try {
            const response = await apiRequest(
                'get',
                `${BASE_URL}api/visit/VacantSlotVisitLists/${user?.userId}?pageNumber=1&pageSize=100&QuerySearch=`
            );

            let requiredJSON = await transformToCalendarJSON(response?.data?.items);
            const physicianVisit = await getVisitPhysician(requiredJSON);
            setVisitList(requiredJSON);
            setVisitPhysician(physicianVisit);
            return requiredJSON;
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        !locationList && dispatch(fetchLocationData());
        !patientDataList && dispatch(fetchPatientList());
        !physicianDataList && dispatch(fetchPhysicianList());

        getVisitList();
    }, []);

    const addSlots = async () => {
        setLoadVisit(true);
        if (excelData.length === 0) {
            setError('Please upload a file.');
            return;
        }
        try {
            let allSlots = await createSlots(excelData, duration);

            for (let visit of allSlots) {
                await createTimeSlots(visit?.startDateTime, visit?.endDateTime);
            }
            getVisitList();
        } catch (error) {
            console.log(error);
        } finally {
            setOpenAddSlots(false);
            setSelectedPhysician(null);
            setLoadVisit(false);
        }
    };

    const createTimeSlots = async (startDateTime, endDateTime) => {
        const data = {
            title: 'Vacant Slot',
            summaryNotes: 'Vacant Slot',
            Patient_NationalID: null,
            Consultant_NationalID: selectedPhysician,
            StartDateTime: moment(startDateTime).format(),
            EndDateTime: moment(endDateTime).format(),
            meetingType: 'Virtual',
            isConfirm: false,
            isActive: false,
            status: 0,
            meetinglink: null,
            locationID: user?.roles == 'Receptionist' ? user?.locationID : locationList[0]?.locationID,
            createdOn: new Date(),
            createdBy: user?.userId
        };

        const response = await axios({
            method: 'post',
            url: `${BASE_URL}api/visit`,
            data,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user?.token}`
            }
        });

        return response;
    };

    const readExcel = (file) => {
        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);
            fileReader.onload = (e) => {
                const bufferArray = e.target.result;
                const wb = XLSX.read(bufferArray, {
                    type: 'buffer',
                    cellDates: true
                });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                const data = XLSX.utils.sheet_to_json(ws);
                resolve(data);
                console.log(data);
                setExcelData(data);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const eventStyleGetter = (event, start, end, isSelected) => {
        var backgroundColor = event.hexColor;
        var style = {
            backgroundColor: backgroundColor,
            borderRadius: '0px',
            opacity: 0.8,
            color: 'white',
            border: '0px',
            display: 'block'
        };
        return {
            style: style
        };
    };

    return (
        <div>
            <Grid mb={2} container direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="h3" color={COLORS.color1} sx={{ py: 1 }}>
                    {translate('ADD_EDIT_SLOTS')}
                </Typography>

                <Grid item>
                    <Button color="primary" onClick={() => setOpenAddSlots(true)}>
                        {translate('ADD_APPOINTMENT_SLOTS')}
                    </Button>
                </Grid>
            </Grid>

            <Box sx={{ paddingBottom: 1 }}>
                {visitPhysician?.length > 0 &&
                    [{ name: 'All', color: '#fff', nationalID: null }, ...visitPhysician]?.map((item) => (
                        <Button
                            sx={{ marginRight: 1 }}
                            size="small"
                            variant={selectedVisitPhysician == item?.nationalID ? 'contained' : ''}
                            startIcon={<SquareIcon sx={{ color: `${item?.colorCode}` }} />}
                            onClick={() => setSelectedVisitPhysician(item?.nationalID)}
                        >
                            {item?.name}
                        </Button>
                    ))}
            </Box>

            {!!visitList && (
                <Paper sx={{ width: '100%', p: 1, mb: -1 }}>
                    <Calendar
                        selectable
                        toolbar
                        step={15}
                        timeslots={2}
                        dayLayoutAlgorithm={'no-overlap'}
                        scrollToTime={new Date()}
                        defaultView="day"
                        views={['month', 'week', 'day']}
                        events={
                            selectedVisitPhysician
                                ? visitList?.filter((i) => i?.consultant_NationalID == selectedVisitPhysician)
                                : visitList
                        }
                        localizer={localizer}
                        startAccessor="start"
                        endAccessor="end"
                        onSelectSlot={(e) => {
                            if (new Date(e.start) > new Date()) {
                                setModal({ open: true, type: 'add', data: e, show: true });
                            }
                        }}
                        onSelectEvent={(e) => {
                            if (new Date(e.start) > new Date()) {
                                setModal({ open: true, type: 'update', data: e, show: true });
                            } else {
                                setModal({ open: true, type: 'update', data: e, show: false });
                            }
                        }}
                        style={{ backgroundColor: '#ffffff', height: '73.5vh' }}
                        eventPropGetter={eventStyleGetter}
                    />
                </Paper>
            )}

            {loading && (
                <Grid sx={styles.loadingContainer}>
                    <CircularProgress size={35} color="inherit" />
                </Grid>
            )}

            {!!error && (
                <Grid sx={styles.loadingContainer}>
                    <Typography>{error?.response?.data?.message ?? error?.response?.data ?? error?.message}</Typography>
                </Grid>
            )}
            <ModalCustom open={openAddSlots} title={translate('ADD_SLOTS')}>
                <Box>
                    <IconButton color="inherit" onClick={() => setOpenAddSlots(false)} sx={{ position: 'absolute', top: 10, right: 10 }}>
                        <CloseIcon />
                    </IconButton>

                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <Autocomplete
                            fullWidth
                            size="small"
                            options={physicianDataList ?? []}
                            getOptionLabel={(physician) => `${physician?.name}  ${physician?.phone} - ${physician?.speciality}`}
                            value={physicianDataList?.find((i) => i?.nationalID == selectedPhysician)}
                            onChange={(event, selected) => {
                                setSelectedPhysician(selected?.nationalID || null);
                            }}
                            renderInput={(params) => <TextField {...params} label={translate('PHYSICIAN')} variant="standard" />}
                        />

                        <FormControl size="small" variant="standard" sx={{ width: 100 }}>
                            <InputLabel id="demo-simple-select-label">{translate('DURATION')}</InputLabel>
                            <Select value={duration} label={translate('DURATION')} onChange={(e) => setDuration(e?.target?.value)}>
                                {[5, 10, 15, 20, 30]?.map((i) => (
                                    <MenuItem value={i}>{i}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>

                    <input
                        type="file"
                        id="xls_input"
                        className="form-control py-1 px-1"
                        onChange={(e) => readExcel(e.target.files[0])}
                        style={{ marginTop: 10 }}
                    />

                    {loadVisit ? (
                        <CircularProgress size={25} color="inherit" sx={{ mt: 2, ml: 20 }} />
                    ) : (
                        <>
                            <Button type="submit" variant="text" sx={{ color: COLORS.secondory }} href={physicianSchedule} download>
                                {translate('DOWNLOAD_SAMPLE')}
                            </Button>
                            <Button type="submit" variant="text" sx={{ color: COLORS.secondory }} onClick={() => addSlots()}>
                                {translate('SAVE')}
                            </Button>
                        </>
                    )}
                </Box>
            </ModalCustom>
            {/* ========================  Modal to Add Slot and Edit Slot  */}
            <CRUDApointmentModal
                modal={modal}
                patientDataList={patientDataList}
                physicianDataList={physicianDataList}
                locationList={locationList}
                onSuccess={async (type, visit) => {
                    getVisitList();
                    if (type == 'add' || 'update') {
                        setModal({ open: false, type: null, data: null });
                    } else {
                        setModal({ open: false, type: null, data: null });
                    }
                }}
                onClose={() => {
                    setModal({ open: false, type: null, data: null });
                }}
            />
        </div>
    );
}

const styles = {
    loadingContainer: {
        height: '70vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    btnContainer: {
        display: 'flex',
        direction: 'row',
        gap: 1,
        justifyContent: 'flex-end'
    }
};
