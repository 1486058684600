import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiRequest } from 'utils/api';

const initialState = {
    data: null,
    isLoading: true,
    error: null
};

export const fetchLocationData = createAsyncThunk('data/fetchLocationData', async () => {
    try {
        const response = await apiRequest('get', 'api/location');
        return response;
    } catch (error) {
        throw error?.response?.data?.message || error?.response?.data || error?.message;
    }
});

const locationSlice = createSlice({
    name: 'location',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchLocationData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchLocationData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = null;
                state.data = action.payload?.data;
            })
            .addCase(fetchLocationData.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error;
            });
    }
});

export default locationSlice.reducer;
