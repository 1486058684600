import { BASE_URL } from 'constants/baseUrl';
import React from 'react';
import { useSelector } from 'react-redux';

export default function PrintHeader() {
    const { user } = useSelector((state) => state?.user);

    return (
        <div style={styles.header}>
            {/* -------------------------------  Main Header  ------------------------------- */}
            <img src={`${BASE_URL}images/${user?.domainLogo}`} alt="logo" style={styles.logo} />

            <div></div>
        </div>
    );
}

const styles = {
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 7
    },
    logo: {
        height: 67
    }
};
