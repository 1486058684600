import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, CircularProgress, Grid, IconButton, Typography } from '@mui/material';
import axios from 'axios';

import { COLORS } from 'constants/colors';
import ModalCustom from 'ui-component/modals/ModalCustom';
import CloseIcon from '@mui/icons-material/Close';
import { BASE_URL } from 'constants/baseUrl';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment';
// ==============================|| LabReports ||============================== //

const LabReports = (props) => {
    const { user } = useSelector((state) => state?.user);
    const [reports, setReports] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedImage, setSelectedImage] = useState({ open: false, image: null });

    const getPatientLabReports = async () => {
        try {
            setLoading(true);
            setError(null);
            setReports(null);

            const res = await axios({
                method: 'get',
                url: `${BASE_URL}api/LabTest/patientLabtests/${user.nationalID}`,
                headers: {
                    Authorization: `Bearer ${user?.token}`
                }
            });

            if (res?.data) {
                const mergedData = res?.data.reduce((acc, item) => {
                    const existingItem = acc.find((obj) => obj.type === item.type);

                    if (existingItem) {
                        existingItem.list.push(item);
                    } else {
                        acc.push({
                            type: item.type,
                            list: [item]
                        });
                    }

                    return acc;
                }, []);
                setReports(mergedData);
            }
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getPatientLabReports();

        return () => {
            setLoading(true);
            setError(null);
            setReports(null);
        };
    }, []);

    return (
        <div>
            <Grid mb={2} container direction="row" justifyContent="space-between" alignItems="center" sx={{ py: 1 }}>
                <Typography variant="h3" color={COLORS.color1}>
                    Lab Reports
                </Typography>
            </Grid>

            {!!reports &&
                reports?.map((item) => (
                    <Accordion defaultExpanded={true} key={item?.type}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography sx={styles.accordianTitle}>{item?.type}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                                {item?.list.map((report) => (
                                    <Box
                                        key={report?.imagePath}
                                        onClick={() => setSelectedImage({ open: true, image: report?.imagePath, domain: report?.domain })}
                                        sx={styles.imageBox}
                                    >
                                        <img
                                            src={`${BASE_URL}media/${report?.domain}/Documents/${report?.imagePath}`}
                                            alt={'lab report'}
                                            style={{
                                                width: 80,
                                                height: 60,
                                                backgroundColor: '#d5d5d5',
                                                display: 'inline-block'
                                            }}
                                        />

                                        <Typography variant="h6">{report?.domain?.toUpperCase()}</Typography>
                                        <Typography variant="h6">Visit ID: {report?.visitId}</Typography>
                                        <Typography variant="h6">{moment(report?.createdOn).format('MMM DD, YYYY')}</Typography>
                                    </Box>
                                ))}
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                ))}

            {loading && (
                <Box sx={styles.loadingContainer}>
                    <CircularProgress size={35} sx={{ color: '#000' }} />
                </Box>
            )}
            {!!error && (
                <Typography sx={{ ...styles.loadingContainer, py: 1 }}>
                    {error?.response?.data?.message || error?.response?.data || error?.message}
                </Typography>
            )}
            <ModalCustom open={selectedImage?.open}>
                <IconButton
                    color="inherit"
                    onClick={() => setSelectedImage({ open: false, image: null })}
                    sx={{ position: 'absolute', top: 8, right: 5, backgroundColor: '#fff' }}
                >
                    <CloseIcon />
                </IconButton>
                <Box sx={{ ml: -2.1, mr: -2.7, mb: -1.7, mt: -3.2 }}>
                    <img
                        src={`${BASE_URL}media/${selectedImage?.domain}/Documents/${selectedImage?.image}`}
                        alt={'select report'}
                        width={'100%'}
                    />
                </Box>
            </ModalCustom>
        </div>
    );
};

export default LabReports;
const styles = {
    loadingContainer: {
        height: 500,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    accordianTitle: { fontSize: 16, fontWeight: 600 },
    imageBox: {
        border: '1px solid #d5d5d5',
        borderRadius: 0.7,
        overFlow: 'hidden',
        padding: 0.5,
        width: 90
    }
};
