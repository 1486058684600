import React from 'react';

import './print.css';
import { BASE_URL } from 'constants/baseUrl';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Typography } from '@mui/material';
import jwt_decode from 'jwt-decode';

const PrintRXForm = React.forwardRef((props, ref) => {
    const { user } = useSelector((state) => state.user);
    const { visit, location } = props;
    var decoded = jwt_decode(user?.token);

    let docDeg = visit?.consultantDegree ?? visit?.doctorDegree;

    return (
        <div ref={ref} style={styles.container}>
            <div style={styles.header}>
                {/* -------------------------------  Main Header  ------------------------------- */}
                <div style={styles.docInfo}>
                    <p style={styles.docName}>
                        {visit?.consultantName?.split('/')[0] ?? visit?.physicianName?.split('/')[0] ?? visit?.doctorName?.split('/')[0]}
                    </p>
                    {docDeg?.split(',')?.map((item, index) => (
                        <p key={`${item}-${index}`} style={styles.docEdu}>
                            {item}
                        </p>
                    ))}

                    <p style={{ ...styles.docEdu, textTransform: 'capitalize' }}>{visit?.doctorSpeciality?.toLowerCase()}</p>
                </div>

                <div>
                    <Typography sx={{ fontSize: '30px', fontWeight: 'bold' }}>{location?.name}</Typography>
                    <Typography>{location?.address}</Typography>
                </div>

                <img src={`${BASE_URL}images/${user?.domainLogo}`} alt="car" style={styles.logo} />
            </div>

            {/* ------------------------------------  RX  ------------------------------------ */}
            <div>
                <div style={styles.line}></div>
                <p style={styles.rx}>Rx</p>
            </div>

            {/* ------------------------------------  Patient Info  ----------------------------- */}
            <div style={styles.patientInfoCont}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <NameComp title={'Visit ID:'} sub={visit?.id} />
                    <NameComp title={'Date:'} sub={moment(visit?.startDateTime).format('MMM DD, YYYY - hh:mm A')} />
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: 13
                    }}
                >
                    <NameComp title={'P. Name:'} sub={`${visit?.patientFirstName} ${visit?.patientLastName}`} />
                    <NameComp title={'Age:'} sub={visit?.patientAge} />
                    <NameComp title={'Gender:'} sub={visit?.patientGender} />
                </div>
            </div>

            {/* ----------------------------------  Main Body  ---------------------------------- */}
            <div style={styles.body}>
                <div style={styles.bodyLeft}>
                    {/* ----------------------------  Vital Signs */}
                    <div>
                        <p style={styles.heading}>Patient Vitals</p>
                        <VitalSignTextComp title={'Weight'} sub={`kg`} />
                        <VitalSignTextComp title={'Height'} sub={''} />
                        <VitalSignTextComp title={'Temp'} sub={`°F`} />
                        <VitalSignTextComp title={'SPO2'} sub={` %`} />
                        <VitalSignTextComp title={'Systolic'} sub={''} />
                        <VitalSignTextComp title={'Diastolic '} sub={''} />
                        <VitalSignTextComp title={'Pulse Rate'} sub={`/min`} />
                        <VitalSignTextComp title={'BSR'} sub={``} />
                    </div>

                    {/* ----------------------------  Diagnosis */}

                    <div style={{ marginTop: 30, paddingRight: 10 }}>
                        <p style={styles.heading}>Diagnosis</p>
                    </div>

                    {/* ----------------------------  Lab Test */}
                    <div style={{ marginTop: 30, paddingRight: 10 }}>
                        <p style={styles.heading}>Lab Test</p>
                    </div>
                </div>

                <div style={styles.bodyRight}>
                    <div style={{ paddingLeft: 20 }}>
                        {/* ---------------------------- Prescription */}
                        <p style={styles.heading}>Prescription</p>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default PrintRXForm;

const NameComp = ({ title, sub }) => {
    return (
        <div style={{ display: 'flex' }}>
            <p style={styles.titleHead}>{title}</p>
            <p style={styles.sub}>{sub}</p>
        </div>
    );
};

const VitalSignTextComp = ({ title, sub }) => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                paddingRight: 15,
                marginTop: 7
            }}
        >
            <p style={styles.sub}>{title}</p>
            <p style={styles.sub}>{sub}</p>
        </div>
    );
};

const styles = {
    container: {
        margin: 50,
        display: 'flex',
        flexDirection: 'column',
        height: 1030
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 7
    },
    logo: {
        height: 85
    },
    docInfo: {
        width: 250,
        marginBottom: 0
    },
    docName: {
        fontFamily: 'Cambria',
        fontWeight: '600',
        fontSize: 23,
        marginBottom: 7,
        color: '#1d97d4'
    },
    docEdu: {
        fontFamily: 'Cambria',
        fontSize: 15,
        color: '#1f1f1f'
    },
    line: {
        height: 8,
        backgroundColor: '#1d97d4',
        width: '100%',
        position: 'relative',
        bottom: -20
    },
    rx: {
        fontSize: 32,
        color: '#000',
        fontFamily: 'Cambria',
        fontWeight: 600,
        backgroundColor: '#fff',
        width: 70,
        height: 20,
        textAlign: 'center',
        marginLeft: 15,
        position: 'relative'
    },
    heading: {
        fontFamily: 'Cambria',
        marginBottom: 15,
        fontSize: 18,
        fontWeight: '600',
        color: '#000'
    },
    titleHead: {
        fontFamily: 'Cambria',
        fontSize: 15,
        fontWeight: 600,
        width: 100,
        color: '#1f1f1f'
    },
    patientInfoCont: {
        border: '3px solid #000',
        padding: 10,
        marginTop: 15
    },
    sub: {
        fontFamily: 'Cambria',
        fontSize: 15,
        color: '#1f1f1f'
    },
    body: {
        display: 'flex',
        flex: 1,
        marginTop: 15
    },
    bodyLeft: {
        borderRight: '2px solid #000',
        // height: "95vh",
        width: '30%'
    },
    bodyRight: { width: '70%' },
    prescriptionCardCont: { display: 'flex', flexWrap: 'wrap', marginBottom: 20 }
};
