// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Box, CircularProgress, Grid, List, ListItem, ListItemText, Modal, Typography } from '@mui/material';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { saveProfileData } from 'store/slices/profileSlice';
import { saveUserData } from 'store/slices/userSlice';
import { switchPatientDomain, switchPhysicianDomain } from 'services/domainSwitching';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState } from 'react';
import ModalCustom from 'ui-component/modals/ModalCustom';
import axios from 'axios';
import { BASE_URL } from 'constants/baseUrl';

// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 16,
    '&:hover': {
        background: theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const NotificationList = (props) => {
    const theme = useTheme();

    return (
        <List
            sx={{
                width: '100%',
                maxWidth: 330,
                py: 0,
                borderRadius: '10px',
                [theme.breakpoints.down('md')]: {
                    maxWidth: 300
                },
                '& .MuiListItemSecondaryAction-root': {
                    top: 22
                },
                '& .MuiDivider-root': {
                    my: 0
                },
                '& .list-container': {
                    pl: 7
                }
            }}
        >
            <NotificationCard notifications={props.notifications} />
        </List>
    );
};

export default NotificationList;

const NotificationCard = (props) => {
    const { user } = useSelector((state) => state?.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [loadSwitchDomain, setLoadSwitchDomain] = useState({ load: false, domain: null });

    const readNotification = async (notificationId) => {
        const res = await axios({
            method: 'put',
            url: `${BASE_URL}api/notification/readNotification/${notificationId}`,
            headers: {
                Authorization: `Bearer ${user?.token}`
            }
        });
        if (res?.data) {
            props.getNotifications();
        }
        return res;
    };

    const onClickNotification = async (selected) => {
        readNotification(selected?.notificationId);
        if (user?.domain == selected?.domain && user?.roles == 'Patient') {
            navigate('/appointment-list', { replace: true });
        }
        if (user?.domain != selected?.domain || user?.roles != selected?.role) {
            setLoadSwitchDomain({ load: true, domain: selected?.domain });
            try {
                let userToSave, resProfile;
                if (selected?.role == 'Physician') {
                    ({ userToSave, resProfile } = await switchPhysicianDomain(user?.email, user?.key, selected?.domain));
                }
                if (selected?.role == 'Patient') {
                    ({ userToSave, resProfile } = await switchPatientDomain(user?.email, user?.key, selected?.domain, selected?.otherId));
                }

                localStorage.setItem('user', JSON.stringify(userToSave));
                dispatch(saveUserData(userToSave));
                dispatch(saveProfileData(resProfile?.data));
                // selected?.role == 'Physician'
                // ? navigate('/dashboard', { replace: true })
                // : navigate('/appointment-list', { replace: true });
            } catch (error) {
                return toast.error(error?.response?.data?.message ?? error?.response?.data ?? error?.message);
            } finally {
                setLoadSwitchDomain({ load: false, domain: null });
            }
        }
    };

    return (
        <>
            {props.notifications?.map((item, index) => (
                <ListItemWrapper
                    onClick={() => {
                        if (user?.roles == 'Patient' || user?.roles == 'Physician') {
                            onClickNotification(item);
                        }
                    }}
                    key={item?.notificationId}
                    sx={{ backgroundColor: item.status == 'Read' ? 'white' : '#AFDBF5' }}
                >
                    <ListItem alignItems="center">
                        <ListItemText>
                            <Grid item xs={12}>
                                {' '}
                                <Typography variant="h4"> {item?.recordType}</Typography>
                            </Grid>
                        </ListItemText>
                        <ListItemText>
                            <Grid container justifyContent="flex-end">
                                <Grid item xs={12}>
                                    <Typography sx={{ color: '#1565c0' }} variant="h6">
                                        {moment(item?.createdOn).format('MMM DD, YYYY  -  hh:mm A')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </ListItemText>
                    </ListItem>
                    <Grid container direction="column">
                        <Grid item xs={12}>
                            <Typography variant="h5">{item.notificationtext}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="column">
                        <Grid item xs={12}>
                            <Typography sx={{ color: '#1565c0' }} variant="h6">
                                {item?.domain?.toUpperCase()}
                            </Typography>
                        </Grid>
                    </Grid>
                </ListItemWrapper>
            ))}

            <ModalCustom open={loadSwitchDomain?.load} title={'Switching...'}>
                <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress size={30} color={'inherit'} />
                </Box>
            </ModalCustom>
        </>
    );
};
