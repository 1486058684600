import { useState, useRef, useEffect } from 'react';

const useAudioRecorder = () => {
    const [isRecording, setIsRecording] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [audioChunks, setAudioChunks] = useState([]);
    const [recordedAudio, setRecordedAudio] = useState(null);
    const [recordingTime, setRecordingTime] = useState(0);
    const mediaRecorderRef = useRef(null);
    const audioRef = useRef(new Audio());
    const timerIntervalRef = useRef(null);
    const [downloadLink, setDownloadLink] = useState(null);

    useEffect(() => {
        return () => {
            cancelRecording();
            clearInterval(timerIntervalRef.current);
        };
    }, []);

    useEffect(() => {
        if (isPlaying) {
            audioRef.current.src = URL.createObjectURL(recordedAudio);
            audioRef.current
                .play()
                .then(() => {
                    setIsPlaying(false); // Set isPlaying to false when playback completes
                })
                .catch((error) => console.error('Error playing audio:', error));
        } else {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
        }
    }, [isPlaying, recordedAudio]);

    const startRecording = () => {
        navigator.mediaDevices
            .getUserMedia({ audio: true })
            .then((stream) => {
                const mediaRecorder = new MediaRecorder(stream);

                mediaRecorder.ondataavailable = (e) => {
                    if (e.data.size > 0) {
                        setAudioChunks((prev) => [...prev, e.data]);
                    }
                };

                // Update recording time every second
                timerIntervalRef.current = setInterval(() => {
                    setRecordingTime((prev) => prev + 1);
                }, 1000);

                mediaRecorder.onstop = () => {
                    const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
                    setRecordedAudio(audioBlob);
                    console.log('audioChunks ', audioChunks);
                    setIsRecording(false);
                    setRecordingTime(0);
                    clearInterval(timerIntervalRef.current);
                };

                mediaRecorderRef.current = mediaRecorder;
                mediaRecorder.start(1000);
                setIsRecording(true);
            })
            .catch((error) => console.error('Error accessing microphone:', error));
    };

    const pauseRecording = () => {
        setIsRecording(false);
        if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {
            mediaRecorderRef.current.pause();
            clearInterval(timerIntervalRef.current);
        }
    };

    const resumeRecording = () => {
        setIsRecording(true);
        if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'paused') {
            mediaRecorderRef.current.resume();
            // Resume the timer
            timerIntervalRef.current = setInterval(() => {
                setRecordingTime((prev) => prev + 1);
            }, 1000);
        }
    };

    const stopRecording = () => {
        if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
            mediaRecorderRef.current.stop();
            generateDownloadLink();
        }
    };

    const cancelRecording = () => {
        stopRecording();
        setAudioChunks([]);
        setIsRecording(false);
        setRecordingTime(0);
        setRecordedAudio(null);
    };

    const saveRecording = () => {
        // Implement your logic to save the recorded audio (recordedAudio blob)
        console.log('Audio Saved');
    };

    const playRecordedAudio = () => {
        setIsPlaying(true);
    };

    const generateDownloadLink = () => {
        if (recordedAudio) {
            const url = URL.createObjectURL(recordedAudio);
            setDownloadLink(url);
        }
    };

    const formatTime = (timeInSeconds) => {
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = timeInSeconds % 60;
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    return {
        isRecording,
        audioChunks,
        recordingTime: formatTime(recordingTime),
        startRecording,
        pauseRecording,
        resumeRecording,
        stopRecording,
        cancelRecording,
        saveRecording,
        isPlaying,
        playRecordedAudio,
        downloadLink,
        audioRef
    };
};

export default useAudioRecorder;
