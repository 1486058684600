import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { COLORS } from 'constants/colors';
import React from 'react';
import { profileImage } from 'utils/fetchImage';
import PrintHeader from 'views/components/PrintHeader';

const PrintPatientList = React.forwardRef((props, ref) => {
    const { patientList } = props;
    return (
        <div ref={ref} style={{ padding: 50 }}>
            <PrintHeader />

            <TableContainer sx={{ maxHeight: 'auto' }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow
                            sx={{
                                '& th': {
                                    color: '#fff',
                                    backgroundColor: COLORS.secondory
                                }
                            }}
                        >
                            <TableCell align={'left'}>Profile Picture</TableCell>

                            <TableCell align={'left'}>Name</TableCell>

                            <TableCell align={'left'}>NIC</TableCell>

                            <TableCell align={'left'}>Mobile</TableCell>

                            <TableCell align={'left'}>Location</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!!patientList &&
                            patientList.items.map((row) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.nationalID}>
                                        <TableCell align={'left'} alignItems={'center'}>
                                            <img
                                                alt={'Profile'}
                                                src={profileImage(row?.profileImage)}
                                                style={{
                                                    width: 40,
                                                    height: 40,
                                                    borderRadius: 20,
                                                    marginTop: -20,
                                                    marginBottom: -15,
                                                    position: 'relative'
                                                }}
                                            />
                                        </TableCell>

                                        <TableCell align={'left'} sx={{ textTransform: 'capitalize' }}>
                                            {row?.name?.toLowerCase()} {row?.lastName?.toLowerCase()}
                                        </TableCell>

                                        <TableCell align={'left'}>{row?.identificationNo}</TableCell>

                                        <TableCell align={'left'}>{row?.phone}</TableCell>

                                        <TableCell align={'left'}>{row?.address}</TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
});

export default PrintPatientList;
