import React from 'react';
import useFetch from 'hooks/useFetch';
import { BASE_URL } from 'constants/baseUrl';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
// import LoadingSpinner from './LoadingSpinner';
const LabTest = (props) => {
    return (
        <Grid container columnSpacing={2} sx={{ width: '100%', height: '100%' }}>
            {/* -----------------------------  Button Grid */}

            <MedicineComp
                visitID={props.visitID}
                patient_NationalID={props.patient_NationalID}
                consultant_NationalID={props.consultant_NationalID}
                domain={props?.domain}
            />
        </Grid>
    );
};
export default LabTest;

const MedicineComp = (props) => {
    const {
        data: patientDiagnosis,
        loading: loadingPatientDiagnosis,
        refetch: refetchPatientDiagnosis
    } = useFetch(`${BASE_URL}api/VisitLabTest/GetByVisit/${props.visitID}/${props?.domain || ''}`);

    return (
        <Box sx={{ width: '100%', height: '100%', backgroundColor: '#fff', borderRadius: 3, p: 1 }}>
            {!loadingPatientDiagnosis && (
                <>
                    {patientDiagnosis &&
                        patientDiagnosis?.map((item) => (
                            <Box sx={{ p: 1, m: 1, borderRadius: 3, backgroundColor: '#eef2f6' }}>
                                <Box sx={{ display: 'flex' }}>
                                    <Typography sx={{ fontWeight: '600', flex: 1 }}>
                                        {item?.type}
                                        {': '} {item?.test}
                                    </Typography>
                                </Box>
                            </Box>
                        ))}
                </>
            )}
            {loadingPatientDiagnosis && (
                <Box sx={{ display: 'flex', flex: 1, justifyContent: 'center', alignContent: 'center' }}>
                    <CircularProgress size={35} color="primary" />
                </Box>
            )}
        </Box>
    );
};
