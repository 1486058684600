import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Paper, Accordion, AccordionDetails, AccordionSummary, Box, CircularProgress, Grid, Typography, Divider } from '@mui/material';
import axios from 'axios';

import { COLORS } from 'constants/colors';
import { BASE_URL } from 'constants/baseUrl';
import moment from 'moment';
// ==============================|| Mediciens ||============================== //

const Mediciens = (props) => {
    const { user } = useSelector((state) => state?.user);
    const [prescription, setPrescription] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const getPrescription = async () => {
        try {
            setLoading(true);
            setError(null);
            setPrescription(null);

            const res = await axios({
                method: 'get',
                url: `${BASE_URL}api/prescription/PrescriptionByPatient/${user.nationalID}`,
                headers: {
                    Authorization: `Bearer ${user?.token}`
                }
            });

            setPrescription(res?.data);
        } catch (error) {
            console.log(error);
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getPrescription();
    }, []);

    return (
        <div>
            <Grid mb={2} container direction="row" justifyContent="space-between" alignItems="center" sx={{ py: 1 }}>
                <Typography variant="h3" color={COLORS.color1}>
                    Medicines
                </Typography>
            </Grid>

            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                {prescription && (
                    <>
                        <Accordion defaultExpanded={true}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                <Typography sx={styles.accordianTitle}>Current Medication</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {prescription.map((item, index) => {
                                    if (item?.isDeleted == false) {
                                        return (
                                            <>
                                                <Box
                                                    key={index}
                                                    sx={{
                                                        display: 'flex',
                                                        flexWrap: 'wrap',
                                                        justifyContent: 'space-between',
                                                        borderBottom: '1px solid gray',
                                                        backgroundColor: '#eef2f6',
                                                        p: 1
                                                    }}
                                                >
                                                    <Box>
                                                        <Typography
                                                            sx={{ fontSize: 14, fontWeight: '600' }}
                                                        >{`${item?.medicineName} (${item?.medicineGenericName})`}</Typography>
                                                        <Typography>
                                                            {item?.frequency} {item?.route} {item?.direction} {item?.dose} {item?.doseUnit}{' '}
                                                            {item?.duration}
                                                        </Typography>
                                                        {item?.dosageInstruction && (
                                                            <Typography>Instruction: {item?.dosageInstruction}</Typography>
                                                        )}
                                                    </Box>
                                                    <Box sx={{ width: 320 }}>
                                                        <Typography>
                                                            Precribed by: {item?.physicianName} from {item?.domain?.toUpperCase()}
                                                        </Typography>
                                                        <Typography>
                                                            Precribed on: {moment(item?.createdOn).format('MMM DD, YYYY - hh:mm A')}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                                <Divider />
                                            </>
                                        );
                                    }
                                })}
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                <Typography sx={styles.accordianTitle}>Discountinued Medication</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {prescription.map((item, index) => {
                                    if (item?.isDeleted == true) {
                                        return (
                                            <>
                                                <Box
                                                    key={index}
                                                    sx={{
                                                        display: 'flex',
                                                        flexWrap: 'wrap',
                                                        justifyContent: 'space-between',
                                                        borderBottom: '1px solid gray',
                                                        backgroundColor: '#eef2f6',
                                                        p: 1
                                                    }}
                                                >
                                                    <Box>
                                                        <Typography
                                                            sx={{ fontSize: 14, fontWeight: '600' }}
                                                        >{`${item?.medicineName} (${item?.medicineGenericName})`}</Typography>
                                                        <Typography>
                                                            {item?.frequency} {item?.route} {item?.direction} {item?.dose} {item?.doseUnit}{' '}
                                                            {item?.duration}
                                                        </Typography>
                                                        {item?.dosageInstruction && (
                                                            <Typography>Instruction: {item?.dosageInstruction}</Typography>
                                                        )}
                                                    </Box>
                                                    <Box sx={{ width: 320 }}>
                                                        <Typography>
                                                            Precribed by: {item?.physicianName} from {item?.domain?.toUpperCase()}
                                                        </Typography>
                                                        <Typography>
                                                            Precribed on: {moment(item?.createdOn).format('MMM DD, YYYY - hh:mm A')}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                                <Divider />
                                            </>
                                        );
                                    }
                                })}
                            </AccordionDetails>
                        </Accordion>
                    </>
                )}
                {loading && (
                    <Box sx={styles.boxStyle}>
                        <CircularProgress size={35} />
                    </Box>
                )}
                {error && (
                    <Box sx={styles.boxStyle}>
                        <Typography>{error?.response?.data?.message ?? error?.response?.data ?? error?.message}</Typography>
                    </Box>
                )}
            </Paper>
        </div>
    );
};

export default Mediciens;

const styles = {
    boxStyle: {
        height: 500,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    accordianTitle: { fontSize: 16, fontWeight: 600 }
};
