import React from 'react';
import Scheduler from './Scheduler';

export default function AddEdit() {
    return (
        <div>
            <Scheduler />
        </div>
    );
}
