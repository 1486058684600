import React, { useEffect, useState, useRef, memo, useContext, useCallback } from 'react';
import { useSelector } from 'react-redux';
import useFetch from 'hooks/useFetch';
import { BASE_URL } from 'constants/baseUrl';
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    Paper,
    Switch,
    TextField,
    Typography
} from '@mui/material';
import axios from 'axios';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import LoadingSpinner from './LoadingSpinner';
const Prescription = (props) => {
    return (
        <Grid container columnSpacing={2} sx={{ width: '100%', height: '100%' }}>
            {/* -----------------------------  Button Grid */}

            <MedicineComp
                visitID={props.visitID}
                patient_NationalID={props.patient_NationalID}
                consultant_NationalID={props.consultant_NationalID}
                domain={props.domain}
            />
        </Grid>
    );
};
export default Prescription;

const MedicineComp = (props) => {
    const { user } = useSelector((state) => state.user);

    const {
        data: patientPrescription,
        loading: loadingPatientPrescription,
        error: errorPatientPrescription,
        refetch: refetchPatientPrescription
    } = useFetch(`${BASE_URL}api/prescription/GetPrescriptionByVisit/${props.visitID}/${props?.domain || ''}`);

    const [selectedMedicine, setSelectedMedicine] = useState('');
    const [selectedRoute, setSelectedRoute] = useState('');
    const [selectedDirection, setSelectedDirection] = useState('');
    const [selectedFrequency, setSelectedFrequency] = useState('');
    const [selectedDuration, setSelectedDuration] = useState('');
    const [qty, setQty] = useState('');
    const [selectedDoseUnit, setSelectedDoseUnit] = useState('');
    const [instruction, setInstruction] = useState('');
    const [loadingContinue, setLoadingcontinue] = useState(false);
    const [loadingDiscontinue, setLoadingDiscontinue] = useState(false);

    const [prescriptionList, setPrescriptionList] = useState([]);

    return (
        <Box sx={{ width: '100%', height: '100%', backgroundColor: '#fff', borderRadius: 3, p: 1 }}>
            {!loadingPatientPrescription && (
                <>
                    {' '}
                    {patientPrescription &&
                        patientPrescription?.map((item) => (
                            <>
                                {' '}
                                <>
                                    <Box sx={{ p: 1, m: 1, borderRadius: 3, backgroundColor: '#eef2f6' }}>
                                        <Typography>
                                            {item?.medicineName} {item?.frequency}
                                            {' for '} {item?.duration} {' ( '}
                                            {item?.medicineGenericName}
                                            {' )'}
                                        </Typography>

                                        {item?.instruction && <Typography>{item?.instruction}</Typography>}
                                    </Box>
                                </>
                            </>
                        ))}
                </>
            )}
            {loadingPatientPrescription && (
                <Box sx={{ display: 'flex', flex: 1, justifyContent: 'center', alignContent: 'center' }}>
                    <CircularProgress size={35} color="primary" />
                </Box>
            )}
        </Box>
    );
};
