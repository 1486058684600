import React, { useContext } from 'react';

import { CircularProgress, Grid, Paper } from '@mui/material';

import { MeetingContext } from 'views/meeting/MeetingContext';
import ShowPrescription from 'components/prescriptionComp/ShowPrescription';
import AddPrescription from 'components/prescriptionComp/AddPrescription';
import { useState } from 'react';
import { useEffect } from 'react';
import { memo } from 'react';

const PrescriptionGrid = ({ visit }) => {
    const { loadingPres, prescriptionList, getPrescriptionByVisit, setPrescriptionList } = useContext(MeetingContext);
    const [count, setCount] = useState(0);

    useEffect(() => {
        setCount((pre) => pre + 1);
    }, [loadingPres]);

    return (
        <Grid container spacing={1}>
            {loadingPres && count <= 1 ? (
                <Grid item lg={12}>
                    <Paper sx={{ p: 3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress size={35} />
                    </Paper>
                </Grid>
            ) : (
                <>
                    <Grid item lg={12}>
                        <Paper sx={{ p: 1 }}>
                            <ShowPrescription
                                prescription={prescriptionList}
                                getPatientPrescription={getPrescriptionByVisit}
                                setPrescriptionList={setPrescriptionList}
                            />
                        </Paper>
                    </Grid>

                    <Grid item lg={12}>
                        <Paper sx={{ p: 1 }}>
                            <AddPrescription visit={visit} getPatientPrescription={getPrescriptionByVisit} />
                        </Paper>
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export default memo(PrescriptionGrid);
