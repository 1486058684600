import axios from 'axios';
import { BASE_URL } from 'constants/baseUrl';
import { deleteData } from 'utils/indexedDB';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const getToken = async (email, password, domain) => {
    try {
        let data2 = {
            userName: email,
            password: password,
            domain: domain,
            grant_type: 'password'
        };

        let formBody = [];
        for (let property in data2) {
            let encodedKey = encodeURIComponent(property);
            let encodedValue = encodeURIComponent(data2[property]);
            formBody.push(encodedKey + '=' + encodedValue);
        }
        formBody = formBody.join('&');

        let config = {
            method: 'post',
            url: `${BASE_URL}oauth/token`,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            data: formBody
        };

        const resp = await axios(config);

        return resp?.data?.access_token;
    } catch (error) {
        const userData = JSON.parse(localStorage.getItem('user'));
        console.log(error?.response?.data?.error, ' --', userData?.email);
        if (error?.response?.data?.error == 'invalid_grant' && !!userData?.email) {
            localStorage.clear();
            sessionStorage.clear();
            deleteData();
            window.history.replaceState({}, document.title, '/login');
            // window.location.reload();
            window.location.replace('/login');
            return toast.error('Wrong Credentials! Please check your email and Login again');
        }
        throw error;
    }
};
