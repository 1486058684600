import React, { useState, useEffect, useContext } from 'react';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './CustomCalender.css'; // Import your custom CSS file

// -----------------  Importing Constants
import { COLORS } from 'constants/colors';

// -----------------  Utilis
import { useDispatch, useSelector } from 'react-redux';
import SquareIcon from '@mui/icons-material/Square';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { Box, Button, CircularProgress, Grid, Paper, Typography } from '@mui/material';
import SearchField from 'ui-component/FormUI/SearchField.js';
const localizer = momentLocalizer(moment);

import { useReactToPrint } from 'react-to-print';
import PrintRXForm from './PrintRXForm';
import PrintToken from './PrintToken';
import TranslationContext from 'context/translation';
import { getVisitPhysician, transformToCalendarJSON } from 'utils/visits';
import { fetchPatientList } from 'store/slices/patientListSlice';
import { fetchLocationData } from 'store/slices/locationSlice';
import { fetchPhysicianList } from 'store/slices/physicianListSlice';
import { apiRequest } from 'utils/api';
import CRUDApointmentModal from 'components/appointment/CRUDApointmentModal';

export default function Scheduler() {
    const { user } = useSelector((state) => state?.user);
    let { config } = useSelector((state) => state?.config);
    const { translate } = useContext(TranslationContext);

    let isShowBillItem = config?.find((i) => i.title == 'ShowBillList') || null;
    let isGenerateTokenNo = config?.find((i) => i.title == 'GenerateTokenNo')?.value || null;

    // ------------------- Get list of Physician, Patient and Location for showing in AutoComplete Component
    const dispatch = useDispatch();
    const { data: locationList } = useSelector((state) => state.location);
    const { data: patientDataList } = useSelector((state) => state.patientList);
    const { data: physicianDataList } = useSelector((state) => state.physicianList);

    const [visitList, setVisitList] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchQuery, setSearchQuery] = useState();

    const [visitPhysician, setVisitPhysician] = useState([]);
    const [selectedVisitPhysician, setSelectedVisitPhysician] = useState(null);

    const [modal, setModal] = useState({ open: false, type: 'add', data: null });

    const [printVisit, setPrintVisit] = useState({});

    const getVisitList = async (searchValue) => {
        setError(null);
        try {
            const response = await apiRequest(
                'get',
                `api/visit/primaryCareVisitsList/${user?.userId}?pageNumber=1&pageSize=1000&QuerySearch=${
                    searchValue ?? ''
                }&IsConfirm=&isCampOn=`
            );

            let requiredJSON = await transformToCalendarJSON(response?.data?.items);
            const physicianVisit = await getVisitPhysician(requiredJSON);
            setVisitList(requiredJSON);
            setVisitPhysician(physicianVisit);
            return requiredJSON;
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        !locationList && dispatch(fetchLocationData());
        !patientDataList && dispatch(fetchPatientList());
        !physicianDataList && dispatch(fetchPhysicianList());

        getVisitList();
    }, []);

    const eventStyleGetter = (event, start, end, isSelected) => {
        var backgroundColor = event.hexColor;
        var style = {
            backgroundColor: backgroundColor,
            borderRadius: '0px',
            opacity: 0.8,
            color: 'white',
            border: '0px',
            display: 'block'
        };
        return {
            style: style,
            className: 'custom-event'
        };
    };

    const EventComponent = ({ event }) => (
        <div style={{ display: 'flex' }}>
            <div style={{ fontSize: 14, marginRight: 10 }}>
                {event.patientFirstName} {event.patientLastName}
            </div>
            <div style={{ fontSize: 14 }}>{` ( ${event.title} )`}</div>
        </div>
    );

    const handlePrintToken = async (visit) => {
        const currentDate = new Date().toISOString().slice(0, 10);
        const todaysVisit = await visitList.filter((item) => new Date(item.startDateTime).toISOString().slice(0, 10) === currentDate);

        const physicianTodayVisit = await todaysVisit?.filter(
            (item) => item?.consultant_NationalID == visit?.consultant_NationalID && item?.id < visit?.id
        );

        setPrintVisit({
            ...visit,
            tokenNumber: physicianTodayVisit?.length + 1,
            location: locationList?.find((i) => i?.locationID == visit?.locationID)
        });
        setModal({ open: false, type: null, data: null });
        printToken();
    };

    const tokenNumberRef = React.useRef(null);
    const printToken = useReactToPrint({
        content: () => tokenNumberRef.current
    });

    const componentRef = React.useRef(null);
    const handlePrintRXForm = useReactToPrint({
        content: () => componentRef.current
    });

    return (
        <div>
            <Grid mb={2} container direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="h3" color={COLORS.color1} sx={{ py: 1 }}>
                    {translate('ADD_EDIT_APPOINTMENT')}
                </Typography>

                <Grid item sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <SearchField
                        label={translate('SEARCH')}
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        onClickSearch={() => {
                            getVisitList(searchQuery);
                        }}
                        onClickClear={() => {
                            setSearchQuery('');
                            getVisitList('');
                        }}
                        titleSearchBtn={'Search Physician'}
                        titleClearBtn={'Clear search list'}
                        size={'small'}
                    />
                </Grid>
            </Grid>

            <Box sx={{ paddingBottom: 1 }}>
                {visitPhysician?.length > 0 &&
                    [{ name: 'All', color: '#fff', nationalID: null }, ...visitPhysician]?.map((item) => (
                        <Button
                            sx={{ marginRight: 1 }}
                            size="small"
                            variant={selectedVisitPhysician == item?.nationalID ? 'contained' : ''}
                            startIcon={<SquareIcon sx={{ color: `${item?.colorCode}` }} />}
                            onClick={() => setSelectedVisitPhysician(item?.nationalID)}
                        >
                            {item?.name}
                        </Button>
                    ))}
            </Box>

            {!!visitList && (
                <>
                    <Paper sx={{ width: '100%', p: 1, mb: -1 }}>
                        <div className="custom-calendar">
                            <Calendar
                                selectable
                                toolbar
                                defaultView="day"
                                step={15}
                                timeslots={2}
                                views={['month', 'week', 'day']}
                                events={
                                    selectedVisitPhysician
                                        ? visitList?.filter((i) => i?.consultant_NationalID == selectedVisitPhysician)
                                        : visitList
                                }
                                localizer={localizer}
                                // onRangeChange={(range) => {
                                //     if (range.length > 0) {
                                //         let rangeLength = range.length - 1;
                                //         let dateTimeRange = {
                                //             start: new Date(range[0]),
                                //             end: new Date(range[rangeLength])
                                //         };
                                //         console.log('oo', dateTimeRange);
                                //     } else {
                                //         console.log('ee', range);
                                //     }
                                // }}
                                dayLayoutAlgorithm={'no-overlap'}
                                startAccessor="start"
                                endAccessor="end"
                                onSelectSlot={(e) => {
                                    if (new Date(e.start) > new Date()) {
                                        setModal({ open: true, type: 'add', data: e, show: true });
                                    }
                                }}
                                onSelectEvent={(e) => {
                                    if (new Date(e.start) > new Date()) {
                                        setModal({ open: true, type: 'update', data: e, show: true });
                                        setPrintVisit(e);
                                    } else {
                                        setModal({ open: true, type: 'update', data: e, show: false });
                                        setPrintVisit(e);
                                    }
                                }}
                                style={{ backgroundColor: '#ffffff', height: '73.5vh' }}
                                eventPropGetter={eventStyleGetter}
                                components={{
                                    event: EventComponent
                                }}
                                scrollToTime={new Date()}
                            />
                        </div>
                    </Paper>
                </>
            )}

            {loading && (
                <Grid sx={styles.loadingContainer}>
                    <CircularProgress size={35} color="inherit" />
                </Grid>
            )}

            {!!error && (
                <Grid sx={styles.loadingContainer}>
                    <Typography>{error?.response?.data?.message ?? error?.response?.data ?? error?.message}</Typography>
                </Grid>
            )}

            <CRUDApointmentModal
                modal={modal}
                patientDataList={patientDataList}
                physicianDataList={physicianDataList}
                locationList={locationList}
                onSuccess={async (type, visit) => {
                    if (type == 'add' || 'update') {
                        const updatedVisitList = await getVisitList();

                        setModal({ open: false, type: null, data: null });

                        if (isShowBillItem?.value == 'true') {
                            let printVst = updatedVisitList?.find((i) => i?.id == visit.id);
                            setPrintVisit(printVst);
                            handlePrintRXForm();
                        } else {
                            if (isGenerateTokenNo == 'true') {
                                let printVst = updatedVisitList?.find((i) => i?.id == visit.id);
                                handlePrintToken(printVst);
                            }
                        }
                    } else {
                        getVisitList();
                        setModal({ open: false, type: null, data: null });
                    }
                }}
                onClose={() => {
                    setModal({ open: false, type: null, data: null });
                }}
                showPrintRXFormButton={true}
                showPrintTokenButton={true}
                handlePrintRXForm={handlePrintRXForm}
                handlePrintToken={handlePrintToken}
            />

            <div style={{ display: 'none' }}>
                <PrintRXForm
                    ref={componentRef}
                    visit={printVisit}
                    location={locationList?.find((i) => i?.locationID == printVisit?.locationID)}
                />
            </div>

            <div style={{ display: 'none' }}>
                <PrintToken ref={tokenNumberRef} visit={printVisit} />
            </div>
        </div>
    );
}

const styles = {
    loadingContainer: {
        height: '70vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    btnContainer: {
        display: 'flex',
        direction: 'row',
        gap: 1,
        justifyContent: 'flex-end'
    }
};
