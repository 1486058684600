import React from 'react';

import { Box, Typography } from '@mui/material';
import moment from 'moment';
import { styles } from './styles';
import { LoadingButton } from '@mui/lab';
import { COLORS } from 'constants/colors';

const PrescriptionCard = ({ prescription, showBtn, btnTitle, onClick, btnColor, loading }) => {
    const {
        medicineName,
        medicineGenericName,
        route,
        frequency,
        dose,
        doseUnit,
        direction,
        duration,
        dosageInstruction,
        physicianName,
        domain,
        createdOn
    } = prescription;

    return (
        <Box sx={styles.prescriptionCard}>
            <Box sx={{ width: '60%', pr: 1 }}>
                <Typography sx={{ fontSize: 16, fontWeight: 600 }}>
                    {`${medicineName} ${medicineGenericName?.toLowerCase() !== 'null' ? `(${medicineGenericName})` : ''}`}
                </Typography>
                <Typography>{`${frequency ? `( ${frequency} )` : ''} ${dose ? `${dose} ` : ''} ${doseUnit ? `${doseUnit}, ` : ''} ${
                    route ? `${route} - ` : ''
                } ${direction ? direction : ''} ${duration ? duration : ''}`}</Typography>
                {dosageInstruction && <Typography>{dosageInstruction}</Typography>}
                <Typography variant="h6" color={COLORS.secondory}>
                    {domain?.toUpperCase()}
                </Typography>
            </Box>

            <Box sx={{ width: '40%' }}>
                <Box sx={{ display: 'flex' }}>
                    <Typography sx={{ fontWeight: '600', mr: 1 }}>Prescribed by: </Typography>
                    <Typography>{physicianName}</Typography>
                </Box>

                <Box sx={{ display: 'flex' }}>
                    <Typography sx={{ fontWeight: '600', mr: 1 }}>Prescribed on: </Typography>
                    <Typography>{moment(createdOn).format('MMM DD, YYYY')}</Typography>
                </Box>

                {showBtn && (
                    <LoadingButton
                        loading={loading}
                        onClick={onClick}
                        size="small"
                        variant="contained"
                        sx={{
                            float: 'right',
                            mt: 1,
                            backgroundColor: btnColor,
                            '&:hover': {
                                backgroundColor: btnColor
                            }
                        }}
                    >
                        {btnTitle}
                    </LoadingButton>
                )}
            </Box>
        </Box>
    );
};

export default PrescriptionCard;
