import { Link } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Grid, Stack, Typography, useMediaQuery } from '@mui/material';

// project imports
import logo from '../../../assets/images/cloud-clinic-logo.png';
import { COLORS } from 'constants/colors';
import OTPVerificationForm from '../auth-forms/OTPVerificationForm';
import { useContext } from 'react';
import { RegisterContext } from './RegisterContext';
import AuthCardWrapper from '../AuthCardWrapper';

// assets

// ================================|| AUTH3 - LOGIN ||================================ //

const OTPVerification = () => {
    const theme = useTheme();
    const { setShowForm, setState, state } = useContext(RegisterContext);
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <AuthCardWrapper>
            <Grid container spacing={2} alignItems="center" justifyContent="center">
                <Grid item sx={{ mb: 3 }}>
                    <Link to="#">
                        <img src={logo} alt="CloudClinicLogo" width="150" />
                    </Link>
                </Grid>
                <Grid item xs={12}>
                    <Grid container direction={matchDownSM ? 'column-reverse' : 'row'} alignItems="center" justifyContent="center">
                        <Grid item>
                            <Stack alignItems="center" justifyContent="center" spacing={1}>
                                <Typography color={COLORS.primary} gutterBottom variant={matchDownSM ? 'h3' : 'h2'}>
                                    Verification Code
                                </Typography>
                                <Typography variant="caption" fontSize="16px" textAlign={matchDownSM ? 'center' : 'inherit'}>
                                    We have sent the code verification to your email. {state?.email}
                                </Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <OTPVerificationForm />
                </Grid>
            </Grid>
        </AuthCardWrapper>
    );
};

export default OTPVerification;
