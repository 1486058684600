export const english = {
    ABNORMAL_LAB_TEST: 'Abnormal Lab Test',
    ABORTION_MISCARRIAGE: 'Abortion/Miscarriage',
    ACCIDENT: 'Accident',
    ACTIVE: 'Active',
    ADD: 'Add',
    ADD_A_NEW_RECEPTIONIST: 'Add a new receptionist',
    ADD_APPOINTMENT: 'Add Appointment',
    ADD_APPOINTMENT_SLOTS: 'Add Appointment Slots',
    ADD_DOCUMENTS: 'Add Document',
    ADD_LOCATION: 'Add Location',
    ADD_MULTIPLE_NEW_PATIENT: 'Add multiple new patients',
    ADD_MULTIPLE_NEW_PHYSICIAN: 'Add Multiple New Physician',
    ADD_NEW_LOCATION: 'Add new Location',
    ADD_NEW_MEDICINE: 'Add new Medicine',
    ADD_NEW_NURSE: 'Add New Medical Officer / Nurse',
    ADD_NEW_PACKAGE: 'Add new package',
    ADD_NEW_PATIENT: 'Add new Patient',
    ADD_NEW_PHYSICIAN: 'Add New Physician',
    ADD_NEW_RECEPTIONIST: 'Add New Receptionist',
    ADD_NEW_WARD: 'Add New Ward',
    ADD_NOTES: 'Add Notes',
    ADD_NURSE: 'Add Medical Officer / Nurse',
    ADD_PACKAGE: 'Add Package',
    ADD_PATIENT: 'Add Patient',
    ADD_PHYSICIAN: 'Add Physician',
    ADD_RECEPTIONIST: 'Add Receptionist',
    ADD_INTAKE_HISTORY: 'Add Intake History',
    ADD_VITAL_SIGNS: 'Add Vital Signs',
    ADD_EDIT_APPOINTMENT: 'Add / Edit an appointment',
    ADD_EDIT: 'Add / Edit',
    ADD_EDIT_SLOTS: 'Add / Edit Slots',
    ADD_SLOTS: 'Add Slots',
    ADD_WARD: 'Add Ward',
    ADDRESS: 'Address',
    AGE: 'Age',
    AGE_AT_MENSTURATION: 'Age At Mensturation',
    AGE_AT_MENOPAUSE: 'Age At Menopause',
    ALLERGIES: 'Allergies',
    ALL: 'All',
    ALL_APPOINTMENTS: 'Appointments List',
    AMOUNT: 'Amount',
    ANEMIA: 'Anemia',
    ANY_OTHER_COMPLAINT: 'Any Other Complaint',
    APPOINTMENTS: 'Appointments',
    APPOINTMENT_LIST: 'Appointment List',
    APPOINTMENTS_SLOT: 'Appointments Slot',
    APPOINTMENTS_TO_BE_ADDED: 'Appointments To Be Added',
    APPOINTMENTS_TO_BE_RESCHEDULDED: 'Appointments to be Rescheduled',
    ARRIVE_BY: 'Arrive by',
    ASSESSMENTS: 'Évaluations',
    ASSESSMENTS_PLAN: 'Assessment & Plan',
    AT_HOME: 'At home',

    BACK: 'Back',
    BACKACHE: 'Backache',
    BACK_TO_NURSE_LIST: 'Back to Nurse List',
    BACK_TO_PATIENT_LIST: 'Back to Patient List',
    BACK_TO_PHYSICIANS_LIST: 'Back to physicians list',
    BACK_TO_RECEPTIONIST_LIST: 'Back to receptionist list',
    BLEEDING_PROBLEM: 'Bleeding Problem',
    BLOOD_DISEASE: 'Blood Disease',
    BLOOD_IN_STOOL: 'Blood in Stool',
    BLOOD_IN_URINE: 'Blood in Urine',
    BLOOD_TRANSFUSION: 'Blood Transfusion',
    BONE_DISEASE: 'Bone Disease',
    BREAST_FEEDING: 'Breast Feeding',
    BREAST_LUMP: 'Breast Lump',
    BURNING_URINE: 'Burning Urine',

    CLEAR: 'Clear',
    CALENDER_COLOR: 'Calender Color',
    CANCER: 'Cancer',
    CANCEL_APPOINTMENT: 'Cancel appointment',
    CANCEL_MEETING: 'Cancel meeting',
    CANCELLED_APPOINTMENTS: 'Cancelled Appointments',
    CHANGE_PASSWORD: 'Change Password',
    CHIEF_COMPALINTS: 'Chief Complaints',
    CHESTPAIN: 'Chest Pain',
    CLOSE: 'Close',
    CANCEL: 'Cancel',
    CONTINUE: 'Continue',
    COLLECTIONS: 'Collections',
    COMING_SOON: 'Coming Soon',
    CONFIRM: 'Confirm',
    CONFIRMED: 'Confirmed',
    CONFIRM_PASSWORD: 'Confirm Password',
    CONSULTATION_NEEDED: 'Consultation Needed',
    CONSULTATION: 'Consultation',
    CONTINUE_PRESCRIPTION: 'Continue Prescription',
    CONTINUE_DIAGNOSTIC: 'Continue Diagnostic',
    COUGH: 'Cough',
    CREATE_APPOINTMENT: 'Create Appointment',
    CREATE_SLOTS: 'Create Slots',
    CURRENT_APPOINTMENT_IF_ANY: 'Current Appointment if any',
    CURRENT_MEDICATION: 'Current Medication',
    CURRENT_MEDICINE: 'Current Medicine',
    CURRENT_DIAGNOSIS: 'Current Diagnosis',

    DASHBOARD: 'Dashboard',
    DATE_TIME: 'Date/Time',
    DATE_OF_BIRTH: 'Date of Birth',
    DATE_OF_VISIT: 'Date of Visit',
    DAY: 'Day',
    DEGREE: 'Degree',
    DELETE: 'Delete',
    DEMENTIA: 'Dementia',
    DENTAL_PROBLEM: 'Dental Problem',
    DESCRIPTION: 'Description',
    DIARRHEA: 'Diarrhea',
    DIABETES: 'Diabetes',
    DIAGNOSIS: 'Diagnosis',

    DIAG_PLAN: 'Diagnosis & Plan',
    DIAG_UPDATED: 'Diagnostic Updated',
    DIAG_BY: 'Diagnosed by',
    DISCOUNTINUED_MEDICATION: 'Discountinued Medication',
    DISCOUNT: 'Discount',
    DISCOUNT_PERC: 'Discount %',
    DISEASES: 'Diseases (Tick all your active problems)',
    DOCTOR: 'Doctor',
    DOMAIN: 'domain',
    DOSAGE: 'Dosage',
    DOSAGE_FORM: 'Dosage Form',
    DOSAGE_INSTRUCTIONS: 'Dosage Instructions',
    DOWNLOAD_SAMPLE: 'Download Sample',
    DRINKING: 'Drinking',
    DRINKING_FREQUENCY: 'Drinking Frequency',
    DRUGS: 'Drugs',
    DRUGS_FREQUENCY: 'Drugs Frequency',

    EAR_PROBLEM: 'Ear Problem',
    EDIT_APPOINTMENT: 'Edit Appointment',
    EDIT_PATIENT: 'Edit Patient',
    EDIT_MEDICINE: 'Edit Medicine',
    EDIT_NURSE: 'Edit Nurse',
    EDIT_PHYSICIAN: 'Edit Physician',
    EDIT_RECEPTIONIST: 'Edit Receptionist',
    EDIT_INTAKE_HISTORY: 'Edit Intake History',
    EDIT_VITAL_SIGNS: 'Edit Vital Signs',
    EMAIL: 'Email',
    EMAIL_ID: 'Email ID',
    EMPLOYEE_ID: 'Employee ID',
    EMPLOYMENT_ID: 'Employment ID',
    END: 'End',
    END_DATE: 'End Date',
    END_TIME: 'End Time',
    ENTER_TEXT_HERE: 'Enter Text Here',
    EYE_PROBLEM: 'Eye Problem',

    FACIAL_PUFFINESS: 'Facial Puffiness',
    FAMILY_HISTORY: 'Family History',
    FAIMLY_MEMBER_DIED_FOR_SPECIFC_ILLNESS: 'Faimly Member died for specifc illness',
    FAIMLY_MEMBER_HAVING_NOTEABLE_ILLNESS: 'Faimly member having noteable illness',
    FAIMLY_MEMBER_HAVING_SAME_MEDICAL_PROBLEMS: 'Faimly member having same medical problems',
    FAMILY: 'Family',
    FEE: 'Fee',
    FEE_SHARE: 'Fee Share',
    FEMALE: 'Female',
    FEMALE_SECTION_ONLY: 'Female Section Only',
    FEVER: 'Fever',
    FIRST_NAME: 'First Name',
    FOLLOWUP_DAY: 'Follow Day',
    FOLLOWUP_NEEDED: 'Follow up Needed',
    FOLLOWUP: 'Follow up',
    FOR_HOW_LONG: 'For How Long',
    FORM: 'Form',
    FREQUENCY: 'Frequency',
    FROM: 'From',

    GENDER: 'Gender',
    GENRIC_NAME: 'Generic Name',
    GUARDIAN_MOBILE: 'Guardian Mobile',
    GUARDIAN_NAME: 'Guardian Name',

    HEADACHE: 'Headache',
    HEALTHCARE_LICENSE_CODE: 'Healthcare license code',
    HEALTHCARE_LICENSE_VALIDITY_DATE: 'Healthcare license validity date',
    HEART_BURN: 'Heart Burn',
    HEART_PROBLEM: 'Heart Problem',
    HEART_RACING: 'Heart Racing',
    HEIGHT: 'Height',
    HEPATITIS: 'Hepatitis',
    HIGH_BLOOD_PRESSURE: 'High Blood Pressure',
    HISTORY: 'History',
    HOLD_TO_SPEAK: 'Hold to Speak',
    HOT_FLASHES: 'Hot Flashes',

    ID: 'ID',
    IMAGE: 'Image',
    IMAGE_LAB_REPORT: 'Image Lab Report',
    IMAGE_LAB_REPORTS: 'Imaging / Lab Reports',
    IMMUNIZATIONS: 'Immunizations',
    IN_HOSPITAL: 'In Hospital',
    IN_PERSON: 'In person',
    IN_PERSON_MEETING: 'In-Person Meeting',
    INITIAL_COMPLAIN: 'initial Complain',
    INITIAL_DIAGNOSIS_PLAN: 'Initial Diagnosis Plan',
    INTAKE_HISTORY: 'Intake History',
    INVOICE_LIST: 'Invoice List',
    INVOICE_DATE: 'Invoice Date',
    INVOICE_NO: 'Invoice No',
    INVOICE_STATUS: 'Invoice Status',
    IRREGULAR_ECG_RHYTHM: 'Irregular ECG Rhythm',

    JAUNDICE: 'Jaundice',
    JOINT_SWELLING_PAIN: 'Joint Swelling/Pain',

    KIDNEY_DISEASE: 'Kidney Disease',
    KIDNEY_STONES: 'Kidney Stones',

    LAB_RESULTs: 'Lab Results',
    LAST_NAME: 'Last Name',
    LAST_MENSTRUAL_PERIOD: 'Last Menstrual Period',
    LAST_PAP_SMEAR: 'Last Pap Smear',
    LAST_MAMOGRAM: 'Last Mamogram',
    LIVER_DISEASE: 'Liver Disease',
    LOCATION: 'Location',
    LOCATIONS: 'Locations',
    LOCATION_LIST: 'Location List',
    LOGIN: 'Login',
    LOGOUT: 'Logout',
    LOSS_OF_CONSCIOUSNESS: 'Loss of Consciousness',
    LUNG_DISEASE: 'Lung Disease',

    MAKE_AN_APPOINTMENT: 'Make an Appointment',
    MALE: 'Male',
    MEDICAL_RECORD_NUMBER: 'Medical Record Number',
    MEDICINE: 'Medicine',
    MEDICINES: 'Medicines',
    MEDICINE_ID: 'Medicine ID',
    MEDICINE_NAME: 'Medicine Name',
    MOBILE: 'Mobile',
    MOBLIE_NUMBER: 'Mobile Number',
    MODIFY: 'Modify',
    MONTH: 'Month',
    MOUTH_PROBLEM: 'Mouth Problem',
    MUSCLE_WEAKNESS: 'Muscle Weakness',
    MY_APPOINTMENTS: 'My Appointments',
    MY_FUTURE_APPOINTMENTS: 'My Future Appointments',
    MY_PATIENTS: 'My Patients',
    MEDICAL_OFFICER_NURSES: 'Medical Officer / Nurses',

    NAME: 'Name',
    NATIONAL_ID: 'National ID',
    NATIONAL_IDENTIFICATION_CODE: 'National Identification Code',
    NET_AMOUNT: 'Net Amount',
    NEXT: 'Next',
    NEW_PASSWORD: 'New Password',
    NEW_APT_REQUEST: 'New Appointment Request',
    NEW_APPOINTMENT_REQUEST: 'New Appointment Request',
    NOSE_PROBLEM: 'Nose Problem',
    NO_CURRENT_DIAGNOSIS: 'No Current Diagnosis',
    NO_CURRENT_MEDICATIONS: 'No Current Medications',
    NO_DISCONTINUED_PRESCRIPTION: 'No Discontinued Prescription',
    NO_IMAGING_LAB_REPORTS_AVAILABLE: 'No Imaging / Lab Reports Available',
    NO_PACKAGE_AVAILABLE: 'No packages available',
    NO_PRESCRIPTION_FOUND: 'No Prescription found',
    NO_PREVIOUS_DIAGNOSIS: 'No Previous Diagnosis',
    NOTES: 'Notes',
    NUMBER_OF_PREGNANCIES: 'Number Of Pregnancies',
    NURSE: 'Nurse',
    NURSE_APPOINTMENTS: 'Nurse Appointments',
    NURSE_LIST: 'Nurse List',
    NURSES: 'Medical Officer / Nurses',

    OLD_PASSWORD: 'Old Password',
    OTHER_COMPALINTS: 'Other Compalaints',
    OTHER_DETAILS: 'Other Details',

    PACKAGE: 'Package',
    PASSWORD: 'password',
    PATIENT: 'Patient',
    PATIENT_ID: 'Patient ID',
    PATIENT_LIST: 'Patients List',
    PATIENT_NAME: 'Patient Name',
    PATIENT_MOBILE: 'Patient Mobile',
    PATIENT_NAME_ID: 'Patient Name/ID',
    PATIENTS: 'Patients',
    PATIENT_VISITS: 'Patient Visits',
    PATIENT_VITAL_SIGNS: 'Patient Vital Signs',
    PERSONAL: 'Personal',
    PHONE_NO: 'Phone No',
    PHONE_NUMBER: 'Phone Number',
    PHYSICIAN: 'Physician',
    PHYSICIAN_APPOINTMENTS: 'Physician Appointments',
    PHYSICIAN_PACKAGE_LIST: 'Physician Package List',
    PHYSICIANS: 'Physicians',
    PHYSICIANS_LIST: 'Physicians List',
    PRESCRIPTION: 'Prescription',
    PRESCRIPTION_DETAILS: 'Prescription Details',
    PRESCRIBED: 'Prescribed on',
    PRESCRIBED_BY: 'Prescribed by',
    PREVIOUS: 'previous',
    PREVIOUS_ASSESSMENTS: 'Previous Assessments',
    PREVIOUS_VISITS: 'Previous Visits',
    PREVIOUS_HISTORY: 'Previous History',
    PREGNANT: 'Pregnant',
    PROFESSION: 'Profession',
    PROFESSION_JOB: 'Profession / Job',
    PROFILE: 'Profile',
    PSYCHOLOGICAL_PROBLEM: 'Psychological Problem',
    PURPOSE: 'Purpose',

    QUANTITY: 'Quantity',

    READY_FOR_SUBMIT: 'Ready for Submit',
    RECEPTIONIST: 'Receptionist',
    RECEPTIONISTS: 'Receptionists',
    REFRERRED_TO: 'Refrerred To',
    REFILL_DETAILS: 'Refill Details',
    REGULAR_ECG_RHYTHM: 'Regular ECG Rhythm',
    REMARKS: 'Remarks',
    REMOVE: 'Remove',
    RESCHEDULE: 'Reschedule',
    RESCHEDULE_MEETING: 'Reschedule meeting',
    RESCHEDULE_APPOINTMENT: 'Reschedule Appointment',
    RESET_PASSWORD: 'Reset Password',
    ROLE: 'Role',
    ROW_PER_PAGE: 'Rows per page',

    SAVE: 'Save',
    SAVE_CHANGES: 'Save Changes',
    SEARCH: 'Search',
    SEARCH_PATIENTS: 'Search Patient',
    SEARCH_PHYSICIAN: 'Search Physician',
    SEIZURE: 'Seizure',
    SELECT_DIAGNOSIS: 'Select Diagnosis',
    SELECT_MEDICINE: 'Select Medicine',
    SELECT_PACKAGE: 'Select a Package',
    SHORTNESS_OF_BREATH: 'Shortness of Breath',
    SKIN_DISEASE: 'Skin Disease',
    SKIN_RASH: 'Skin Rash',
    SLEEP_DISTURBANCE: 'Sleep Disturbance',
    SMOKING: 'Smoking',
    SMOKING_REQUENCY: 'Smoking Frequency',
    SOCIAL_HISTORY: 'Social History',
    SPECIALITY: 'Speciality',
    START: 'Start',
    START_DATE: 'Start Date',
    START_MEETING: 'Start Meeting',
    START_TIME: 'Start Time',
    STARTS_AT: 'starts at',
    STATUS: 'Status',
    STOMACHPAIN: 'Stomach Pain',
    SUBMIT: 'Update',
    STROKE: 'Stroke',
    SUMMARY_NOTES: 'Summary Notes',
    SUMMARY: 'Summary',
    SURGERIES_OPERATIONS: 'Surgeries Operations',
    SWALLOWING_PROBLEM: 'Swallowing Problem',
    SWOLLEN_FEET: 'Swollen Feet',
    SYMPTOMS: 'Symptoms (Tick all your active problems)',

    THROAT_PROBLEM: 'Throat Problem',
    THYROID_PROBLEM: 'Thyroid Problem',
    TITLE: 'Title',
    TODAY: 'Today',
    TODAY_VISIT: 'Today Visits',
    TODAY_APPOINTMENTS: "Today's Appointments",
    TODAY_VIRTUAL_APPOINTMENTS: "Today's Virtual Appointments",
    TODAY_INPERSON_APPOINTMENTS: "Today's In-person Appointments",
    TOTAL_APPOINTMENTS: 'Total Appointments',
    TO_BE_ADDED: 'To Be Added',
    TRANSCRIPT: 'transcript',
    TUMOR: 'Tumor',
    TYPE: 'Type',

    UNCONFIRMED: 'Unconfirmed',
    UPCOMING_VISITS: 'Upcoming Visits',
    UPDATE: 'Update',
    UPDATE_DIAGNOSTIC: 'Update Diagnostic',
    UPDATE_PACKAGE: 'Update Package',
    UPDATE_PRESCRIPTION: 'Update Prescription',
    UPLOAD: 'Upload',
    UPLOAD_DOCUMENTS: 'Upload Documents',
    UPLOAD_PHYSICIANS: 'Upload Physicians',
    UPLOAD_IMAGE_LAB_REPORTS: 'Upload Reports',
    UPLOAD_LAB_REPORTS: 'Upload Lab Reports',
    UPLOAD_REPORTS: 'Upload Reports',
    USER_LIST: 'User List',
    USER_NAME: 'User name',
    USERNAME: 'Username',
    USERS: 'Users',
    USER_PROFILE: 'User Profile',
    UTERUS_BLEEDING: 'Uterus Bleeding',

    VACCINES: 'Vaccines',
    VOMITING: 'Vomiting',
    VIEW_LAB_REPORTS: 'View Lab Reports',
    VIEW_REPORTS: 'View Reports',
    VIEW_LOCATIONS: 'View Locations',
    VIEW_MEDICINES: 'View Medicines',
    VIEW_PROFILE: 'View Profile',
    VIEW_WARDS: 'View Wards',
    VIRTUAL: 'Virtual',
    VIRTUAL_MEETING: 'Virtual Meeting',
    VIRTUAL_CLINIC: 'VIRTUAL CLINIC',
    VISIT_DATE_TIME: 'Visit Date / Time',
    VISIT_DESCRIPTION: 'Visit Description',
    VISIT_ID: 'Visit ID',
    VISIT: 'Visit',
    VISIT_REASON: 'Visit Reason',
    VITAL_SIGNS: 'Vital Signs',

    WARD: 'Ward',
    WARD_LIST: 'Ward List',
    WARDS: 'Wards',
    WEAKNESS: 'Weakness',
    WEIGHT: 'Weight',
    WEIGHTLOSS: 'Weight Loss',
    WEEK: 'Week',

    PLEASE_ENTER_A_VALID_EMAIL_ID: 'Please Enter a Valid Email ID',
    WARD_SUCCESS_MESSAGE: 'Ward successfully updated',
    PLEASE_ENTER_USER_NAME: 'Please Enter User Name',
    PLEASE_ENTER_PASSWORD: 'Please Enter Password',
    INCORRECT_EMAIL_PASSWORD: 'Email or Password is incorrect',
    PRESCRIPTION_UPDATED: 'Prescription Updated',
    PLEASE_COMPLETE_THE_FORM: 'Please Complete the form',
    SORRY_NO_RECORD_FOUND: 'Sorry No Record Found',
    START_DATETIME_PAST_DATETIME: 'Start Date and Time should not be Past Date and Time',
    END_DATETIME_AFTER_DATETIME: 'End Date and Time should be after start Date and Time',

    ULTRASOUND: 'Ultrasound',
    OTHER: 'Other',
    LAB_TEST: 'Lab Test',
    MRI: 'MRI',
    CT_SCAN: 'CT Scan',
    X_RAY: 'X-Ray',

    DASHBOARD: 'Dashboard',
    PHYSICIAN_NAME: 'Physician Name',
    FOLLOW_UP_DATE_TIME: 'Follow Up Date Time',
    ACTION: 'Action',
    SCHEDULE_VISIT: 'Schedule this Visit',
    TO_BE_CANCELLED: 'To Be Cancelled',
    AVAILABLE_SLOTS: 'Available Slots',
    COMPLETED: 'Completed',
    CANCELLED: 'Cancelled',
    PAYMENT: 'Payment',
    DEVICE: 'Device',
    UPDATE_APPOINTMENT: 'Update Appointment',
    START_DATE_TIME: 'Start Date Time',
    END_DATE_TIME: 'End Date Time',
    PRINT: 'Print',
    PRINT_TOKEN: 'Print Token',
    APPOINTMENT_ADDED_SUCCESSFULLY: 'Appointment added successfully',
    APPOINTMENT_UPDATED_SUCCESSFULLY: 'Appointment updated successfully',
    APPOINTMENT_DELETED_SUCCESSFULLY: 'Appointment deleted successfully',
    COMPLETED_APPOINTMENTS: 'Completed Appointments',
    PAID: 'Paid',
    UN_PAID: 'Un-Paid',
    PRINT_SUMMARY: 'Print Summary',
    PRINT_PRESCRIPTION_BY: 'Print Prescription by',
    OVERALL_PRESCRIPTION: 'Overall Prescription',
    LOADING_PRESCRIPTION: 'Loading Prescription Data',
    SEND_SMS: 'Send SMS',
    DURATION: 'Duration',
    CHOOSE_FILE: 'Choose File',
    APPOINTMENT_RESCHEDULE_DATE_TIME: 'Appointment Reschedule Date Time',
    CANCEL_CONFIRMATION: 'Cancel Confirmation',
    CANCEL_CONFIRMATION_TEXT: 'Are you sure, you want to cancel this appointment?',
    YES: 'Yes',
    NO: 'No',
    CANCEL_REQUEST_SENT: 'Cancel Request Sent',
    NEW_PHYSICIAN: 'New Physician',
    MULTIPLE_NEW_PHYSICIAN: 'Multiple New Physician',
    BATCH_VISIT: 'Batch Visit',
    CLEAR_SEARCH_LIST: 'Clear search list',
    PROFILE_PICTURE: 'Profile Picture',

    PERSONAL_INFORMATION: 'Personal Information',
    EDUCATION_AND_SPECIALITY: 'Education and Speciality',
    HEALTHCARE_LICENSE_INFORMATION: 'Healthcare License Information',
    NEW_PATIENT: 'New Patient',
    MULTIPLE_NEW_PATIENT: 'Multiple New Patient',
    ALL_PATIENTS: 'All Patients',
    OUTSIDE_HOSPITAL: 'Outside Hospital',
    EMERGENCY_CONTACT_NAME: `Emergency Contact Name`,
    EMERGENCY_CONTACT_NUMBER: `Emergency Contact Number`,
    NEW_RECEPTIONIST: 'New Receptionist'
};
