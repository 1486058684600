import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { onMessageListener } from './firebaseInit';
import '@cyntler/react-doc-viewer/dist/index.css';
// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { syncVisit } from 'store/slices/campVisitSlice';
import { loadTranscription, startedTranscription } from 'store/slices/transcriptionSlice';
// ==============================|| APP ||============================== //

const App = () => {
    const { user } = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const customization = useSelector((state) => state.customization);
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    onMessageListener()
        .then((payload) => {
            console.log('payload ', payload);
            dispatch(syncVisit(payload?.data['gcm.notification.visitId'])); // used to trigger next meeting when a visit is a batchVisit (Camp Visit, Notification on Physician Side)
            if (payload.notification.title == 'Transcription Started') {
                dispatch(startedTranscription(true));
            } else if (payload.notification.title == 'Transcription Updated') {
                dispatch(loadTranscription(payload.notification.body)); // used to trigger transcription or notes pop-up in meeting (Receptionist and Nurse Side)
            } else {
                toast.success(payload.notification.body);
            }

            forceUpdate();
        })
        .catch((err) => console.log('failed: ', err));

    useEffect(() => {
        Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
                return;
            }
        });
    }, []);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <NavigationScroll>
                    <Routes />
                    <ToastContainer />
                </NavigationScroll>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
