import { useContext, useEffect, useState } from 'react';

// material-ui
import { Button, CircularProgress, Paper, Grid, TextField, Box, Typography } from '@mui/material';

// third party
import * as Yup from 'yup';
import { Form, Formik } from 'formik';

import { COLORS } from 'constants/colors';

import axios from 'axios';
import { BASE_URL } from 'constants/baseUrl';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router';
import { RegisterContext } from '../register/RegisterContext';
import Textfield from 'ui-component/FormUI/Textfield';
import SelectCustom from 'ui-component/FormUI/Select';
import MuiPhoneNumber from 'material-ui-phone-number';
import ModalCustom from 'ui-component/modals/ModalCustom';
import DomainLogo from 'components/DomainLogo';
import Loader from 'ui-component/Loader';
import { Error } from '@mui/icons-material';
import { getAllDomains } from 'services/getDomains';
import { getToken } from 'services/getToken';

// ============================|| FIREBASE - LOGIN ||============================ //

const SignupForm = ({ ...others }) => {
    const { setShowForm, setState, state } = useContext(RegisterContext);

    const [dateOfBirth, setDateOfBirth] = useState(null);
    const [age, setAge] = useState(null);
    const [guardianPhone, setGuardianPhone] = useState('');
    const [mobileNo, setMobileNo] = useState('');
    const [loading, setLoading] = useState(false);

    const [allDomains, setAllDomains] = useState(null);
    const [loadingDomains, setLoadingDomains] = useState(true);
    const [errorDomains, setErrorDomains] = useState(null);
    const [showDomainModal, setShowDomainModal] = useState(false);
    const [selectedDomain, setSelectedDomain] = useState({
        title: 'Select Healthcare Provider',
        domain: null
    });

    const INITIAL_FORM_STATE = {
        firstName: '',
        lastName: '',
        identificationNo: '',
        gender: '',
        address: '',
        guardianName: ''
    };

    const FORM_VALIDATION = Yup.object().shape({
        firstName: Yup.string().trim().required('Required'),
        lastName: Yup.string().required('Required'),
        identificationNo: Yup.number()
            .typeError('National ID must be a number')
            .required('Required')
            .test('length', 'Input must be exactly 13 characters', (value) => value && value.toString().length === 13),
        gender: Yup.string().required('Required'),
        address: Yup.string().required('Required')
    });

    const getAllDomain = async () => {
        try {
            const res = await getAllDomains();
            setAllDomains(res?.data);
        } catch (error) {
            setErrorDomains(error);
        } finally {
            setLoadingDomains(false);
        }
    };

    useEffect(() => {
        getAllDomain();

        return () => {
            setAllDomains();
            setLoadingDomains(true);
            setErrorDomains(null);
        };
    }, []);

    const handleSubmit = async (values, resetForm) => {
        setLoading(true);
        try {
            if (selectedDomain?.domain == null) {
                return toast.error('Please select any Healthcare Provider');
            }

            if (!!mobileNo?.trim().replace(/\D/g, '') == false) {
                return toast.error('Please enter mobile number');
            }
            if (mobileNo?.trim().replace(/\D/g, '')?.length < 12) {
                return toast.error('Please enter valid mobile number');
            }

            if (dateOfBirth == null) {
                return toast.error('Please enter date of birth');
            }

            const { firstName, lastName, identificationNo, gender, address, guardianName } = values;

            let data = {
                email: state?.email,
                userName: state?.email,
                nationalID: '',
                firstName: firstName?.trim(),
                lastName: lastName?.trim(),
                roleName: 'Patient',
                phoneNumber: mobileNo?.trim().replace(/\D/g, ''),
                address: address?.trim(),
                titles: gender == 'Male' ? 'Mr' : 'Ms',
                employeeCode: '',
                identificationNo: identificationNo?.trim(),
                createdBy: 'from signup web app',
                createdOn: new Date(),
                profileImage: 'cloudclinic_dummy.jpg',
                name: firstName?.trim(),
                dob: dateOfBirth,
                phone: mobileNo?.trim().replace(/\D/g, ''),
                gender: gender,
                relation: 'self',
                guardianName: guardianName?.trim(),
                guardianPhone: guardianPhone?.trim().replace(/\D/g, ''),
                inHospital: false,
                mrNumber: '',
                role: 'Patient',
                isActive: true,
                wardID: null
            };

            const responseCreateAccount = await axios({
                method: 'post',
                url: `${BASE_URL}api/accounts/signup`,
                data: {
                    ...data,
                    domain: selectedDomain?.domain,
                    code: state?.code
                }
            });

            if (responseCreateAccount?.data) {
                const token = await getToken(state?.email, '', selectedDomain?.domain);

                const resCreatePatient = await axios({
                    url: `${BASE_URL}api/patient`,
                    method: 'post',
                    data: {
                        ...data,
                        userID: responseCreateAccount?.data?.id
                    },
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`
                    }
                });

                if (resCreatePatient?.data) {
                    resetForm();
                    setMobileNo('+92');
                    setGuardianPhone('+92');
                    setDateOfBirth(null);
                    setAge(null);
                    return setShowForm('Success');
                }
            }
        } catch (error) {
            return toast.error(
                error?.response?.data?.modelState[''][0] || error?.response?.data?.message || error?.response?.data || error?.message
            );
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Formik
                initialValues={{
                    ...INITIAL_FORM_STATE
                }}
                validationSchema={FORM_VALIDATION}
                onSubmit={(values, { resetForm }) => handleSubmit(values, resetForm)}
            >
                <Form>
                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <Grid container direction="row" p={2} spacing={2}>
                            <Grid item lg={3} md={4} sm={6} xs={12}>
                                <Button
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    sx={{ marginBottom: -5 }}
                                    onClick={() => setShowDomainModal(true)}
                                >
                                    {selectedDomain?.title}
                                </Button>
                            </Grid>

                            <Grid item lg={3} md={4} sm={6} xs={12}>
                                <Textfield name="firstName" label="First Name" />
                            </Grid>

                            <Grid item lg={3} md={4} sm={6} xs={12}>
                                <Textfield name="lastName" label="Last Name" />
                            </Grid>

                            <Grid item lg={3} md={4} sm={6} xs={12}>
                                <Textfield name="identificationNo" label="National ID" />
                            </Grid>

                            <Grid item lg={3} md={4} sm={6} xs={12}>
                                <SelectCustom defaultValue="" name="gender" label="Gender" options={['Male', 'Female', 'Other']} />
                            </Grid>

                            <Grid item lg={2} md={4} sm={6} xs={12}>
                                <TextField
                                    type="date"
                                    variant="standard"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    label="Date of Birth"
                                    value={dateOfBirth}
                                    InputProps={{
                                        inputProps: {
                                            max: new Date().toLocaleDateString('en-CA')
                                        }
                                    }}
                                    onChange={(e) => {
                                        let x = e.target.value;
                                        let y = x.split('-')[0];
                                        let a = new Date().getFullYear();

                                        let b = a - y;
                                        setDateOfBirth(x);
                                        setAge(b);
                                    }}
                                />
                            </Grid>

                            <Grid item lg={1} md={1} sm={2} xs={4}>
                                <TextField
                                    label="Age"
                                    variant="standard"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    value={age}
                                    onChange={(event) => {
                                        const age = event.target.value;
                                        setAge(age);

                                        // Calculate DOB and set it
                                        if (age) {
                                            const today = dateOfBirth ? new Date(dateOfBirth) : new Date();
                                            const birthYear = new Date().getFullYear() - parseInt(age, 10);
                                            const dob = new Date(birthYear, today.getMonth(), today.getDate());
                                            setDateOfBirth(new Date(dob).toLocaleDateString('en-CA'));
                                        } else {
                                            // If age is cleared, clear the DOB as well
                                            setDateOfBirth(null);
                                        }
                                    }}
                                />
                            </Grid>

                            <Grid item lg={3} md={4} sm={6} xs={12}>
                                <MuiPhoneNumber
                                    fullWidth
                                    onlyCountries={['us', 'pk']}
                                    defaultCountry={'pk'}
                                    autoFormat={true}
                                    disableAreaCodes={true}
                                    label="Mobile Number"
                                    value={mobileNo}
                                    onChange={(value) => setMobileNo(value)}
                                />
                            </Grid>

                            <Grid item lg={3} md={4} sm={6} xs={12}>
                                <Textfield name="guardianName" label="Emergency Contact Name" />
                            </Grid>

                            <Grid item lg={3} md={4} sm={6} xs={12}>
                                <MuiPhoneNumber
                                    fullWidth
                                    onlyCountries={['us', 'pk']}
                                    defaultCountry={'pk'}
                                    autoFormat={true}
                                    disableAreaCodes={true}
                                    label="Emergency Contact Number"
                                    value={guardianPhone}
                                    onChange={(value) => setGuardianPhone(value)}
                                />
                            </Grid>

                            <Grid item lg={6} md={4} sm={12} xs={12}>
                                <Textfield name="address" label="Address" />
                            </Grid>

                            <Grid item lg={3} md={4} sm={6} xs={12}>
                                <Button
                                    fullWidth
                                    disabled={loading}
                                    type="submit"
                                    variant="contained"
                                    sx={{ backgroundColor: COLORS.secondory, marginBottom: -3, '&: hover': { background: COLORS.primary } }}
                                >
                                    {loading ? <CircularProgress size={25} color="inherit" /> : 'Save'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Form>
            </Formik>

            <ModalCustom open={showDomainModal} title={'Select Healthcare Provider'}>
                {allDomains && (
                    <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center', flexWrap: 'wrap' }}>
                        {allDomains?.map((item) => (
                            <DomainLogo
                                key={item?.domain}
                                title={item?.title}
                                logo={item?.image}
                                domain={item?.domain}
                                selected={selectedDomain}
                                onClick={() => {
                                    setSelectedDomain(item);
                                    setShowDomainModal(false);
                                }}
                            />
                        ))}
                    </Box>
                )}
                {loadingDomains && <Loader />}
                {errorDomains && (
                    <Typography>
                        {errorDomains?.response?.data?.message || errorDomains?.response?.data || errorDomains?.message}
                    </Typography>
                )}
            </ModalCustom>
        </>
    );
};

export default SignupForm;
