import { Box, Button, CircularProgress, IconButton, LinearProgress, Typography } from '@mui/material';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import ModalCustom from 'ui-component/modals/ModalCustom';
import CloseIcon from '@mui/icons-material/Close';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import AnimatedMic from 'ui-component/FormUI/Button/AnimatedMic';
import SendIcon from '@mui/icons-material/Send';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import ImageIcon from '../../assets/images/icons/mic-icon.png';
import VoiceRecorder from '../../assets/images/voice-recorder.png';
import { COLORS } from 'constants/colors';
import PauseIcon from '@mui/icons-material/Pause';
import StopIcon from '@mui/icons-material/Stop';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import useAudioRecorder from 'hooks/useAudioRecorder';

const GPT = forwardRef(({ open, onClose, visit, isAutoStart = false, showAudioButton = true, onGetPrescription }, ref) => {
    const [modalState, setModalState] = useState('empty');
    const {
        isRecording,
        recordingTime,
        startRecording,
        pauseRecording,
        resumeRecording,
        stopRecording,
        audioChunks,
        cancelRecording,
        downloadLink
    } = useAudioRecorder();

    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [transcription, setTranscription] = useState('');
    const downloadLinkRef = useRef(null);

    useEffect(() => {
        return () => {
            cancelRecording();
            setModalState('empty');
        };
    }, []);

    const handleDownload = (audioBlob) => {
        const url = URL.createObjectURL(audioBlob);
        downloadLinkRef.current.href = url;
        downloadLinkRef.current.download = 'audio_file.wav';
        downloadLinkRef.current.click();
        URL.revokeObjectURL(url); // Clean up the object URL to free resources
    };

    const sendAudioToServer = async () => {
        setLoading(true);
        const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
        handleDownload(audioBlob);
        const formData = new FormData();
        formData.append('audio', audioBlob);
        try {
            // const response = await axios({
            //     method: 'post',
            //     url: 'http://pytest.zedflix.pk/transcribe',
            //     data: formData,
            //     onDownloadProgress: (progressEvent) => {
            //         const total = parseFloat(progressEvent.currentTarget.responseHeaders['Content-Length']);
            //         const current = progressEvent.currentTarget.response.length;

            //         let percentCompleted = Math.floor((current / total) * 100);
            //         setProgress(percentCompleted);
            //     }
            // });
            const response = await fetch('http://pytest.zedflix.pk/transcribe', {
                method: 'POST',
                body: formData
            });
            const data = await response.json(); // data = {text: "Your transcription here"}
            console.log(data);
            setTranscription(data.parsedContent); // Correctly extract and set the transcription text
            onGetPrescription(data.parsedContent);
            return data.text; // Update the transcription state with the server response
        } catch (error) {
            console.error('Error sending audio to server:', error);
            setTranscription('Error transcribing audio.'); // Update the transcription state in case of error
            toast.error(error?.response?.data?.message ?? error?.response?.data ?? error?.message);
            throw error; // Rethrow the error to handle it in the calling function
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (isAutoStart) {
            startRecording();
            setModalState('recording');
        }
    }, []);

    const uploadAudio = () => {
        stopRecording();
        sendAudioToServer();
    };

    useImperativeHandle(ref, () => {
        return {
            uploadAudio
        };
    });

    return (
        // <ModalCustom open={open} title={'GPT Version'}>
        <Box>
            {/* <Box sx={{ position: 'absolute', top: 10, right: 10, display: 'flex' }}>
                <IconButton color="inherit" onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </Box> */}
            {modalState == 'empty' ? (
                <Box style={styles.innerContainer}>
                    {/* <img src={ImageIcon} alt="Mic Icon" style={{ height: 80, width: 80 }} /> */}

                    <Typography sx={{ marginBottom: 2 }}>
                        To start recording press the <b>Start Recording</b> button.
                    </Typography>

                    <Button
                        size="small"
                        onClick={() => {
                            startRecording();
                            setModalState('recording');
                        }}
                        variant="contained"
                    >
                        Start Recording
                    </Button>
                </Box>
            ) : modalState == 'recording' ? (
                <Box style={styles.innerContainer}>
                    {/* <img src={VoiceRecorder} alt="voice recorder" style={{ height: 100, objectFit: 'contain', color: COLORS.primary }} /> */}
                    {loading ? (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <CircularProgress size={20} sx={{ marginRight: 1 }} />
                            <Typography>Generating Prescription</Typography>
                        </Box>
                    ) : (
                        <>
                            <Typography sx={{ marginBottom: 1.6 }}>
                                <b>{recordingTime}</b>
                            </Typography>
                            <Button onClick={uploadAudio}>Generate Prescription</Button>
                        </>
                    )}

                    {showAudioButton && (
                        <Box sx={{ display: 'flex', gap: 1 }}>
                            <IconButton
                                size="small"
                                onClick={() => {
                                    isRecording ? pauseRecording() : resumeRecording();
                                }}
                                title="Pause Recording"
                                sx={{ backgroundColor: COLORS.primary, '&:hover': { backgroundColor: COLORS.secondory } }}
                            >
                                {isRecording ? <PauseIcon sx={{ color: '#fff' }} /> : <PlayArrowIcon sx={{ color: '#fff' }} />}
                            </IconButton>

                            <IconButton
                                size="small"
                                onClick={() => {
                                    stopRecording();
                                    sendAudioToServer();
                                }}
                                title="Stop Recording"
                                sx={{ backgroundColor: COLORS.primary, '&:hover': { backgroundColor: COLORS.secondory } }}
                            >
                                <StopIcon sx={{ color: '#fff' }} />
                            </IconButton>

                            <IconButton
                                size="small"
                                onClick={() => {
                                    cancelRecording();
                                    setModalState('empty');
                                }}
                                title="Cancel Recording"
                                sx={{ backgroundColor: COLORS.primary, '&:hover': { backgroundColor: COLORS.secondory } }}
                            >
                                <CloseIcon sx={{ color: '#fff' }} />
                            </IconButton>
                        </Box>
                    )}
                </Box>
            ) : (
                <Typography>Good</Typography>
            )}
            <a
                ref={downloadLinkRef}
                style={{
                    position: 'absolute',
                    left: '-9999px',
                    width: '1px',
                    height: '1px',
                    overflow: 'hidden'
                }}
                href="/"
                download="audio_file.wav"
            >
                Download Audio
            </a>
        </Box>
        // {/* </ModalCustom> */}
    );
});

export default GPT;

const styles = {
    boxStyle: {
        position: 'relative',
        border: '1px solid gray',
        borderRadius: 2,
        height: 150,
        overflow: 'auto',
        p: 1
    },
    innerContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    }
};
