import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    campVisit: null
};

export const campVisitSlice = createSlice({
    name: 'campVisit',
    initialState,
    reducers: {
        syncVisit: (state = initialState, action) => {
            state.campVisit = action.payload;
        }
    }
});

export const { syncVisit } = campVisitSlice.actions;

export default campVisitSlice.reducer;
