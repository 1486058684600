import React, { useContext } from 'react';

import { CircularProgress, Grid, Paper } from '@mui/material';

import { MeetingContext } from 'views/meeting/MeetingContext';
import ShowDiagnosis from 'components/diagnosisComps/ShowDiagnosis';
import AddDiagnosis from 'components/diagnosisComps/AddDiagnosis';
import { useEffect } from 'react';
import { useState } from 'react';
import { memo } from 'react';

const DiagnosisGrid = ({ visit }) => {
    const { loadingDiag, diagnosisList, setDiagnosisList, getDiagnosisByVisit } = useContext(MeetingContext);
    const [count, setCount] = useState(0);

    useEffect(() => {
        setCount((pre) => pre + 1);
    }, [loadingDiag]);

    return (
        <Grid container spacing={1}>
            {loadingDiag && count <= 1 ? (
                <Grid item lg={12}>
                    <Paper sx={{ p: 3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress size={35} />
                    </Paper>
                </Grid>
            ) : (
                <>
                    <Grid item lg={12}>
                        <Paper sx={{ p: 1 }}>
                            <ShowDiagnosis
                                diagnosisList={diagnosisList}
                                getPatientDiagnosis={getDiagnosisByVisit}
                                setDiagnosisList={setDiagnosisList}
                            />
                        </Paper>
                    </Grid>

                    <Grid item lg={12}>
                        <Paper sx={{ p: 1 }}>
                            <AddDiagnosis visit={visit} getPatientDiagnosis={getDiagnosisByVisit} showDiagAndPlan={false} />
                        </Paper>
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export default memo(DiagnosisGrid);
