function encrypt(text) {
    let encryptedText = '';
    for (let i = 0; i < text.length; i++) {
        const charCode = text.charCodeAt(i);
        const encryptedCharCode = charCode + 7;
        encryptedText += String.fromCharCode(encryptedCharCode);
    }
    return encryptedText;
}

function decrypt(encryptedText) {
    let decryptedText = '';
    for (let i = 0; i < encryptedText.length; i++) {
        const charCode = encryptedText.charCodeAt(i);
        const decryptedCharCode = charCode - 7;
        decryptedText += String.fromCharCode(decryptedCharCode);
    }
    return decryptedText;
}

export { encrypt, decrypt };
