import { Link } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Grid, Stack, Typography, useMediaQuery } from '@mui/material';

// project imports
import logo from '../../../assets/images/cloud-clinic-logo.png';
import { COLORS } from 'constants/colors';
import EmailRegisterForm from '../auth-forms/EmailRegisterForm';
import AuthCardWrapper from '../AuthCardWrapper';

// assets

// ================================|| AUTH3 - LOGIN ||================================ //

const EmailRegister = () => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <AuthCardWrapper>
            <Grid container spacing={2} alignItems="center" justifyContent="center">
                <Grid item sx={{ mb: 3 }}>
                    <Link to="#">
                        <img src={logo} alt="CloudClinicLogo" width="150" />
                    </Link>
                </Grid>
                <Grid item xs={12}>
                    <Grid container direction={matchDownSM ? 'column-reverse' : 'row'} alignItems="center" justifyContent="center">
                        <Grid item>
                            <Stack alignItems="center" justifyContent="center" spacing={1}>
                                <Typography color={COLORS.primary} gutterBottom variant={matchDownSM ? 'h3' : 'h2'}>
                                    Register your account
                                </Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <EmailRegisterForm />
                </Grid>
            </Grid>
        </AuthCardWrapper>
    );
};

export default EmailRegister;
