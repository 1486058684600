import axios from 'axios';
import { BASE_URL } from 'constants/baseUrl';

export const loginUser = async (email, password, token) => {
    const res = await axios({
        method: 'post',
        url: `${BASE_URL}api/accounts/users/login?UserName=${email}&Password=${password}`,
        headers: { Authorization: `Bearer ${token}` }
    });

    return res;
};

export const getProfiles = async (token, userId) => {
    const res = await axios({
        method: 'get',
        url: `${BASE_URL}api/patient/getPatient/${userId}`,
        headers: { Authorization: `Bearer ${token}` }
    });

    return res;
};

export const getPhysicianProfile = async (token, userId) => {
    const res = await axios({
        method: 'get',
        url: `${BASE_URL}api/physician/getPhysician/${userId}`,
        headers: { Authorization: `Bearer ${token}` }
    });

    return res;
};
