import { Link } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Divider, Grid, Stack, Typography, useMediaQuery } from '@mui/material';

// project imports
import AuthWrapper1 from '../AuthWrapper1';
import AuthCardWrapper from '../AuthCardWrapper';
import AuthFooter from 'ui-component/cards/AuthFooter';
import EmailRegister from './EmailRegister';
import OTPVerification from './OTPVerification';
import { RegisterContext } from './RegisterContext';
import { useState } from 'react';
import SignUp from './SignUp';
import Success from './Success';

// assets

// ===============================|| AUTH3 - REGISTER ||=============================== //

const Register = () => {
    const theme = useTheme();
    const [state, setState] = useState({ email: null, code: null });
    const [showForm, setShowForm] = useState('EmailRegister');

    return (
        <RegisterContext.Provider value={{ state, setState, setShowForm }}>
            <AuthWrapper1>
                <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '100vh' }}>
                    <Grid item xs={12}>
                        <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)' }}>
                            <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
                                {showForm == 'SignupForm' ? (
                                    <SignUp />
                                ) : showForm == 'OTPForm' ? (
                                    <OTPVerification />
                                ) : showForm == 'Success' ? (
                                    <Success />
                                ) : (
                                    <EmailRegister />
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{ m: 3, mt: 1 }}>
                        <AuthFooter />
                    </Grid>
                </Grid>
            </AuthWrapper1>
        </RegisterContext.Provider>
    );
};

export default Register;
