import axios from 'axios';
import { addData } from './indexedDB';
const { BASE_URL } = require('constants/baseUrl');

const syncListToLS = async () => {
    const userData = JSON.parse(localStorage.getItem('user'));

    try {
        const resMed = await axios({
            method: 'get',
            url: `${BASE_URL}api/medicine`,
            headers: { Authorization: `Bearer ${userData?.token}` }
        });

        const resDia = await axios({
            method: 'get',
            url: `${BASE_URL}api/diagnostic`,
            headers: { Authorization: `Bearer ${userData?.token}` }
        });

        const resLab = await axios({
            method: 'get',
            url: `${BASE_URL}api/LabTestType`,
            headers: { Authorization: `Bearer ${userData?.token}` }
        });

        const resRoute = await axios({
            method: 'get',
            url: `${BASE_URL}api/SetupItem/GetByType/route`,
            headers: { Authorization: `Bearer ${userData?.token}` }
        });

        const resDirection = await axios({
            method: 'get',
            url: `${BASE_URL}api/SetupItem/GetByType/direction`,
            headers: { Authorization: `Bearer ${userData?.token}` }
        });

        const resDuration = await axios({
            method: 'get',
            url: `${BASE_URL}api/SetupItem/GetByType/duration`,
            headers: { Authorization: `Bearer ${userData?.token}` }
        });

        const resDoseUnit = await axios({
            method: 'get',
            url: `${BASE_URL}api/SetupItem/GetByType/doseunit`,
            headers: { Authorization: `Bearer ${userData?.token}` }
        });

        const resFrequency = await axios({
            method: 'get',
            url: `${BASE_URL}api/SetupItem/GetByType/frequency`,
            headers: { Authorization: `Bearer ${userData?.token}` }
        });

        addData('medicine', resMed?.data);
        addData('diagnosis', resDia?.data);
        addData('labTestType', resLab?.data);
        localStorage.setItem('route', JSON.stringify(resRoute?.data));
        localStorage.setItem('duration', JSON.stringify(resDuration?.data));
        localStorage.setItem('frequency', JSON.stringify(resFrequency?.data));
        localStorage.setItem('direction', JSON.stringify(resDirection?.data));
        localStorage.setItem('doseUnit', JSON.stringify(resDoseUnit?.data));
    } catch (error) {
        console.log('Error in File SyncListToLS ', error);
    }
};

export default syncListToLS;
