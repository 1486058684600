// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Avatar, List, ListItem, ListItemAvatar, ListItemText, Grid } from '@mui/material';
import React, { useContext } from 'react';
// assets
import FlagUS from 'assets/images/flag/flag-united-state.png';
import FlagFrance from 'assets/images/flag/flag-france.png';
import TranslationContext from 'context/translation';
// styles
import { Button } from '@mui/material';
const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 14,
    '&:hover': {
        background: theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const LanguageList = () => {
    const theme = useTheme();
    const { setLanguage } = useContext(TranslationContext);

    const englishLanguageHandler = () => {
        localStorage.setItem('lang', 'english');
        setLanguage('english');
    };

    const frenchLanguageHandler = () => {
        localStorage.setItem('lang', 'french');
        setLanguage('french');
    };
    return (
        <List
            sx={{
                width: '100%',
                maxWidth: 330,
                py: 0,
                borderRadius: '10px',
                [theme.breakpoints.down('md')]: {
                    maxWidth: 300
                },
                '& .MuiListItemSecondaryAction-root': {
                    top: 22
                },
                '& .MuiDivider-root': {
                    my: 0
                },
                '& .list-container': {
                    pl: 7
                }
            }}
        >
            <Grid>
                <Button size="small" onClick={englishLanguageHandler}>
                    <LanguageCard language={'English'} flag={FlagUS} />
                </Button>
            </Grid>
            <Grid>
                <Button size="small" onClick={frenchLanguageHandler}>
                    <LanguageCard language={'French'} flag={FlagFrance} />
                </Button>
            </Grid>
        </List>
    );
};

export default LanguageList;

const LanguageCard = ({ language, flag }) => {
    return (
        <ListItemWrapper>
            <ListItem alignItems="center">
                <ListItemAvatar>
                    <Avatar alt={language} src={flag} variant={'square'} sx={{ width: 24, height: 16 }} />
                </ListItemAvatar>
                <ListItemText primary={language} sx={{ ml: -2 }} />
            </ListItem>
        </ListItemWrapper>
    );
};
