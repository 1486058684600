const { getToken } = require('./getToken');
const { loginUser, getProfiles } = require('./loginUser');
import jwt_decode from 'jwt-decode';
import { decrypt } from 'utils/ecryptDecrypt';

const switchPhysicianDomain = async (email, password, domain) => {
    try {
        const token = await getToken(email, decrypt(password), domain);

        if (token) {
            const resLogin = await loginUser(email, decrypt(password), token);
            const resUser = resLogin?.data[0];
            var decoded = jwt_decode(token);

            let resProfile = null;
            if (resUser?.roles?.includes('Patient')) {
                resProfile = await getProfiles(token, resUser?.userId);
            }

            var decoded = jwt_decode(token);
            const userToSave = {
                ...resUser,
                domainLogo: decoded?.Image,
                domain: decoded?.Domain,
                token: token,
                key: password,
                ...(resUser?.roles?.includes(',') && {
                    roles: 'Physician',
                    allRoles: resUser?.roles?.split(','),
                    openModal: false
                })
            };

            return { userToSave, resProfile };
        }
    } catch (error) {
        throw Error(error);
    }
};

const switchPatientDomain = async (email, password, domain, nationID) => {
    try {
        const token = await getToken(email, decrypt(password), domain);

        if (token) {
            const resLogin = await loginUser(email, decrypt(password), token);
            const resUser = resLogin?.data[0];
            var decoded = jwt_decode(token);

            let resProfile = await getProfiles(token, resUser?.userId);

            let curProfile = resProfile?.data?.filter((i) => i?.nationalID == nationID);
            let { profileImage, name, lastName, titles, identificationNo, dob, relation, nationalID, gender, phone, isActive } =
                curProfile[0];

            const userToSave = {
                ...resUser,
                domainLogo: decoded?.Image,
                domain: decoded?.Domain,
                key: password,
                token: token,
                profileImage,
                firstName: name,
                lastName,
                fullName: `${name} ${lastName}`,
                nationalID,
                identificationNo,
                relation,
                gender,
                phone,
                isActive,
                dob,
                titles,
                roles: 'Patient',
                allRoles: resUser?.roles?.split(',')
            };

            return { userToSave, resProfile };
        }
    } catch (error) {
        throw Error(error);
    }
};

export { switchPhysicianDomain, switchPatientDomain };
