import * as React from 'react';

import { Grid, TextField } from '@mui/material';
import { useState } from 'react';

export default function VitalSignForm({ data, onChange, visit }) {
    const [weight, setWeight] = useState(data?.weight || '');
    const [height, setHeight] = useState(data?.height || '');
    const [temp, setTemp] = useState(data?.temp || '');
    const [spO2, setSPO2] = useState(data?.spO2 || '');
    const [bsr, setBSR] = useState(data?.bsr || '');

    const [sys, setSys] = useState(data?.sys || '');
    const [dia, setDia] = useState(data?.dia || '');
    const [pi, setPi] = useState(data?.pi || '');
    const [pr, setPr] = useState(data?.pr || '');

    let state = {
        vitalSignID: data?.vitalSignID,
        patient_NationalID: visit?.patient_NationalID,
        visitID: visit?.id,

        dateTime1: data?.dateTime1 || new Date(),
        dateTime2: data?.dateTime2 || new Date(),
        datetimeECG: data?.datetimeECG || new Date(),
        datetimeBP: data?.datetimeBP || new Date(),

        weight,
        height,
        temp,
        spO2,
        bsr,

        sys,
        dia,
        pi,
        pr,

        hr: pr,
        bpm: pr,
        map: sys * (1 / 3) + (2 / 3) * dia,

        no: data?.no || '',
        ecg: data?.ecg || '',
        qrs: data?.qrs || '',
        qt: data?.qt || '',
        qtc: data?.qtc || '',
        nibP1: data?.nibP1 || '',
        nibP2: data?.nibP2 || '',
        manual1: data?.manual1 || '',
        manual2: data?.manual2 || '',
        mmHG1: data?.mmHG1 || '',
        mmHG2: data?.mmHG2 || '',

        resP1: data?.resP1 || '',
        resP2: data?.resP1 || '',

        ma: data?.ma || ''
    };

    onChange(state); // Pass the updated state to the callback function

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item lg={3} md={3} sm={6} xs={12}>
                    <TextField
                        type="number"
                        fullWidth
                        value={weight}
                        onChange={(e) => setWeight(e.target.value)}
                        label="Weight (kg)"
                        variant="standard"
                    />
                </Grid>

                <Grid item lg={3} md={3} sm={6} xs={12}>
                    <TextField
                        type="number"
                        fullWidth
                        value={height}
                        onChange={(e) => setHeight(e.target.value)}
                        label="Height (feet)"
                        variant="standard"
                    />
                </Grid>

                <Grid item lg={3} md={3} sm={6} xs={12}>
                    <TextField
                        type="number"
                        fullWidth
                        value={temp}
                        onChange={(e) => setTemp(e.target.value)}
                        label="Temp (°F)"
                        variant="standard"
                    />
                </Grid>

                <Grid item lg={3} md={3} sm={6} xs={12}>
                    <TextField
                        type="number"
                        fullWidth
                        value={spO2}
                        onChange={(e) => setSPO2(e.target.value)}
                        label="SPO2"
                        variant="standard"
                    />
                </Grid>

                <Grid item lg={2.4} md={2.4} sm={6} xs={12}>
                    <TextField
                        type="number"
                        fullWidth
                        value={sys}
                        onChange={(e) => setSys(e.target.value)}
                        label="Systolic"
                        variant="standard"
                    />
                </Grid>

                <Grid item lg={2.4} md={2.4} sm={6} xs={12}>
                    <TextField
                        type="number"
                        fullWidth
                        value={dia}
                        onChange={(e) => setDia(e.target.value)}
                        label="Diastolic"
                        variant="standard"
                    />
                </Grid>

                <Grid item lg={2.4} md={2.4} sm={6} xs={12}>
                    <TextField
                        type="number"
                        fullWidth
                        value={pi}
                        onChange={(e) => setPi(e.target.value)}
                        label="Perfusion Index"
                        variant="standard"
                    />
                </Grid>

                <Grid item lg={2.4} md={2.4} sm={6} xs={12}>
                    <TextField
                        type="number"
                        fullWidth
                        value={pr}
                        onChange={(e) => setPr(e.target.value)}
                        label="Pulse Rate"
                        variant="standard"
                    />
                </Grid>

                <Grid item lg={2.4} md={2.4} sm={6} xs={12}>
                    <TextField
                        type="number"
                        fullWidth
                        value={bsr}
                        onChange={(e) => setBSR(e.target.value)}
                        label="BSR"
                        variant="standard"
                    />
                </Grid>
            </Grid>
        </div>
    );
}
