import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    Paper,
    TextField,
    Typography
} from '@mui/material';
import { BASE_URL } from 'constants/baseUrl';
import useFetch from 'hooks/useFetch';
import React, { useState, useRef } from 'react';
import { useLocation } from 'react-router';
import { profileImage } from 'utils/fetchImage';
import moment from 'moment';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MeetingContextProvider } from 'views/meeting/MeetingContext';
import PrescriptionGrid from './PrecriptionGrid';
import DiagnosisGrid from './DiagnosisGrid';
import LabTestComp from 'components/LabTestComp';
import VitalSign from 'views/components/vitalSign';
import ModalCustom from 'ui-component/modals/ModalCustom';
import PrintSummary from 'views/meeting/PrintSummary';
import { AlertPrintPopUp } from 'components/AlertPrintPopUp';
import UploadReportComp from 'components/UploadReportComp';
import PrevIntakeHistoryComp from 'components/PrevIntakeHistoryComp';
import ViewReportsByPatient from 'components/ViewReportsByPatient';

export default function MeetingDetail() {
    const { state } = useLocation();
    const { user } = useSelector((state) => state?.user);

    let notes = useRef('');
    let initialComplain = useRef('');
    const [loadingDandP, setLoadingDandP] = useState(false);

    const [openPrintModal, setOpenPrintModal] = useState(false);
    const [printByVisit, setPrintByVisit] = useState(false);
    const componentRef = useRef();

    const [openHistoryModal, setOpenHistoryModal] = useState(false);
    const [openUploadReportModal, setOpenUploadReportModal] = useState(false);
    const [openViewReportModal, setOpenViewReportModal] = useState(false);
    const [openNotifyPtModal, setOpenNotifyPtModal] = useState(false);

    const [tags, setTags] = useState({ prescription: false, diagnostics: false, labTest: false, notes: false, diagAndPlan: false });

    const {
        data: visit,
        loading: loadingVisit,
        error: errorVisit,
        refetch: refetchVisit
    } = useFetch(`${BASE_URL}api/visit/getVisit/${state?.id}`);

    const submitDiagAndPlan = async () => {
        try {
            setLoadingDandP(true);
            const resUpdateVisit = await axios({
                method: 'put',
                url: `${BASE_URL}api/visit/updateVisit/${state.id}`,
                data: {
                    initialComplain: initialComplain.current.value,
                    notes: notes.current.value
                },
                headers: { Authorization: `Bearer ${user?.token}` }
            });

            if (resUpdateVisit?.data) {
                return toast.success('Notes and Plan are updated');
            }
        } catch (error) {
            return toast.error(error?.response?.data?.message ?? error?.response?.data ?? error?.message);
        } finally {
            setLoadingDandP(false);
        }
    };

    const notifyPatient = async () => {
        try {
            let tagsToBeSended = `${tags?.prescription ? 'prescription,' : ''}${tags?.diagnostics ? 'diagnostics,' : ''}${
                tags?.labTest ? 'labTest,' : ''
            }${tags?.notes ? 'notes,' : ''}${tags?.diagAndPlan ? 'diagAndPlan' : ''}`;

            const res = await axios({
                method: 'post',
                url: `${BASE_URL}api/visit/sendSMSToPatient`,
                data: {
                    visitid: state?.id,
                    tag: tagsToBeSended
                },
                headers: { Authorization: `Bearer ${user?.token}` }
            });
        } catch (error) {
            toast?.error(error?.response?.data?.message ?? error?.response?.data ?? error?.message);
        }
    };

    return (
        <MeetingContextProvider visit={state}>
            <Grid container spacing={2}>
                <Grid item lg={7.8}>
                    {loadingVisit ? (
                        <Box sx={{ display: 'flex', height: '320px', justifyContent: 'center', alignItems: 'center' }}>
                            <CircularProgress size={35} />
                        </Box>
                    ) : (
                        <Grid container spacing={2}>
                            <Grid item lg={12}>
                                <ButtonContainer
                                    onClickSummary={() => setOpenPrintModal(true)}
                                    onClickHistory={() => {
                                        setOpenHistoryModal(true);
                                    }}
                                    onClickViewReport={() => {
                                        setOpenViewReportModal(true);
                                    }}
                                    onClickUploadReport={() => {
                                        setOpenUploadReportModal(true);
                                    }}
                                    onClickNotifyPatient={() => setOpenNotifyPtModal(true)}
                                />
                            </Grid>

                            <Grid item lg={6}>
                                <PatientInfoCard
                                    image={visit[0]?.patientProfileImage}
                                    name={`${visit[0]?.patientFirstName} ${visit[0]?.patientLastName}`}
                                    age={visit[0]?.patientAge}
                                    gender={visit[0]?.patientGender}
                                />
                            </Grid>
                            <Grid item lg={6}>
                                <PhysicianInfoCard
                                    image={visit[0]?.consultantProfileImage}
                                    name={visit[0]?.consultantName?.split('/')[0]}
                                    speciality={visit[0]?.doctorSpeciality?.toLowerCase()}
                                />
                            </Grid>

                            <Grid item lg={6}>
                                <Paper sx={{ p: 2 }}>
                                    <Typography sx={{ fontSize: 16 }}>Visit ID: {state?.id}</Typography>
                                    <Typography sx={{ fontSize: 13 }}>
                                        Visit Date Time: {moment(state?.startDateTime)?.format('MMM DD, YYYY - hh:mm A')}
                                    </Typography>
                                </Paper>
                            </Grid>

                            <Grid item lg={6}>
                                <Paper sx={{ p: 2 }}>
                                    <Typography sx={{ fontSize: 16 }}>Title: {state?.title}</Typography>
                                    <Typography sx={{ fontSize: 13 }}>Summary: {state?.summaryNotes}</Typography>
                                </Paper>
                            </Grid>

                            <Grid item lg={12}>
                                <Paper sx={{ p: 1 }}>
                                    <TextField
                                        inputRef={notes}
                                        defaultValue={visit[0]?.notes}
                                        size="small"
                                        fullWidth
                                        placeholder="Add Notes here..."
                                    />

                                    <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
                                        <TextField
                                            inputRef={initialComplain}
                                            defaultValue={visit[0]?.initialComplain}
                                            size="small"
                                            fullWidth
                                            placeholder="Add Diagnosis and Plan here..."
                                        />
                                        <LoadingButton onClick={submitDiagAndPlan} size="small" variant="outlined" loading={loadingDandP}>
                                            Save
                                        </LoadingButton>
                                    </Box>
                                </Paper>
                            </Grid>
                        </Grid>
                    )}
                </Grid>

                <Grid item lg={4.2} sx={{ height: '325px' }}>
                    <VitalSign visitID={state?.id} show={false} />
                </Grid>

                <Grid item lg={6}>
                    <Typography sx={{ fontSize: 17, fontWeight: 'bold', mb: 1 }}>Prescription</Typography>
                    <PrescriptionGrid visit={state} />
                </Grid>

                <Grid item lg={6}>
                    <Grid container spacing={2}>
                        <Grid item lg={12}>
                            <Typography sx={{ fontSize: 17, fontWeight: 'bold', mb: 1 }}>Diagnostics</Typography>
                            <Paper sx={{ p: 0.5 }}>
                                <DiagnosisGrid visit={state} />
                            </Paper>
                        </Grid>

                        <Grid item lg={12}>
                            <Typography sx={{ fontSize: 17, fontWeight: 'bold', mb: 1 }}>Lab Test</Typography>
                            <Paper sx={{ p: 0.5 }}>
                                <LabTestComp visit={state} />
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {/* -----------------------  History Modal  -------------------------------------- */}
            {openHistoryModal && (
                <PrevIntakeHistoryComp
                    open={openHistoryModal}
                    onClose={() => {
                        setOpenHistoryModal(false);
                    }}
                    visit={state}
                />
            )}

            {/* -----------------------  Upload Reports Modal  -------------------------------------- */}
            {openUploadReportModal && (
                <UploadReportComp
                    open={openUploadReportModal}
                    onClose={() => {
                        setOpenUploadReportModal(false);
                    }}
                    visit={state}
                />
            )}

            {/* -----------------------  View Reports Modal  -------------------------------------- */}
            {openViewReportModal && (
                <ViewReportsByPatient
                    open={openViewReportModal}
                    onClose={() => {
                        setOpenViewReportModal(false);
                    }}
                    visit={state}
                />
            )}

            {/* ----------------------------  Print Summary Comp  ------------------------------ */}
            <div style={{ height: '0px', width: '0px', overflow: 'hidden' }}>
                <PrintSummary ref={componentRef} visit={state} printByVisit={printByVisit} />
            </div>

            <ModalCustom open={openPrintModal} title={'Print Summary'}>
                <IconButton color="inherit" onClick={() => setOpenPrintModal(false)} sx={{ position: 'absolute', top: 10, right: 10 }}>
                    <CloseIcon />
                </IconButton>

                <AlertPrintPopUp
                    selectedPrint={printByVisit}
                    onSelectedPrint={(e) => setPrintByVisit(e.target.value)}
                    content={() => componentRef.current}
                />
            </ModalCustom>

            {/* ----------------------------  Notify Patient Comp  ------------------------------ */}

            <ModalCustom open={openNotifyPtModal} title={'Notify Patient'}>
                <IconButton color="inherit" onClick={() => setOpenNotifyPtModal(false)} sx={{ position: 'absolute', top: 10, right: 10 }}>
                    <CloseIcon />
                </IconButton>

                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={tags?.prescription}
                                onChange={(e) => setTags((pre) => ({ ...pre, prescription: e?.target?.checked }))}
                            />
                        }
                        label="Prescription"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={tags?.diagnostics}
                                onChange={(e) => setTags((pre) => ({ ...pre, diagnostics: e?.target?.checked }))}
                            />
                        }
                        label="Diagostics"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={tags?.labTest}
                                onChange={(e) => setTags((pre) => ({ ...pre, labTest: e?.target?.checked }))}
                            />
                        }
                        label="Lab Test"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox checked={tags?.notes} onChange={(e) => setTags((pre) => ({ ...pre, notes: e?.target?.checked }))} />
                        }
                        label="Notes"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={tags?.diagAndPlan}
                                onChange={(e) => setTags((pre) => ({ ...pre, diagAndPlan: e?.target?.checked }))}
                            />
                        }
                        label="Diagnosis and Plan"
                    />
                </FormGroup>

                <Box display="flex" justifyContent="right">
                    <Button onClick={notifyPatient}>Send</Button>
                </Box>
            </ModalCustom>
        </MeetingContextProvider>
    );
}

const PatientInfoCard = ({ image, name, age, gender }) => {
    return (
        <Box sx={{ display: 'flex' }}>
            <Box sx={{ width: 55, height: 55, borderRadius: 55 / 2, overflow: 'hidden', backgroundColor: '#adadad' }}>
                <img alt={'Profile'} src={profileImage(image)} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
            </Box>

            <Box sx={{ ml: 2 }}>
                <Typography sx={{ fontSize: 16, fontWeight: '600' }}>{name}</Typography>
                <Typography sx={{ fontSize: 12 }}>Gender: {gender}</Typography>
                <Typography sx={{ fontSize: 12 }}>Age: {age}</Typography>
            </Box>
        </Box>
    );
};

const PhysicianInfoCard = ({ image, name, speciality }) => {
    return (
        <Box sx={{ display: 'flex' }}>
            <Box sx={{ width: 55, height: 55, borderRadius: 55 / 2, overflow: 'hidden', backgroundColor: '#adadad' }}>
                <img alt={'Profile'} src={profileImage(image)} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
            </Box>

            <Box sx={{ ml: 2 }}>
                <Typography sx={{ fontSize: 16, fontWeight: '600' }}>{name}</Typography>
                <Typography sx={{ fontSize: 12, textTransform: 'capitalize' }}>{speciality}</Typography>
            </Box>
        </Box>
    );
};

const ButtonContainer = ({ onClickSummary, onClickHistory, onClickViewReport, onClickUploadReport, onClickNotifyPatient }) => {
    return (
        <Grid container spacing={1}>
            <Grid item lg={2.4}>
                <Button onClick={onClickHistory} size="small" fullWidth variant="contained">
                    Intake History
                </Button>
            </Grid>

            <Grid item lg={2.4}>
                <Button onClick={onClickUploadReport} size="small" fullWidth variant="contained">
                    Upload Report
                </Button>
            </Grid>

            <Grid item lg={2.4}>
                <Button onClick={onClickViewReport} size="small" fullWidth variant="contained">
                    View Report
                </Button>
            </Grid>

            <Grid item lg={2.4}>
                <Button onClick={onClickSummary} size="small" fullWidth variant="contained">
                    Print Summary
                </Button>
            </Grid>

            <Grid item lg={2.4}>
                <Button onClick={onClickNotifyPatient} size="small" fullWidth variant="contained">
                    Notify Patient
                </Button>
            </Grid>
        </Grid>
    );
};
