import { Box, CircularProgress, Grid, Paper, Typography } from '@mui/material';
import axios from 'axios';
import DomainLogo from 'components/DomainLogo';
import { BASE_URL } from 'constants/baseUrl';
import { COLORS } from 'constants/colors';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeDuplicates } from 'utils/compareArrayOfObjects';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ModalCustom from 'ui-component/modals/ModalCustom';
import { getToken } from 'services/getToken';
import { getProfiles, loginUser } from 'services/loginUser';
import { decrypt, encrypt } from 'utils/ecryptDecrypt';
import jwt_decode from 'jwt-decode';
import { saveUserData } from 'store/slices/userSlice';
import { saveProfileData } from 'store/slices/profileSlice';
import { useNavigate } from 'react-router';

export default function HealthcareProviders() {
    const { user } = useSelector((state) => state?.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [allDomain, setAllDomain] = useState(null);
    const [joinedDomains, setJoinDomains] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [loadJoinDomain, setLoadJoinDomain] = useState(false);

    const getAllDomain = async () => {
        try {
            const resJoinedDomains = await axios({
                method: 'get',
                url: `${BASE_URL}api/tenant/UserTenants?id=${user?.userId}&email=${user?.email}`,
                headers: {
                    Authorization: `Bearer ${user?.token}`
                }
            });

            const resAllDomains = await axios({
                method: 'get',
                url: `${BASE_URL}api/tenant`
            });

            const extractedDomains = await removeDuplicates(resAllDomains?.data, resJoinedDomains?.data, 'domain');

            setAllDomain(extractedDomains);
            setJoinDomains(resJoinedDomains?.data);
        } catch (error) {
            setError(error?.response?.data?.message || error?.response?.data || error?.message);
        } finally {
            setLoading(false);
        }
    };

    const joinDomain = async (selected) => {
        setLoadJoinDomain({ isLoad: true, message: 'Joining' });
        try {
            const res = await axios({
                url: `${BASE_URL}api/accounts/PatientJoinTenant`,
                method: 'post',
                data: {
                    domain: selected?.domain,
                    userId: user?.userId,
                    RoleName: user?.roles
                },
                headers: {
                    Authorization: `Bearer ${user?.token}`
                }
            });

            if (res?.data) {
                setLoading(true);
                setAllDomain(null);
                getAllDomain();
            }
        } catch (error) {
            return toast.error(error?.response?.data?.message ?? error?.response?.data ?? error?.message);
        } finally {
            setLoadJoinDomain({ isLoad: false, message: '' });
        }
    };

    const switchDomain = async (selected) => {
        setLoadJoinDomain({ isLoad: true, message: 'Switching' });
        try {
            const token = await getToken(user?.email, decrypt(user?.key), selected?.domain);

            if (token) {
                const resLogin = await loginUser(user?.email, decrypt(user?.key), token);
                const resUser = resLogin?.data[0];
                var decoded = jwt_decode(token);

                let resProfile = null;
                let profileImage, name, lastName, titles, identificationNo, nationalID, dob, relation;
                if (resUser?.roles?.includes('Patient')) {
                    resProfile = await getProfiles(token, resUser?.userId);
                    ({ profileImage, name, lastName, titles, identificationNo, nationalID, dob, relation } = resProfile?.data[0]);
                }

                var decoded = jwt_decode(token);
                const userToSave = {
                    ...resUser,
                    domainLogo: decoded?.Image,
                    domain: decoded?.Domain,
                    token: token,
                    key: user?.key,
                    ...(resUser?.roles?.includes('Patient') && {
                        profileImage,
                        firstName: name,
                        lastName,
                        fullName: `${name} ${lastName}`,
                        nationalID,
                        identificationNo,
                        relation,
                        dob,
                        titles
                    }),
                    ...(resUser?.roles?.includes(',') && {
                        roles: resUser?.roles?.split(',')[0],
                        allRoles: resUser?.roles?.split(','),
                        openModal: true
                    }),
                    ...(resUser?.roles?.includes('Patient') && {
                        openModal: true
                    })
                };

                localStorage.setItem('user', JSON.stringify(userToSave));
                dispatch(saveUserData(userToSave));
                dispatch(saveProfileData(resProfile?.data));
                navigate('/dashboard', { replace: true });
            }
        } catch (error) {
            return toast.error(error?.response?.data?.message ?? error?.response?.data ?? error?.message);
        } finally {
            setLoadJoinDomain({ isLoad: false, message: '' });
        }
    };

    useEffect(() => {
        getAllDomain();

        return () => {
            setAllDomain(null);
            setJoinDomains(null);
            setError(null);
            setLoading(true);
        };
    }, []);

    return (
        <div>
            {joinedDomains && allDomain && (
                <Paper sx={{ display: 'flex', flexDirection: 'column', p: 2, flex: 1 }}>
                    <Typography variant="h3" color={COLORS.color1} sx={{ mb: 2 }}>
                        Your Healthcare Providers
                    </Typography>

                    <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                        {joinedDomains?.map((item) => (
                            <DomainLogo
                                key={item?.domain}
                                selectedDomain={user?.domain == item?.domain}
                                title={item?.title}
                                logo={item?.image}
                                domain={item?.domain}
                                onClick={() => switchDomain(item)}
                            />
                        ))}
                    </Box>

                    <Typography variant="h3" color={COLORS.color1} sx={{ mb: 2, mt: 4 }}>
                        Join Healthcare Providers
                    </Typography>

                    <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                        {allDomain?.map((item) => (
                            <DomainLogo
                                key={item?.domain}
                                title={item?.title}
                                logo={item?.image}
                                domain={item?.domain}
                                onClick={() => joinDomain(item)}
                            />
                        ))}
                    </Box>
                </Paper>
            )}

            {loading && (
                <Grid sx={styles.loadingContainer}>
                    <CircularProgress size={35} color="inherit" />
                </Grid>
            )}

            {!!error && (
                <Grid sx={styles.loadingContainer}>
                    <Typography>{error?.response?.data?.message ?? error?.response?.data ?? error?.message}</Typography>
                </Grid>
            )}

            <ModalCustom open={loadJoinDomain?.isLoad}>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography sx={{ mb: 1, color: '#444' }}>{loadJoinDomain?.message}...</Typography>
                    <CircularProgress size={35} color="inherit" />
                </Box>
            </ModalCustom>
        </div>
    );
}

const styles = {
    loadingContainer: {
        height: 500,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
};
