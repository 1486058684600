import { useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Button, CircularProgress, FormControl, FormHelperText, InputLabel, OutlinedInput } from '@mui/material';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project imports
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';

import { COLORS } from 'constants/colors';

import axios from 'axios';
import { BASE_URL } from 'constants/baseUrl';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router';
import { useContext } from 'react';
import { RegisterContext } from '../register/RegisterContext';

// ============================|| FIREBASE - LOGIN ||============================ //

const OTPVerificationForm = ({ ...others }) => {
    const theme = useTheme();
    const scriptedRef = useScriptRef();
    const { state, setState, setShowForm } = useContext(RegisterContext);

    const [loading, setLoading] = useState(false);

    const verifyOTP = async (otp) => {
        setLoading(true);
        try {
            const res = await axios({
                method: 'post',
                url: `${BASE_URL}api/accounts/VerifySignupToken`,
                data: {
                    email: state?.email,
                    code: otp
                }
            });

            if (res?.data) {
                setState({ email: state?.email, code: res?.data?.code });
                setShowForm('SignupForm');
            }
        } catch (error) {
            toast.error(error?.response?.data?.message || error?.response?.data || error?.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Formik
                initialValues={{
                    otp: '',
                    submit: null
                }}
                validationSchema={Yup.object().shape({
                    otp: Yup.number()
                        .typeError('OTP must be a number')
                        .integer('OTP must be an integer')
                        .required('OTP is required')
                        .min(1000, 'OTP must be at least 4 digits')
                        .max(9999, 'OTP must be at most 4 digits')
                })}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    try {
                        if (scriptedRef.current) {
                            setStatus({ success: true });
                            setSubmitting(false);

                            const { otp } = values;
                            verifyOTP(otp);
                        }
                    } catch (err) {
                        console.error(err);
                        if (scriptedRef.current) {
                            setStatus({ success: false });
                            setErrors({ submit: err.message });
                            setSubmitting(false);
                        }
                    }
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
                    <form noValidate onSubmit={handleSubmit} {...others}>
                        <FormControl fullWidth error={Boolean(touched.otp && errors.otp)} sx={{ ...theme.typography.customInput }}>
                            <InputLabel htmlFor="outlined-adornment-otp-login">OTP</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-otp-login"
                                value={values.otp}
                                name="otp"
                                onBlur={handleBlur}
                                type="number"
                                onChange={handleChange}
                                label="OTP"
                                inputProps={{}}
                            />
                            {touched.otp && errors.otp && (
                                <FormHelperText error id="standard-weight-helper-text-otp-login">
                                    {errors.otp}
                                </FormHelperText>
                            )}
                        </FormControl>

                        <Box sx={{ mt: 2 }}>
                            <AnimateButton>
                                <Button
                                    disableElevation
                                    disabled={loading}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    sx={{
                                        backgroundColor: COLORS.primary
                                    }}
                                >
                                    {loading ? <CircularProgress size={25} color="inherit" /> : 'Submit'}
                                </Button>
                            </AnimateButton>
                        </Box>
                    </form>
                )}
            </Formik>
        </>
    );
};

export default OTPVerificationForm;
