import { Card, Typography } from '@mui/material';
import { BASE_URL } from 'constants/baseUrl';
import React from 'react';

export default function DomainLogo({ title, logo, domain, selectedDomain = null, onClick }) {
    return (
        <Card variant="outlined" sx={[styles.container, { backgroundColor: selectedDomain ? '#d1e7ff' : '#f5f5f5' }]} onClick={onClick}>
            <img alt={'healthcare-logo'} src={`${BASE_URL}/media/logo/${logo}`} style={styles.domainLogo} />
            <Typography sx={{ textAlign: 'center', mt: 1 }}>{title}</Typography>
        </Card>
    );
}

const styles = {
    container: {
        width: 120,
        padding: 1,
        backgroundColor: '#f5f5f5',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        transition: '0.4s',
        '&:hover': {
            backgroundColor: '#d1e7ff',
            cursor: 'pointer',
            transition: '0.4s'
        }
    },
    domainLogo: {
        height: 70,
        width: 70,
        borderRadius: 40
    }
};
