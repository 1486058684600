// project imports
import React, { useState, useEffect, useContext, memo, useRef } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, CircularProgress, Typography } from '@mui/material';
import { BASE_URL } from 'constants/baseUrl';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getData } from 'utils/indexedDB';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { MeetingContext } from 'views/meeting/MeetingContext';

const DiagnosisComp = ({ visit, diagPlanRef }) => {
    const { user } = useSelector((state) => state?.user);

    // ----------------------------  Getting Patient Diagnosis List from Context
    const { diagnosisList, setDiagnosisList, getDiagnosisByVisit } = useContext(MeetingContext);

    const [diagnosis, setDiagnosis] = useState(null);
    const [loading, setLoading] = useState(true);

    // ---------------------------- Getting All Diagnosis selectable list from indexed DB
    useEffect(() => {
        getData('diagnosis').then((res) => {
            setDiagnosis(res);
            setLoading(false);
        });
    }, []);

    let diagRef = useRef('');

    const handleAdd = async () => {
        if (diagRef?.current?.value == '') {
            return toast.error('Please select diagnosis');
        }

        const diag = {
            Consultant_NationalID: visit.consultant_NationalID,
            Patient_NationalID: visit.patient_NationalID,
            code: diagRef?.current?.value?.split(':')[0].trim(),
            VisitID: visit.id,
            medicineName: diagRef?.current?.value?.split(':')[1].trim(),
            createdBy: user.userId,
            createdOn: new Date().toLocaleString(),
            name: diagRef?.current?.value?.split(':')[1].trim()
        };

        setDiagnosisList([...diagnosisList, diag]);

        diagRef.current.value = '';

        const resDiag = await axios({
            method: 'post',
            url: `${BASE_URL}api/patientdiagnostic`,
            data: diag,
            headers: {
                Authorization: `Bearer ${user?.token}`
            }
        });

        if (resDiag?.data) {
            getDiagnosisByVisit();
        }
    };

    const handleDelete = async (diagnosis) => {
        const newList = diagnosisList?.filter((item) => item?.id !== diagnosis?.id);
        setDiagnosisList(newList);

        const resDiag = await axios({
            method: 'delete',
            url: `${BASE_URL}api/patientdiagnostic/${diagnosis?.id}`,
            data: {
                deleteOn: new Date(),
                deleteBy: user?.userId
            },
            headers: {
                Authorization: `Bearer ${user?.token}`
            }
        });

        if (resDiag?.data) {
            getDiagnosisByVisit();
        }
    };

    return (
        <Box sx={{ backgroundColor: '#fff', padding: 0.5, borderRadius: 2 }}>
            {diagnosis && (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <input
                            placeholder={'Diagnosis and Plan'}
                            type="text"
                            ref={diagPlanRef}
                            style={styles.inputField}
                            onFocus={(e) => {
                                e.target.style.border = '2px solid #2196f3'; // change border color on focus
                            }}
                            onBlur={(e) => {
                                e.target.style.border = '1px solid #bfc0c2'; // revert border color on blur
                            }}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', flex: 1, gap: 0.5 }}>
                        <AutoCompleteCom
                            listName={'diagnosis'}
                            data={diagnosis}
                            inputref={diagRef}
                            placeholder={'Select Diagnosis'}
                            showKey={['code', 'name', 'diseaseDiscription']}
                        />
                        <Button variant="outlined" onClick={handleAdd}>
                            Add
                        </Button>
                    </Box>
                </Box>
            )}

            {diagnosisList?.length > 0 && (
                <Box sx={[styles.scrollBarStyle, { maxHeight: '24.5vh' }]}>
                    {/* -----------------------------  Current Diagnosis */}
                    <Accordion defaultExpanded={true}>
                        <AccordionSummary
                            sx={{ px: 1 }}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography sx={styles?.accordianTitle}>
                                Current Diagnosis - {diagnosisList?.filter((i) => i.isDeleted == false).length}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ p: 0, mt: -2 }}>
                            {diagnosisList?.map((item, index) => (
                                <div key={index}>
                                    {item?.isDeleted == false && (
                                        <DiagnosisCard diagnosis={item} btnTitle={'Remove'} onClick={() => handleDelete(item)} />
                                    )}
                                </div>
                            ))}
                        </AccordionDetails>
                    </Accordion>

                    {/* -----------------------------  Discountinued Diagnosis */}
                    <Accordion>
                        <AccordionSummary
                            sx={{ px: 1 }}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography sx={styles?.accordianTitle}>
                                Discountinued Diagnosis - {diagnosisList?.filter((i) => i.isDeleted == true).length}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ p: 0, mt: -2 }}>
                            {diagnosisList?.map((item, index) => (
                                <div key={index}>{item?.isDeleted == true && <DiagnosisCard diagnosis={item} />}</div>
                            ))}
                        </AccordionDetails>
                    </Accordion>
                </Box>
            )}

            {loading && (
                <Box sx={styles.loadingBox}>
                    <CircularProgress size={35} color="inherit" />
                </Box>
            )}
        </Box>
    );
};

export default React.memo(DiagnosisComp, (prevProps, nextProps) => {
    // Compare relevant props, excluding data
    return prevProps.visit === nextProps.visit;
});

const AutoCompleteCom = memo(({ data, inputref, placeholder, showKey, listName }) => {
    return (
        <div style={{ width: '100%' }}>
            <input
                list={listName}
                placeholder={placeholder}
                style={styles.inputField}
                type="text"
                ref={inputref}
                onFocus={(e) => {
                    e.target.style.border = '2px solid #2196f3'; // change border color on focus
                }}
                onBlur={(e) => {
                    e.target.style.border = '1px solid #bfc0c2'; // revert border color on blur
                }}
            />
            <datalist id={listName}>
                {data &&
                    data.map((item, index) => (
                        <option key={index} value={`${item[showKey[0]]} : ${item[showKey[1]]} ( ${item[showKey[2]]} )`} />
                    ))}
            </datalist>
        </div>
    );
});

const DiagnosisCard = React.memo(({ diagnosis, btnTitle, onClick }) => {
    const { code, name } = diagnosis;

    return (
        <Box sx={styles.prescriptionCardContainer}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography sx={{ fontWeight: '600', flex: 1, gap: 1 }}>
                    {code}: {name}
                </Typography>

                {btnTitle && (
                    <Button size="small" sx={{ p: 0, color: 'red' }} variant="standard" onClick={onClick}>
                        {btnTitle}
                    </Button>
                )}
            </Box>
        </Box>
    );
});

// -----------------------  Styling Sheet
const styles = {
    inputField: {
        padding: 10,
        borderRadius: 10,
        border: '1px solid #bfc0c2',
        fontSize: 14,
        width: '100%',
        outline: 'none',
        backgroundColor: '#f8fafc'
    },
    loadingBox: {
        display: 'flex',
        flex: 1,
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    addBtn: {
        backgroundColor: '#fff',
        '&:hover': {
            backgroundColor: '#d1e7ff'
        }
    },
    prescriptionCardContainer: {
        p: 0.5,
        m: 0.5,
        borderRadius: 2,
        backgroundColor: '#eef2f6'
    },
    accordianTitle: { fontSize: 16, fontWeight: 600 },
    scrollBarStyle: {
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: '0.2em'
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            borderRadius: 10
        }
    }
};
