import CloseIcon from '@mui/icons-material/Close';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Card, CircularProgress, Grid, IconButton, Paper, Typography } from '@mui/material';

// project imports
import { IconStethoscope, IconWheelchair, IconPlus } from '@tabler/icons';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { useDispatch, useSelector } from 'react-redux';
import { saveUserData } from 'store/slices/userSlice';
import ModalCustom from 'ui-component/modals/ModalCustom';
import CreateProfile from './CreateProfile';
import { useState } from 'react';
import { profileImage } from 'utils/fetchImage';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getPhysicianProfile, loginUser } from 'services/loginUser';
import AddProfiles from './AddProfiles';
import { decrypt } from 'utils/ecryptDecrypt';

// ===============================|| AUTH3 - REGISTER ||=============================== //

const SwitchProfile = () => {
    const theme = useTheme();
    const { user } = useSelector((state) => state.user);
    const { profiles } = useSelector((state) => state.profiles);
    const dispatch = useDispatch();

    const [loadRole, setLoadRole] = useState({ load: false, role: null });

    const [showCreateProfile, setShowCreateProfile] = useState(false);

    const switchRole = async (role) => {
        setLoadRole({ load: true, role: role });
        try {
            let resUserProfile =
                role == 'Physician'
                    ? await getPhysicianProfile(user?.token, user?.userId)
                    : await loginUser(user?.email, decrypt(user?.key), user?.token);
            const { profileImage, name, lastName, titles, identificationNo, nationalID, dob } = resUserProfile?.data[0];

            const userUp = {
                ...user,
                profileImage,
                firstName: name,
                lastName,
                fullName: `${name} ${lastName}`,
                nationalID,
                identificationNo,
                relation: 'self',
                dob,
                titles,
                openModal: false,
                ...(role !== 'Physician' && resUserProfile?.data[0]),
                roles: role
            };
            // console.log('userUp ', userUp);

            localStorage.setItem('user', JSON.stringify(userUp));
            dispatch(saveUserData(userUp));
        } catch (error) {
            if (error?.response?.data == 'Email or Password is incorrect') {
                localStorage.clear();
                window.location.replace('/login');
                dispatch(saveUserData(null));
                sessionStorage.clear();
                deleteData();
                return;
            }
            return toast.error(error?.response?.data?.message ?? error?.response?.data ?? error?.message);
        } finally {
            setLoadRole({ load: false, role: null });
        }
    };

    const switchProfile = (profile) => {
        const { profileImage, name, lastName, titles, identificationNo, nationalID, dob, relation } = profile;
        const userUp = {
            ...user,
            profileImage,
            firstName: name,
            lastName,
            fullName: `${name} ${lastName}`,
            nationalID,
            identificationNo,
            roles: 'Patient',
            relation,
            dob,
            titles,
            openModal: false
        };

        localStorage.setItem('user', JSON.stringify(userUp));
        dispatch(saveUserData(userUp));
    };

    return (
        <div>
            <AddProfiles />

            <Paper sx={{ display: 'flex', gap: 2, p: 2 }}>
                {user?.allRoles?.includes('Physician') && (
                    <Card onClick={() => switchRole('Physician')} variant="outlined" sx={styles.boxStyle}>
                        {loadRole?.load == true && loadRole?.role == 'Physician' ? (
                            <CircularProgress size={35} />
                        ) : (
                            <>
                                <IconStethoscope size={40} />
                                <Typography variant="h5" sx={{ mt: 0.5 }}>
                                    Physician
                                </Typography>
                            </>
                        )}
                    </Card>
                )}

                {user?.allRoles?.includes('Admin') && (
                    <Card onClick={() => switchRole('Admin')} variant="outlined" sx={styles.boxStyle}>
                        {loadRole?.load == true && loadRole?.role == 'Admin' ? (
                            <CircularProgress size={35} />
                        ) : (
                            <>
                                <AdminPanelSettingsIcon sx={{ fontSize: 40 }} />
                                <Typography variant="h5" sx={{ mt: 0.5 }}>
                                    Admin
                                </Typography>
                            </>
                        )}
                    </Card>
                )}

                {user?.allRoles?.includes('Receptionist') && (
                    <Card onClick={() => switchRole('Receptionist')} variant="outlined" sx={styles.boxStyle}>
                        {loadRole?.load == true && loadRole?.role == 'Receptionist' ? (
                            <CircularProgress size={35} />
                        ) : (
                            <>
                                <AdminPanelSettingsIcon sx={{ fontSize: 40 }} />
                                <Typography variant="h5" sx={{ mt: 0.5 }}>
                                    Receptionist
                                </Typography>
                            </>
                        )}
                    </Card>
                )}

                {profiles?.map((profile) => (
                    <Card onClick={() => switchProfile(profile)} variant="outlined" sx={styles.boxStyle}>
                        <img alt={'Profile'} src={profileImage(profile?.profileImage)} style={styles.cardProfilePicture} />
                        <Typography variant="h5" sx={{ mt: 0.5, textAlign: 'center' }}>
                            {profile?.name}
                        </Typography>
                    </Card>
                ))}

                <Card onClick={() => setShowCreateProfile(true)} variant="outlined" sx={styles.boxStyle}>
                    <IconPlus size={40} />
                    <Typography variant="h5" sx={{ mt: 1 }}>
                        Create New
                    </Typography>
                </Card>

                <ModalCustom open={showCreateProfile} title={'Create New Profile'}>
                    <IconButton
                        color="inherit"
                        onClick={() => setShowCreateProfile(false)}
                        sx={{ position: 'absolute', top: 10, right: 10 }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <CreateProfile closeModal={() => setShowCreateProfile(false)} />
                </ModalCustom>
            </Paper>
        </div>
    );
};

export default SwitchProfile;

const styles = {
    boxStyle: {
        padding: 1,
        width: 110,
        height: 110,
        backgroundColor: '#f5f5f5',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        transition: '0.4s',
        '&:hover': {
            backgroundColor: '#d1e7ff',
            cursor: 'pointer',
            transition: '0.4s'
        }
    },
    cardProfilePicture: {
        width: 50,
        height: 50,
        borderRadius: 80 / 2,
        marginBottom: 5,
        objectFit: 'cover'
    }
};
