import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import PhysicianRoutes from './PhysicianRoutes';
import PatientRoutes from './PatientRoutes';
import NurseRoutes from './NurseRoutes';
import ReceptionistRoutes from './ReceptionistRoutes';
import { useDispatch, useSelector } from 'react-redux';
import { saveUserData } from 'store/slices/userSlice';
import { useContext, useEffect } from 'react';
import { BASE_URL } from 'constants/baseUrl';
import axios from 'axios';
import { saveConfig } from 'store/slices/configSlice';
import jwt_decode from 'jwt-decode';
import { getToken } from 'services/getToken';
import { decrypt } from 'utils/ecryptDecrypt';
import { getProfiles } from 'services/loginUser';
import { saveProfileData } from 'store/slices/profileSlice';
import { deleteData } from 'utils/indexedDB';
import TranslationContext from 'context/translation';
import DCMViewer from '../components/dicom';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    const dispatch = useDispatch();
    const { setLanguage } = useContext(TranslationContext);

    const getconfig = async (token) => {
        try {
            const res = await axios({
                method: 'get',
                url: `${BASE_URL}api/SetupItem/GetByType/Config`,
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            dispatch(saveConfig(res?.data));
        } catch (error) {
            console.log('Error while route/index.js line 32 ', error);
        }
    };

    const getTokenAndSave = async (user) => {
        const token = await getToken(user?.email, decrypt(user?.key), user?.domain);
        localStorage.setItem('user', JSON.stringify({ ...user, token }));
        !!user && dispatch(saveUserData({ ...user, token }));
        getUserProfiles(token, user?.userId);
        getconfig(token);
    };

    const getUserProfiles = async (token, userId) => {
        const resProfile = await getProfiles(token, userId);
        dispatch(saveProfileData(resProfile?.data));
    };

    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem('user'));
        const lang = localStorage.getItem('lang');
        setLanguage(lang);

        if (!!userData) {
            const currentTime = Date.now() / 1000;
            try {
                const decodedToken = jwt_decode(userData?.token);
                if (decodedToken?.exp < currentTime) {
                    getTokenAndSave(userData);
                } else {
                    dispatch(saveUserData(userData));
                    getUserProfiles(userData?.token, userData?.userId);
                    getconfig(userData?.token);
                }
            } catch (error) {
                localStorage.clear();
                window.location.replace('/login');
                dispatch(saveUserData(null));
                sessionStorage.clear();
                deleteData();
            }
        }
    }, []);

    const { user } = useSelector((state) => state.user);

    const role = user?.roles ?? 'Admin';

    let showRoute = {
        Admin: MainRoutes,
        Physician: PhysicianRoutes,
        Patient: PatientRoutes,
        Nurse: NurseRoutes,
        Receptionist: ReceptionistRoutes
    };

    let isLoggedIn = !!user;

    let HomeRoute = showRoute[role];
    let RootRoute = isLoggedIn ? HomeRoute : AuthenticationRoutes;

    return useRoutes([RootRoute, { path: '/dcm-viewer/:domain/:dcmId', element: <DCMViewer /> }]);
}
