import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { COLORS } from 'constants/colors';
import React from 'react';
import moment from 'moment';
import PrintHeader from 'views/components/PrintHeader';
import { useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';

const PrintBillingDetail = React.forwardRef((props, ref) => {
    const { user } = useSelector((state) => state?.user);
    var decoded = jwt_decode(user?.token);

    const { billingDetail } = props;

    return (
        <div ref={ref} style={{ padding: 50 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <PrintHeader />
                    <Typography sx={{ fontSize: 40, fontWeight: 'bold', marginLeft: 3 }}>{decoded?.Name}</Typography>
                </Box>
                <Typography>Date: {moment(new Date()).format('MMM DD, YYYY - hh:mm A')}</Typography>
            </Box>

            <Box sx={{ p: 2, my: 3, border: '2px solid #000' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex' }}>
                        <Typography sx={{ fontWeight: 'bold', width: 130 }}>Bill Id:</Typography>
                        <Typography>{billingDetail?.billId}</Typography>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: 250 }}>
                        <Typography sx={{ fontWeight: 'bold' }}>Date:</Typography>
                        <Typography>{moment(billingDetail?.billDate).format('MMM DD, YYYY - hh:mm A')}</Typography>
                    </Box>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex' }}>
                        <Typography sx={{ fontWeight: 'bold', width: 130 }}>Patient Name:</Typography>
                        <Typography>
                            {billingDetail?.bill_Name || `${billingDetail?.patientFirstName} ${billingDetail?.patientLastName}`}
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: 250 }}>
                        <Typography sx={{ fontWeight: 'bold' }}>Status:</Typography>
                        <Typography>{billingDetail?.status}</Typography>
                    </Box>
                </Box>

                <Box sx={{ display: 'flex', width: 350 }}>
                    <Typography sx={{ fontWeight: 'bold', width: 130 }}>Physician Name:</Typography>
                    <Typography>{billingDetail?.consultantName?.split('/')[0]}</Typography>
                </Box>

                {billingDetail?.note && (
                    <Box sx={{ display: 'flex' }}>
                        <Typography sx={{ fontWeight: 'bold', width: 130 }}>Note:</Typography>
                        <Typography>{billingDetail?.note}</Typography>
                    </Box>
                )}
            </Box>

            <TableContainer sx={{ maxHeight: 'auto' }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow
                            sx={{
                                '& th': {
                                    color: '#fff',
                                    backgroundColor: COLORS.secondory
                                }
                            }}
                        >
                            <TableCell align={'left'}>Item</TableCell>

                            <TableCell align={'right'}>Quantity</TableCell>

                            <TableCell align={'right'}>Rate</TableCell>

                            <TableCell align={'right'}>Amount</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!!billingDetail &&
                            billingDetail?.billItems?.map((row) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id} sx={{ '&:hover': { cursor: 'pointer' } }}>
                                        <TableCell align={'left'}>{row?.name}</TableCell>

                                        <TableCell align={'right'}>{row?.quantity}</TableCell>

                                        <TableCell align={'right'}>{row?.rate}</TableCell>

                                        <TableCell align={'right'}>{row?.amount}</TableCell>
                                    </TableRow>
                                );
                            })}

                        <TableRow>
                            <TableCell align={'left'}>Discount: {billingDetail?.discount?.toFixed(2)}</TableCell>

                            <TableCell align={'left'}>Net Amount: {billingDetail?.netAmount?.toFixed(2)}</TableCell>

                            <TableCell align={'right'}>Total</TableCell>

                            <TableCell align={'right'}>{billingDetail?.totalAmount?.toFixed(2)}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
});

export default PrintBillingDetail;
