import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    profiles: null
};

export const profileSlice = createSlice({
    name: 'profiles',
    initialState,
    reducers: {
        saveProfileData: (state = initialState, action) => {
            state.profiles = action.payload;
        },
        removeProfileData: (state = initialState) => {
            state.profiles = null;
        }
    }
});

export const { saveProfileData, removeProfileData } = profileSlice.actions;

export default profileSlice.reducer;
