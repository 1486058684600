import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Button, CircularProgress, Grid, IconButton, Typography, Box, TextField } from '@mui/material';
import { IconPlus } from '@tabler/icons';
import { COLORS } from 'constants/colors';
import PrintIcon from '@mui/icons-material/Print';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import { BASE_URL } from 'constants/baseUrl';
import axios from 'axios';
import { useSelector } from 'react-redux';
import useFetch from 'hooks/useFetch';
import moment from 'moment';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ModalConfirmation from 'ui-component/modals/ModalConfirmation';
import ModalCustom from 'ui-component/modals/ModalCustom';
import { useLocation } from 'react-router';
import { useEffect } from 'react';
import { getData } from 'utils/indexedDB';
import PrintBillingDetail from './PrintBillingDetail';
import { useReactToPrint } from 'react-to-print';

export default function Location() {
    const { user } = useSelector((state) => state?.user);
    const { state } = useLocation();

    const [openModal, setOpenModal] = useState(false);
    const [openUpdateModal, setOpenUpdateModal] = useState(false);
    const [openAddModal, setOpenAddModal] = useState(false);

    const [loading, setLoading] = useState(false);
    const [loadingReq, setLoadingReq] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    const [netAmount, setNetAmount] = useState(state?.netAmount);
    const [totalAmount, setTotalAmount] = useState(state?.amount);

    useEffect(() => {
        getData('medicine').then((res) => {
            setMedicine(res);
            return;
        });
    }, []);
    const [medicine, setMedicine] = useState(null);

    const {
        data: billItemList,
        loading: loadingBillItemList,
        error: errorBillItemList,
        refetch: refetchBillItemList
    } = useFetch(`${BASE_URL}api/billitem/BillItemList/${state?.billId}`);

    let medRef = React.useRef('');
    const [rate, setRate] = useState(0);
    const [quantity, setQuantity] = useState(1);

    const handleAdd = async () => {
        if (medRef?.current?.value == '') {
            return toast.error('Please select medicine');
        }
        if (quantity <= 0 || quantity == null) {
            return toast.error('Quantity should be 1 or more');
        }
        if (rate < 0 || rate == null) {
            return toast.error('Please enter rate');
        }
        setLoadingReq(true);
        try {
            const resp = await axios({
                method: 'post',
                url: `${BASE_URL}api/billitem`,
                data: {
                    BillId: state?.billId,
                    medicineID: medRef?.current?.value.split('-')[0].trim(),
                    quantity: parseFloat(quantity),
                    rate: parseFloat(rate),
                    amount: rate * quantity,
                    createdBy: user?.userId,
                    createdOn: new Date()
                },
                headers: {
                    Authorization: `Bearer ${user?.token}`
                }
            });

            setTotalAmount((pre) => pre + rate * quantity);
            setNetAmount((pre) => pre + rate * quantity);

            if (resp?.data) {
                const resp1 = await axios({
                    method: 'put',
                    url: `${BASE_URL}api/bill/${state?.billId}`,
                    data: {
                        ...state,
                        amount: totalAmount,
                        netAmount: netAmount,
                        paidAmount: netAmount,
                        lastModifiedOn: new Date(),
                        lastModifiedBy: user?.userId
                    },
                    headers: {
                        Authorization: `Bearer ${user?.token}`
                    }
                });
                if (resp1?.data) {
                    refetchBillItemList();
                    medRef.current.value = '';
                    setOpenAddModal(false);
                    setQuantity(1);
                    setRate(0);
                    return toast.success('Medicine added successfully');
                }
            }
        } catch (error) {
            return toast.error(error?.response?.data?.message ?? error?.response?.data ?? error?.message);
        } finally {
            setLoadingReq(false);
        }
    };

    const handleUpdate = async () => {
        setLoadingReq(true);
        try {
            const resp = await axios({
                method: 'put',
                url: `${BASE_URL}api/billitem/${selectedRow?.billItemId}`,
                data: { ...selectedRow, quantity, rate, amount: quantity * rate, lastModifiedOn: new Date(), lastModifiedBy: user?.userId },
                headers: {
                    Authorization: `Bearer ${user?.token}`
                }
            });

            setTotalAmount((pre) => pre - selectedRow?.amount + rate * quantity);
            setNetAmount((pre) => pre - selectedRow?.amount + rate * quantity);

            if (resp?.data) {
                const resp1 = await axios({
                    method: 'put',
                    url: `${BASE_URL}api/bill/${selectedRow?.billId}`,
                    data: {
                        ...state,
                        amount: totalAmount,
                        netAmount: netAmount,
                        paidAmount: netAmount,
                        lastModifiedOn: new Date(),
                        lastModifiedBy: user?.userId
                    },
                    headers: {
                        Authorization: `Bearer ${user?.token}`
                    }
                });
                if (resp1?.data) {
                    refetchBillItemList();
                    setOpenUpdateModal(false);
                    setQuantity(1);
                    setRate(0);
                    return toast.success('Medicine updated successfully');
                }
            }
        } catch (error) {
            return toast.error(error?.response?.data?.message ?? error?.response?.data ?? error?.message);
        } finally {
            setLoadingReq(false);
        }
    };

    const handleDelete = async () => {
        setLoadingReq(true);
        try {
            const resp = await axios({
                method: 'delete',
                url: `${BASE_URL}api/billitem/${selectedRow?.billItemId}`,
                data: { ...selectedRow, quantity, rate, amount: quantity * rate, lastModifiedOn: new Date(), lastModifiedBy: user?.userId },
                headers: {
                    Authorization: `Bearer ${user?.token}`
                }
            });

            setTotalAmount((pre) => pre - selectedRow?.amount);
            setNetAmount((pre) => pre - selectedRow?.amount);

            if (resp?.data) {
                const resp1 = await axios({
                    method: 'put',
                    url: `${BASE_URL}api/bill/${selectedRow?.billId}`,
                    data: {
                        ...state,
                        amount: totalAmount,
                        netAmount: netAmount,
                        paidAmount: netAmount,
                        lastModifiedOn: new Date(),
                        lastModifiedBy: user?.userId
                    },
                    headers: {
                        Authorization: `Bearer ${user?.token}`
                    }
                });
                if (resp1?.data) {
                    refetchBillItemList();
                    setOpenModal(false);
                    return toast.success('Medicine deleted successfully');
                }
            }
        } catch (error) {
            return toast.error(error?.response?.data?.message ?? error?.response?.data ?? error?.message);
        } finally {
            setLoadingReq(false);
        }
    };

    const billingDetailRef = React.useRef(null);
    const handlePrintDetail = useReactToPrint({
        content: () => billingDetailRef.current
    });

    return (
        <div>
            <Grid mb={2} container direction="row" justifyContent="space-between" alignItems="center" sx={{ py: 1 }}>
                <Typography variant="h3" color={COLORS.color1}>
                    Patient Bill
                </Typography>

                <IconButton onClick={handlePrintDetail} title="Print Bill Detail">
                    <PrintIcon />
                </IconButton>
            </Grid>

            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <Grid container justifyContent="space-between" alignItems="center" p={2} rowGap={2}>
                    <Grid item lg={1}>
                        <Typography>Bill Id: {state?.billId}</Typography>
                    </Grid>
                    <Grid item lg={3}>
                        <Typography>Patient Name: {state?.patientName}</Typography>
                    </Grid>
                    <Grid item lg={3}>
                        <Typography>Physician Name: {state?.consultantName?.split('/')[0]}</Typography>
                    </Grid>
                    <Grid item lg={2}>
                        <Typography>Status: {state?.status}</Typography>
                    </Grid>
                    <Grid item lg={3}>
                        <Typography>Date: {moment(state?.billDate).format('MMM DD, YYYY - hh:mm A')}</Typography>
                    </Grid>
                    <Grid item lg={4}>
                        <Typography>Note: {state?.note}</Typography>
                    </Grid>
                    <Grid item lg={3}>
                        <Typography>Discount: {state?.discount?.toFixed(2)}</Typography>
                    </Grid>
                    <Grid item lg={2}>
                        <Typography>Net Amount: {netAmount?.toFixed(2)}</Typography>
                    </Grid>
                    <Grid item lg={3}>
                        <Typography>Total Amount: {totalAmount?.toFixed(2)}</Typography>
                    </Grid>
                </Grid>

                <TableContainer sx={{ maxHeight: 'auto' }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow
                                sx={{
                                    '& th': {
                                        color: '#fff',
                                        backgroundColor: COLORS.secondory
                                    }
                                }}
                            >
                                <TableCell align={'left'}>Item</TableCell>

                                <TableCell align={'right'}>Quantity</TableCell>

                                <TableCell align={'right'}>Rate</TableCell>

                                <TableCell align={'right'}>Amount</TableCell>

                                <TableCell align="right" colSpan={2}>
                                    <Button
                                        onClick={() => setOpenAddModal(true)}
                                        variant="contained"
                                        startIcon={<IconPlus size={17} />}
                                        sx={{ backgroundColor: COLORS.primary, '&: hover': { background: COLORS.secondory } }}
                                    >
                                        New Item
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!!billItemList &&
                                billItemList?.map((row) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.id}
                                            sx={{ '&:hover': { cursor: 'pointer' } }}
                                        >
                                            <TableCell align={'left'}>{row?.name}</TableCell>

                                            <TableCell align={'right'}>{row?.quantity?.toFixed(2)}</TableCell>

                                            <TableCell align={'right'}>{row?.rate?.toFixed(2)}</TableCell>

                                            <TableCell align={'right'}>{row?.amount?.toFixed(2)}</TableCell>

                                            <TableCell align={'right'}>
                                                <IconButton
                                                    sx={{ m: -1 }}
                                                    title="Edit Item"
                                                    onClick={() => {
                                                        setSelectedRow(row);
                                                        setQuantity(row?.quantity);
                                                        setRate(row?.rate);
                                                        setOpenUpdateModal(true);
                                                    }}
                                                >
                                                    <BorderColorIcon style={{ color: COLORS.secondory }} />
                                                </IconButton>
                                            </TableCell>

                                            <TableCell align="center">
                                                <IconButton
                                                    sx={{ m: -1 }}
                                                    title="Delete Item"
                                                    onClick={() => {
                                                        setOpenModal(true);
                                                        setSelectedRow(row);
                                                    }}
                                                >
                                                    <DeleteIcon style={{ color: 'red' }} />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}

                            {loadingBillItemList && (
                                <TableRow sx={{ height: 300 }}>
                                    <TableCell align="center" colSpan={6}>
                                        <CircularProgress size={35} color="inherit" />
                                    </TableCell>
                                </TableRow>
                            )}

                            {!!errorBillItemList && (
                                <TableRow sx={{ height: 300 }}>
                                    <TableCell align="center" colSpan={6}>
                                        <Typography>{errorBillItemList?.response?.data?.message ?? errorBillItemList?.message}</Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>

            <ModalConfirmation
                open={openModal}
                header={'Delete Confirmation'}
                description={'Are you sure, you want to delete this medicine?'}
                loading={loadingReq}
                onClickNo={() => setOpenModal(false)}
                onClickYes={() => handleDelete()}
            />

            <ModalCustom open={openUpdateModal} title={'Update Medicine'}>
                <Grid container direction="row" spacing={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <TextField value={`${selectedRow?.medicineID} - ${selectedRow?.name}`} fullWidth size="small" label="Medicine" />
                    </Grid>

                    <Grid item lg={4} md={4} sm={4} xs={12}>
                        <TextField
                            value={quantity}
                            onChange={(e) => setQuantity(e.target.value)}
                            fullWidth
                            type="number"
                            size="small"
                            label="Quantity"
                            inputProps={{
                                min: 1, // Minimum value
                                max: 100 // Maximum value
                            }}
                        />
                    </Grid>

                    <Grid item lg={4} md={4} sm={4} xs={12}>
                        <TextField
                            value={rate}
                            onChange={(e) => setRate(e.target.value)}
                            fullWidth
                            type="number"
                            size="small"
                            label="Rate"
                            inputProps={{
                                min: 0 // Minimum value
                            }}
                        />
                    </Grid>

                    <Grid item lg={4} md={4} sm={4} xs={12}>
                        <TextField value={quantity * rate} label="Amount" size="small" fullWidth />
                    </Grid>

                    <Grid item lg={12} md={12} sm={12} xs={12} mt={1}>
                        <Box sx={styles.btnContainer}>
                            {loadingReq ? (
                                <CircularProgress size={25} color="inherit" />
                            ) : (
                                <>
                                    <Button onClick={() => setOpenUpdateModal(false)} variant="text" sx={{ color: 'red' }}>
                                        Cancel
                                    </Button>
                                    <Button type="submit" onClick={handleUpdate} variant="text" sx={{ color: COLORS.secondory }}>
                                        Update
                                    </Button>
                                </>
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </ModalCustom>

            <ModalCustom open={openAddModal} title={'Add Medicine'}>
                <Grid container direction="row" spacing={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <AutoCompleteCom
                            listName={'medicine'}
                            data={medicine}
                            inputref={medRef}
                            placeholder={'Select Medicine'}
                            showKey={['medicineID', 'name', 'genericName']}
                            onClick={(selectedMed) => setRate(selectedMed?.rate || 0)}
                        />
                    </Grid>

                    <Grid item lg={4} md={4} sm={4} xs={12}>
                        <TextField
                            value={quantity}
                            onChange={(e) => setQuantity(e.target.value)}
                            fullWidth
                            type="number"
                            size="small"
                            label="Quantity"
                            inputProps={{
                                min: 1, // Minimum value
                                max: 100 // Maximum value
                            }}
                        />
                    </Grid>

                    <Grid item lg={4} md={4} sm={4} xs={12}>
                        <TextField
                            value={rate}
                            onChange={(e) => setRate(e.target.value)}
                            fullWidth
                            type="number"
                            size="small"
                            label="Rate"
                            inputProps={{
                                min: 0 // Minimum value
                            }}
                        />
                    </Grid>

                    <Grid item lg={4} md={4} sm={4} xs={12}>
                        <TextField value={quantity * rate} label="Amount" size="small" fullWidth />
                    </Grid>

                    <Grid item lg={12} md={12} sm={12} xs={12} mt={1}>
                        <Box sx={styles.btnContainer}>
                            {loadingReq ? (
                                <CircularProgress size={25} color="inherit" />
                            ) : (
                                <>
                                    <Button
                                        onClick={() => {
                                            setOpenAddModal(false);
                                            setRate(0);
                                        }}
                                        variant="text"
                                        sx={{ color: 'red' }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button type="submit" onClick={handleAdd} variant="text" sx={{ color: COLORS.secondory }}>
                                        Add
                                    </Button>
                                </>
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </ModalCustom>

            <div style={{ display: 'none' }}>
                <PrintBillingDetail ref={billingDetailRef} billingDetail={{ ...state, totalAmount, netAmount, billItems: billItemList }} />
            </div>
        </div>
    );
}

const styles = {
    btnContainer: {
        display: 'flex',
        direction: 'row',
        gap: 1,
        justifyContent: 'flex-end'
    },
    inputField: {
        padding: 10,
        borderRadius: 10,
        border: '1px solid #bfc0c2',
        fontSize: 14,
        width: '100%',
        outline: 'none',
        backgroundColor: '#f8fafc'
    }
};

const AutoCompleteCom = React.memo(
    ({ data, inputref, placeholder, showKey, listName, onClick }) => {
        const handleOptionClick = (event) => {
            const selectedValue = event.target.value;
            const selectedObject = data.find(
                (item) =>
                    `${item[showKey[0]]} ${listName === 'medicine' ? `- ${item[showKey[1]]} - ${item[showKey[2]]}` : ''}` === selectedValue
            );
            if (selectedObject) {
                onClick(selectedObject);
            }
        };

        return (
            <div style={{ width: '100%' }}>
                <input
                    list={listName}
                    placeholder={placeholder}
                    style={styles.inputField}
                    type="text"
                    ref={inputref}
                    onFocus={(e) => {
                        e.target.style.border = '2px solid #2196f3'; // change border color on focus
                    }}
                    onBlur={(e) => {
                        e.target.style.border = '1px solid #bfc0c2'; // revert border color on blur
                    }}
                    onChange={handleOptionClick}
                />
                <datalist id={listName}>
                    {data &&
                        data.map((item, index) => (
                            <option
                                key={index}
                                value={`${item[showKey[0]]} ${listName == 'medicine' ? `- ${item[showKey[1]]} - ${item[showKey[2]]}` : ''}`}
                            />
                        ))}
                </datalist>
            </div>
        );
    },
    (prevProps, nextProps) => {
        // Compare relevant props, excluding data
        return prevProps.listName === nextProps.listName;
    }
);
