// project imports
import React, { useState, useEffect, useContext, memo } from 'react';
import { Box, Button, Checkbox, CircularProgress, Typography } from '@mui/material';
import { BASE_URL } from 'constants/baseUrl';
import { getData } from 'utils/indexedDB';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { MeetingContext } from 'views/meeting/MeetingContext';
import { useRef } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LabTestComp = ({ visit }) => {
    const { user } = useSelector((state) => state?.user);

    // ------------------------  Get Lab Test By VisitID from Context
    const { labTestList, setLabTestList, getLabTestByVisit } = useContext(MeetingContext);

    const [labTestType, setLabTestType] = useState(null);
    const [loading, setLoading] = useState(true);

    // ------------------------  Getting LabTest list from Indexed DB
    useEffect(() => {
        getData('labTestType').then((res) => {
            setLabTestType(res);
            setLoading(false);
        });
    }, []);

    // ------------------------  Setting Ref to handle selected lab test and instruction
    let labTestRef = useRef('');
    let instructionRef = useRef('');

    const handleAdd = async () => {
        if (labTestRef?.current?.value == '') {
            return toast.error('Please select lab test');
        }

        const lab = {
            Consultant_NationalID: visit?.consultant_NationalID,
            LabTestTypeID: labTestRef?.current?.value?.split('-')[0].trim(),
            Patient_NationalID: visit?.patient_NationalID,
            VisitID: visit?.id,
            CreatedOn: new Date(),
            CreatedBy: user?.userId,
            notes: instructionRef?.current?.value,
            code: labTestRef?.current?.value?.split('-')[0].trim(),
            test: labTestRef?.current?.value?.split(':')[1].trim()
        };

        //----------------------  Appending New Lab Test in LabTestList
        setLabTestList([lab, ...labTestList]);

        //-----------------------  Reseting all Values
        labTestRef.current.value = '';
        instructionRef.current.value = '';

        //-----------------------  Posting New Precription
        const resLab = await axios({
            method: 'post',
            url: `${BASE_URL}api/VisitLabTest`,
            data: lab,
            headers: {
                Authorization: `Bearer ${user?.token}`
            }
        });

        if (resLab?.data) {
            getLabTestByVisit();
        }
    };

    //------------------------------ Delete from LabTestList
    const handleDelete = async (labTest) => {
        const newList = labTestList?.filter((item) => item.visitLabTestID !== labTest?.visitLabTestID);
        setLabTestList(newList);

        const resDel = await axios({
            method: 'delete',
            url: `${BASE_URL}api/VisitLabTest/${labTest?.visitLabTestID}`,
            data: {
                deletedOn: new Date(),
                deletedBy: user?.userId
            },
            headers: {
                Authorization: `Bearer ${user?.token}`
            }
        });

        if (resDel?.data) {
            getLabTestByVisit();
        }
    };

    return (
        <Box sx={{ backgroundColor: '#fff', padding: 0.5, borderRadius: 2 }}>
            {labTestType && (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <AutoCompleteCom
                            listName={'labTestType'}
                            data={labTestType}
                            inputref={labTestRef}
                            placeholder={'Select Lab Test'}
                            showKey={['code', 'test']}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', flex: 1, gap: 0.5 }}>
                        <input
                            placeholder={'Instruction'}
                            type="text"
                            ref={instructionRef}
                            style={styles.inputField}
                            onFocus={(e) => {
                                e.target.style.border = '2px solid #2196f3'; // change border color on focus
                            }}
                            onBlur={(e) => {
                                e.target.style.border = '1px solid #bfc0c2'; // revert border color on blur
                            }}
                        />

                        {/* ------------------  Add Button */}
                        <Button variant="outlined" onClick={handleAdd}>
                            Add
                        </Button>
                    </Box>
                </Box>
            )}

            {labTestType && (
                <Box sx={[styles.scrollBarStyle, { maxHeight: '15vh' }]}>
                    {labTestList &&
                        labTestList?.map((item, index) => (
                            <Box key={index} sx={{ p: 0.5, m: 0.5, borderRadius: 2, backgroundColor: '#eef2f6' }}>
                                <Box sx={{ display: 'flex' }}>
                                    <Typography sx={{ fontWeight: '600', flex: 1 }}>
                                        {item?.code}: {item?.test}
                                    </Typography>
                                    <Button size="small" sx={{ p: 0, color: 'red' }} variant="standard" onClick={() => handleDelete(item)}>
                                        Delete
                                    </Button>
                                </Box>
                                {item?.notes && <Typography>{item?.notes}</Typography>}
                            </Box>
                        ))}
                </Box>
            )}

            {loading && (
                <Box sx={styles.loadingBox}>
                    <CircularProgress size={35} color="inherit" />
                </Box>
            )}
        </Box>
    );
};

export default React.memo(LabTestComp, (prevProps, nextProps) => {
    // Compare relevant props, excluding data
    return prevProps.visit.id === nextProps.visit.id;
});

const AutoCompleteCom = memo(
    ({ data, inputref, placeholder, showKey, listName }) => {
        return (
            <div style={{ width: '100%' }}>
                <input
                    list={listName}
                    placeholder={placeholder}
                    style={styles.inputField}
                    type="text"
                    ref={inputref}
                    onFocus={(e) => {
                        e.target.style.border = '2px solid #2196f3'; // change border color on focus
                    }}
                    onBlur={(e) => {
                        e.target.style.border = '1px solid #bfc0c2'; // revert border color on blur
                    }}
                />
                <datalist id={listName}>
                    {data &&
                        data.map((item, index) => (
                            <option key={index} value={`${item?.labTestTypeID} - ${item[showKey[0]]} : ${item[showKey[1]]}`} />
                        ))}
                </datalist>
            </div>
        );
    },
    (prevProps, nextProps) => {
        // Compare relevant props, excluding data
        return prevProps.listName === nextProps.listName;
    }
);

// -----------------------  Styling Sheet
const styles = {
    inputField: {
        padding: 10,
        borderRadius: 10,
        border: '1px solid #bfc0c2',
        fontSize: 14,
        width: '100%',
        outline: 'none',
        backgroundColor: '#f8fafc'
    },
    loadingBox: {
        display: 'flex',
        flex: 1,
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    addBtn: {
        backgroundColor: '#fff',
        '&:hover': {
            backgroundColor: '#d1e7ff'
        }
    },
    prescriptionCardContainer: {
        p: 0.5,
        m: 0.5,
        borderRadius: 3,
        backgroundColor: '#eef2f6'
    },
    accordianTitle: { fontSize: 16, fontWeight: 600 },
    scrollBarStyle: {
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: '0.2em'
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            borderRadius: 10
        }
    }
};
