import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiRequest } from 'utils/api';

const initialState = {
    data: null,
    isLoading: true,
    error: null
};

export const fetchPhysicianList = createAsyncThunk('data/fetchPhysicianList', async () => {
    try {
        const response = await apiRequest('get', 'api/physician/physicianSelectList?pageNumber=1&pageSize=100&QuerySearch=');
        return response;
    } catch (error) {
        throw error?.response?.data?.message || error?.response?.data || error?.message;
    }
});

const physicianListSlice = createSlice({
    name: 'physicianList',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchPhysicianList.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchPhysicianList.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = null;
                state.data = action.payload?.data;
            })
            .addCase(fetchPhysicianList.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error;
            });
    }
});

export default physicianListSlice.reducer;
