import { IconButton, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import { useSelector } from 'react-redux';

function FilterDiagnosis({ diagnosisData, gptDiagnosis: diag, visit, filtered, setFiltered }) {
    const { user } = useSelector((state) => state?.user);
    const [gptDiagnosis, setGPTDiagnosis] = useState(diag);
    const [selected, setSelected] = useState({});

    const [selectEdit, setSelectedEdit] = useState(null);
    const [editValue, setEditValue] = useState(null);

    const saveDiag = () => {
        let arr = gptDiagnosis.find((i) => i?.name == selectEdit);
        arr.name = editValue;
        setGPTDiagnosis(gptDiagnosis);
    };

    const addDiagnosis = (item) => {
        const singleItem = {
            visitID: visit?.id,
            consultant_NationalID: visit?.consultant_NationalID,
            patient_NationalID: visit?.patient_NationalID,
            code: item?.code,
            createdOn: new Date().toLocaleString(),
            createdBy: user.userId,
            medicineName: item?.name,
            ename: selected?.name
        };
        setFiltered((pre) => [...pre, singleItem]);
        setSelected({});
    };

    const deleteDiagnosis = (item) => {
        setFiltered((pre) => pre?.filter((i) => i?.code !== item?.code));
        setSelected({});
    };

    return (
        <div style={{ display: 'flex', flex: 1, backgroundColor: '#f5f5f5', marginTop: 15, borderRadius: 10, padding: 10, gap: 10 }}>
            <Box width={'50%'}>
                {gptDiagnosis
                    ?.filter((item1) => !filtered.some((item2) => item1.name?.includes(item2.ename)))
                    ?.map((item) => (
                        <Box display={'flex'} alignItems={'center'}>
                            {selectEdit === item?.name ? (
                                <TextField
                                    value={editValue}
                                    onChange={(e) => setEditValue(e?.target?.value)}
                                    fullWidth
                                    size="small"
                                    inputProps={{ style: { padding: 8 } }}
                                />
                            ) : (
                                <Box
                                    sx={{
                                        p: 1,
                                        cursor: 'pointer',
                                        bgcolor: item?.name == selected?.name ? '#d5d5d5' : 'none',
                                        borderRadius: 1,
                                        flex: 1
                                    }}
                                    onClick={() => {
                                        setSelected(item);
                                        setSelectedEdit(null);
                                    }}
                                >
                                    <Typography>{item?.name}</Typography>
                                </Box>
                            )}

                            {item?.name === selected?.name && (
                                <IconButton
                                    onClick={() => {
                                        if (selectEdit) {
                                            setSelectedEdit(null);
                                            saveDiag();
                                        } else {
                                            setSelectedEdit(item?.name);
                                            setEditValue(item?.name);
                                        }
                                    }}
                                    size="small"
                                >
                                    {selectEdit ? <CheckIcon fontSize="10" /> : <EditIcon fontSize="10" />}
                                </IconButton>
                            )}
                        </Box>
                    ))}
            </Box>
            <Box width={'50%'}>
                {diagnosisData?.map((item) => {
                    if (item?.name?.toLowerCase()?.includes(selected?.name?.toLowerCase()?.split(' ')[0]))
                        return (
                            <Box p={0.5} display={'flex'} alignItems={'center'}>
                                <Typography flex={1}>{item?.name}</Typography>
                                <IconButton onClick={() => addDiagnosis(item)} size="small">
                                    <AddIcon fontSize="10" />
                                </IconButton>
                            </Box>
                        );
                })}
            </Box>
            <Box width={'50%'}>
                {filtered?.map((item) => (
                    <Box p={0.5} my={0.3} bgcolor={'#e5e5e5'} borderRadius={1}>
                        <Box display={'flex'} alignItems={'center'}>
                            <Typography flex={1}>{item?.medicineName}</Typography>
                            <IconButton onClick={() => deleteDiagnosis(item)} size="small">
                                <DeleteIcon sx={{ color: 'red' }} fontSize="10" />
                            </IconButton>
                        </Box>
                    </Box>
                ))}
            </Box>
        </div>
    );
}

export default FilterDiagnosis;
