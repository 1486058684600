// project importsla
import React from 'react';
import MeetingRoom1 from './MeetingRoom1';
import MeetingRoom2 from './MeetingRoom2';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';

const Meeting = (props) => {
    const { state } = useLocation();
    const { user } = useSelector((state) => state?.user);

    return (
        <div>{user?.domain == 'idc' && user?.roles == 'Physician' ? <MeetingRoom2 state={state} /> : <MeetingRoom1 state={state} />}</div>
    );
};

export default Meeting;
