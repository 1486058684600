import React, { useEffect, useState, useRef, memo, useContext, useCallback } from 'react';
import { useSelector } from 'react-redux';
import useFetch from 'hooks/useFetch';
import { BASE_URL } from 'constants/baseUrl';
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    Paper,
    Switch,
    TextField,
    Typography
} from '@mui/material';
import axios from 'axios';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import LoadingSpinner from './LoadingSpinner';
const Diagnosis = (props) => {
    return (
        <Grid container columnSpacing={2} sx={{ width: '100%', height: '100%' }}>
            {/* -----------------------------  Button Grid */}

            <MedicineComp
                visitID={props.visitID}
                patient_NationalID={props.patient_NationalID}
                consultant_NationalID={props.consultant_NationalID}
                domain={props?.domain}
            />
        </Grid>
    );
};
export default Diagnosis;

const MedicineComp = (props) => {
    const { user } = useSelector((state) => state.user);

    const {
        data: patientDiagnosis,
        loading: loadingPatientDiagnosis,
        error: errorPatientPrescription,
        refetch: refetchPatientDiagnosis
    } = useFetch(`${BASE_URL}api/patientdiagnostic/patientDiagnosticsByVisit/${props.visitID}/${props.domain || ''}`);

    return (
        <Box sx={{ width: '100%', height: '100%', backgroundColor: '#fff', borderRadius: 3, p: 1 }}>
            {!loadingPatientDiagnosis && (
                <>
                    {' '}
                    {patientDiagnosis &&
                        patientDiagnosis?.map((item) => (
                            <>
                                {' '}
                                {item?.status == 'Continued' && (
                                    <>
                                        <Box sx={{ p: 1, m: 1, borderRadius: 3, backgroundColor: '#eef2f6' }}>
                                            <Box sx={{ display: 'flex' }}>
                                                <Typography sx={{ fontWeight: '600', flex: 1 }}>
                                                    {item?.code} {item?.medicineName}
                                                    {' ( '}
                                                    {item?.medicineGenericName}
                                                    {' )'}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </>
                                )}
                            </>
                        ))}
                </>
            )}
            {loadingPatientDiagnosis && (
                <Box sx={{ display: 'flex', flex: 1, justifyContent: 'center', alignContent: 'center' }}>
                    <CircularProgress size={35} color="primary" />
                </Box>
            )}
        </Box>
    );
};
const AutoCompleteCom = memo(({ data, onChange, value, placeholder, showKey, listName }) => {
    return (
        <div style={{ width: '100%' }}>
            <input
                list={listName}
                placeholder={placeholder}
                style={{
                    padding: 10,
                    borderRadius: 10,
                    border: '1px solid #bfc0c2',
                    fontSize: 14,
                    width: '100%',
                    backgroundColor: '#f8fafc'
                }}
                onChange={onChange}
                value={value}
            />
            <datalist id={listName}>
                {data &&
                    data.map((item, index) => (
                        <option
                            key={index}
                            value={`${item[showKey[0]]} ${listName == 'diagnosis' ? `: ${item[showKey[1]]} ( ${item[showKey[2]]} )` : ''}`}
                        />
                    ))}
            </datalist>
        </div>
    );
});
